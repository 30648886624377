import snakeCase from 'lodash/snakeCase';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import isObject from 'lodash/isObject';
import qs from 'qs';

function transformKeysToSnakeCase(params) {
  if (!isObject(params)) return params;

  return mapKeys(
    mapValues(params, value => transformKeysToSnakeCase(value)),
    (_, key) => snakeCase(key),
  );
}

export default function queryStringEncode(params) {
  const obj = transformKeysToSnakeCase(params);

  return qs.stringify(obj);
}
