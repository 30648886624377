import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('homeshieldStarterHomePackPage');

const component = {
  methods: {
    ...mapActions([
      'setPackSku',
    ]),
    scrollToPacks() {
      this.$scrollTo('.starter-home-packs');
    },
    showHomeshieldModal(sku) {
      this.setPackSku(sku);
      this.$modal.show('homeshield-modal');
      this.$nextTick(() => window.specEvents.push('homeshield-pack-modal.shown'));
    },
  },
};

export default component;
