import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('basket');
const { mapState: mapCheckoutState } = createNamespacedHelpers('checkout');

const component = {
  props: {
    isStatic: Boolean,
  },
  computed: {
    ...mapState(['basket', 'updating']),
    ...mapCheckoutState(['paymentMethod']),
    isShippingFree() {
      return this.basket.shippingTotal <= 0;
    },
    savingsVisible() {
      return this.basket.showHiveLiveSavings || this.basket.showVoucherSavings;
    },
    totalSavings() {
      return this.basket.savingsTotal;
    },
    subscriptionsVisible() {
      return this.basket.visibleSubscriptions.length > 0;
    },
    v12Selected() {
      return this.paymentMethod === 'v12';
    },
    showTotal() {
      const {
        upfrontTotalWithoutSubscriptions,
        benefitsPlusRedemptionJourney,
      } = this.basket;

      return upfrontTotalWithoutSubscriptions !== 0
        || benefitsPlusRedemptionJourney
        || this.v12Selected;
    },
    showOutstandingBalance() {
      return this.basket.oneOffPriceLater !== 0 || this.v12Selected;
    },
    productSubtotal() {
      if (this.v12Selected || this.basket.journey === 'installation_redemption') return 0;

      return this.basket.productSubtotal;
    },
    upfrontTotalWithoutSubscriptions() {
      return this.v12Selected ? 0 : this.basket.upfrontTotalWithoutSubscriptions;
    },
    oneOffPriceLater() {
      return this.v12Selected ? 0 : this.basket.oneOffPriceLater;
    },
  },
};
export default component;
