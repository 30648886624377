<template>
  <modal
    v-bind:name="modalName"
    classes="hive-modal quiz-modal"
    v-on:before-close="onClose"
  >
    <div class="modal-dialog modal-sm">
      <div
        v-if="isActive(MODAL_SCREENS.AUTHENTICATED)"
        class="modal-content px-3 pb-3"
      >
        <div class="modal-body text-center">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'32'" v-bind:height="'32'" v-bind:class="'d-block mx-auto mb-2'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><rect width="24" height="24"/><polyline stroke="#33495B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" transform="rotate(-180 12 12)" points="3 19 16 5 21 10"/></g></svg>

          <h4>Login successful</h4>
        </div>
      </div>

      <div
        v-if="isActive(MODAL_SCREENS.CONFIRM_EXIT)"
        class="modal-content px-3 pb-3 quiz-confirmation"
      >
        <div class="modal-body mb-4 text-center">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'32'" v-bind:height="'32'" v-bind:class="'d-block mx-auto mb-2'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><rect width="24" height="24"/><path d="M12.43 3c.806 0 1.222.338 1.196 1.196l-.442 11.284c-.026.416-.234.728-.754.728s-.754-.312-.78-.728l-.416-11.284C11.208 3.364 11.65 3 12.43 3zm0 17.914c-.884 0-1.43-.442-1.43-1.456s.546-1.482 1.43-1.482c.91 0 1.43.468 1.43 1.482s-.52 1.456-1.43 1.456z" fill="#33495B"/></g></svg>
          <h3>Exit quiz</h3>

          <p class="mb-5">
            Any progress will be lost if you choose to leave. Are you sure?
          </p>

          <button
            type="button"
            class="btn btn-secondary btn-block mb-2"
            v-on:click="closeModal"
          >
            Yes
          </button>

          <button
            type="button"
            class="btn btn-secondary btn-block"
            v-on:click="goTo(MODAL_SCREENS.QUIZ)"
          >
            Not now
          </button>
        </div>
      </div>

      <div
        v-if="isActive(MODAL_SCREENS.QUIZ)"
        class="modal-content"
      >
        <quiz-modal-close-button />
        <div
          id="quiz-container"
          class="modal-body quiz-modal-body"
        >
          <quiz-chat v-on:play-video="playVideo" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import makeModule from 'store/modules/quiz';
import { MODAL_SCREENS } from 'store/modules/quiz/router';
import QuizChat from './chat.vue';
import QuizModalCloseButton from './modal_close_button.vue';

const EXIT = 'exit';

const component = {
  props: {
    modalName: String,
    images: Object,
    authenticated: Boolean,
    loginUrl: String,
    searchParamName: String,
    searchParamValue: String,
    videoModalId: String,
    hdlCustomer: Object,
  },
  inject: ['dataLayer'],
  provide() {
    return {
      images: this.images,
      loginUrl: this.loginUrl,
    };
  },
  beforeCreate() {
    const module = makeModule({});

    this.$store.registerModule('quiz', module);

    const {
      mapMutations: mapQuizMutations,
      mapActions: mapQuizActions,
    } = createNamespacedHelpers('quiz');

    const {
      mapGetters: mapRouterGetters,
      mapActions: mapRouterActions,
    } = createNamespacedHelpers('quiz/router');

    this.$options.computed = {
      ...mapRouterGetters(['isActive']),
      // Expose it to template
      MODAL_SCREENS: () => ({ ...MODAL_SCREENS }),
    };

    this.$options.methods = {
      ...mapRouterActions(['goTo']),
      ...mapQuizActions(['reset', 'start']),
      ...mapQuizMutations(['authenticate']),
      closeModal() {
        this.$modal.hide(this.modalName, EXIT);
        this.reset();
      },
      onClose(event) {
        // Only exit if explicitly told so.
        if (event.params === EXIT) return;

        // Prevent closing by Esc button.
        event.stop();
        this.goTo(MODAL_SCREENS.CONFIRM_EXIT);
      },
      playVideo() {
        this.$modal.hide(this.modalName, EXIT);
        this.$modal.show(this.videoModalId);
      },
      completeAuthentication() {
        this.dataLayer.customer = this.hdlCustomer;

        // Hide the login step for authenticated users.
        this.authenticate();

        const url = new URL(window.location.href);
        const query = new URLSearchParams(url.search);

        if (query.get(this.searchParamName) !== this.searchParamValue) return;

        // Remove the query parameter from current url
        // so that the quiz modal doesn't show up again on page reload.
        query.delete(this.searchParamName);
        url.search = query.toString();
        window.history.pushState({ path: url.href }, '', url.href);

        this.goTo(MODAL_SCREENS.AUTHENTICATED);
        this.start();

        this.$nextTick(() => {
          this.$modal.show(this.modalName);
        });
      },
    };
  },
  beforeMount() {
    if (this.authenticated) this.completeAuthentication();
  },
  components: {
    QuizModalCloseButton,
    QuizChat,
  },
};

export default component;
</script>
