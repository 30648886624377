const component = {
  props: ['chatData', 'chatSettings', 'oldSettings'],
  mounted() {
    const initESW = (gslbBaseURL) => {
      window.embedded_svc.settings = Object.assign(window.embedded_svc.settings, this.chatSettings);

      if (this.oldSettings) {
        window.embedded_svc.settings.directToButtonRouting = (prechatFormData) => {
          let buttonId = '';

          const BTN_ID_LABEL = 'Snapins Button Routing ID';
          for (let i = 0; i < prechatFormData.length; i++) {
            if (prechatFormData[i].label === BTN_ID_LABEL) {
              buttonId = prechatFormData[i].value;
            }
          }
          return buttonId;
        };
      }

      window.embedded_svc.init(
        this.chatData.domain,
        this.chatData.liveAgentUrl,
        gslbBaseURL,
        this.chatData.orgId,
        this.chatData.snapinName,
        {
          baseLiveAgentContentURL: this.chatData.contenttUrl,
          deploymentId: this.chatData.deploymentId,
          buttonId: this.chatData.buttonId,
          baseLiveAgentURL: this.chatData.chatUrl,
          eswLiveAgentDevName: this.chatData.liveAgentDevName,
          isOfflineSupportEnabled: false,
        },
      );
    };
    if (!window.embedded_svc) {
      const s = document.createElement('script');
      s.setAttribute('src', this.chatData.jsUrl);
      s.onload = () => {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW('https://service.force.com');
    }
  },
};

export default component;
