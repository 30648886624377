<template>
  <div class="orders-progress-bar mb-5 mb-lg-8">
    <div class="orders-progress-bar-container">
      <div class="progress-bar" v-bind:style="{ width: `${width}%`}" />
    </div>
    <div class="orders-progress-bar-steps text-center">
      <div
        v-for="(step, index) in parsedSteps"
        v-bind:key="index"
        class="step"
        v-bind:class="{ completed: step.completed, active: step.active }"
      >
        <img
          v-if="step.active"
          class="svg-white svg-stroke-width-2"
          src="//images.ctfassets.net/mijf9lz5yt3u/7IUyhNQ5NpbfXSf2SZSpoi/1c6db3b54990b4f5dd0fadf599cac949/icon-tick.svg"
          width="12"
          height="12"
        >
        <div class="label">
          <span v-bind:class="step.labelClass">{{ step.name }}</span>
          <span v-if="step.active">{{ step.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const component = {
  props: {
    steps: {
      type: Array,
      required: true,
      default() { return []; },
    },
    currentStep: {
      required: true,
    },
    activeLabels: {
      type: Object,
      default() { return {}; },
    },
  },
  computed: {
    currentStepIndex() {
      return this.steps.indexOf(this.currentStep);
    },
    width() {
      if (this.currentStepIndex < 0) { return 0; }
      if (this.stepsCount <= 1) { return (this.currentStepIndex + 1) * 100; }

      return this.currentStepIndex / (this.stepsCount - 1) * 100;
    },
    stepsCount() {
      return this.steps.length;
    },
    parsedSteps() {
      return this.steps.map((step) => {
        const stepIndex = this.steps.indexOf(step);
        const active = stepIndex === this.currentStepIndex;
        const completed = stepIndex < this.currentStepIndex;
        const label = this.activeLabels[step];

        return {
          name: step,
          labelClass: [
            active ? 'text-blue fw-bolder' : 'text-gray-light',
            label ? 'd-block' : '',
          ],
          active,
          completed,
          label,
        };
      });
    },
  },
};

export default component;
</script>
