import Vue from 'vue/dist/vue.esm';
import orderBy from 'lodash/orderBy';
import spliceByProperty from 'utils/splice_by_property';

import {
  STATUSES,
  getters as statusGetters,
  mutations as statusMutations,
} from '../utils/status_state';
import {
  reload as reloadMutation,
  markAsPending as markAsPendingMutation,
  resetPending as resetPendingMutation,
} from '../utils/mutation_helpers';
import {
  hasItems as hasItemsGetter,
} from '../utils/getter_helpers';

export const RELOAD_PAYMENT_METHODS = 'RELOAD_PAYMENT_METHODS';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const MARK_AS_PENDING = 'MARK_AS_PENDING';
export const RESET_PENDING = 'RESET_PENDING';
export const UPDATE_HOSTED_PAGE = 'UPDATE_HOSTED_PAGE';

export const getters = {
  ...statusGetters,
  hasPaymentMethods: hasItemsGetter('paymentMethods'),
  sortedPaymentMethods: ({ paymentMethods }) => orderBy(
    paymentMethods,
    [
      pm => pm.isDefault,
      pm => pm.createdAt,
    ],
    ['desc', 'desc']
  ),
  hasDirectDebit: ({ paymentMethods }) => (paymentMethods || [])
    .some(pm => pm.isDirectDebit),
  defaultPaymentMethod: ({ paymentMethods }) => (paymentMethods || [])
    .find(pm => pm.isDefault),
  isDirectDebitCustomer: (_, { defaultPaymentMethod }) => (defaultPaymentMethod || {})
    .isDirectDebit,
};

export const mutations = {
  ...statusMutations,
  [RELOAD_PAYMENT_METHODS]: reloadMutation('paymentMethods'),
  [MARK_AS_PENDING]: markAsPendingMutation('paymentMethods', 'id'),
  [RESET_PENDING]: resetPendingMutation('paymentMethods'),
  [DELETE_PAYMENT_METHOD]({ paymentMethods }, paymentMethodId) {
    spliceByProperty(paymentMethods, 'id', paymentMethodId);
  },
  [UPDATE_HOSTED_PAGE](state, { hostedScriptUrl, hostedPageParams, hostedPagePrepopulateFields }) {
    Vue.set(state, 'hostedScriptUrl', hostedScriptUrl);
    Vue.set(state, 'hostedPageParams', hostedPageParams);
    Vue.set(state, 'hostedPagePrepopulateFields', hostedPagePrepopulateFields);
  },
};

const api = (context, client, apiMethod, pathArgs = {}) => {
  const { getters, commit } = context; // eslint-disable-line no-shadow

  if (getters.isPending) { return Promise.reject(); }

  commit(STATUSES.PENDING);
  if (pathArgs.paymentMethodId) {
    commit(MARK_AS_PENDING, pathArgs.paymentMethodId);
  }

  return client[apiMethod](pathArgs)
    .then((response) => {
      commit(STATUSES.SUCCESS);
      return response;
    })
    .catch((error) => {
      commit(STATUSES.FAILURE);
      throw error;
    })
    .finally(() => {
      commit(RESET_PENDING);
    });
};

const makeActions = ({ client }) => ({
  getPaymentMethods(
    context,
    {
      zuoraHostedPage: zuora_hosted_page = false,
      paymentMethods: payment_methods = 'all',
    }
  ) {
    return api(context, client, 'getPaymentMethods', { zuora_hosted_page, payment_methods })
      .then(({
        hostedScriptUrl,
        hostedPageParams,
        hostedPagePrepopulateFields,
        paymentMethods,
      }) => {
        context.commit(UPDATE_HOSTED_PAGE, {
          hostedScriptUrl,
          hostedPageParams,
          hostedPagePrepopulateFields,
        });
        context.commit(RELOAD_PAYMENT_METHODS, paymentMethods);
      });
  },
  savePaymentMethodForLater(context, { id: paymentMethodId }) {
    return api(context, client, 'savePaymentMethodForLater', { paymentMethodId })
      .then(({ paymentMethods }) => {
        context.commit(RELOAD_PAYMENT_METHODS, paymentMethods);
      });
  },
  deletePaymentMethod(context, { id: paymentMethodId }) {
    return api(context, client, 'deletePaymentMethod', { paymentMethodId })
      .then(() => {
        context.commit(DELETE_PAYMENT_METHOD, paymentMethodId);
      });
  },
  setDefaultPaymentMethod(context, { id: paymentMethodId }) {
    return api(context, client, 'setDefaultPaymentMethod', { paymentMethodId })
      .then(({ paymentMethods }) => {
        context.commit(RELOAD_PAYMENT_METHODS, paymentMethods);
      });
  },
});

const makeModule = (injections) => {
  const actions = makeActions(injections);

  return {
    namespaced: true,
    state: {
      hostedScriptUrl: '',
      hostedPageParams: {},
      hostedPagePrepopulateFields: {},
      paymentMethods: [],
    },
    getters,
    mutations,
    actions,
  };
};

export default makeModule;
