import { TYPE_CONFIRM } from '../constants';

const SLT5 = [
  {
    id: 1,
    text: 'switch the boiler off at the isolation switch/fuse spur (this should be located near their Hive thermostat next to their boiler)',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5sUIazFUCA0BjFx3EEqOX2/56a921555591432c7bd5d5d43c25f826/image1-31.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 2,
  },
  {
    id: 2,
    text: `Please press and hold down the 'back arrow' button and the 'menu' button on the thermostat at the same time (these are the bottom left and bottom middle buttons below the silver dial). Once holding down the buttons you will see a 10 second countdown appear, do not let go until this countdown disappears from the screen. You will then see the boot screen “Boot Vx.x.xx...” followed by ‘Welcome, searching’. If buttons aren’t released it will not go to “Searching”.  
      If correctly done the thermostat display says 'Welcome, searching'.  
      Now Switch the boiler back on at the isolation switch/fuse spur.  `,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/3fUZffo4ny5rpCu3hTkBte/a94f55f8cfb1c7e41c38022c4f332e61/buttons.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 3,
  },
  {
    id: 3,
    text: `Please wait until the 'Search' message disappears from the thermostat screen,
      the thermostat will then take you through a welcome tour.`,
    imageUrl: '',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
];

export default SLT5;


