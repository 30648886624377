import MyEVPage from 'mixins/my_ev_page';
import EvBookingDate from 'components/views/ev_booking_date';

const component = {
  mixins: [MyEVPage],
  computed: {
    currentOrderStatus() {
      return 'Engineer call';
    },
  },
  methods: {
    bookAppointment() {
      window.open(this.currentOrder.evJobOrders[0].vhcBookingLink);
      this.$emit('go-back');
    },
  },
  components: {
    EvBookingDate,
  },
};

export default component;
