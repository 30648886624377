import RequiredProductChoice from 'components/base/required_product_choice';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters } = createNamespacedHelpers('productBuy');

const OPTION_SINGLE = 'single';
const OPTION_DUAL = 'dual';
const OPTION_INITIAL = null;

const SWITCH_KEY_SINGLE_CHANNEL = 'single_channel';
const SWITCH_KEY_DUAL_CHANNEL = null;

// Hot water tank choice
const component = {
  extends: RequiredProductChoice,
  computed: {
    isSingleChannel() {
      return this.selected === OPTION_SINGLE;
    },
    isDualChannel() {
      return this.selected === OPTION_DUAL;
    },
    complete() {
      return this.selected !== OPTION_INITIAL;
    },
    ...mapGetters({
      available: 'hotWaterTankAvailable',
    }),
    ...mapState({
      sku({ product }) {
        return [].concat(product.sku)[0];
      },
    }),
  },
  data() {
    return {
      requirement: 'hasWaterTankRequirement',
      selected: OPTION_INITIAL,
      switchKey: SWITCH_KEY_DUAL_CHANNEL,
    };
  },
  methods: {
    choiceEventData() {
      return { sku: this.sku, callback: this.emitCompleteSpecEvent };
    },
    chooseSingleChannel() {
      this.switchKey = SWITCH_KEY_SINGLE_CHANNEL;
      this.onSelect(OPTION_SINGLE);
    },
    chooseDualChannel() {
      this.switchKey = SWITCH_KEY_DUAL_CHANNEL;
      this.onSelect(OPTION_DUAL);
    },
    reset() {
      this.switchKey = SWITCH_KEY_DUAL_CHANNEL;
      this.selected = OPTION_INITIAL;
    },
    afterUpdateChanges() {
      this.dataToLocalStorage();
      this.updateRequirement();
    },
  },
};

export default component;
