/*
  Modal container component that wraps vue modal components.
  This becomes useful when we need event handlers or other custom logic to interact with
  elements in modals.
  Ajax requests to submit form data, events that hide/show elements after user actions,
  cookie manipulation etc.
  Some modals using this component: geo ip redirect, multizone and nhs discount.
*/
const component = {
  name: 'modal-container',
  inject: ['cookies', 'client'],
  props: {
    target: { type: String, required: true },
    openOnMount: { type: Boolean, default: false },
    dataInit: { type: Object, default: {} },
  },
  data() {
    return {
      apiCallSuccessful: undefined,
      ...this.dataInit,
    };
  },
  methods: {
    openModal() {
      this.$modal.show(this.target);
    },
    closeModal(opts = {}) {
      const { setCookiesOnClose } = opts;

      if (setCookiesOnClose) {
        setCookiesOnClose.forEach(
          ({ cookieName, cookieValue, expires }) => {
            if (cookieName) this.cookies.set(cookieName, cookieValue, { expires: (expires || '1d') });
          }
        );
      }

      this.$modal.hide(this.target);
    },
    apiCallTo(endPoint, data) {
      return this.client[endPoint]({}, data)
        .then(() => {
          this.apiCallSuccessful = true;
        })
        .catch(() => {
          this.apiCallSuccessful = false;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.openOnMount) this.$modal.show(this.target);
    });
  },
};

export default component;
