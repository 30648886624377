import { createNamespacedHelpers } from 'vuex';
import HoneyCameraColours from 'components/views/honey/camera_colours';
import isEmpty from 'lodash/isEmpty';

const { mapState } = createNamespacedHelpers('bgRewardsPackBuyPage');
const { mapState: mapPackBuyState } = createNamespacedHelpers('packBuyPage');

const component = {
  props: {
    initialSku: String,
    whiteCameraSku: String,
    blackCameraSku: String,
    title: String,
    initialQuantity: Number,
    price: Number,
    discountedPrice: Number,
    quantityLocked: {
      type: Boolean,
      default: false,
    },
    titleOptions: {
      type: Object,
      default() { return {}; },
    },
  },
  data() {
    return {
      sku: this.initialSku,
      quantity: this.initialQuantity,
    };
  },
  computed: {
    ...mapState([
      'hubOption',
      'colour',
      'packSkuMappings',
    ]),
    ...mapPackBuyState([
      'homeshieldOption',
    ]),
    showPackItem() {
      return !this.isHubItemAndHublessPack;
    },
    isWhiteCamera() {
      return (this.isBgRewardsPack && this.colour === 'white')
        || (!this.isBgRewardsPack && this.sku === this.whiteCameraSku);
    },
    isBlackCamera() {
      return (this.isBgRewardsPack && this.colour === 'black')
        || (!this.isBgRewardsPack && this.sku === this.blackCameraSku);
    },
    isBgRewardsPack() {
      return Object.keys(this.packSkuMappings).length !== 0;
    },
    isHubItemAndHublessPack() {
      return this.isBgRewardsPack
        && this.hubOption === 'hubless'
        && this.sku === 'HCD00010';
    },
    itemTitle() {
      if (!isEmpty(this.titleOptions)) {
        const stateKey = this.titleOptions.key;
        const stateValue = this[stateKey];
        return this.titleOptions.options[stateValue];
      }

      return this.title;
    },
  },
  methods: {
    changeSku(newSku) {
      this.sku = newSku;
    },
    increment() {
      this.quantity = this.quantity + 1;
    },
    decrement() {
      this.quantity = this.quantity - 1;
    },
    remove() {
      this.quantity = 0;
    },
  },
  watch: {
    sku(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('swap-sku', newValue, oldValue);
      }
    },
    quantity(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('change-quantity', this.sku, newValue);
      }
    },
  },
  components: {
    HoneyCameraColours,
  },
};

export default component;
