import { createNamespacedHelpers } from 'vuex';

import productBuyPageMethods from 'components/base/product_buy_page/methods';
import BasketSummary from 'components/views/basket_summary';
import BulbFitting from 'components/views/bulb_fitting';
import CameraCount from 'components/views/camera_count';
import ColourSelection from 'components/views/colour_selection';
import ConnectToBasket from 'components/base/connect_to_basket';
import HahInstallation from 'components/views/hah_installation';
import HotWaterTank from 'components/views/hot_water_tank';
import HubHubless from 'components/views/hub_hubless';
import HdlPromotion from 'components/views/hdl_promotion';
import OwlCarousel from 'components/views/carousel';
import ProductGallery from 'components/views/product_gallery';
import ProductInstallation from 'components/views/product_installation';
import ProductPrice from 'components/base/product_price';
import ProductsIncluded from 'components/views/products_included';
import SelectBoilerAndCounty from 'components/views/select_boiler_and_county';
import ThermostatCompatibility from 'components/views/thermostat_compatibility';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import MultipackSelector from 'components/views/multipack_selector';
import NowWithHiveVideoPlayback from 'components/views/now_with_hive_video_playback';
import FreeEchoDot from 'components/views/free_echo_dot';

const mapProductState = createNamespacedHelpers('productBuy').mapState;
const mapBasketState = createNamespacedHelpers('basket').mapState;

const component = {
  inject: ['dataLayer', 'eventBus', 'client'],
  props: {
    dataLayerProductLoad: Boolean,
    productType: {
      type: String,
      required: true,
    },
  },
  components: {
    'basket-summary': BasketSummary,
    'colour-selection': ColourSelection,
    'connect-to-basket': ConnectToBasket,
    'hah-installation': HahInstallation,
    'hot-water-tank': HotWaterTank,
    'hub-hubless': HubHubless,
    'hdl-promotion': HdlPromotion,
    'free-echo-dot': FreeEchoDot,
    'owl-carousel': OwlCarousel,
    'products-included': ProductsIncluded,
    'product-gallery': ProductGallery,
    'product-installation': ProductInstallation,
    'product-price': ProductPrice,
    'thermostat-compatibility': ThermostatCompatibility,
    'trust-pilot-popup': TrustPilotPopup,
    'camera-count': CameraCount,
    'bulb-fitting': BulbFitting,
    'select-boiler-and-county': SelectBoilerAndCounty,
    'multipack-selector': MultipackSelector,
    'now-with-hive-video-playback': NowWithHiveVideoPlayback,
  },
  computed: {
    ...mapBasketState({
      currency: state => state.basket.currency,
      locale: state => state.basket.locale,
    }),
    ...mapProductState({
      productSku: state => state.product.sku,
      loading: state => state.updating,
      options: state => state.options,
      buyButtonAvailable: state => state.options.buyButtonAvailable,
      description: state => state.description,
      name: state => state.name,
    }),
  },
  methods: {
    ...productBuyPageMethods,
  },
  data() {
    return {
      hide: {
        'v3-colour-selection': false,
        'v3-camera-count': false,
      },
      requirements: {
        'v3-product-installation': 'hasProductInstallationRequirement',
        'v3-camera-installation': 'hasProductInstallationRequirement',
        'v3-security-installation': 'hasProductInstallationRequirement',
        'v3-colour-selection': 'hasProductColorRequirement',
        'v3-camera-count': 'hasCameraCountRequirement',
      },
      productOptions: {},
    };
  },
  created() {
    const { getProduct } = this.client;
    this.eventBus.subscribeToBulbFittingUpdate(this.onBulbFittingUpdate(getProduct));
    this.eventBus.subscribeToMultipackUpdate(this.onMultipackUpdate(getProduct));
  },
  mounted() {
    // TODO Can we do this in the store?
    if (this.dataLayerProductLoad) {
      this.dataLayer.productLoaded();
    }
    // Dispatch the action after all child components are mounted.
    // this.$nextTick(() => {
    //
    // });
  },
};

export default component;
