import Vue from 'vue/dist/vue.esm';

const COLOUR_BLACK = 'black';
const COLOUR_WHITE = 'white';

const normalize = (value) => {
  const result = String(value).toLowerCase();

  if ([COLOUR_BLACK, COLOUR_WHITE].includes(result)) return result;

  return null;
};

const vuexModule = {
  namespaced: true,
  state() {
    return {
      selected: null,
    };
  },
  mutations: {
    reset(state) {
      Vue.set(state, 'selected', null);
    },
    select(state, value) {
      const normalizedValue = normalize(value);

      if (normalizedValue === null) return;

      Vue.set(state, 'selected', normalizedValue);
    },
  },
  getters: {
    indoorCameraWhiteQty({ selected }) {
      if (selected === COLOUR_WHITE) return 1;

      return 0;
    },
    indoorCameraBlackQty({ selected }) {
      if (selected === COLOUR_BLACK) return 1;

      return 0;
    },
  },
};

export default vuexModule;
