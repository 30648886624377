<template>
  <div>
    <div class="d-flex justify-content-between">
      <h5>{{ paymentMethod.type }}</h5>
      <hires-image v-bind:image="paymentMethod.cardImage" classes="" />
    </div>
    <div v-if="paymentMethod.isDirectDebit">
      Account: {{ paymentMethod.accountNumber }}
    </div>
    <div v-if="!paymentMethod.isDirectDebit">
      {{ paymentMethod.cardNumber }}
    </div>
    <div v-if="paymentMethod.isDirectDebit">
      Sort code: {{ paymentMethod.sortCode }}
    </div>
    <div v-if="!paymentMethod.isDirectDebit">
      Exp: {{ paymentMethod.expiryDate }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paymentMethod: {
      type: Object,
      default() { return {}; },
    },
  },
};
</script>
