import compose from 'lodash/fp/compose';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import constant from 'lodash/fp/constant';
import cond from 'lodash/fp/cond';
import isObject from 'lodash/fp/isObject';
import T from 'lodash/fp/stubTrue';
import identity from 'lodash/fp/identity';
import over from 'lodash/fp/over';
import join from 'lodash/fp/join';
import isEqual from 'lodash/fp/isEqual';
import includes from 'lodash/fp/includes';

const isDebug = isEqual('debug');
const isError = isEqual('error');
const isWarn = isEqual('warning');
const isInfo = isEqual('info');

const getAcceptedLevels = cond([
  [isDebug, constant(['debug', 'error', 'warning', 'info'])],
  [isError, constant(['error'])],
  [isWarn, constant(['error', 'warning'])],
  [isInfo, constant(['info'])],
  [T, constant([])],
]);

// a very minimal logger inspired by winston
const createLogger = (options) => {
  const transports = options.transports || [];
  return {
    error(message, payload) {
      this.log('error', message, payload);
    },
    warn(message, payload) {
      this.log('warning', message, payload);
    },
    info(message, payload) {
      this.log('info', message, payload);
    },
    debug(message, payload) {
      this.log('debug', message, payload);
    },
    log(level, message, payload) {
      const data = payload ? { ...payload, level, message } : { level, message };
      transports.forEach((transport) => {
        const acceptedLevels = getAcceptedLevels(transport.level);
        const isAccepted = actualLevel => includes(actualLevel, acceptedLevels);
        if (isAccepted(level) && !transport.silent) {
          transport.log(data);
        }
      });
    },
  };
};

const createConsoleTransport = (options) => {
  const { format } = options;
  return {
    ...options,
    log(payload) {
      // eslint-disable-next-line
      console.log(format(payload));
      if (payload.message.stack) {
        // `format(payload)` makes the stack trace unreadable
        // eslint-disable-next-line
        console.log(payload.message.stack);
      }
    },
  };
};

const env = process.env.NODE_ENV;

const logTimestamp = get('timestamp');
const logEnv = constant(`[${env}]`);
const logLevel = compose(
  cond([
    [isDebug, constant('🐞')],
    [isError, constant('☠️')],
    [isWarn, constant('⚠️')],
    [isInfo, constant('ℹ️')],
    [T, constant('❓')],
  ]),
  get('level'),
);
const logMessage = compose(
  cond([
    [isObject, obj => JSON.stringify(obj, null, 2)],
    [T, identity],
  ]),
  getOr('', 'message'),
);

const loggerCustomFormat = compose(
  join(' '),
  over([logLevel, logEnv, logTimestamp, logMessage]),
);

const addTimestamp = payload => ({
  ...payload,
  timestamp: (new Date()).toISOString(),
});

const isProduction = process.env.NODE_ENV === 'production';

const loggerFormat = compose(loggerCustomFormat, addTimestamp);

const logger = createLogger({
  transports: [
    createConsoleTransport({
      format: loggerFormat,
      level: 'debug',
      silent: isProduction,
    }),
  ],
});

export default logger;
