import { createNamespacedHelpers } from 'vuex';

import get from 'lodash/get';

const { mapState } = createNamespacedHelpers('productBuy');

// Products included section
const component = {
  computed: {
    ...mapState({
      renderable(state) {
        return get(state, ['widgets', 'v3-free-echo-dot', 'renderable'], false) !== false;
      },
    }),
  },
};

export default component;
