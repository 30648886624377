import * as Cookies from 'tiny-cookie';

const ENABLED = '1';
const DISABLED = '0';

class ABTest {
  constructor(cookie, block = () => {}) {
    this.cookie = cookie;
    this.block = block;

    this.setDefault();
  }

  get state() {
    return Cookies.get(this.cookie);
  }

  set state(val) {
    Cookies.set(this.cookie, val);
  }

  isActive() {
    return String(this.state) === ENABLED;
  }

  enable(doReloadOrRedirectToUrl = true) {
    if (this.isActive()) { return; }
    this.state = ENABLED;
    this.block.call();

    // The logic of passing a redirect URL is quite specific to https://jira.bgchtest.info/browse/HWS-1246
    // Instead of this generic class getting too complicated,
    // we should probably extract a ABTest class for hah_options specifically
    if (typeof doReloadOrRedirectToUrl === 'boolean') {
      if (doReloadOrRedirectToUrl) { window.location.reload(); }
    } else if (typeof doReloadOrRedirectToUrl === 'string') {
      window.location.href = doReloadOrRedirectToUrl;
    }
  }

  disable(reload) {
    this.state = DISABLED;
    if (reload) { window.location.reload(); }
  }

  setDefault() {
    if (this.state === null) {
      this.disable();
    }
  }
}

export default ABTest;
