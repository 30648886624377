import { createNamespacedHelpers } from 'vuex';
import HoneyProductColours from 'components/views/honey/product_colours';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import AddToBasketButton from 'mixins/add_to_basket_button';
import HoneyOptionSelector from 'components/views/honey/option_selector';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('simpleProductBuyPage');
const { mapState: mapBasketState } = createNamespacedHelpers('basket');

const component = {
  inject: ['config', 'client'],
  mixins: [AddToBasketButton],
  props: {
    initialSku: String,
    homeshieldSku: String,
    thermostatStandSku: String,
    thermostatMiniStandSku: String,
    showInstallationPage: Boolean,
    supportsHomeshield: Boolean,
    includeRequirementsInBasket: Boolean,
    namespace: { // required by the installation_widget
      type: String,
      default: '_unused',
    },
    baseInstallationOption: String,
    loadProfessionalInstallationPrice: Boolean,
    baseProfessionalInstallationSku: String,
  },
  data() {
    return {
      buyAllThreeButtonLoading: false,
      buttonDisabled: false,
    };
  },
  computed: {
    ...mapBasketState(['basket']),
    ...mapState([
      'quantity',
      'isPriceLoading',
      'pricing',
      'installationOption',
    ]),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'monthlyPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
      'regularMonthlyPrice',
      'defaultInstallationOption',
      'professionalInstallationPrice',
      'professionalInstallationSku',
      'sku',
    ]),
    discountBadge() {
      if (!this.hasDiscount) {
        return '';
      }

      let currencySymbol;
      if (this.basket.marketScope === 'uk') currencySymbol = '£';
      else currencySymbol = '€';

      return `Save ${currencySymbol}${this.totalDiscount.toFixed(2)}`;
    },
    isFreeDeliveryVisible() {
      return this.totalDiscountedPrice > this.config.deliveryThreshold;
    },
  },
  created() {
    const {
      initialSku,
      homeshieldSku,
      showInstallationPage,
      includeRequirementsInBasket,
      baseInstallationOption,
      baseProfessionalInstallationSku,
    } = this;
    const params = {
      sku: initialSku,
      homeshieldSku,
      showInstallationPage,
      includeRequirementsInBasket,
      professionalInstallationSku: baseProfessionalInstallationSku,
    };

    if (baseInstallationOption) {
      params.installationOption = baseInstallationOption;
    }

    this.init(params);
  },
  methods: {
    ...mapActions([
      'init',
      'increment',
      'decrement',
      'setInstallationOption',
      'loadPricing',
      'setProductSku',
    ]),
    onBuyThreeButtonClick(buyAllTwoSku) {
      this.buyAllThreeButtonLoading = true;
      this.buttonDisabled = true;

      const showInstallationPage = false;
      const { homeshieldSku } = this;

      const productBuy = {
        product: {
          sku: [
            { sku: buyAllTwoSku, quantity: 1 },
            { sku: homeshieldSku, quantity: 1 },
          ],
          showInstallationPage,
        },
      };
      this.addToBasket({ productBuy, quantity: 1, errorCallback: this.scrollToTop });
    },
    moveToSlide(slideIndex) {
      if (this.$refs.carousel) {
        this.$refs.carousel.goToPage(slideIndex);
      }
    },
  },
  components: {
    TrustPilotPopup,
    HoneyProductColours,
    HoneyOptionSelector,
  },
};

export default component;
