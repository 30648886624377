import MinimumStarReviews from 'mixins/minimum_star_reviews';
import TrustpilotAverageRating from 'mixins/trustpilot_average_rating';

const component = {
  mixins: [MinimumStarReviews, TrustpilotAverageRating],
  inject: ['client'],
  props: {
    apiKey: { type: String, required: true },
    skus: { type: String, required: true },
    starIconPaths: { type: Array, required: true },
  },
  data() {
    return {
      target: 'trust-pilot-cards',
      reviews: [],
      perPage: 100,
      locale: 'en-GB',
      page: 0,
      total: 0,
      roundedStarsAverage: 0,
      starLabels: ['Bad', 'Poor', 'Average', 'Great', 'Excellent'],
    };
  },
  created() {
    this.getSummary();
    this.getReviews();
  },
};

export default component;
