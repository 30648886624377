// Redirect to a given url
// this component should wrap an anchor element or similar

const component = {
  inject: ['client'],
  props: {
    // url to be directed, if defined it overrides default
    to: {
      type: String,
    },
    // if true set geoip cookie
    disableGeoip: {
      type: String,
    },
    // true if link is already active
    isActive: {
      type: Boolean,
    },
    // apply redirect automatically
    auto: {
      type: Boolean,
    },
  },
  data() {
    return {
      redirecting: false,
    };
  },
  methods: {
    goTo(event) {
      // use to prop or currentTarget href if not defined
      const url = this.to || event.currentTarget.href;

      this.redirecting = true;
      this.$emit('redirect', url);
      this.client.goTo(url, this.disableGeoip);
    },
  },
  mounted() {
    if (this.auto) {
      this.goTo();
    }
  },
};

export default component;
