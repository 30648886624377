<template>
  <h5 class="mb-0">
    {{ product.name }}
    <span v-bind:class="{ 'text-reset': !nowrap, 'd-block': !nowrap }">Pro-Install</span>
  </h5>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
