import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const moduleGetters = {
  ...baseGetters,
  pricingPayload({ sku, hubSku, quantity }) {
    const products = [{ sku, quantity }];

    if (hubSku) products.push({ sku: hubSku, quantity: 1 });

    return { products, sku };
  },
  basketPayload(state) {
    const {
      sku,
      hubSku,
      quantity,
    } = state;

    const skus = [{ sku, quantity }];

    if (hubSku) {
      skus.push({ sku: hubSku, quantity: 1 });
    }

    const productBuy = { product: { sku: skus } };

    return { productBuy, quantity: 1 };
  },
};

const mutations = {
  ...baseMutations,
  setHubSku(state, sku) {
    Vue.set(state, 'hubSku', sku);
  },
  setProductType(state, productType) {
    Vue.set(state, 'productType', productType);
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  setSensorSku({ commit, dispatch }, sku) {
    commit('setProductSku', { sku });

    return dispatch('loadPricing');
  },
  setHubSku({ commit, dispatch }, sku) {
    commit('setHubSku', sku);

    return dispatch('loadPricing');
  },
  setProductType({ commit }, productType) {
    commit('setProductType', productType);
  },
  init({ commit, dispatch }, { sku, hubSku }) {
    commit('setHubSku', hubSku);
    commit('setProductSku', { sku });

    return dispatch('loadPricing');
  },

});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      sku: null,
      hubSku: null,
      isPriceLoading: false,
      quantity: 1,
      pricing: {},
      productType: 'motion_sensor',
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
