import ZuoraHostedPage from 'components/views/zuora_hosted_page';

const component = {
  data() {
    return {
      paymentMethodId: null,
      error: null,
    };
  },
  methods: {
    onZuoraSubmit() {
      this.error = null;
    },
    onZuoraSuccess(paymentMethodId, response) {
      console.log(response); // eslint-disable-line no-console
      this.paymentMethodId = paymentMethodId;
    },
    onzZuoraError(key, code, message) {
      console.log(key, code, message); // eslint-disable-line no-console
      this.error = message.replace(/&nbsp;/g, '');
    },
  },
  components: {
    'zuora-hosted-page': ZuoraHostedPage,
  },
};

export default component;
