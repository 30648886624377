import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const FITTING_OPTIONS = ['screw', 'bayonet'];
const BULB_OPTIONS = ['dimmable', 'cool_to_warm', 'colour_changing'];
const MULTIPACK_OPTIONS = ['x1', 'x2', 'x3', 'x5', 'x6', 'x10'];
const HUB_OPTIONS = ['hub', 'hubless'];

const calculateDiscount = (pack, packPrice, basePrice, product) => {
  if (pack.price === pack.discounted_price) {
    const quantity = parseInt(product.substring(1), 10);

    return basePrice * quantity - packPrice;
  }

  return pack.price - pack.discounted_price;
}

const mutations = {
  ...baseMutations,
  setPdpLightsSkuMappings(state, pdpLightsSkuMappings) {
    Vue.set(state, 'pdpLightsSkuMappings', { ...pdpLightsSkuMappings });
  },
  setProductMultipacks(state, productMultipacks) {
    Vue.set(state, 'productMultipacks', { ...productMultipacks });
  },
  setPdpLightsSku(state, sku) {
    Vue.set(state, 'sku', sku);
  },
  setFittingType(state, option) {
    if (!FITTING_OPTIONS.includes(option)) throw new Error(`Invalid fitting option: ${option}`);

    Vue.set(state, 'fittingType', option);
  },
  setBulbType(state, option) {
    if (!BULB_OPTIONS.includes(option)) throw new Error(`Invalid bulb option: ${option}`);

    Vue.set(state, 'bulbType', option);
  },
  setMultipackType(state, type) {
    Vue.set(state, 'multipackType', type);
  },
  setHubSku(state, sku) {
    Vue.set(state, 'hubSku', sku);
  },
  setHubOption(state, option) {
    if (!HUB_OPTIONS.includes(option)) throw new Error(`Invalid hub option: ${option}`);

    Vue.set(state, 'hubOption', option);
  },
};

const moduleGetters = {
  ...baseGetters,
  sku(state, { pdpLights }) {
    return pdpLights.sku;
  },
  pdpLights({
    fittingType, bulbType, pdpLightsSkuMappings, multipackType, hubOption,
  }) {
    return pdpLightsSkuMappings[multipackType][bulbType][fittingType][hubOption];
  },
  multipack({
    fittingType, bulbType, pdpLightsSkuMappings, hubOption,
  }) {
    return pdpLightsSkuMappings[MULTIPACK_OPTIONS[0]][bulbType][fittingType][hubOption];
  },
  multipackHasDiscount(state, { multipack }) {
    return multipack.discounted_price && multipack.discounted_price !== multipack.price;
  },
  multipackBigger({
    fittingType, bulbType, pdpLightsSkuMappings, productMultipacks, hubOption,
  }) {
    return pdpLightsSkuMappings[productMultipacks[1]][bulbType][fittingType][hubOption];
  },
  multipackBiggest({
    fittingType, bulbType, pdpLightsSkuMappings, productMultipacks, hubOption,
  }) {
    return pdpLightsSkuMappings[productMultipacks[2]][bulbType][fittingType][hubOption];
  },
  multipackPrice(state, { multipack }) {
    return multipack.discounted_price;
  },
  multipackPriceBigger(state, { multipackBigger }) {
    return multipackBigger.discounted_price;
  },
  multipackPriceBiggest(state, { multipackBiggest }) {
    return multipackBiggest.discounted_price;
  },
  multipackSave(state, { multipack, multipackPrice, productMultipacks }) {
    return calculateDiscount(multipack, multipackPrice, multipackPrice, productMultipacks[0]);
  },
  multipackSaveBigger(state, {
    multipackBigger, multipackPrice, multipackPriceBigger, productMultipacks,
  }) {
    return calculateDiscount(
      multipackBigger, multipackPriceBigger, multipackPrice, productMultipacks[1]
    );
  },
  multipackSaveBiggest(state, {
    multipackBiggest, multipackPrice, multipackPriceBiggest, productMultipacks,
  }) {
    return calculateDiscount(
      multipackBiggest, multipackPriceBiggest, multipackPrice, productMultipacks[2]
    );
  },
  basketPayload(state, { sku, hubSku, quantity }) {
    const skus = [{ sku, quantity }];

    if (hubSku) {
      skus.push({ sku: hubSku, quantity: 1 });
    }

    const productBuy = { product: { sku: skus } };

    return { productBuy, quantity: 1 };
  },
  pricingPayload(state, { sku, hubSku, quantity }) {
    const products = [{ sku, quantity }];

    if (hubSku) products.push({ sku: hubSku, quantity: 1 });

    return { products, sku };
  },
  fittingType({ fittingType }) {
    return fittingType;
  },
  bulbType({ bulbType }) {
    return bulbType;
  },
  multipackType({ multipackType }) {
    return multipackType;
  },
  quantity({ quantity }) {
    return quantity;
  },
  hubSku({ hubSku }) {
    return hubSku;
  },
  productMultipacks({ productMultipacks }) {
    return productMultipacks;
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  init({ commit, dispatch }, {
    pdpLightsSkuMappings, productMultipacks, hubSku, multipackTypeInitial, bulbTypeInitial,
    fittingTypeInitial,
  }) {
    commit('setPdpLightsSkuMappings', pdpLightsSkuMappings);
    commit('setProductMultipacks', productMultipacks);
    commit('setHubSku', hubSku);
    commit('setBulbType', bulbTypeInitial);
    commit('setFittingType', fittingTypeInitial);
    commit('setMultipackType', multipackTypeInitial);

    return dispatch('loadPricing');
  },
  setFittingType({ commit, dispatch }, selected) {
    commit('setFittingType', selected);

    return dispatch('loadPricing');
  },
  setBulbType({ commit, dispatch }, selected) {
    commit('setBulbType', selected);

    return dispatch('loadPricing');
  },
  setMultipackType({ commit, dispatch }, selected) {
    commit('setMultipackType', selected);

    return dispatch('loadPricing');
  },
  setHubSku({ commit, dispatch }, sku) {
    commit('setHubSku', sku);

    return dispatch('loadPricing');
  },
  setHubOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setHubOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      pdpLightsSkuMappings: {},
      sku: null,
      fittingType: FITTING_OPTIONS[0],
      bulbType: BULB_OPTIONS[0],
      multipackType: MULTIPACK_OPTIONS[0],
      hubOption: HUB_OPTIONS[0],
      quantity: 1,
      productMultipacks: [],
      hubSku: null,
      pricing: {},
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
