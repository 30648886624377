import { createNamespacedHelpers } from 'vuex';

const component = {
  props: {
    widgetId: String,
    currentSelection: String,
    emitEvent: String,
    disabled: Boolean,
    namespace: {
      type: String,
      default: 'heatingBuyPage',
    },
    reverseOrder: {
      type: Boolean,
      default: false,
    },
    professionalInstallationPrice: Number,
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);

    this.$options.computed = {
      ...mapState(['installationType', 'hubOption', 'tetherOption']),
      isHubless() {
        return this.hubOption === 'hubless';
      },
      isSelfInstall() {
        return this.installationType === 'self_install';
      },
      isProInstall() {
        return this.installationType === 'pro';
      },
    };
  },
  methods: {
    onSelect(selected) {
      const specEvent = `productWidgets.${this.widgetId}.complete`;
      this.$emit(this.emitEvent, { selected, specEvent });
    },
  },
};

export default component;
