import ValidatedForm from 'mixins/validated_form';

const mixin = {
  mixins: [ValidatedForm],
  inject: ['dataLayer'],
  props: {
    marketingPreferenceWithNoContact: {
      type: Boolean,
      default: true,
    },
    dmpgSubmitEvent: String,
    initialTitle: String,
    initialFirstName: String,
    initialLastName: String,
    initialEmail: String,
    initialPhone: String,
    initialEmailPreference: {
      type: Boolean,
      default: false,
    },
    initialTextPreference: {
      type: Boolean,
      default: false,
    },
    initialPhonePreference: {
      type: Boolean,
      default: false,
    },
    initialNoContactPreference: {
      type: Boolean,
      default: false,
    },
    initialTermsAndConditions: {
      type: Boolean,
      default: false,
    },
    recaptchaAction: String,
  },
  data() {
    return {
      title: this.initialTitle,
      firstName: this.initialFirstName,
      lastName: this.initialLastName,
      email: this.initialEmail,
      phone: this.initialPhone,
      termsAndConditions: this.initialTermsAndConditions,
      inDayEngineerQuoteCheck: false,
      emailPreference: this.initialEmailPreference,
      textPreference: this.initialTextPreference,
      phonePreference: this.initialPhonePreference,
      noContactPreference: this.initialNoContactPreference,
      recaptchaToken: null,
      recaptchaTokenIntervalID: null,
    };
  },
  mounted() {
    if (this.recaptchaAction) {
      this.setRecaptcha();
      // refresh recaptcha token each 2 min;
      this.recaptchaTokenIntervalID = setInterval(this.setRecaptcha, 2 * 60 * 1000);

      setTimeout(() => {
        const recaptcha = this.$recaptchaInstance;
        if (recaptcha) recaptcha.showBadge();
      }, 2000);
    }
  },
  unmounted() {
    if (this.recaptchaTokenIntervalID) {
      clearInterval(this.recaptchaTokenIntervalID);
    }
  },
  computed: {
    marketingPreferenceRequired() {
      return this.marketingPreferenceWithNoContact;
    },
    marketingPreferenceSelected() {
      return this.emailPreference
        || this.textPreference
        || this.phonePreference
        || this.noContactPreference;
    },
    isSubmitButtonDisabled() {
      const {
        validated,
        invalid,
        dirty,
        pending,
      } = this.validation;
      const validatedOrDirty = validated || dirty;
      const marketingPreferenceInvalid = (
        this.marketingPreferenceRequired && !this.marketingPreferenceSelected
      );

      return this.submitting
        || !this.customSubmitButtonValidation
        || invalid
        || pending
        || (validatedOrDirty && (invalid || marketingPreferenceInvalid));
    },
    customSubmitButtonValidation() {
      // overwrite in inheriting component
      return true;
    },
  },
  methods: {
    async setRecaptcha() {
      await this.$recaptchaLoaded();
      this.recaptchaToken = await this.$recaptcha(this.recaptchaAction);
    },
    isMarketingPreferenceDisabled() {
      return false;
    },
    beforeSubmit() {
      this.dataLayer.triggerCartEvent(this.dmpgSubmitEvent);
    },
    onDevPopulate(data) {
      Object.assign(this, data);
      this.$refs.termsAndConditions.click();
      if (this.$refs.noContactPreference && !this.initialNoContactPreference) {
        this.$refs.noContactPreference.click();
      }
    },
    marketingPreferenceChange(fieldName) {
      if (fieldName === 'noContactPreference') {
        this.resetContactPreferences();
      } else {
        this.resetNoContactPreference();
      }
      window.specEvents.push('marketingPreferenceChanged');
    },
    resetNoContactPreference() {
      this.noContactPreference = false;
    },
    resetContactPreferences() {
      this.emailPreference = false;
      this.textPreference = false;
      this.phonePreference = false;
    },
  },
};

export default mixin;
