// Within integration tests, we often need to be able to tell
// that some kind of async event completed. Generic checks for
// generic events are not reliable, therefore allow application
// code to emit custom events and then let specs "listen" for them.
//
// The instance of this class is used by Rspec in `expect_spec_event`
//
// Usage:
//
//  window.specEvents.push('myEvent');
//  window.specEvents.happend('myEvent'); => # true
//  window.specEvents.happend(/my*vent/); => # true
//
export default class SpecEvents {
  constructor() {
    this.events = [];
  }

  // Remember an event
  push(event) {
    // Use setTimeout to ensure this is visible only on next event loop
    setTimeout(() => { this.events.push(event); }, 50);
  }

  // Check if event happened by name
  happened(expectedEvent) {
    // Make a copy of the events array, to prevent race conditions
    const events = this.events.slice();
    const index = events.findIndex(event => event.match(expectedEvent));
    const happened = index >= 0;

    // Reset if we found what we looked for
    // This ensures the list is empty for future events and assertions
    if (happened) {
      events.splice(index, 1);
      this.events = events;
    }

    return happened;
  }
}
