const component = {
  props: {
    heatingPlusType: String,
    discountBadge: String,
  },
  methods: {
    onSelect(selected) {
      this.$emit('change-heating-plus', selected);
      if (selected === 'no') { this.$modal.show('heating-plus-benefits-modal'); }
    },
  },
};

export default component;
