import orderBy from 'lodash/orderBy';

const getters = {
  isBasketEmpty: (state, { lineItems }) => lineItems.length === 0,
  getAllEffectiveProducts: state => (skus) => {
    const { effectiveProducts } = state.basket;
    const skuSet = new Set([].concat(skus));
    if (!effectiveProducts) return [];

    return effectiveProducts.filter(ep => skuSet.has(ep.sku)) || [];
  },
  getEffectiveProduct: state => (sku) => {
    const { effectiveProducts } = state.basket;
    if (!effectiveProducts) return null;

    return effectiveProducts.find(ep => ep.sku === sku);
  },
  lineItems: ({ basket: { lineItemsBySku } }) => {
    const iteratee = item => item.timestamp;

    const lineItems = Object.values(lineItemsBySku);

    return orderBy(lineItems, [iteratee], ['desc']);
  },
};

export default getters;
