import { setTimeSlotOnDate, createTime } from 'components/pages/my/helpers';

const TIME_SLOTS = {
  PM: '12pm - 6pm',
  AM: '8am - 1pm',
};

const f = (n) => { return n < 10 ? `0${n}` : `${n}`; };

const component = {
  props: ['scheduledDate', 'scheduledSlot', 'startTime', 'endTime', 'eventTitle'],
  computed: {
    scheduledTimeRange() {
      if (this.scheduledSlot) {
        return TIME_SLOTS[this.scheduledSlot];
      }

      let h1 = this.eventStartTime.getHours();
      let h2 = this.eventEndTime.getHours();

      let slot1 = 'pm'; // slot for h1 >= 12
      let slot2 = 'pm'; // slot for h2 >= 12

      if (h1 < 12) slot1 = 'am';
      if (h2 < 12) slot2 = 'am';

      if (h1 > 12) h1 -= 12;
      if (h2 > 12) h2 -= 12;

      return `${f(h1)}:${f(this.eventStartTime.getMinutes())}${slot1} - ${f(h2)}:${f(this.eventEndTime.getMinutes())}${slot2}`;
    },
    eventStartTime() {
      if (this.startTime) return createTime(this.scheduledDate, this.startTime);

      const date = new Date(this.scheduledDate);
      setTimeSlotOnDate(date, this.scheduledSlot);

      return date;
    },
    eventEndTime() {
      if (this.endTime) return createTime(this.scheduledDate, this.endTime);

      const date = new Date(this.scheduledDate);

      setTimeSlotOnDate(date, this.scheduledSlot);
      date.setHours(date.getHours() + (this.scheduledSlot === 'AM' ? 5 : 6));

      return date;
    },
    formattedDate() {
      return new Date(this.scheduledDate).toLocaleDateString('en-GB');
    },
  },
};

export default component;
