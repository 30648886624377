import { createNamespacedHelpers } from 'vuex';

import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HoneyOptionSelector from 'components/views/honey/option_selector';
import AmountDiscountBadge from 'mixins/amount_discount_badge';
import AddToBasketButton from 'mixins/add_to_basket_button';
import HoneyHeatingPlus from 'components/views/honey/heating_plus';
import HoneyCountyInstallation from 'components/views/honey/county_installation';
import CodeValidator from 'components/views/honey/code_validator.vue';
import MultipackSelector from 'components/views/multipack_selector';
import HoneyProductRegularBuyHubCompatibility from 'components/views/honey/product_regular_buy_hub_compatibility';

import { CHANNEL_OPTIONS, BOILER_TYPES } from 'store/modules/heating_buy_page';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('heatingBuyPage');

const component = {
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    heatingSkuMappings: Object,
    thermostatStandSkuMappings: Object,
    freeMonths: String,
    initialHubOption: String,
    initialChannelOption: String,
    initialHeatingPlusType: String,
    thermostatType: String,
    newMultizoneSelection: Boolean,
    barclaysValidationRequired: Boolean,
    thermostatStandPrice: Number,
    irelandPdp: Boolean,
    accountEmailValidationRequired: Boolean,
  },
  data() {
    return {
      basketInterval: null,
      namespace: 'heatingBuyPage',
      barclaysMortgageValid: null,
      installationAvailable: false,
      accountEmail: '',
      accountEmailValid: false,
      accountEmailValidated: false,
    };
  },
  computed: {
    ...mapState([
      'quantity',
      'installationType',
      'boilerType',
      'hubOption',
      'channelOption',
      'thermostatStandOption',
      'heatingZonesOption',
      'colourSku',
      'isPriceLoading',
      'heatingPlusType',
      'alternativeQuantity',
      'trvBundleSku',
      'hiveMiniOption'
    ]),
    ...mapGetters([
      // 'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
      'annualPrice',
      'monthlyPrice',
      'recurringAnnualPrice',
      'sku',
    ]),
    ...mapGetters(
      {
        pricingDiscount: 'hasDiscount',
      }
    ),
    hasDiscount() {
      return this.pricingDiscount;
    },
    heatingPlusDiscountBadge() {
      if (this.totalDiscount === 0) return 'can save 10%';
      if (this.thermostatStandOption === 'yes') {
        return `saved 10% (£${(this.totalDiscount + (this.thermostatStandPrice * 0.1 * this.alternativeQuantity)).toFixed(2)})`;
      }

      return `saved 10% (£${this.totalDiscount.toFixed(2)})`;
    },
    heatingZonesOptionDisabled() {
      return this.channelOption === null;
    },
    channelOptionSelected() {
      return CHANNEL_OPTIONS.includes(this.channelOption);
    },
    boilerTypeSelected() {
      return BOILER_TYPES.includes(this.boilerType);
    },
    isProInstallAvailable() {
      const {
        channelOptionSelected,
        heatingZonesOption,
        installationAvailable,
        boilerType,
        irelandPdp,
      } = this;
      const proInstall = channelOptionSelected && heatingZonesOption === 'Single';

      if (!irelandPdp) return proInstall;
      return proInstall && boilerType === 'gas' && installationAvailable;
    },
    /* eslint-disable */
    installationTypeDisabled() {
      if (this.newMultizoneSelection) return false;

      if (this.channelOption === null || this.heatingZonesOption === null) {
        return true;
      }

      if (this.isProInstallAvailable) return false;

      // this.setInstallationType({ selected: 'self_install' });
      return true;
    },
    /* eslint-enable */
    buttonDisabled() {
      return this.heatingZonesOption === null
        || (this.barclaysValidationRequired && !this.barclaysMortgageValid)
        || (this.irelandPdp && !this.boilerTypeSelected)
        || (this.accountEmailValidationRequired && !this.accountEmailValid)
        || (this.hiveMiniOption === 'No' && this.trvBundleSku === 'no_pack')
    },
    thermostatStandSelected() {
      return this.thermostatStandOption === 'yes';
    },
    thermostatStandTotalPrice() {
      if (this.hasDiscount) {
        return this.thermostatStandPrice * this.alternativeQuantity * 0.9;
      }
      return this.thermostatStandPrice * this.alternativeQuantity;
    },
    discountBadge() {
      if (!this.hasDiscount) {
        return '';
      }

      if (this.thermostatStandOption === 'yes') {
        return `saved 10% (${this.config.currency.symbol}${(this.totalDiscount + (this.thermostatStandPrice * 0.1 * this.alternativeQuantity)).toFixed(2)})`;
      }
      return `Save ${this.config.currency.symbol}${this.totalDiscount.toFixed(2)}`;
    },
    showAccountEmailValidationError() {
      const {
        accountEmailValidationRequired,
        accountEmailValid,
        accountEmailValidated,
      } = this;

      return accountEmailValidationRequired && accountEmailValidated && !accountEmailValid;
    },
  },
  created() {
    const {
      heatingSkuMappings,
      thermostatStandSkuMappings,
      initialHubOption,
      initialChannelOption,
      initialHeatingPlusType,
      thermostatType,
      newMultizoneSelection,
    } = this;

    this.init({
      heatingSkuMappings,
      thermostatStandSkuMappings,
      thermostatType,
      alternativeQuantity: 1,
      hubOption: initialHubOption,
      channelOption: initialChannelOption,
      heatingPlusType: initialHeatingPlusType,
      heatingZonesOption: 'Single',
      newMultizoneSelection,
    });

    if (this.barclaysValidationRequired) {
      this.setHeatingPlusType('annual');
    }
  },
  beforeDestroy() {
    clearInterval(this.basketInterval);
  },
  watch: {
    heatingZonesOption(newHeatingZone) {
      if (newHeatingZone === 'Multiple' && !this.newMultizoneSelection) {
        this.$modal.show('pro_install_call_direct_modal');
        if (this.installationType === 'pro') this.setInstallationType({ selected: 'self_install' });
      }
    },
  },
  methods: {
    ...mapActions([
      'setColour',
      'setHubOption',
      'setChannelOption',
      'setThermostatStandOption',
      'setHeatingZonesOption',
      'setInstallationType',
      'setHeatingPlusType',
      'init',
      'decrement',
      'increment',
      'alternativeIncrement',
      'alternativeDecrement',
      'setStandQuantity',
      'setBoilerType',
      'setAdditionalTrvBundle',
      'setHiveMiniOption',
    ]),
    validateMortgageNumber(accountNumber) {
      if (accountNumber.match(/^\d{6}$/)) {
        setTimeout(() => {
          this.barclaysMortgageValid = true;
          window.specEvents.push('valid-mortgage-number');
        }, 1000);
      } else {
        this.barclaysMortgageValid = false;
      }
    },
    validateAccountEmail() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValid = emailPattern.test(this.accountEmail.trim());

      this.accountEmailValidated = true;
      this.accountEmailValid = isValid;
    },
    setInstallationAvailable(val) {
      this.installationAvailable = val;

      if (!val && this.installationType === 'pro') {
        this.setInstallationType({ selected: 'self_install' });
      }
    },
    onChangedSku({ sku }) {
      this.setAdditionalTrvBundle(sku)
    },
  },
  components: {
    TrustPilotPopup,
    HoneyOptionSelector,
    HoneyHeatingPlus,
    CodeValidator,
    HoneyCountyInstallation,
    MultipackSelector,
    HoneyProductRegularBuyHubCompatibility,
  },
};

export default component;
