<template>
  <span v-if="showDiscount">
    <span class="font-weight-bold text-red">
      <formatted-money v-bind:value="totalPrice" />
      <span v-if="isAnnual">per year</span>
      <span v-else>per month</span>
    </span>
    <span class="text-muted">
      <s><formatted-money v-bind:value="listPrice" /></s>
    </span>
    <span class="text-uppercase text-red">
      Save <formatted-money v-bind:value="discount" />
    </span>
  </span>
  <span v-else>
    <formatted-money v-bind:value="totalPrice" />
    <span v-if="isAnnual">per year</span>
    <span v-else>per month</span>
  </span>
</template>

<script>
const component = {
  props: {
    subscription: { type: Object, required: true },
  },
  computed: {
    isAnnual() {
      return this.subscription.annual;
    },
    totalPrice() {
      return this.subscription.basketTotalPrice;
    },
    listPrice() {
      return this.subscription.basketListPrice;
    },
    discount() {
      return this.subscription.basketDiscountAmount;
    },
    showDiscount() {
      return this.totalPrice !== this.listPrice;
    },
  },
};

export default component;
</script>
