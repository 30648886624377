import THERMOSTAT_ON_RECEIVER_OFF from './api_reset_steps/slt2_3_3b_3c_3d_6/thermostat_on_receiver_off';
import THERMOSTAT_OFF_RECEIVER_ON from './api_reset_steps/slt2_3_3b_3c_3d_6/thermostat_off_receiver_on';
import THERMOSTAT_OFF_RECEIVER_OFF from './api_reset_steps/slt2_3_3b_3c_3d_6/thermostat_off_receiver_off';
import THERMOSTAT_OFFLINE from './api_reset_steps/slt5/thermostat_offline';

const STEPS_API_RESET = {
  SLT5: {
    THERMOSTAT_OFFLINE,
  },
  SLT_2_3_3B_3C_3D_6: {
    THERMOSTAT_OFF_RECEIVER_OFF,
    THERMOSTAT_OFF_RECEIVER_ON,
    THERMOSTAT_ON_RECEIVER_OFF,
  },
};

export default STEPS_API_RESET;
