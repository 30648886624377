import binaryQuestion from './shared/binary';

const vuexModule = {
  ...binaryQuestion,
  getters: {
    outdoorCameraQty({ selected }) {
      return selected === true ? 1 : 0;
    },
  },
};

export default vuexModule;
