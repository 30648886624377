<template>
  <validation-observer ref="observer" slim>
    <address-autocomplete-by-postcode
      v-if="!showFormWithForce"
      v-bind:allow-enter-manually="false"
      v-on:update-address="updateAddress"
      v-on:enter-manually="hideAutocompleteByPostcode"
      v-bind:disabled="disabled"
    />

    <div v-if="isUk && showFormWithForce">
      <input
        v-model="address.address1"
        type="hidden"
        v-bind:name="fieldName('address1')"
      >
      <input
        v-model="address.city"
        type="hidden"
        v-bind:name="fieldName('city')"
      >
      <input
        v-model="address.postcode"
        type="hidden"
        v-bind:name="fieldName('postcode')"
      >
      <div v-if="isShowPostcodeAutocomplete" class="row g-0">
        <div class="col">
          <label class="font-weight-bold">Selected address:</label>
        </div>
        <div class="col text-end">
          <button
            type="button"
            class="btn btn-link btn-link-inherit text-underline font-weight-bold js-enter-manually"
            v-on:click="showPostcodeAutocomplete"
          >
            Search by new postcode
          </button>
        </div>
      </div>
      <address-block
        v-bind:address="address"
        v-bind:fallback="false"
        v-bind:compact="false"
      />
    </div>

    <div v-if="!isUk">
      <validated-field rules="required|max:80" v-bind:disabled="disabled">
        <div class="row g-0">
          <div class="col">
            <label class="font-weight-bold">Address</label>
          </div>
          <div v-if="isShowPostcodeAutocomplete" class="col text-end">
            <button
              type="button"
              class="btn btn-link btn-link-inherit text-underline font-weight-bold js-enter-manually"
              v-on:click="showPostcodeAutocomplete"
            >
              Search by new postcode
            </button>
          </div>
        </div>
        <input
          id="address_address1"
          v-model="address.address1"
          type="text"
          class="form-control"
          autocomplete="nope"
          placeholder="Street Address"
          v-bind:name="fieldName('address1')"
          v-bind:disabled="disabled"
        >
        <template v-slot:error-max>
          Your street name must contain less than 80 characters
        </template>
        <template v-slot:error-required>
          Please enter a valid street name
        </template>
      </validated-field>

      <address-autocomplete-by-address
        v-if="isIe"
        v-bind:address="address.address1"
        v-on:update-address="updateAddress"
      />

      <validated-field rules="required" v-bind:disabled="disabled">
        <label class="font-weight-bold">Town/City</label>
        <input
          id="address_city"
          v-model="address.city"
          type="text"
          class="form-control"
          autocomplete="nope"
          placeholder="City"
          v-bind:name="fieldName('city')"
          v-bind:disabled="disabled"
        >
        <template v-slot:error-required>
          Please enter a valid town
        </template>
      </validated-field>

      <validated-field
        v-if="!isIe"
        v-bind:rules="postcodeRules"
        v-bind:immediate="isInvalidPostcode"
        v-bind:disabled="disabled"
      >
        <label class="font-weight-bold">Postcode</label>
        <input
          id="address_postcode"
          v-model="address.postcode"
          type="text"
          class="form-control"
          autocomplete="nope"
          placeholder="Postcode"
          v-bind:name="fieldName('postcode')"
          v-bind:disabled="disabled"
        >
        <template v-slot:error-required>
          Please enter a valid postcode
        </template>
        <template v-slot:error-is_not>
          Please enter a valid postcode
        </template>
      </validated-field>

      <validated-field
        v-if="!isUk"
        rules="required"
        v-bind:disabled="disabled"
      >
        <label class="font-weight-bold">County</label>
        <select
          id="address_territory"
          v-model="address.territory"
          class="form-control"
          v-bind:name="fieldName('territory')"
          v-bind:disabled="disabled"
        >
          <option value="">
            County
          </option>
          <option
            v-for="(territory, index) in territories"
            v-bind:key="index"
            v-bind:value="territory"
          >
            {{ territory }}
          </option>
        </select>
        <template v-slot:error-required>
          Please enter a valid county
        </template>
      </validated-field>
    </div>
  </validation-observer>
</template>

<script>
  import debounce from 'lodash/debounce';
  import values from 'lodash/values';
  import AddressAutocompleteByPostcode from 'components/base/address_autocomplete_by_postcode';
  import AddressAutocompleteByAddress from 'components/base/address_autocomplete_by_address';
  import AddressBlock from 'components/base/address_block';
  import {handlers as testHandlers} from 'utils/bridge';

  export default {
  inject: ['config'],
  props: {
    showForm: {
      type: Boolean,
      default: false,
    },
    invalidPostcode: {
      type: Boolean,
      default: false,
    },
    initAddress: {
      type: Object,
      default() { return {}; },
    },
    initAddress1: {
      type: String,
      default: '',
    },
    initCity: {
      type: String,
      default: '',
    },
    initPostcode: {
      type: String,
      default: '',
    },
    initTerritory: {
      type: String,
      default: '',
    },
    territories: {
      type: Array,
      default: () => [],
    },
    namePrefix: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forceHideForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowForm: false, // initial value is set in mounted() below
      address: {
        address1: this.initAddress.address1 || this.initAddress1,
        city: this.initAddress.city || this.initCity,
        postcode: this.initAddress.postcode || this.initPostcode,
        territory: this.initAddress.territory || this.initTerritory,
      },
    };
  },
  computed: {
    isUk() {
      return this.config.marketScope === 'uk';
    },
    isIe() {
      return this.config.marketScope === 'ie';
    },
    isShowFormOnLoad() {
      return this.showForm || values(this.address).some(el => el);
    },
    isInvalidPostcode() {
      return this.invalidPostcode && !!this.initPostcode;
    },
    postcodeRules() {
      return [
        'required',
        ...(this.isInvalidPostcode ? [`is_not:${this.initPostcode}`] : []),
      ].join('|');
    },
    isShowPostcodeAutocomplete() {
      return this.isUk && !this.disabled;
    },
    showFormWithForce() {
      if (this.forceHideForm) {
        return false;
      }

      return this.isShowForm;
    },
  },
  watch: {
    address: {
      handler() {
        this.debouncedSetAddress();
      },
      deep: true,
    },
  },
  created() {
    this.debouncedSetAddress = debounce(this.setAddress, 500);
    testHandlers.populateAddress(this.updateAddress);
  },
  mounted() {
    // setting that here as getters are not available in the data()
    this.isShowForm = this.isShowFormOnLoad || !this.isUk;
  },
  methods: {
    hideAutocompleteByPostcode() {
      this.isShowForm = true;
    },
    showPostcodeAutocomplete() {
      this.isShowForm = false;
    },
    updateAddress(address) {
      this.address.address1 = address.address1;
      this.address.city = address.city;
      this.address.postcode = address.postcode;
      this.address.territory = address.territory;
      this.hideAutocompleteByPostcode();
    },
    setAddress() {
      this.$emit('address-update', this.address);
    },
    validate() {
      this.$refs.observer.validate();
    },
    fieldName(field) {
      if (!this.namePrefix) { return field; }

      const [head, ...tail] = [...this.namePrefix.split('.'), field];
      return [
        head,
        ...tail.map(el => `[${el}]`),
      ].join('');
    },
  },
  components: {
    AddressAutocompleteByPostcode,
    AddressAutocompleteByAddress,
    AddressBlock,
  },
};
</script>
