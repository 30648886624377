// Show a menu on click.

const component = {
  // this is the local state
  data() {
    return {
      isOpen: false,
    };
  },
  // methods are called inside template and are triggered by dom events
  methods: {
    toggle() {
      // reactively change the state
      this.isOpen = !this.isOpen;
    },
  },
};

export default component;
