<template>
  <quiz-chat-step v-bind:step="step">
    <template v-slot:question>
      What colour indoor camera would you like?
    </template>

    <template v-slot:buttons="{ onSelect }">
      <div class="d-flex justify-content-end">
        <transition
          appear
          enter-active-class="animated fadeIn fast"
        >
          <button
            type="button"
            class="btn btn-sm h-auto p-0 me-3 mb-2 d-inline-block"
            v-on:click="onSelect('black')"
          >
            <div class="bg-gray-white px-1 rounded">
              <hires-image v-bind:image="images.camera_black" />
            </div>
            <div class="pt-2">
              Black/Brushed <br>Copper
            </div>
          </button>
        </transition>
        <transition
          appear
          enter-active-class="animated fadeIn fast"
        >
          <button
            type="button"
            class="btn btn-sm h-auto p-0 mb-2 d-inline-block"
            v-on:click="onSelect('white')"
          >
            <div class="bg-gray-white px-1 rounded">
              <hires-image v-bind:image="images.camera_white" />
            </div>
            <div class="pt-2">
              White/Champagne <br>Gold
            </div>
          </button>
        </transition>
      </div>
    </template>
  </quiz-chat-step>
</template>

<script>
import QuizChatStep from './chat_step.vue';

const component = {
  inject: ['images'],
  props: {
    step: String,
  },
  components: {
    QuizChatStep,
  },
};

export default component;
</script>
