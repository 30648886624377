import ValidatedForm from 'mixins/validated_form';

const component = {
  mixins: [ValidatedForm],
  data() {
    return {
      password: null,
      passwordConfirmation: null,
    };
  },
};

export default component;
