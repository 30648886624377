import ValidatedForm from 'mixins/validated_form';

const component = {
  mixins: [ValidatedForm],
  props: {
    initialRetailerName: String,
    initialCouponCode: String,
  },
  data() {
    return {
      retailerName: this.initialRetailerName,
      couponCode: this.initialCouponCode,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const { retailerName, couponCode, validation } = this;

      if (retailerName && couponCode) {
        validation.validate();
      }
    });
  },
};

export default component;
