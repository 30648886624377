import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const mutations = {
  ...baseMutations,
  setSku(state, sku) {
    Vue.set(state, 'sku', sku);
  },
  setAdditionalInstallationSku(state, additionalInstallationSku) {
    Vue.set(state, 'additionalInstallationSku', additionalInstallationSku);
  },
  setVoucherCode(state, voucherCode) {
    Vue.set(state, 'voucherCode', voucherCode);
  },
  setVoucherCodeEligibility(state, voucherCodeEligibility) {
    Vue.set(state, 'voucherCodeEligibility', voucherCodeEligibility);
  },
  setRetailerName(state, retailerName) {
    Vue.set(state, 'retailerName', retailerName);
  },
  setVoucherCodeLoading(state, value) {
    Vue.set(state, 'voucherCodeLoading', value);
  },
  setVoucherCodeError(state, value) {
    Vue.set(state, 'voucherCodeError', value);
  },
};

export const moduleGetters = {
  ...baseGetters,
  pricingPayload({
    sku,
    quantity,
    additionalInstallationSku,
    voucherCode,
  }) {
    const products = [{ sku, quantity: 1 }];

    if (quantity > 1 && additionalInstallationSku) {
      products.push({ sku: additionalInstallationSku, quantity: quantity - 1 });
    }

    const productBuy = { products, sku };

    if (voucherCode) {
      productBuy.voucher_code = voucherCode;
    }

    return productBuy;
  },
  basketPayload({
    sku,
    quantity,
    voucherCode,
    additionalInstallationSku,
    retailerName,
  }) {
    const products = [{ sku, quantity: 1 }];

    if (quantity > 1 && additionalInstallationSku) {
      products.push({ sku: additionalInstallationSku, quantity: quantity - 1 });
    }

    const productBuy = { product: { sku: products } };

    if (voucherCode) {
      productBuy.voucherCode = voucherCode;
    }

    if (retailerName) {
      productBuy.retailerName = retailerName;
    }

    return { productBuy, quantity: 1 };
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  async getVoucherDetails({ commit }, { voucherCode, retailerName }) {
    commit('setVoucherCodeEligibility', null);
    commit('setVoucherCodeError', null);
    commit('setVoucherCodeLoading', true);
    const promise = client.getVoucherDetails({ voucherCode });
    const response = await promise;
    if (response.error) {
      commit('setVoucherCodeError', response.error);
    } else {
      commit('setVoucherCodeEligibility', response.details);
      commit('setVoucherCode', voucherCode);
      commit('setRetailerName', retailerName);
    }

    commit('setVoucherCodeLoading', false);
    window.specEvents.push('voucher-details-loaded');
  },
  setSku({ commit, dispatch }, sku) {
    commit('setSku', sku);

    return dispatch('loadPricing');
  },
  setVoucherCode({ commit, dispatch }, { voucherCode, retailerName }) {
    commit('setVoucherCode', voucherCode);
    commit('setRetailerName', retailerName);

    return dispatch('loadPricing');
  },
  init({ commit, dispatch }, { sku, additionalInstallationSku }) {
    commit('setSku', sku);
    commit('setAdditionalInstallationSku', additionalInstallationSku);

    return dispatch('loadPricing');
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      sku: '',
      additionalInstallationSku: null,
      quantity: 1,
      voucherCode: '',
      pricing: {},
      retailerName: null,
      voucherCodeEligibility: null,
      voucherCodeLoading: false,
      voucherCodeError: null,
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
