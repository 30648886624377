<template>
  <div
    class="d-flex carousel-pagination mx-lg-5 mt-2 justify-content-center"
    v-bind:class="thumbContainerClass()"
  >
    <div
      v-for="(page, index) in paginationCount"
      v-bind:key="`${page}_${index}`"
      class="mx-1 mx-lg-2 hidden"
      v-bind:class="thumbStyle(index)"
      v-on:click="goToPage(index)"
    >
      <hires-image
        classes="img-fluid rounded"
        v-bind:image="thumb(index)"
      />
    </div>
  </div>
</template>

<script>
const SLIDES_IN_NAVIGATION = 5;

const component = {
  inject: ['carousel'],
  props: ['items'],
  computed: {
    counts() {
      return this.carousel.pageCount;
    },
    paginationCount() {
      return this.carousel && this.carousel.scrollPerPage
        ? this.carousel.pageCount
        : this.carousel.slideCount || 0;
    },
  },
  methods: {
    goToPage(index) {
      this.carousel.goToPage(index);
    },
    thumb(index) {
      return this.items[index].thumb;
    },
    thumbStyle(index) {
      if (index === this.carousel.currentPage) {
        return 'active-thumb';
      }

      return '';
    },
    thumbContainerClass() {
      return this.carousel.currentPage < SLIDES_IN_NAVIGATION ? 'display-first-five' : 'display-last-five';
    },
  },
};

export default component;

</script>
