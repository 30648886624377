import updates from './updates';
import { UPDATE_BASKET } from './mutations';

const UPSELL_PAGE_URL_REGEX = /\/other-smart-products$/;

const fromUpsell = () => UPSELL_PAGE_URL_REGEX.test(window.location.pathname);

const {
  getAddToBasketUpdate,
  getRemoveFromBasketUpdate,
  getUpdateQuantityUpdate,
} = updates;

const handleApiRequest = ({ dispatch, commit, dataLayer }, apiRequestPromise) => apiRequestPromise
  .then((response) => {
    const { hdl, basket } = response;
    commit(UPDATE_BASKET.SUCCESS, { basket });
    if (typeof hdl === 'object') {
      dataLayer.replace(hdl);
    }
    return response;
  })
  .catch((error) => {
    commit(UPDATE_BASKET.FAILURE, error.responseJSON);
    dispatch(
      'global/showError',
      { message: error.responseJSON.message, noWarning: true },
      { root: true }
    );
    return error;
  });

const optimisticUpdate = (injections, newBasket, apiRequestPromise, errorCallback) => {
  const { commit, dispatch } = injections;

  commit(UPDATE_BASKET.PENDING);
  commit(UPDATE_BASKET.OPTIMISTIC_UPDATE, newBasket);

  return handleApiRequest(injections, apiRequestPromise).catch(() => {
    if (errorCallback) errorCallback();
    dispatch('global/hideHiveLoader', {}, { root: true });
  });
};

const buildLineItem = (sku, quantity) => {
  const product = { product: { sku } };

  return { product, quantity };
};

const lineItemToPayload = (item) => {
  const { product: { product } } = item;

  return { sku: product.sku, ...item };
};

const buildBasketRequirements = ({ options, basket_requirement: requirement }) => {
  const basketRequirements = [];
  if (typeof requirement === 'object' && Object.keys(requirement).length > 0) {
    basketRequirements.push(requirement);
  }

  const { videoPlaybackType } = options || {};

  if (videoPlaybackType) {
    basketRequirements.push({
      name: `video_playback_${videoPlaybackType}`,
    });
  }

  const { installationType } = options || {};

  if (installationType) {
    basketRequirements.push({
      name: 'outdoor_camera_pro_install',
      values: { type: 'pro' },
    });
  }

  return basketRequirements;
};

const proffInstallForHomeshieldRetailJourney = res => (
  // eslint-disable-next-line no-restricted-globals
  res.basket.homeshieldRetailJourney && location.pathname === '/smart-home-security/installation-requirements'
);

const buildAddToBasketPayload = (lineItems, productBuy, upsellLastProductSku) => {
  const [firstItem, ...otherItems] = lineItems.map(item => lineItemToPayload(item));
  const {
    product: { sku, ...otherProductProps }, ...others
  } = productBuy;

  // eslint-disable-next-line camelcase
  const basket_requirements = buildBasketRequirements(productBuy);
  const productPayload = { ...otherProductProps, ...others, basket_requirements };

  // First line item has extra information, like basket requirements
  const firstSku = firstItem ? [].concat(firstItem.sku)[0] : sku;
  const productItem = {
    ...firstItem,
    product: {
      sku: firstSku,
      ...productPayload,
    },
  };

  return {
    line_items: [productItem, ...otherItems],
    from_upsell: fromUpsell(),
    upsell_last_product_sku: upsellLastProductSku,
    voucher_code: productBuy.voucherCode,
    retailer_name: productBuy.retailerName,
  };
};

const makeActions = ({ client, dataLayer, currentWindow = window }) => ({
  changeShippingMethod({ commit, dispatch }, { shippingMethod }) {
    const { changeShippingMethod } = client;
    const payload = { shipping_preference: shippingMethod };

    commit(UPDATE_BASKET.PENDING);

    return handleApiRequest({ dispatch, commit, dataLayer }, changeShippingMethod({}, payload))
      .then((response) => {
        const { basket } = response;

        dispatch('reloadBasket', basket);

        return response;
      });
  },
  changeDeliveryAddress({ commit }, { deliveryAddress }) {
    return client.changeDeliveryAddress({}, { delivery_address_reference: deliveryAddress })
      .catch(({ responseJSON }) => {
        commit(UPDATE_BASKET.FAILURE, responseJSON);
      });
  },
  getBasket({ commit, dispatch }) {
    const { getBasket } = client;

    commit(UPDATE_BASKET.PENDING);

    return handleApiRequest({ dispatch, commit, dataLayer }, getBasket());
  },
  addToBasket(
    {
      state: { basket }, rootState: { global }, commit, dispatch,
    },
    {
      productBuy, quantity, errorCallback,
    }
  ) {
    if (!basket) return Promise.resolve(false);

    const { addToBasket } = client;
    const { product } = productBuy;
    const { upsell: { lastProductSku: upsellLastProductSku } } = global;

    // product.sku is misleading, as it is actually a collection of SKUs or line_items
    // [ 'HCD00017', { sku: 'SUB00015', quantity: 2 } ]
    //
    // If the quantity is missing, it is implicit.
    // For the first sku or line item, it is taken from the primary product (product.quantity)
    // For any remaining skus, it is set to 1.
    const firstSku = [].concat(product.sku)[0];

    // For each sku or line item
    const lineItems = [].concat(product.sku).map((skuOrObject) => {
      if (typeof skuOrObject === 'object') {
        const object = skuOrObject;

        return buildLineItem(object.sku, object.quantity);
      }

      const sku = skuOrObject;

      if (sku === firstSku) {
        return buildLineItem(sku, quantity);
      }

      return buildLineItem(sku, 1);
    });

    const updatedBasket = getAddToBasketUpdate(basket, lineItems);
    const payload = buildAddToBasketPayload(lineItems, productBuy);

    const apiRequestPromise = addToBasket({}, payload);

    return optimisticUpdate(
      { dispatch, commit, dataLayer },
      updatedBasket, apiRequestPromise, errorCallback
    ).then((res) => {
      dispatch('global/hideHiveLoader', {}, { root: true });

      if (product.proceedToCheckout) {
        // eslint-disable-next-line no-param-reassign
        currentWindow.location = client.checkoutLoginPath();
      } else if (proffInstallForHomeshieldRetailJourney(res)) {
        // eslint-disable-next-line no-param-reassign
        currentWindow.location = client.getBasketPath();
      } else if (product.showInstallationPage) {
        // eslint-disable-next-line no-param-reassign
        currentWindow.location = client.securityInstallationRequirementsPath();
      } else if (product.noRedirect) {
        // stay on the same page
        if (product.successCallback) product.successCallback();
      } else {
        const cleanSku = typeof firstSku === 'object' ? firstSku.sku : firstSku;
        // eslint-disable-next-line no-param-reassign
        currentWindow.location = (fromUpsell() && upsellLastProductSku)
          ? client.pricingPath({ sku: upsellLastProductSku, addedSku: cleanSku })
          : client.pricingPath({ sku: cleanSku });
      }
    });
  },
  removeFromBasket({ state: { basket }, commit, dispatch }, { product, errorCallback }) {
    const { removeFromBasket } = client;
    const { product: { sku } } = product;

    if (!basket || !basket.lineItemsBySku[sku]) return Promise.resolve(false);

    const updatedBasket = getRemoveFromBasketUpdate(basket, sku);
    const apiRequestPromise = removeFromBasket({ sku }, {});

    return optimisticUpdate(
      { dispatch, commit, dataLayer },
      updatedBasket, apiRequestPromise, errorCallback
    ).then(() => dataLayer.productRemoveFromCart(product));
  },
  incrementQuantity({ dispatch }, params) {
    const { item } = params;
    const quantity = item.quantity + 1;

    return dispatch('updateQuantity', { ...params, quantity });
  },
  decrementQuantity({ dispatch }, params) {
    const { item } = params;
    const quantity = item.quantity - 1;

    return dispatch('updateQuantity', { ...params, quantity });
  },
  updateQuantity({ dispatch, state: { basket }, commit }, { item, quantity, errorCallback }) {
    const { updateBasket } = client;
    const { product: { sku } } = item.product;
    const oldQuantity = item.quantity;

    if (!basket || !basket.lineItemsBySku[sku]) return Promise.resolve(false);
    if (quantity < 1) return dispatch('removeFromBasket', item);

    const updatedBasket = getUpdateQuantityUpdate(basket, { sku, quantity });
    const apiRequestPromise = updateBasket({ sku }, { sku, quantity });
    window.specEvents.push('basket.quantityUpdated');

    return optimisticUpdate(
      { dispatch, commit, dataLayer },
      updatedBasket, apiRequestPromise, errorCallback
    ).then(() => dataLayer.productQtyChange({ sku, quantity, oldQuantity }));
  },
  reloadBasket({ commit }, newBasketState) {
    commit(UPDATE_BASKET.RELOAD, newBasketState);
  },
  selectPaymentMethod({ dispatch, commit, state: { basket } }, { paymentMethod, errorCallback }) {
    const { togglePaymentMethod } = client;
    const requestedToBeBundled = paymentMethod === 'monthly';

    commit(UPDATE_BASKET.PENDING);

    const updatedBasket = { ...basket, requestedToBeBundled };
    const apiRequestPromise = togglePaymentMethod({}, { payment_method: paymentMethod });

    return optimisticUpdate(
      { dispatch, commit, dataLayer },
      updatedBasket, apiRequestPromise, errorCallback
    );
  },
  switchInstallation({ commit, dispatch }) {
    const { switchInstallation } = client;

    commit(UPDATE_BASKET.PENDING);

    return handleApiRequest({ dispatch, commit, dataLayer }, switchInstallation())
      .then((response) => {
        const { basket } = response;

        dispatch('reloadBasket', basket);
      });
  },
  switchHubOption({ commit, dispatch }, value) {
    commit(UPDATE_BASKET.PENDING);

    return handleApiRequest({ dispatch, commit, dataLayer }, client.switchHubOption({}, { hub_option: value }))
      .then((response) => {
        const { basket } = response;

        dispatch('reloadBasket', basket);
      });
  },
  setChannelOption({ commit, dispatch }, value) {
    commit(UPDATE_BASKET.PENDING);

    return handleApiRequest({ dispatch, commit, dataLayer }, client.setChannelOption({}, { channel_option: value }))
      .then((response) => {
        const { basket } = response;

        dispatch('reloadBasket', basket);
      });
  },
});

export default makeActions;
