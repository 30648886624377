import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('devTools');

const component = {
  props: ['initialIncludeTrackingScripts', 'initialIncludeAdobeDtmScript', 'initialAbTests'],
  computed: mapState([
    'isOpen',
    'includeTrackingScripts',
    'includeAdobeDtmScript',
    'debugUIWidgets',
    'abTests',
  ]),
  methods: {
    ...mapActions([
      'toggleDebugUIWidgets',
      'toggleIncludeTrackingScripts',
      'toggleAdobeDtmScript',
      'toggleABTest',
      'toggle',
      'init',
    ]),
    isABTestToggled(test) {
      return this.$store.state.devTools.abTests[test];
    },
    handleCtrlShiftD(event) {
      if ((event.metaKey || event.ctrlKey)
          && event.shiftKey
          && (String.fromCharCode(event.which).toLowerCase() === 'd')) {
        event.preventDefault();
        this.toggle();
      }
    },
  },
  mounted() {
    // don't bind key event listener on production
    if (window.Hive.environment !== 'production') {
      document.addEventListener('keydown', this.handleCtrlShiftD);
    }
    // init state only if component is present
    this.init({
      includeTrackingScripts: !!this.initialIncludeTrackingScripts,
      includeAdobeDtmScript: !!this.initialIncludeAdobeDtmScript,
      debugUIWidgets: false,
      abTests: this.initialAbTests,
    });
  },
  beforeDestroy() {
    if (this.listener) {
      document.removeEventListener(this.listener);
    }
  },
};

export default component;
