import MyAccountHeader from 'components/base/my_account_header';
import webview from 'utils/webview';
import AppointmentSchedulerCalendar from 'components/views/appointment_scheduler_calendar';

const component = {
  created() {
    webview.ready();
  },
  components: {
    MyAccountHeader,
    AppointmentSchedulerCalendar,
  },
};

export default component;
