<template>
  <quiz-chat-speech-bubble-animation
    speed=".5s"
    v-on:complete="start"
  >
    <div class="row align-items-end pb-3 pt-3">
      <div class="col p-0 pr-2 quiz-hive-icon">
        <img
          src="//images.ctfassets.net/mijf9lz5yt3u/38AEynebjA4RYinGMcbyX7/e09c12fdadcedc4617e8f18c49867b5a/icon-hive-circle.svg"
          width="32"
          height="32"
        >
      </div>
      <div class="col-8 pb-2 pt-3">
        Creating your kit...
        <div class="progress quiz-progress">
          <div
            class="progress-bar bg-blue"
            role="progressbar"
            v-bind:style="{ width: value + '%'}"
            v-bind:aria-valuenow="value"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    </div>
  </quiz-chat-speech-bubble-animation>
</template>

<script>
import QuizChatSpeechBubbleAnimation from './chat_speech_bubble_animation';

const component = {
  data() {
    return {
      value: 0,
      interval: null,
      timeout: null,
    };
  },
  destroyed() {
    const { interval, timeout } = this;

    if (interval !== null) clearInterval(interval);

    if (timeout !== null) clearTimeout(timeout);
  },
  methods: {
    start() {
      this.value = 0;

      this.interval = setInterval(() => {
        if (this.value >= 100) {
          clearInterval(this.interval);
          // Wait a bit after the valuebar stops.
          this.timeout = setTimeout(() => this.$emit('finish'), 1000);
        } else {
          this.value = this.value + 20;
        }
      }, 100);
    },
  },
  components: {
    QuizChatSpeechBubbleAnimation,
  },
};

export default component;
</script>
