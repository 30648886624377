<template>
  <div>
    <div>
      <slot v-for="item in paginatedItems" v-bind:item="item" />
    </div>

    <div v-if="isPrevPageAvailable || isNextPageAvailable" class="text-center mt-5">
      <div class="btn-group btn-group-sm">
        <button
          type="button"
          class="btn btn-outline-primary"
          v-bind:disabled="!isPrevPageAvailable"
          v-on:click="prevPage"
        >
          Newer
        </button>
        <button
          type="button"
          class="btn btn-outline-primary"
          v-bind:disabled="!isNextPageAvailable"
          v-on:click="nextPage"
        >
          Older
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    perPage: {
      type: Number,
      default: 20,
      validator: value => value > 0,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    numberOfPages() {
      return Math.trunc(this.items.length / this.perPage)
        + (this.items.length % this.perPage > 0 ? 1 : 0);
    },
    isPrevPageAvailable() {
      return this.currentPage > 1;
    },
    isNextPageAvailable() {
      return this.currentPage < this.numberOfPages;
    },
    paginatedItems() {
      return this.items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage,
      );
    },
  },
  methods: {
    prevPage() {
      if (!this.isPrevPageAvailable) { return; }

      this.currentPage -= 1;
    },

    nextPage() {
      if (!this.isNextPageAvailable) { return; }

      this.currentPage += 1;
    },
  },
};
</script>
