const mixin = {
  methods: {
    getReviews() {
      const minStars = 4;

      this.isLoading = true;
      this.page += 1;
      return this.client.trustpilotReviews({
        apikey: this.apiKey,
        sku: this.skus,
        perPage: this.perPage,
        page: this.page,
        locale: this.locale,
      })
        .then(({ productReviews }) => {
          const filteredReviews = productReviews.filter(review => review.stars >= minStars);
          this.reviews = this.reviews.concat(filteredReviews);
          this.isLoading = false;
        });
    },
  },
};

export default mixin;
