<template>
  <div class="p-1 col-12">
    <div v-if="isLoading">
      loading...
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'rotate'" v-bind:width="'40'" v-bind:height="'40'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><rect width="24" height="24"/><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2" stroke="#33495B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
    </div>
    <div v-else-if="devices">
      <div class="bg-gray-white rounded p-5">
        <DeviceItem
          v-bind="devices['hub']"
          v-bind:offset-level="0"
        />
        <div v-for="thermostat in devices['thermostats']" v-bind:key="thermostat.id">
          <div>
            <DeviceItem
              v-bind="thermostat"
              v-bind:power-cycle-steps-completed="powerCycleStepsCompleted"
              v-bind:offset-level="1"
              v-on:report-issue="reportIssue"
              v-on:device-check="startVisualCheckAndApiReset"
            />
            <div v-for="receiver in thermostat['receivers']" v-bind:key="receiver.id">
              <DeviceItem
                v-bind="receiver"
                v-bind:offset-level="2"
                v-bind:power-cycle-steps-completed="powerCycleStepsCompleted"
                v-on:report-issue="reportIssue"
                v-on:device-check="startVisualCheckAndApiReset"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>Something went wrong! Device list is empty!</h3>
    </div>

    <div v-if="displayIssueButton" class="text-red text-center p-3">
      Something went wrong! <br>
      Please try to Refresh or click the Report Issue button below to log a case with Hive
      <br>
      <button class="btn btn-secondary mt-1 me-1" v-on:click="getList()">
        Refresh
      </button>
      <button class="btn btn-secondary mt-1" v-on:click="reportIssue">
        Report Issue
      </button>
    </div>

    <div v-if="!displayIssueButton" class="text-center p-1">
      <button
        class="btn btn-secondary"
        v-on:click="getList()"
      >
        Refresh
      </button>
    </div>
  </div>
</template>

<script>

import DeviceItem from './device_item.vue';

const component = {
  inject: ['client'],
  props: {
    displayReportIssue: Boolean,
    powerCycleStepsCompleted: Boolean,
  },
  data() {
    return {
      devices: null,
      isLoading: true,
      displayIssueButton: false,
    };
  },
  computed: {
    thermostatType() {
      return this.thermostat?.deviceType;
    },
    thermostatOnline() {
      return this.thermostat?.status === 'Online';
    },
    receiverOnline() {
      return this.thermostat?.receivers[0]?.status === 'Online';
    },
    thermostat() {
      return this.devices?.thermostats[0];
    },
  },
  methods: {
    startVisualCheckAndApiReset() {
      this.sendDevicesStatus();
      this.$emit('start-visual-check-and-api-reset');
    },
    sendDevicesStatus() {
      const { thermostatType, thermostatOnline, receiverOnline } = this;
      const info = { thermostatType, thermostatOnline, receiverOnline };

      this.$emit('update-device-status', info);
    },
    reportIssue() {
      this.$emit('create-case');
    },
    getList() {
      this.isLoading = true;
      this.displayIssueButton = false;

      this.client.getDevicesList()
        .then((response) => {
          if (response.data) {
            this.devices = response.data;
            this.sendDevicesStatus();
            this.isLoading = false;
          } else {
            this.displayIssueButton = true;
          }
        });
    },
  },
  created() {
    this.getList();
  },
  components: {
    DeviceItem,
  },
};

export default component;
</script>
