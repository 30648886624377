import HiveLoader from 'components/base/hive_loader';
import MinimumStarReviews from 'mixins/minimum_star_reviews';
import TrustpilotAverageRating from 'mixins/trustpilot_average_rating';

const component = {
  mixins: [MinimumStarReviews, TrustpilotAverageRating],
  inject: ['client'],
  props: {
    apiKey: { type: String, required: true },
    skus: { type: String, required: true },
    starIconPaths: { type: Array, required: true },
    mappedName: { type: String, required: false },
  },
  data() {
    return {
      target: 'trust-pilot-popup',
      reviews: [],
      perPage: 10,
      locale: 'en-GB',
      page: 0,
      total: 0,
      roundedStarsAverage: 0,
      starsAverage: 0,
      isLoading: false,
      starLabels: ['Bad', 'Poor', 'Average', 'Great', 'Excellent'],
    };
  },
  computed: {
    showWidget() {
      return this.total > 0 && this.roundedStarsAverage > 0;
    },
    showMoreReviewsButton() {
      return !this.isLoading && (this.page * this.perPage < this.total);
    },
    starLabel() {
      return this.starLabels[this.roundedStarsAverage - 1];
    },
  },
  methods: {
    openModal() {
      this.$modal.show(this.target);
      if (this.reviews.length === 0) {
        this.getReviews();
      }
    },
    closeModal() {
      this.$modal.hide(this.target);
    },
    starIconPath(rating) {
      return this.starIconPaths[rating - 1];
    },
  },
  components: {
    'hive-loader': HiveLoader,
  },
};

export default component;
