/* eslint-disable */

import Utils from 'legacy/utils';

const $el = that => $(that).prev('.collapse-with-chevron, .collapse-with-chevron-before, .collapse-with-chevron-after');

$(document).on('show.bs.collapse', '.collapse', function() {
  return $el(this).addClass('toggled');
});

$(document).on('hide.bs.collapse', '.collapse', function() {
  return $el(this).removeClass('toggled');
});

const openAndScrollTo = function(targetId) {
  // Find target link
  const $targetContainer = $(`#${targetId}`);
  // console.info $targetContainer.length
  $targetContainer.collapse('show');

  return Utils.scrollToAnchor(targetId);
};

// All anchor links within the collapsible elements should open the collapsible that of the target on click
$(document).on('click', '.collapse a', function(e) {
  const $link = $(this);
  const targetHref = $link.attr('href').toString();
  const pathAndId = targetHref.indexOf('#') >= 0 ? targetHref.split('#') : [targetHref, null];

  // Ensure the anchor is present, and that the href links to the current page
  const currentPath = window.location.pathname.toString();
  const hrefIsCurrentPath = pathAndId[0].length === 0 || currentPath === pathAndId[0];
  if (pathAndId[1] && hrefIsCurrentPath) {
    e.preventDefault();
    return openAndScrollTo(pathAndId[1]);
  }
});
