const component = {
  template: `
    <a href="#" v-on:click="print"><slot></slot></a>
  `,
  methods: {
    print() {
      return window.print();
    },
  },
};

export default component;
