import throttle from 'lodash/throttle';

const template = `
<div ref="container">
  <slot v-bind:is-in-viewport="isInViewport"></slot>
</div>
`;

const THROTTLE_INTERVAL = 150;

const component = {
  template,
  data() {
    return {
      isInViewport: true,
    };
  },
  methods: {
    handleScroll: throttle(function _handleScroll() {
      const rect = this.$refs.container.getBoundingClientRect();

      const viewportTop = window.scrollY;
      const viewportBottom = viewportTop + window.screen.availHeight;

      const elementTop = rect.top + viewportTop;
      const elementBottom = elementTop + rect.height;

      this.isInViewport = elementBottom > viewportTop && elementTop < viewportBottom;
    }, THROTTLE_INTERVAL),
  },
  mounted() {
    window.addEventListener('resize', this.handleScroll);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleScroll);
    window.removeEventListener('scroll', this.handleScroll);
  },
};

export default component;
