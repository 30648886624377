import SLT2 from './factory_reset_steps/slt2';
import SLT3 from './factory_reset_steps/slt3';
import SLT5 from './factory_reset_steps/slt5';
import SLT6 from './factory_reset_steps/slt6';

const STEPS_FACTORY_RESET = {
  SLT2,
  SLT3,
  SLT3b: SLT3,
  SLT3c: SLT3,
  SLT5,
  SLT6,
};

export default STEPS_FACTORY_RESET;
