<template>
  <div class="m-3 row text-center">
    <div v-if="deviceListVisible">
      <DeviceList
        ref="deviceListComponent"
        v-bind:power-cycle-steps-completed="powerCycleStepsCompleted"
        v-on:start-visual-check-and-api-reset="startReset"
        v-on:create-case="createCase"
        v-on:update-device-status="setDevicesInfo"
      />
    </div>
    <div v-else>
      <button
        class="btn btn-primary mt-4"
        type="submit"
        v-on:click="openModal()"
      >
        Start!
      </button>
    </div>

    <modal
      ref="modal"
      name="stepsModal"
      classes="hive-modal"
      v-on:before-close="beforeModalClose"
      v-bind:scrollable="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <a ref="linkToScroll" href="#targetRef" class="hidden">click</a>

          <div class="modal-header px-0">
            <button
              type="button"
              class="close"
              v-on:click="$modal.hide('stepsModal')"
            >
              <svg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'40'" v-bind:height="'40'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M42.828 40l12.586-12.586a2 2 0 00-2.828-2.828L40 37.172 27.414 24.586a2 2 0 00-2.828 2.828L37.172 40 24.586 52.586a2 2 0 002.828 2.828L40 42.828l12.586 12.586a2 2 0 002.828-2.828L42.828 40z" fill="#29424C" fill-rule="nonzero"/></svg>
              <span class="sr-only">close</span>
            </button>
          </div>

          <div id="answers-container" class="modal-body">
            <div id="answers" class="text-center col-10 offset-1">
              <div>
                <div
                  v-for="step in completedSteps"
                  v-bind:key="step.id"
                  class="mb-3"
                >
                  <FlowStep
                    v-if="!isFunction(step)"
                    v-bind="step"
                    v-on:answer="handleAnswer"
                    v-on:report-issue="createCase"
                  />
                </div>
              </div>

              <div
                v-if="currentStep"
                id="currentStep"
                v-bind:class="{ 'border-1-blue rounded': !isFunction(currentStep) }"
              >
                <FlowStep
                  v-bind:key="index"
                  v-bind="currentStep"
                  v-bind:display-report-issue="displayReportIssue"
                  v-on:answer="handleAnswer"
                  v-on:report-issue="createCase"
                />
              </div>
            </div>

            <div ref="targetRef" id="targetRef" style="height: 50px;">
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import BASE_STEPS from './heating_offline_flows/steps/base';
import VISUAL_CHECK_STEPS from './heating_offline_flows/steps/visual_check';
import STEPS_POWERCYCLE_RESET from './heating_offline_flows/steps/power_check';
import STEPS_API_RESET from './heating_offline_flows/steps/api_reset';

import STEPS_FACTORY_RESET from './heating_offline_flows/steps/factory_reset';

import FlowStep from './heating_offline_flows/flow_step.vue';
import DeviceList from './heating_offline_flows/device_list.vue';

import { FUNCTION, QUESTION } from './heating_offline_flows/steps/constants';

const SLT5 = 'SLT5';
const SLT_2_3_3B_3C_3D_6 = 'SLT_2_3_3B_3C_3D_6';
const THERMOSTAT_OFFLINE = 'THERMOSTAT_OFFLINE';
const THERMOSTAT_OFF_RECEIVER_ON = 'THERMOSTAT_OFF_RECEIVER_ON';
const THERMOSTAT_OFF_RECEIVER_OFF = 'THERMOSTAT_OFF_RECEIVER_OFF';
const THERMOSTAT_ON_RECEIVER_OFF = 'THERMOSTAT_ON_RECEIVER_OFF';

const POINTER_FR = {
  SLT_2_3_3B_3C_3D_6: {
    THERMOSTAT_OFF_RECEIVER_ON: 3,
    THERMOSTAT_ON_RECEIVER_OFF: 1,
    THERMOSTAT_OFF_RECEIVER_OFF: 1,
  },
  SLT5: {
    THERMOSTAT_OFFLINE: 2,
  },
};

// correct order of steps group:
// - base steps
// - visual check steps
// - power check steps(optional depends on conditions)
// - api reset step(optional depends on conditions)

const component = {
  inject: ['client', 'dataLayer'],
  data() {
    return {
      completedSteps: [],
      index: 1,
      powerCycleStepsCompleted: false,
      factoryResetCompleted: false,
      apiResetStepsVisible: false,
      visualCheckStepsVisible: false,
      deviceListVisible: false,
      thermostatType: null,
      thermostatOnline: false,
      receiverOnline: false,
      formattedAnswersLog: [],
      closeModalWithoutConfirmation: false,
      displayReportIssue: false,
    };
  },
  computed: {
    steps() {
      if (this.visualCheckStepsVisible) {
        return VISUAL_CHECK_STEPS[this.typeKey][this.statusKey];
      }

      if (this.apiResetStepsVisible) {
        return this.stepsApiReset;
      }

      if (this.powercycleResetStepsVisible) {
        return STEPS_POWERCYCLE_RESET[this.thermostatType];
      }

      return BASE_STEPS;
    },
    currentStep() {
      return this.steps.find(q => q.id === this.index);
    },
    typeKey() {
      return this.thermostatType === 'SLT5' ? SLT5 : SLT_2_3_3B_3C_3D_6;
    },
    statusKey() {
      if (this.typeKey === SLT5) {
        return THERMOSTAT_OFFLINE;
      }

      if (!this.receiverOnline) {
        return this.thermostatOnline ? THERMOSTAT_ON_RECEIVER_OFF : THERMOSTAT_OFF_RECEIVER_OFF;
      }

      return THERMOSTAT_OFF_RECEIVER_ON;
    },
    stepsApiReset() {
      const apiSteps = STEPS_API_RESET[this.typeKey][this.statusKey];

      const factoryResetSteps = STEPS_FACTORY_RESET[this.thermostatType];

      const cursorForFRSteps = POINTER_FR[this.typeKey][this.statusKey];

      apiSteps.splice(cursorForFRSteps, 0, ...factoryResetSteps);

      apiSteps.forEach((element, index) => {
        element.id = index + 1;
        const newVal = index < apiSteps.length - 1 ? index + 2 : null;
        if (element.type === QUESTION) {
          element.nextStepYes = newVal;
        } else if ((typeof element.nextStep !== 'function')) {
          element.nextStep = newVal;
        }
      });

      return apiSteps;
    },
  },
  methods: {
    handleAnswer(nextStep, selectedAnswer) {
      if (typeof nextStep === 'function') {
        nextStep(this);
      } else {
        this.currentStep.selectedAnswer = selectedAnswer;
        this.completedSteps.push({ ...this.currentStep });

        const log = [this.currentStep.text, this.currentStep.selectedAnswer, '<br />'].join();
        this.formattedAnswersLog.push(log);

        this.addDLEvents();

        this.index = nextStep;
        this.$nextTick(() => {
          const el = this.$refs.linkToScroll; // todo fix this trick
          el.click();
        });
      }
    },
    displayPowercycleResetSteps() {
      this.clearSteps();
      this.powercycleResetStepsVisible = true;
    },
    displayDeviceList(params) {
      if (this.deviceListVisible) {
        this.reloadDeviceList();
      } else {
        this.deviceListVisible = true;
      }

      if (params && params.powerCycled) {
        this.powerCycleStepsCompleted = true;
      }

      if (params && params.factoryReset) {
        this.factoryResetCompleted = true;
      }

      this.closeModalWithoutConfirmation = true;
      this.$modal.hide('stepsModal');
      this.closeModalWithoutConfirmation = false;
      this.clearSteps();
    },
    goToChat() {
      window.alert('Please contact with an agent via chat!');
    },
    startReset() {
      this.clearSteps();

      if (this.powerCycleStepsCompleted) {
        this.apiResetStepsVisible = true;
      } else {
        this.visualCheckStepsVisible = true;
      }

      this.openModal();
    },
    setDevicesInfo(devicesInfo) {
      this.thermostatType = devicesInfo.thermostatType;
      this.thermostatOnline = devicesInfo.thermostatOnline;
      this.receiverOnline = devicesInfo.receiverOnline;
    },
    isFunction(step) {
      return step.type === FUNCTION;
    },
    displayReportIssueButton() {
      this.displayReportIssue = true;
    },
    createCase() {
      const answers = this.formattedAnswersLog.join();

      const params = {
        answers,
        thermostatType: this.thermostatType,
        thermostatPowerCycled: this.powerCycleStepsCompleted,
        receiverPowerCycled: this.powerCycleStepsCompleted,
        thermostatFactoryReset: this.factoryResetCompleted,
        receiverFactoryReset: this.factoryResetCompleted,
        heatingDevicesBackOnline: this.thermostatOnline && this.receiverOnline,
      };

      this.client.createCase({ ...params })
        .then((resp) => {
          if (resp.success) {
            window.alert('Case created! A case has been recorded on our system if you require further assistance from support.');
            window.location.reload();
          }
        });
    },
    clearSteps() {
      this.powercycleResetStepsVisible = false;
      this.visualCheckStepsVisible = false;
      this.apiResetStepsVisible = false;

      this.index = 1;
      this.completedSteps = [];
    },
    openModal() {
      this.$modal.show('stepsModal');
    },
    beforeModalClose(event) {
      if (this.closeModalWithoutConfirmation) return;

      let msg;

      if (this.apiResetStepsVisible || this.powercycleResetStepsVisible) {
        msg = 'If you exit during the reset troubleshooting, your devices will not be back online and working. Are you sure you want to exit?';
      } else {
        msg = 'You will lose your progress! Are you sure?';
      }

      // eslint-disable-next-line no-alert
      const confirmed = window.confirm(msg);

      if (!confirmed) {
        event.stop();
      }
    },
    reloadDeviceList() {
      this.$refs.deviceListComponent.getList();
    },
    addDLEvents() {
      const baseSteps = !this.visualCheckStepsVisible && !this.apiResetStepsVisible && !this.powercycleResetStepsVisible;

      if (baseSteps) {
        let ev;
        if (this.currentStep.id === 3 && this.currentStep.selectedAnswer === 'Yes') {
          ev = 'HubRepositionedYes';
        }

        if (this.currentStep.id === 6 && this.currentStep.selectedAnswer === 'No') {
          ev = 'SignalBoosterInstalledNo';
        }

        if (this.currentStep.id === 7 && this.currentStep.selectedAnswer === 'Yes') {
          ev = 'SignalBoosterPurchaseYes';
        }

        if (ev) {
          this.dataLayer.addHeatingOfflineEvent(
            `heatingOffline.${ev}`
          )
        }
      }

      let text = this.currentStep.type === 'function' ? 'function' : this.currentStep.text;

      this.dataLayer.addHeatingOfflineEvent(
        'heatingOffline.step.click',
          { 
            question: text,
            answer: this.currentStep.selectedAnswer
          }
        )
    }
  },
  watch: {
    currentStep(newValue) {
      if (newValue) {
        newValue.selectedAnswer = null;
      }
    },
  },
  components: {
    FlowStep,
    DeviceList,
  },
};

export default component;

</script>
