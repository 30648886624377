import { TYPE_CONFIRM } from '../constants';

const SLT3 = [
  {
    id: 1,
    text: `Switch the boiler off at the isolation switch/fuse spur (this should be located near the Hive Receiver next to the boiler).
          When this has been done, all the lights on the receiver will go out.`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5sUIazFUCA0BjFx3EEqOX2/56a921555591432c7bd5d5d43c25f826/image1-31.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 2,
  },
  {
    id: 2,
    text: `Press and hold the ‘back arrow’ icon and the ‘menu’ icon on the thermostat touch screen at the same time (these are located bottom left and bottom middle, below the silver dial). You should hold these until you see the boot screen “Boot Vx.x.xx…”.
           Correct - Thermostat will display “Welcome, searching”.
           Incorrect – Thermostat will not go to “Searching” as the two icons on the touchscreen were not pressed for long enough.`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/1t5E2GtxiUzKd2eDg3CvpP/9cdb8b778962b6f2fee7c2bfef5d9d2e/image3-33.jpeg?h=250',
    ...TYPE_CONFIRM,
    nextStep: 3,
  },
  {
    id: 3,
    text: 'Remove one battery from the thermostat.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/7w3YATpk4qQ81Awr7vtNoe/17d9797386f4fc9bd5bfa84a7decf4d8/image3-34.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
  {
    id: 4,
    text: `Switch the boiler back on at the isolation switch/fuse spur (as in step 1).
           The status light (top right corner of the receiver) should flash either pink or amber, then turn to a solid green colour.`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/4Fp68miSuQILRYh9AkV9gh/594829d036563c98a31d69c24205dc82/image5-small-39.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 5,
  },
  {
    id: 5,
    text: `Hold the central heating button down on the receiver (bottom right) until the status light is flashing pink.
          Once this has flashed pink release the button and then hold again until the status light is double flashing amber.`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/0NX3rbNecX0maKnmKgFCD/6e66114e20bc3adedbcfe0e869cd0d2c/bu.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 6,
  },
  {
    id: 6,
    text: `Put the battery back in the thermostat. The thermostat will display “Welcome, searching” again.
           Wait until this disappears, once this has happened the receiver status light will turn green.
           The thermostat will then take you through a welcome tour.`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/uK6cxqJVe7WslKBOwGYSb/a62927339080d836979240a8ef4f1072/______________2023-11-26___16.57.33.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 7,
  },
];

export default SLT3;
