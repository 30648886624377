import MinimumStarReviews from 'mixins/minimum_star_reviews';
import TrustpilotAverageRating from 'mixins/trustpilot_average_rating';

const component = {
  mixins: [MinimumStarReviews, TrustpilotAverageRating],
  inject: ['client'],
  props: {
    apiKey: { type: String, required: true },
    skus: { type: String, required: true },
    starIconPaths: { type: Array, required: true },
  },
  data() {
    return {
      stars: 0,
      total: 0,
      isLoading: false,
      reviews: [],
      page: 0,
      perPage: 100,
      locale: 'en-GB',
      activeReviewIndex: 0,
    };
  },
  created() {
    this.getSummary();
    this.getReviews();
  },
  computed: {
    review() {
      return this.reviews[this.activeReviewIndex];
    },
  },
  methods: {
    nextReview() {
      const newValue = this.activeReviewIndex + 1;

      if (newValue >= this.reviews.length) {
        this.activeReviewIndex = 0;
      } else {
        this.activeReviewIndex = newValue;
      }
    },
    prevReview() {
      const newValue = this.activeReviewIndex - 1;

      if (newValue < 0) {
        this.activeReviewIndex = this.reviews.length - 1;
      } else {
        this.activeReviewIndex = newValue;
      }
    },
  },
};

export default component;
