// we need to fill forms with random phone numbers
// because they needs to be unique for each checkout run (SalesForce)
import randomPhone from 'random/phone';
import randomEmail from 'random/email';

// keys(rules) should be snake_cased
const rules = {
  address: (marketScope) => {
    const data = {
      title: 'Mr',
      firstName: 'John',
      lastName: 'Doe',
      email: randomEmail(),
      // commented out the following because we need a click
      // on the terms & conditions and marketing preference check boxes
      // instead of setting it logically.
      // termsAndConditions: true,
      // noContactPreference: true,
    };
    data.emailConfirmation = data.email;

    switch (marketScope) {
      case 'ie':
        data.city = 'Dublin';
        data.address1 = 'Sample street 1';
        data.territory = 'Dublin';
        data.phone = randomPhone(marketScope);
        break;
      case 'it':
        data.title = 'Signore';
        data.postcode = '30132';
        data.city = 'Venezia';
        data.address1 = 'Strada campione 1';
        data.territory = 'Veneto';
        data.phone = randomPhone(marketScope);
        break;
      case 'fr':
        data.title = 'M.';
        data.postcode = '75014';
        data.city = 'Paris';
        data.address1 = '8 ALLEE DES LILAS';
        data.territory = 'Île-de-France';
        data.phone = randomPhone(marketScope);
        break;
      case 'us':
        data.address1 = 'Sample street 1';
        data.city = 'MONTPELIER';
        data.postcode = '05601';
        data.territory = 'Vermont';
        data.phone = randomPhone(marketScope);
        data.timezone = 'America/New_York';
        break;
      case 'ca':
        data.address1 = 'Sample street 1';
        data.postcode = 'T8N 1H9';
        data.territory = 'Ontario';
        data.city = 'Toronto';
        data.phone = randomPhone(marketScope);
        data.timezone = 'America/New_York';
        break;
      default:
        data.postcode = 'TW18 3AA'; // 'LE2 1AB'
        data.city = 'Staines-upon-Thames';
        data.address1 = 'Swan Court, Watermans Business Park';
        data.state = 1;
        data.phone = randomPhone(marketScope);
    }

    return data;
  },
};

const component = {
  props: ['marketScope', 'rule', 'target'],
  template: '<button type="button" class="btn btn-primary btn-sm" v-on:click="populate">[DEV] Populate</button>',
  methods: {
    populate() {
      if (rules[this.rule]) {
        const data = rules[this.rule].call(null, this.marketScope);
        this.$emit('populate', data);
        if (this.target) {
          const form = $(this.target);
          const keys = Object.keys(data);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (Object.prototype.hasOwnProperty.call(data, key)) {
              form.find(`#${key}`).val(data[key]);
            }
          }
        }
      } else {
        // eslint-disable-next-line no-alert
        alert(`Rule '${this.rule}' is not defined. Add your rule in app/javascript/components/base/populate_button.js`);
      }
    },
  },
};

export default component;
