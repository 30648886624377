/**
 *
 * Allow to enter only numeric values
 *
 * Based on https://stackoverflow.com/questions/48700683/input-field-only-accepting-numeric-value-using-directive-in-vue-js
 */
const directive = {
  bind(el) {
    el.addEventListener('keyup', () => {
      const regex = /^[0-9]*$/;
      if (!regex.test(el.value)) {
        // eslint-disable-next-line
        el.value = el.value.slice(0, -1);
      }
    });
  },
};

export default directive;
