// This mixin attaches data-component-name to the root
// element of each component. It's generated based on the
// component name.
//
// This can be used by other DOM parsing scripts, determining
// names for buttons and links inside components, alongside
// `data-widget` attribute.
//
const mixin = {
  mounted() {
    const componentName = this.$options.name;

    // Some components Vue can't name automatically, so if it's important
    // for analytics add the 'name' attribute manually to the component.
    if (componentName && this.$el.dataset) {
      this.$el.dataset.vueComponentName = componentName;
    }
  },
};

export default mixin;
