import {
  TYPE_QUESTION,
  TYPE_CONFIRM,
  TYPE_FUNCTION,
  NEXT_STEP_NO_CREATE_CASE,
  NEXT_STEP_RENDER_DEVICE_LIST_AFTER_FACTORY_RESET,
  ACTION_RESET_DEVICE,
  ACTION_TO_UP_MODE,
  ACTION_SET_TO_DISCOVERY_MODE,
} from '../../constants';

const THERMOSTAT_OFF_RECEIVER_ON = [
  {
    id: 1,
    ...TYPE_FUNCTION,
    ...ACTION_RESET_DEVICE,
    nextStep: 2,
  },
  {
    id: 2,
    ...TYPE_QUESTION,
    text: 'Is the receiver double amber flashing?',
    nextStep: 3,
    ...NEXT_STEP_NO_CREATE_CASE,
  },
  {
    id: 3,
    ...TYPE_CONFIRM,
    text: 'You will now need to factory reset the device. Please follow the next steps',
    button: 'Next',
    nextStep: 4,
  },
  {
    id: 4,
    ...TYPE_CONFIRM,
    text: 'Have you completed Factory reset?',
    nextStep: 5,
  },
  {
    id: 5,
    ...TYPE_QUESTION,
    text: 'Is the thermostat displaying searching?',
    nextStepYes: 6,
    ...NEXT_STEP_NO_CREATE_CASE,
  },
  {
    id: 6,
    ...TYPE_FUNCTION,
    text: 'pairing',
    ...ACTION_SET_TO_DISCOVERY_MODE,
    nextStep: 7,
  },
  {
    id: 7,
    ...TYPE_QUESTION,
    text: 'Is the thermostat showing the welcome message?',
    nextStepYes: 8,
    ...NEXT_STEP_NO_CREATE_CASE,
  },
  {
    id: 8,
    ...TYPE_CONFIRM,
    text: 'Please wait 30 seconds',
    nextStep: 9,
  },
  {
    id: 9,
    ...TYPE_FUNCTION,
    ...ACTION_TO_UP_MODE,
    nextStep: 10,
  },
  {
    id: 10,
    text: 'The device is now online! Check the list!',
    ...TYPE_CONFIRM,
    ...NEXT_STEP_RENDER_DEVICE_LIST_AFTER_FACTORY_RESET,
  },
];

export default THERMOSTAT_OFF_RECEIVER_ON;
