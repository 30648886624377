import MyEVPage from 'mixins/my_ev_page';

import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('customer');

const component = {
  mixins: [MyEVPage],
  computed: {
    ...mapState(['actions']),
    currentOrderStatus() {
      return 'Engineer call';
    },
    externalInstall() {
      return this.actions.externalInstallEVOrders?.length;
    },
  },
  methods: {
    bookAppointment() {
      window.open(this.currentOrder.evJobOrders[0].vhcBookingLink);
      this.$emit('go-back');
    },
  },
};

export default component;
