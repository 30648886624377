/**
 * Thermostat compatibility card
 */
const component = {
  template: `<div v-bind:class='cardClasses' v-on:click="$emit('selected', answer)">
    <div class="px-3 px-md-0"><img :src='answer.icon' width='96' height='96' class='d-block mx-auto mt-2' v-if="answer.icon != null"></div>
    <p class='compatibility-checker-carousel-title mx-auto mt-2 text-center' v-if="answer.text != null">{{answer.text}}</p>
  </div>`,
  props: {
    answer: { type: Object, required: true },
    selectedCard: { type: Object },
  },
  computed: {
    cardClasses() {
      let cardClasses = 'border-1-gray-lightest card rounded cursor-pointer mb-lg-2 mx-lg-2';
      if (this.selectedCard === this.answer) cardClasses += ' active';
      return this.answer.newLine ? 'w-100 d-none d-lg-block' : cardClasses;
    },
  },
};

export default component;
