import BasketItem from 'components/views/basket_item';
import BasketSummary from 'components/views/basket_summary';

import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapState } = createNamespacedHelpers('basket');

const component = {
  components: {
    'basket-item': BasketItem,
    'basket-summary': BasketSummary,
  },
  computed: {
    ...mapGetters([
      'isBasketEmpty',
      'lineItems',
    ]),
    ...mapState([
      'updating',
      'basket',
    ]),
  },
};

export default component;
