import { dragscroll } from 'vue-dragscroll';
import ViewportChecker from 'components/base/viewport_checker';

const component = {
  components: {
    'viewport-checker': ViewportChecker,
  },
  directives: {
    dragscroll,
  },
};

export default component;
