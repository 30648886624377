/**
 * Product Buy Page Gallery Widget
 */
import Carousel from 'components/views/carousel';
import HiresImage from 'components/base/hires_image';
import map from 'lodash/map';
import { createNamespacedHelpers } from 'vuex';
import Pricing from 'mixins/pricing';

const mapProductState = createNamespacedHelpers('productBuy').mapState;

const component = {
  inject: ['config'],
  name: 'product-gallery',
  mixins: [Pricing],
  data() {
    return {
      activeImageIndex: 0,
    };
  },
  methods: {
    onActivateImage(imageIndex) {
      this.activeImageIndex = imageIndex;
    },
    activeImage(imageIndex) {
      return imageIndex === this.activeImageIndex;
    },
    offerBadge(image) {
      if (!image.offerBadgePrice) return null;
      let offerBadgeValue;

      if (this.displayPercentageSaving) offerBadgeValue = this.percentageSaving();
      else offerBadgeValue = this.formattedSaving(this.cameraQuantity);

      return `${image.offerBadgeText} ${offerBadgeValue}`;
    },
  },
  computed: {
    ...mapProductState({
      widgets(state) {
        return state.widgets;
      },
      cameraQuantity(state) {
        return state.options.cameraQuantity || 1;
      },
      galleryWidget(state) {
        return state['v3-product-gallery'] || {};
      },
    }),
    urls() {
      const images = this.images || [];
      return map(images, image => image.large.src);
    },
    images() {
      return this.galleryWidget.images || {};
    },
    imageSavingsBadge() {
      return this.galleryWidget.imageSavingsBadge;
    },
  },
  watch: {
    images(val) {
      if (this.activeImageIndex >= val.length) {
        this.activeImageIndex = 0;
      }
    },
  },
  components: {
    'owl-carousel': Carousel,
    'hires-image': HiresImage,
  },
};

export default component;
