import Vue from 'vue/dist/vue.esm';

const PROFESSIONAL_INSTALLATION = 'professional';
const SELF_INSTALLATION = 'self-install';
const INSTALLATION_OPTIONS = [
  PROFESSIONAL_INSTALLATION,
  SELF_INSTALLATION,
];

export const baseMutations = {
  setProductSku(state, skuData) {
    const key = Object.keys(skuData)[0];
    Vue.set(state, key, skuData[key]);
  },
  setReloading(state, message) {
    Vue.set(state, 'reloading', true);

    // eslint-disable-next-line no-alert
    if (message) alert(message);

    window.location.reload();
  },
  setLoading(state, isPriceLoading) {
    Vue.set(state, 'isPriceLoading', isPriceLoading);
  },
  setPricing(state, pricing) {
    const {
      price,
      savingDisplayType,
      discountedPrice,
      monthlyPrice,
      annualPrice,
      recurringAnnualPrice,
      saving,
      recurringListPrice,
      items,
      deposit,
      oneOffPriceLater,
    } = pricing;

    const normalizedItems = items.map((item) => {
      const {
        productType,
        priceTotal,
        listPrice,
        discountAmount,
        discountPercentage,
        deposit: itemDeposit,
      } = item;

      return {
        productType: String(productType),
        priceTotal: parseFloat(priceTotal),
        deposit: parseFloat(itemDeposit),
        listPrice: parseFloat(listPrice),
        discountAmount: parseFloat(discountAmount),
        discountPercentage: parseInt(discountPercentage, 10),
      };
    });

    Vue.set(state, 'pricing', {
      price: parseFloat(price),
      discountedPrice: parseFloat(discountedPrice),
      monthlyPrice: parseFloat(monthlyPrice),
      annualPrice: parseFloat(annualPrice),
      recurringAnnualPrice: parseFloat(recurringAnnualPrice),
      saving: parseFloat(saving),
      items: normalizedItems,
      regularMonthlyPrice: recurringListPrice,
      savingDisplayType,
      deposit,
      oneOffPriceLater: parseFloat(oneOffPriceLater),
    });
  },
  incrementQuantity(state, increment) {
    Vue.set(state, 'quantity', state.quantity + increment);
  },
  incrementAlternativeQuantity(state, increment) {
    Vue.set(state, 'alternativeQuantity', state.alternativeQuantity + increment);
  },
  setQuantity(state, quantity) {
    Vue.set(state, 'quantity', quantity);
  },
  setAlternativeQuantity(state, alternativeQuantity) {
    Vue.set(state, 'alternativeQuantity', alternativeQuantity);
  },
  setInstallationOption(state, option) {
    if (!INSTALLATION_OPTIONS.includes(option)) {
      throw new Error(`Invalid installation option: ${option}`);
    }

    Vue.set(state, 'installationOption', option);
  },
  setProfessionalInstallationPrice(state, price) {
    Vue.set(state, 'professionalInstallationPrice', price);
  },
  setProfessionalInstallationSku(state, sku) {
    Vue.set(state, 'professionalInstallationSku', sku);
  },
  setLoadPricingPromise(state, promise) {
    Vue.set(state, 'loadPricingPromise', promise);
  },
};

export const baseGetters = {
  totalPrice({ pricing }) {
    return pricing.price;
  },
  deposit({ pricing }) {
    return pricing.deposit;
  },
  oneOffPriceLater({ pricing }) {
    return pricing.oneOffPriceLater;
  },
  totalDiscountedPrice({ pricing }) {
    return pricing.discountedPrice;
  },
  totalDiscount({ pricing }) {
    return pricing.saving || 0;
  },
  hasDiscount(_state, { totalDiscount }) {
    return totalDiscount > 0;
  },
  monthlyPrice({ pricing }) {
    return pricing.monthlyPrice;
  },
  regularMonthlyPrice({ pricing }) {
    return pricing.regularMonthlyPrice;
  },
  annualPrice({ pricing }) {
    return pricing.annualPrice;
  },
  recurringAnnualPrice({ pricing }) {
    return pricing.recurringAnnualPrice;
  },
  defaultInstallationOption() {
    return PROFESSIONAL_INSTALLATION;
  },
  professionalInstallationPrice(state) {
    return state.professionalInstallationPrice;
  },
  professionalInstallationSku(state) {
    return state.professionalInstallationSku;
  },
  loadPricingPromise(state) {
    return state.loadPricingPromise;
  },
};

export const makeBaseActions = ({ client }) => ({
  async loadPricing({ commit, getters }, sku) {
    const { pricingPayload } = getters;

    const promise = client.getPricing({}, pricingPayload);
    commit('setLoadPricingPromise', promise);

    commit('setLoading', true);
    const pricing = await promise;

    if (pricing.error) {
      // when the user leaves the browser open for longer than session timeout
      // and he requests pricing information the API will returning error.
      // we need to handle this case by reloading the page.
      commit('setReloading', pricing.error);
    } else {
      // update price only if coming from the most recent pricing call
      // this avoids displaying a price for a different product than what is selected
      if (promise === getters.loadPricingPromise) {
        commit('setPricing', pricing);
        commit('setLoading', false);
      }

      window.setTimeout(
        () => {
          let event = 'price-loaded';
          if (sku) event = `${event}-${sku}`;

          window.specEvents.push(event);
        },
        1000
      );
    }
  },
  incrementQuantity({ commit, dispatch, getters }, increment) {
    commit('incrementQuantity', increment);

    if (getters.professionalInstallationPrice !== undefined) {
      dispatch('loadProfessionalInstallationPrice');
    }

    return dispatch('loadPricing');
  },
  incrementAlternativeQuantity({ commit, dispatch, getters }, increment) {
    commit('incrementAlternativeQuantity', increment);

    if (getters.professionalInstallationPrice !== undefined) {
      dispatch('loadProfessionalInstallationPrice');
    }

    return dispatch('loadPricing');
  },
  increment({ dispatch }) {
    return dispatch('incrementQuantity', 1);
  },
  alternativeIncrement({ dispatch }) {
    return dispatch('incrementAlternativeQuantity', 1);
  },
  decrement({ dispatch }) {
    return dispatch('incrementQuantity', -1);
  },
  alternativeDecrement({ dispatch }) {
    return dispatch('incrementAlternativeQuantity', -1);
  },
  setQuantity({ commit, dispatch }, quantity) {
    commit('setQuantity', quantity);

    return dispatch('loadPricing');
  },
  setInstallationOption({ commit }, { selected }) {
    commit('setInstallationOption', selected);
  },
  async loadProfessionalInstallationPrice({ commit, getters }) {
    const { pricingPayload } = getters;

    const products = pricingPayload.products.map(product => [product.sku, product.quantity]);
    const response = await client.getInstallationPrice({}, { skus: Object.fromEntries(products) });

    commit('setProfessionalInstallationPrice', response.price);
    commit('setProfessionalInstallationSku', response.sku);

    window.specEvents.push('installation-widget.price-loaded');
  },
});
