import uniqBy from 'lodash/uniqBy';
import { createNamespacedHelpers } from 'vuex';
import Notifications from 'components/base/notifications';
import MyAccountHeader from 'components/base/my_account_header';
import Paginate from 'components/base/paginate';
import webview from 'utils/webview';
import Multipage from 'mixins/multipage';
import ScrollToTop from 'mixins/scroll_to_top';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');
const {
  mapState: mapCustomerState,
  mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');

const NUMBER_OF_DOWNLOAD_ATTEMPTS = 5;
const RETRY_AFTER_IN_MS = 1000;

const component = {
  inject: ['dataLayer'],
  mixins: [Multipage, ScrollToTop],
  data() {
    return {
      currentInvoice: {},
      invoiceDownloadAttempts: 0,
      invoiceDownloading: false,
    };
  },
  created() {
    this.showListOrEmpty();
    webview.ready();
  },
  computed: {
    ...mapCustomerState(['invoices']),
    ...mapCustomerGetters(['hasInvoices']),
    currentProducts() {
      return uniqBy(
        (this.currentInvoice.products || []).filter(p => p.type !== 'Delivery'),
        p => p.name
      );
    },
    isWebview() {
      return webview.isWebview();
    },
  },
  methods: {
    ...mapGlobalActions([
      'showError',
    ]),
    showListOrEmpty() {
      this.showPage(this.hasInvoices ? 'list' : 'empty');
      this.currentInvoice = {};
    },
    showInvoiceDetails(invoice) {
      this.dataLayer.manualClick({ moduleId: 'invoices', moduleItemId: invoice.id });

      this.invoiceDownloadAttempts = 0;
      this.currentInvoice = { ...invoice };
      this.currentPage = 'details';
      this.scrollToTop();
    },
    proxyShowInvoiceDetails(invoice) {
      return () => this.showInvoiceDetails(invoice);
    },
    downloadCurrentInvoice() {
      this.invoiceDownloading = true;
      this.invoiceDownloadAttempts += 1;

      this.downloadFile()
        .catch((e) => {
          if (e.message === '503') {
            if (NUMBER_OF_DOWNLOAD_ATTEMPTS > this.invoiceDownloadAttempts) {
              setTimeout(() => {
                this.downloadCurrentInvoice();
              }, RETRY_AFTER_IN_MS);
            } else {
              this.showError('Invoice is being regenerated. Please try again later.');
              this.invoiceDownloading = false;
            }
          } else {
            this.showError('Something went wrong. Please try again');
            this.invoiceDownloading = false;
          }
        })
        .then((success) => {
          if (success) this.invoiceDownloading = false;
        });
    },
    async downloadFile() {
      const link = this.currentInvoice.downloadLink;
      const fileName = link.split('/').pop();
      const response = await fetch(link);

      if (!response.ok) throw new Error(response.status);

      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');

      anchor.style.display = 'none';
      anchor.href = blobUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(blobUrl);

      return true;
    },
  },
  components: {
    Notifications,
    MyAccountHeader,
    Paginate,
  },
};

export default component;
