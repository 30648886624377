import { createNamespacedHelpers } from 'vuex';
import RequiredProductChoice from 'components/base/required_product_choice';
import INSTALLATION_TYPE from 'store/modules/product_buy/constants';

const { mapState, mapActions } = createNamespacedHelpers('productBuy');

const component = {
  inject: ['cookies'],
  props: ['countiesAvailableForOilHeatingInstall', 'countiesAvailableForGasHeatingInstall'],
  extends: RequiredProductChoice,
  data() {
    return {
      requirement: 'hasBoilerAndCountyRequirement',
      selectedGasHeating: false,
      selectedOilHeating: false,
      selectedCounty: undefined,
      isCountySelectorOpen: false,
    };
  },
  watch: {
    selectedGasHeating(newValue) {
      if (newValue) {
        this.cookies.set('ireland-heating-option', 'gas');
      }

      this.cookies.set('ireland-county', undefined);
      this.selectedCounty = undefined;

      this.updateRequirement();
    },
    selectedOilHeating(newValue) {
      if (newValue) {
        this.cookies.set('ireland-heating-option', 'oil');
      }

      this.cookies.set('ireland-county', undefined);
      this.selectedCounty = undefined;

      this.updateRequirement();
    },
    selectedCounty(newValue) {
      if (newValue) {
        this.cookies.set('ireland-county', newValue);
      }

      this.updateRequirement();
    },
  },
  computed: {
    complete() {
      return this.selectedCounty && this.hasInstallationAvailable;
    },
    ...mapState({
      isProfessionalInstall({ options }) {
        return options.heatingProductInstallationType === undefined
          || options.heatingProductInstallationType === INSTALLATION_TYPE.PRO;
      },
    }),
    enableCountySelector() {
      return this.selectedGasHeating || this.selectedOilHeating;
    },
    hasInstallationAvailable() {
      return (this.selectedGasHeating && this.countiesAvailableForGasHeatingInstall
        .indexOf(this.selectedCounty) !== -1) || (this.selectedOilHeating
          && this.countiesAvailableForOilHeatingInstall.indexOf(this
            .selectedCounty) !== -1);
    },
  },
  methods: {
    ...mapActions(['selectHahInstallationType']),
    revertToSelfInstall() {
      this.selectHahInstallationType({
        // Pretend we're updating from the installation widget,
        // so that the correct switch key is selected.
        widgetId: 'v3-hah-installation',
        selected: INSTALLATION_TYPE.SELF,
      });
    },
    onChangeButtonClick() {
      this.selectedGasHeating = false;
      this.selectedOilHeating = false;
    },
    selectGasHeating() {
      this.selectedGasHeating = true;
      this.selectedOilHeating = false;
    },
    selectOilHeating() {
      this.selectedGasHeating = false;
      this.selectedOilHeating = true;
    },
    selectCounty(county) {
      this.selectedCounty = county;
      this.isCountySelectorOpen = false;
      window.specEvents.push('productWidgets.v3-select-boiler-and-county.complete');
    },
    toggle() {
      if (this.enableCountySelector) {
        this.isCountySelectorOpen = !this.isCountySelectorOpen;
      }
    },
  },
  beforeMount() {
    const { cookies } = this;

    this.selectedGasHeating = cookies.get('ireland-heating-option') === 'gas';
    this.selectedOilHeating = cookies.get('ireland-heating-option') === 'oil';
    this.selectedCounty = cookies.get('ireland-county');
  },
};

export default component;
