import { createNamespacedHelpers } from 'vuex';
import HiveLoader from 'components/base/hive_loader';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapState } = createNamespacedHelpers('homeshieldStarterHomePackPage');
const { mapState: mapBasketState } = createNamespacedHelpers('basket');

const component = {
  mixins: [AddToBasketButton],
  props: {
    homeshieldSku: String,
    freeHomeshieldSku: String,
  },
  computed: {
    ...mapState(['sku']),
    ...mapBasketState(['basket']),
  },
  methods: {
    addHomeshieldToBasket() {
      this.addPackToBasket(this.homeshieldSku);
    },
    addFreeHomeshieldToBasket() {
      this.addPackToBasket(this.freeHomeshieldSku);
    },
    addPackToBasket(homeshieldSku) {
      // actually we don't need packSku for now: we use it to calculate proff intall price
      // but proff install price is the same for both packs
      // leave it here in case we need it in the future
      const productBuy = {
        product: {
          sku: [
            // { sku: this.packSku, quantity: 1 },
            { sku: homeshieldSku, quantity: 1 },
          ],
        },
      };

      this.addToBasket({ productBuy, quantity: 1, errorCallback: this.errorCallback });
    },
    errorCallback() {
      this.$modal.hide('homeshield-modal');
      this.scrollToTop();
    },
  },
  components: {
    'hive-loader': HiveLoader,
  },
};

export default component;
