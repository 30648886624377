const component = {
  props: ['products'],
  data() {
    return {
      slides: this.products.length,
      active: 0,
    };
  },
  methods: {
    isActive(index) {
      return this.active === index;
    },
    activate(index) {
      this.active = index;
      this.$refs.wrapper.scrollLeft = index * 218;
    },
  },
};

export default component;
