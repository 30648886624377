import Waves from 'vendors/waves';

/**
 * This mixins applies the wave effect to all rendered page elements when components is mounted.
 * Ideally, we should apply the effect only to the content of this component.
 * However, it is not trivial because of its current implementation.
 */
const mixin = {
  mounted() {
    Waves.displayEffect();
  },
};

export default mixin;
