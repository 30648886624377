/**
 * A Vue alternative for hires_image_tag output
 */
const component = {
  template: `
    <img
    v-bind:data-image-id="index"
    v-bind:width="image.width"
    v-bind:height="image.height"
    v-bind:class="computedClasses"
    v-bind:alt="image.alt"

    v-bind:src="computedSrcData.src"
    v-bind:srcset="computedSrcData.srcset"

    v-bind:data-src="computedSrcData.dataSrc"
    v-bind:data-srcset="computedSrcData.dataSrcset"
  />`,
  props: {
    image: {
      type: Object,
      required: true,
      validator: (value) => {
        const attrs = ['src', 'width', 'height', 'alt'];
        return attrs.every(attr => Object.prototype.hasOwnProperty.call(value, attr));
      },
    },
    classes: {
      type: String,
      default: 'img-fluid',
    },
    // extra attributes
    index: {
      type: Number,
      required: false,
    },
  },

  computed: {
    computedSrcData() {
      const lazyload = this.image.class !== undefined && this.image.class.includes('lazyload');

      return {
        src: lazyload ? '' : this.image.src,
        srcset: lazyload ? '' : this.image.srcset,
        dataSrc: lazyload ? this.image.data.src : null,
        dataSrcset: lazyload ? this.image.data.srcset : null,
      };
    },

    computedClasses() {
      const lazyload = this.image.class !== undefined && this.image.class.includes('lazyload');

      if (lazyload) {
        return `${this.classes} lazyload`;
      }
      return this.classes;
    },
  },
};

export default component;
