import Vue from 'vue/dist/vue.esm';

const HUB_OPTIONS = ['hubless', 'hub'];
const FITTING_OPTIONS = ['screw', 'bayonet'];
const COLOUR_OPTIONS = ['black', 'white'];

const mutations = {
  setPackSkuMappings(state, packSkuMappings) {
    Vue.set(state, 'packSkuMappings', { ...packSkuMappings });
  },
  setColour(state, option) {
    if (!COLOUR_OPTIONS.includes(option)) throw new Error(`Invalid colour option: ${option}`);

    Vue.set(state, 'colour', option);
  },
  setHubOption(state, option) {
    if (!HUB_OPTIONS.includes(option)) throw new Error(`Invalid hub option: ${option}`);

    Vue.set(state, 'hubOption', option);
  },
  setFittingType(state, option) {
    if (!FITTING_OPTIONS.includes(option)) throw new Error(`Invalid fitting option: ${option}`);

    Vue.set(state, 'fittingType', option);
  },
};

const moduleGetters = {
  sku({
    hubOption, fittingType, colour, packSkuMappings,
  }) {
    return packSkuMappings[colour][fittingType][hubOption];
  },
  hubOption({ hubOption }) {
    return hubOption;
  },
  colour({ colour }) {
    return colour;
  },
  fittingType({ fittingType }) {
    return fittingType;
  },
};

const makeActions = () => ({
  init({ commit }, { packSkuMappings }) {
    commit('setPackSkuMappings', packSkuMappings);
  },
  setColour({ commit }, selected) {
    commit('setColour', selected);
  },
  setFittingType({ commit }, selected) {
    commit('setFittingType', selected);
  },
  setHubOption({ commit }, { selected, specEvent }) {
    commit('setHubOption', selected);
    window.specEvents.push(specEvent);
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      packSkuMappings: {},
      fittingType: FITTING_OPTIONS[0],
      hubOption: HUB_OPTIONS[0],
      colour: COLOUR_OPTIONS[0],
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
