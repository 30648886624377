const component = {
  data() {
    return {
      isCompatible: null,
    };
  },
  methods: {
    noModal() {
      this.isCompatible = true;
    },
    showModal() {
      this.$modal.show('hub-compatibility');
      this.isCompatible = false;
    },
  },
};

export default component;
