/* eslint-disable */

class HiveTooltip {
  static initClass() {
    this.prototype.attr = {
      init: 'data-tooltip-init',
      toggle: 'data-bs-toggle="tooltip"',
      isActive: 'aria-describedby'
    };
  }

  constructor() {
    this.selector = `[${this.attr.toggle}]`;
  }

  init(event) {
    const $el = $(event.currentTarget);
    if (this.isInit($el)) { return; }

    return $el.tooltip()
      .attr(this.attr.init, true)
      .trigger(event); // without that tooltip will not shown on first hover
  }

  isInit($el) {
    return $el.attr(this.attr.init);
  }

  showTooltip($el) {
    if (!this.isInit($el)) { return; }
    return $el.tooltip('show');
  }

  hideAllTooltips() {
    const $el = $(this.selector).filter(`[${this.attr.isActive}]`);
    if (!$el.length) { return; }

    return $el.tooltip('hide');
  }
}
HiveTooltip.initClass();

const hiveTooltip = new HiveTooltip;

$(document).on('mouseenter touchstart', hiveTooltip.selector, event => hiveTooltip.init(event));

// Hover don't work correctly on mobiles
// Improved logic for touch devices with touch events
$(document).on('touchstart', hiveTooltip.selector, event => hiveTooltip.showTooltip($(event.currentTarget)));

$(document).on('touchstart', function(e) {
  if (!$(e.target).closest(hiveTooltip.selector).length) {
    return hiveTooltip.hideAllTooltips();
  }
});
