import { createNamespacedHelpers } from 'vuex';

import PrintButton from 'components/base/print_button';
import BasketSummary from 'components/views/basket_summary';
import BasketSummaryTable from 'components/views/basket_summary_table';
import BasketPage from 'components/pages/basket_page';

const { mapState } = createNamespacedHelpers('basket');
const { mapState: mapCheckoutState } = createNamespacedHelpers('checkout');

const component = {
  components: {
    'print-button': PrintButton,
    'basket-summary': BasketSummary,
    'basket-summary-table': BasketSummaryTable,
    'basket-page': BasketPage,
  },
  computed: {
    ...mapState({
      basket: 'basket',
    }),
    ...mapCheckoutState(['paymentMethod']),
  },
  mounted() {
    window.specEvents.push('checkout-confirmation-page.mounted');
  },
};

export default component;
