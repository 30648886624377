import YouTubePlayer from 'youtube-player';

const PlayerState = {
  UNSTARTED: -1,
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  CUED: 5,
};

const component = {
  inject: ['dataLayer'],
  props: {
    playerId: String,
    videoId: String,
  },
  data() {
    return {
      player: null,
      currentTime: 0,
    };
  },
  methods: {
    trackAction(action, params = {}) {
      const { videoId: id, dataLayer } = this;

      dataLayer.programme({ action, id, ...params });
    },
    async onPlayerReady() {
      const { player } = this;

      const duration = await player.getDuration();

      this.trackAction('load', { duration });

      player.playVideo();
    },
    onPlayerStateChange({ data: state }) {
      switch (state) {
        case PlayerState.PLAYING:
          this.trackAction('manualPlay');
          break;
        case PlayerState.PAUSED:
          this.trackAction('pause');
          break;
        case PlayerState.ENDED:
          this.trackAction('end');
          this.currentTime = 0;
          break;
        default:
          break;
      }
    },
    loadPlayer() {
      const { videoId, playerId, currentTime } = this;

      const player = YouTubePlayer(playerId);
      player.loadVideoById(videoId, currentTime);
      player.on('ready', this.onPlayerReady);

      // This is a one-time listener that is removed once `autoPlay` event is fired.
      const autoPlayListener = player.on('stateChange', ({ data: state }) => {
        if (state === PlayerState.PLAYING) {
          this.trackAction('autoPlay');
          player.off(autoPlayListener);
          player.on('stateChange', this.onPlayerStateChange);
        }
      });

      this.player = player;
    },
    async closeModal() {
      this.currentTime = await this.player.getCurrentTime();

      await this.player.destroy();

      this.trackAction('close');
      this.$refs.modal.visible = false;
    },
  },
};

export default component;
