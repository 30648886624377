/**
 * Some pages, for instance /plans/hive-welcome-home-plan/bt-portal
 * and /hive-app, include a picture of a phone using the
 * Hive mobile app. Said phone shows the Hive app view relevant to
 * the aspect of the product our customer is looking at.
 *
 * This controller changes the image on this virtual phone by hiding
 * or showing one of a series of relevant "carousel" images.
 */

/**
 * nextImage() returns the next image in a carousel, and handles
 * wrap-around. This saves us having to read properties from the DOM.
 */
const nextImageIndex = function nextImageIndex(carouselSizes, carousel, index) {
  const nextImage = index + 1;
  const lastIndex = carouselSizes[carousel];
  return nextImage === lastIndex ? 0 : nextImage;
};

/**
 * prevImage() returns the previous image in a carousel, and handles
 * wrap-around.
 */
const prevImageIndex = function prevImageIndex(carouselSizes, carousel, index) {
  const prevImage = index - 1;
  const lastIndex = carouselSizes[carousel] - 1;
  return prevImage < 0 ? lastIndex : prevImage;
};

/** Simply updating an element in the imageIndex array will not make
 * Vue react. We have to create a whole new array and replace the
 * original.
 */
const newImageIndex = function newImageIndex(imageIndex, carouselId, index) {
  const newArr = imageIndex;
  newArr[carouselId] = index;
  return [].concat(newArr);
};

/**
 * Vue component which responds to various events and updates the embedded
 * Hive App image accordingly. We use dependency injection to provide the
 * component with its own jQuery instance. This means our unit tests do not
 * have to worry about contaminating the global jQuery object and therefore
 * impacting other tests.
 */
const HiveAppMobilePicComponent = {
  inject: [
    'jQuery',
  ],
  props: {
    carouselSizes: Array,
  },
  data() {
    return {
      carouselId: 0,
      // keep an array of image indexes so when we go back to a tab
      // visited earlier, we see the last image we viewed in that tab.
      imageIndex: [0, 0, 0, 0],
    };
  },
  methods: {
    hideMe(carousel, image) {
      return !(carousel === this.carouselId
               && image === this.imageIndex[this.carouselId]);
    },
    unhideTabImage(tabId) {
      this.carouselId = tabId;
      setTimeout(() => { window.specEvents.push('mobile-app.tab-shown'); }, 1000);
    },
    jumpToCarouselImage(id) {
      this.imageIndex = newImageIndex(this.imageIndex, this.carouselId, id);
    },
    unhidePreviousCarouselImage() {
      const idx = prevImageIndex(this.carouselSizes, this.carouselId,
        this.imageIndex[this.carouselId]);
      this.imageIndex = newImageIndex(this.imageIndex, this.carouselId, idx);
    },
    unhideNextCarouselImage() {
      const idx = nextImageIndex(this.carouselSizes, this.carouselId,
        this.imageIndex[this.carouselId]);
      this.imageIndex = newImageIndex(this.imageIndex, this.carouselId, idx);
    },
    selectTabFromMenu(id) {
      this.carouselId = id;
      const tabLinkRef = `tab-link-${id}`;
      const collapseRef = `hive-app-dropdown-menu-${id}`;
      this.jQuery(this.$refs[tabLinkRef]).tab('show');
      this.jQuery(this.$refs[collapseRef]).collapse('hide');
    },
  },
};

export default HiveAppMobilePicComponent;
