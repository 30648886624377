const component = {
  data() {
    return {
      currentEntry: 0,
    };
  },
  methods: {
    showEntry(i) {
      this.currentEntry = i;
    },
  },
};

export default component;
