const component = {
  name: 'now-with-hive-video-playback',
  data() {
    return {
      target: 'hive-video-playback-modal',
    };
  },
  methods: {
    openModal() {
      this.$modal.show(this.target);
    },
    closeModal() {
      this.$modal.hide(this.target);
    },
  },
};

export default component;
