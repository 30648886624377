import { createNamespacedHelpers } from 'vuex';
import HoneyPackItem from 'components/views/honey/pack_item';
import AddToBasketButton from 'mixins/add_to_basket_button';
import AmountDiscountBadge from 'mixins/amount_discount_badge';
import BarclaysHomeshieldPack from 'mixins/barclays_homeshield_pack';

const { mapGetters, mapActions } = createNamespacedHelpers('packBuyPage');

const component = {
  inject: ['cookies'],
  mixins: [AmountDiscountBadge, AddToBasketButton, BarclaysHomeshieldPack],
  props: {
    packTitle: String,
    packSkuMappings: Object,
    showInstallationPageAfterPurchase: Boolean,
    homeshieldSku: String,
    playbackSku: String,
    baseIncludedProducts: {
      type: Object,
      required: true,
      validator: obj => Object.values(obj).every(quantity => typeof quantity === 'number'),
    },
  },
  data() {
    return {
      includedProducts: this.baseIncludedProducts,
      quantityLocked: false,
      namespace: 'packBuyPage',
      withHub: true,
    };
  },
  computed: {
    ...mapGetters([
      'totalDiscountedPrice',
      'totalDiscount',
      'sku',
      'basketPayload',
      'defaultHubOption',
      'defaultPetFriendlyOption',
    ]),
    withoutPetFriendly() {
      // do nothing
    },
    withPetFriendly() {
      // do nothing
    },
    totalPrice() {
      return this.totalDiscountedPrice;
    },
  },
  methods: {
    ...mapActions(['init', 'setInstallationOption']),
    installationOption() {
      // do nothing
    },
    homeshieldOption() {
      // do nothing
    },
  },
  created() {
    const {
      packSkuMappings,
      showInstallationPageAfterPurchase,
      homeshieldSku,
      playbackSku,
    } = this;

    this.init({
      packSkuMappings,
      showInstallationPageAfterPurchase,
      homeshieldSku,
      playbackSku,
    });
  },
  components: {
    HoneyPackItem,
  },
};

export default component;
