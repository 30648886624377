import spliceByProperty from 'utils/splice_by_property';
import {
  STATUSES,
  getters as statusGetters,
  mutations as statusMutations,
} from '../utils/status_state';
import {
  reload as reloadMutation,
  markAsPending as markAsPendingMutation,
  resetPending as resetPendingMutation,
} from '../utils/mutation_helpers';
import {
  hasItems as hasItemsGetter,
} from '../utils/getter_helpers';

export const RELOAD_ADDRESSES = 'RELOAD_ADDRESSES';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const MARK_AS_PENDING_ADDRESS = 'MARK_AS_PENDING_ADDRESS';
export const RESET_ADDRESS_PENDING = 'RESET_ADDRESS_PENDING';
export const MARK_AS_PENDING_SUBSCRIPTION_ORDER = 'MARK_AS_PENDING_SUBSCRIPTION_ORDER';
export const RESET_SUBSCRIPTION_ORDER_PENDING = 'RESET_SUBSCRIPTION_ORDER_PENDING';
export const UPDATE_SUBSCRIPTION_ORDER = 'UPDATE_SUBSCRIPTION_ORDER';
export const RELOAD_APPOINTMENTS = 'RELOAD_APPOINTMENTS';
export const RELOAD_INVOICES = 'RELOAD_INVOICES';
export const UPDATE_TOTAL_BALANCE = 'UPDATE_TOTAL_BALANCE';
export const SET_RECENTLY_ADDED_ADDRESS = 'SET_RECENTLY_ADDED_ADDRESS';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const UPDATE_ACTIONS = 'UPDATE_ACTIONS';
export const UPDATE_RETURN = 'UPDATE_RETURN';

// getter helpers

const defaultFinderGetter = type => ({ addresses }) => (addresses || [])
  .find(address => address[`default_${type}`]);

// action helpers

const pendingFn = fn => (context, apiMethod, pathArgs) => {
  const primaryKeyValue = Object.values(pathArgs)[0];

  if (!primaryKeyValue) { return false; }

  return fn(context, apiMethod, primaryKeyValue);
};

const markItemAsPending = pendingFn(({ commit }, apiMethod, primaryKeyValue) => {
  let mutation;
  if (apiMethod.match(/address/i)) {
    mutation = MARK_AS_PENDING_ADDRESS;
  } else if (apiMethod.match(/order/i)) {
    mutation = MARK_AS_PENDING_SUBSCRIPTION_ORDER;
  } else {
    return;
  }

  commit(mutation, primaryKeyValue);
});

const resetPending = pendingFn(({ commit }, apiMethod, primaryKeyValue) => {
  let mutation;
  if (apiMethod.match(/address/i)) {
    mutation = RESET_ADDRESS_PENDING;
  } else if (apiMethod.match(/order/i)) {
    mutation = RESET_SUBSCRIPTION_ORDER_PENDING;
  } else {
    return;
  }

  commit(mutation, primaryKeyValue);
});

const isItemPending = pendingFn(({ state }, apiMethod, primaryKeyValue) => {
  let type;
  let key;
  if (apiMethod.match(/address/i)) {
    [type, key] = ['addresses', 'reference'];
  } else if (apiMethod.match(/order/i)) {
    return state.subscriptions.some(sub => sub.orders.some(
      order => order.number === primaryKeyValue && order.pending
    ));
  } else {
    return false;
  }

  const record = state[type].find(item => item[key] === primaryKeyValue);

  return record.pending;
});

const api = (context, client, apiMethod, pathArgs = {}, data = null) => {
  if (isItemPending(context, apiMethod, pathArgs)) {
    const error = new Error();
    error.code = 'PENDING_ITEM';

    return Promise.reject(error);
  }

  const { commit } = context; // eslint-disable-line no-shadow

  markItemAsPending(context, apiMethod, pathArgs);

  return client[apiMethod](pathArgs, data)
    .then((response) => {
      commit(STATUSES.SUCCESS);
      return response;
    })
    .catch((error) => {
      commit(STATUSES.FAILURE);
      throw error;
    })
    .finally(() => {
      resetPending(context, apiMethod, pathArgs);
    });
};

// the state

export const getters = {
  ...statusGetters,
  hasAddresses: hasItemsGetter('addresses'),
  deliveryAddress: defaultFinderGetter('delivery'),
  installationAddress: defaultFinderGetter('installation'),
  billingAddress: defaultFinderGetter('billing'),
  hasSubscriptions: hasItemsGetter('subscriptions'),
  hasAppointments: hasItemsGetter('appointments'),
  hasInvoices: hasItemsGetter('invoices'),
  hasOutstandingBalance: ({ totalBalance }) => totalBalance > 0,
};

const markAsPendingOrder = markAsPendingMutation('orders', 'number');
const resetPendingOrder = resetPendingMutation('orders', 'number');
export const mutations = {
  ...statusMutations,
  [RELOAD_ADDRESSES]: reloadMutation('addresses'),
  [SET_RECENTLY_ADDED_ADDRESS]: reloadMutation('recentlyAddedAddress'),
  [MARK_AS_PENDING_ADDRESS]: markAsPendingMutation('addresses', 'reference'),
  [RESET_ADDRESS_PENDING]: resetPendingMutation('addresses', 'reference'),
  [DELETE_ADDRESS]({ addresses }, addressReference) {
    spliceByProperty(addresses, 'reference', addressReference);
  },
  [MARK_AS_PENDING_SUBSCRIPTION_ORDER]({ subscriptions }, number) {
    const subscription = subscriptions.find(sub => sub.orders.some(o => o.number === number));

    if (subscription) {
      markAsPendingOrder(subscription, number);
    }
  },
  [RESET_SUBSCRIPTION_ORDER_PENDING]({ subscriptions }, number) {
    subscriptions.every(sub => resetPendingOrder(sub, number));
  },
  [UPDATE_SUBSCRIPTION_ORDER]({ subscriptions }, order) {
    const subscription = subscriptions.find(sub => sub.number === order.subscriptionNumber);

    if (subscription) {
      spliceByProperty(subscription.orders, 'number', order.number, order);
    }
  },
  [RELOAD_APPOINTMENTS]: reloadMutation('appointments'),
  [RELOAD_INVOICES]: reloadMutation('invoices'),
  [UPDATE_TOTAL_BALANCE]: reloadMutation('totalBalance'),
  [UPDATE_BILLING]: reloadMutation('billing'),
  [UPDATE_ACTIONS]: reloadMutation('actions'),
  [UPDATE_RETURN]: reloadMutation('currentReturn'),
};

const makeActions = ({ client }) => ({
  // Addresses API
  createAddress(context, { data: address }) {
    return api(context, client, 'createAddress', {}, { address })
      .then(({ addresses, recentlyAddedAddress }) => {
        context.commit(RELOAD_ADDRESSES, addresses);
        context.commit(SET_RECENTLY_ADDED_ADDRESS, recentlyAddedAddress);
      });
  },
  updateAddress(context, { reference: addressReference, data: address }) {
    return api(context, client, 'updateAddress', { addressReference }, { address })
      .then(({ addresses }) => {
        context.commit(RELOAD_ADDRESSES, addresses);
      });
  },
  deleteAddress(context, { reference: addressReference }) {
    return api(context, client, 'deleteAddress', { addressReference })
      .then(() => {
        context.commit(DELETE_ADDRESS, addressReference);
      });
  },
  // Orders API
  getOrder(context, number) {
    return api(context, client, 'getOrder', { number })
      .then(({ order }) => {
        context.commit(UPDATE_SUBSCRIPTION_ORDER, order);

        return order;
      });
  },
  createUpload(context, { number, photoMetaData, skipPhoto }) {
    const data = photoMetaData;

    return api(context, client, 'createUpload', { number, skipPhoto }, data)
      .then(res => res);
  },
  // Billings API
  getBilling(context) {
    return api(context, client, 'getBilling')
      .then(({ billing }) => {
        context.commit(UPDATE_BILLING, billing);

        return billing;
      });
  },
  // Actions API
  getActions(context) {
    return api(context, client, 'getActions')
      .then(({ actions }) => {
        context.commit(UPDATE_ACTIONS, actions);

        return actions;
      });
  },
  // Appointments API
  updateAppointment(context, { appointmentId, data: slot }) {
    return api(context, client, 'updateAppointment', { appointmentId }, { slot })
      .then(({ appointments }) => {
        context.commit(RELOAD_APPOINTMENTS, appointments);
      });
  },
  // Invoices API
  getInvoices(context, type) {
    return api(context, client, 'getInvoices', { type })
      .then(({ totalBalance, invoices }) => {
        context.commit(UPDATE_TOTAL_BALANCE, totalBalance);
        context.commit(RELOAD_INVOICES, invoices);
      });
  },
  // eslint-disable-next-line camelcase
  payInvoices(
    context,
    {
      amount,
      source,
      // eslint-disable-next-line camelcase
      paymentMethodId: payment_method_id,
      // eslint-disable-next-line camelcase
      saveForLater: save_for_later,
      // eslint-disable-next-line camelcase
      saveAsDefault: save_as_default,
    }
  ) {
    return api(
      context,
      client,
      'payInvoices',
      {},
      {
        payment: {
          amount, source, payment_method_id, save_for_later, save_as_default,
        },
      },
    ).then(({ totalBalance, invoices, billing }) => {
      context.commit(UPDATE_TOTAL_BALANCE, totalBalance);
      context.commit(RELOAD_INVOICES, invoices);
      context.commit(UPDATE_BILLING, billing);
    });
  },
  getReturn(context, id) {
    return api(context, client, 'getReturn', { id })
      .then((riturn) => {
        context.commit(UPDATE_RETURN, riturn);

        return riturn;
      });
  },
  getReturnables(context, number) {
    return api(context, client, 'getReturnables', { subscriptionNumber: number })
      .then((returnables) => {
        return returnables;
      });
  },
  createReturn(context, { subscriptionNumber, addressReference, orderNumber, reason, reasonCode, items}) {
    return api(context, client, 'createReturn', {}, {
                                                      subscription: subscriptionNumber, address: addressReference,
                                                      orderNumber: orderNumber, reason: reason, items: items, reasonCode: reasonCode
                                                    })
      .then((resp) => {
        return resp;
      });
  },
  cancelReturn(context, id) {
    return api(context, client, 'cancelReturn', { returnId: id }).then(resp => resp);
  },
  cancelSubscription(context, { subscriptionNumber, sku, selectedReturnReason }) {
    return api(context, client, 'cancelSubscription', { subscriptionNumber, sku, reason: selectedReturnReason }).then(resp => resp);
  },
  renewSubscription(context, { subscriptionNumber, sku }) {
    return api(context, client, 'renewSubscription', { subscriptionNumber, sku }).then(resp => resp);
  },
  // getDevicesList(context) {
  //   return api(context, client, 'getDevicesList').then(resp => resp);
  // },
});

const makeModule = (injections) => {
  const actions = makeActions(injections);

  return {
    namespaced: true,
    state: {
      totalBalance: 0,
      recentlyAddedAddress: null,
      addresses: [],
      subscriptions: [],
      appointments: [],
      invoices: [],
      billing: {},
      actions: {},
    },
    getters,
    mutations,
    actions,
  };
};

export default makeModule;
