import { createNamespacedHelpers } from 'vuex';

import ValidatedForm from 'mixins/validated_form';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('redemptionPage');
const { mapActions: mapBasketActions } = createNamespacedHelpers('basket');

const pluralize = (count, noun, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;
// monthly
// IF discount amount = 100%: First X months free on a monthly plan
// IF discount amount <100%: X% off monthly plan for the first X months
// yearly
// IF number of years = 1: X% off annual plan for the first year
// IF number of years >1:  X% off annual plan for the first X years
// an example: 50 % off for the first 2 Months
// 70 % off for the first 1 Year(s)
const mapPercentCTA = (cta, period) => {
  const percentRegular = /(\d+)(?=\s*%)/;
  const percentRes = cta.match(percentRegular);
  const percentOff = percentRes && parseInt(percentRes[0], 10);

  if (period === 'month') {
    const monthRes = cta.match(/(\d+)(?=\s*month)/i);
    const months = monthRes && parseInt(monthRes[0], 10);
    if (cta.startsWith('100')) return `First ${pluralize(months, 'month')} free on a monthly plan`;
    return `${percentOff}% off monthly plan for the first ${pluralize(months, 'month')}`;
  }

  // period === year
  const yearRes = cta.match(/(\d+)(?=\s*year)/i);
  const years = yearRes && parseInt(yearRes[0], 10);
  return `${percentOff}% off annual plan for the first ${pluralize(years, 'year')}`;
};

// 2 off for the first 2 Months
const mapFixedDiscountCTA = cta => `£${cta}`;

const mapCTA = (cta, period) => {
  if (/%/.test(cta)) return mapPercentCTA(cta, period);

  return mapFixedDiscountCTA(cta);
};

const component = {
  mixins: [ValidatedForm, AddToBasketButton],
  props: {
    initialRetailerName: String,
    initialVoucherCode: String,
    installationSku: String,
    additionalInstallationSku: String,
    showMultipleProductsButton: Boolean,
    initialStep: {
      type: Number,
      default: 2,
    },
    heatingPlusRedemption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      voucherCodePresent: false,
      currentStep: this.initialStep,
      retailerName: this.initialRetailerName,
      voucherCode: this.initialVoucherCode,
      multipleProducts: false,
      error: 'Voucher code invalid or does not exist',
      showError: true,
      drivewayAndParking: false,
      property: false,
    };
  },
  computed: {
    ...mapState([
      'sku',
      'quantity',
      'isPriceLoading',
      'voucherCodeEligibility',
      'voucherCodeLoading',
      'voucherCodeError',
    ]),
    ...mapGetters([
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
      'pricingPayload',
      'deposit',
      'oneOffPriceLater',
    ]),
    hasDiscount() {
      return (this.totalDiscountedPrice && this.totalDiscountedPrice < this.totalPrice)
        || this.totalDiscountedPrice === 0;
    },
    buttonDisabled() {
      const {
        isPriceLoading,
        voucherCodePresent,
        validation,
        voucherCodeApplied,
      } = this;

      return isPriceLoading
        || (voucherCodePresent && (validation.invalid || !voucherCodeApplied));
    },
    voucherCodeApplied() {
      const { voucherCodePresent, hasDiscount } = this;

      return voucherCodePresent && hasDiscount;
    },
    showVoucherCodeError() {
      const {
        showError,
        isPriceLoading,
        pricingPayload,
        hasDiscount,
        voucherCodeError,
        heatingPlusRedemption,
      } = this;

      if (heatingPlusRedemption) return showError && voucherCodeError;

      return showError && pricingPayload.voucher_code && !isPriceLoading && !hasDiscount;
    },
    monthlyButtonCopy() {
      return mapCTA(this.voucherCodeEligibility.monthlyCTA, 'month');
    },
    yearlyButtonCopy() {
      return mapCTA(this.voucherCodeEligibility.yearlyCTA, 'year');
    },
  },
  watch: {
    pricingPayload(newPayload, oldPayload) {
      if (newPayload.voucher_code !== oldPayload.voucher_code) {
        this.showError = true;
      }
    },
    drivewayAndParking(newValue) {
      if (newValue && this.property) this.currentStep = 2;
    },
    property(newValue) {
      if (newValue && this.drivewayAndParking) this.currentStep = 2;
    },
    voucherCodeEligibility(newValue) {
      if (newValue) this.currentStep = 2;
    },
  },
  mounted() {
    if (this.heatingPlusRedemption) return;

    const { installationSku, additionalInstallationSku } = this;
    this.init({ sku: installationSku, additionalInstallationSku });
  },
  methods: {
    ...mapBasketActions(['addToBasket']),
    ...mapActions([
      'init',
      'setVoucherCode',
      'getVoucherDetails',
      'decrement',
      'increment',
    ]),
    setVoucherCodePresent(val) {
      this.voucherCodePresent = val;

      if (!val) {
        this.voucherCode = null;
        this.setVoucherCode({ voucherCode: null, retailerName: null });
      }

      this.currentStep = 3;
    },
    goBack() {
      const { multipleProducts, currentStep, initialStep } = this;

      if (multipleProducts) {
        this.multipleProducts = false;
        return;
      }

      if (currentStep > 2) {
        this.currentStep = 2;
        return;
      }

      if (currentStep === 2 && initialStep === 1) {
        this.drivewayAndParking = false;
        this.property = false;
        this.currentStep = 1;
        return;
      }

      window.location.href = '/install';
    },
    setMultipleProducts() {
      this.multipleProducts = true;
    },
    applyVoucherCode() {
      const { heatingPlusRedemption, voucherCode, retailerName } = this;
      if (heatingPlusRedemption) {
        this.getVoucherDetails({ voucherCode: voucherCode.trim(), retailerName });
        this.showError = true;
      } else {
        this.setVoucherCode({ voucherCode: voucherCode.trim(), retailerName });
        this.showError = true;
        this.currentStep = 3;
      }
    },
    hideError() {
      this.showError = false;
    },
    addProductToBasket(sku) {
      const { voucherCode, retailerName } = this;
      const products = [{ sku, quantity: 1 }];

      const productBuy = { product: { sku: products } };

      if (voucherCode) {
        productBuy.voucherCode = voucherCode;
      }

      if (retailerName) {
        productBuy.retailerName = retailerName;
      }

      this.addToBasket({ productBuy, quantity: 1 });
    },
  },
};

export default component;
