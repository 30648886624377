import Vue from 'vue/dist/vue.esm';
import get from 'lodash/get';

export const CHANNELS = {
  BUY_PAGE: {
    BULB_FITTING: {
      UPDATED: 'bulb-fitting-updated',
    },
    MULTIPACK: {
      UPDATED: 'multipack-updated',
    },
    QUANTITY: {
      UPDATED: 'camera-quantity-updated',
    },
  },
};

const instance = new Vue({
  name: 'EventBus',
  methods: {
    channel(dig) {
      return get(CHANNELS, dig, null);
    },
    publishBulbFittingUpdate(payload) {
      const event = CHANNELS.BUY_PAGE.BULB_FITTING.UPDATED;
      this.$emit(event, payload);
    },
    subscribeToBulbFittingUpdate(callback) {
      const event = CHANNELS.BUY_PAGE.BULB_FITTING.UPDATED;
      this.$on(event, callback);
    },
    publishMultipackUpdate(payload) {
      const event = CHANNELS.BUY_PAGE.MULTIPACK.UPDATED;
      this.$emit(event, payload);
    },
    subscribeToMultipackUpdate(callback) {
      const event = CHANNELS.BUY_PAGE.MULTIPACK.UPDATED;
      this.$on(event, callback);
    },
    publishQuantityUpdate(payload) {
      const event = CHANNELS.BUY_PAGE.QUANTITY.UPDATED;
      this.$emit(event, payload);
    },
    subscribeToQuantityUpdate(callback) {
      const event = CHANNELS.BUY_PAGE.QUANTITY.UPDATED;
      this.$on(event, callback);
    },
  },
});

export default instance;
