import Vue from 'vue/dist/vue.esm';
import flattenDeep from 'lodash/flattenDeep';

const findProduct = (actionProducts, actionId, sku) => (
  actionProducts[actionId].find(product => product.sku === sku)
);

const changeQuantity = (product, delta) => {
  Vue.set(product, 'quantity', product.quantity + delta);
};

export const QUANTITY_OPS = {
  INCREMENT: 'QUANTITY_INCREMENT',
  DECREMENT: 'QUANTITY_DECREMENT',
  namespaced: {
    INCREMENT: 'hiveActions/QUANTITY_INCREMENT',
    DECREMENT: 'hiveActions/QUANTITY_DECREMENT',
  },
};

const mutations = {
  [QUANTITY_OPS.INCREMENT]({ hiveActions }, { sku, actionId }) {
    changeQuantity(findProduct(hiveActions.actionProducts, actionId, sku), 1);
  },
  [QUANTITY_OPS.DECREMENT]({ hiveActions }, { sku, actionId }) {
    changeQuantity(findProduct(hiveActions.actionProducts, actionId, sku), -1);
  },
};

const getters = {
  productList: ({ hiveActions = {} }) => {
    const { actionProducts = {} } = hiveActions;
    const values = Object.values(actionProducts);
    return flattenDeep(values);
  },
};

const hiveActionsModule = {
  namespaced: true,
  state: {
    hiveActions: {
      actionProducts: {},
    },
  },
  getters,
  mutations,
};

export default hiveActionsModule;
