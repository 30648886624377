<template>
  <div class="mt-4 d-flex flex-column align-items-stretch d-md-inline-block me-md-1">
    <button class="btn btn-primary w-auto" v-on:click="goBack()" v-if="showBackButton">
      Done
    </button>

    <button v-if="showRescheduleButton" class="btn btn-secondary mt-1 mt-md-0 ms-md-1 w-auto" v-on:click="$emit('reschedule')">
      Reschedule
    </button>

    <button v-on:click="showContactModal()"
            v-bind:class="{ 'mt-1 mt-md-0 ms-md-1 btn-secondary': (showBackButton || showRescheduleButton), 'btn-primary': !(showBackButton && showRescheduleButton) }"
            v-if="showContactButton"
            class="btn w-auto d-block d-md-inline-block">
      Contact us
    </button>
  </div>
</template>

<script>
export default {
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    showContactButton: {
      type: Boolean,
      default: true,
    },
    showRescheduleButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showContactModal() {
      this.$modal.show('ev-contact');
    },
    goBack() {
      this.$emit('go-back');
    },
  },
};
</script>
