import { createNamespacedHelpers } from 'vuex';
import DiscoverHiveArticle from 'components/views/discover_hive_article';
import HiveLoader from 'components/base/hive_loader';

const { mapGetters, mapActions } = createNamespacedHelpers('discoverHiveArticles');

const component = {
  computed: {
    ...mapGetters([
      'leadingArticle',
      'floatingArticles',
      'wideArticles',
      'normalArticles',
      'hasMoreArticles',
      'isPending',
    ]),
  },
  methods: {
    ...mapActions([
      'loadMore',
    ]),
  },
  components: {
    'discover-hive-article': DiscoverHiveArticle,
    'hive-loader': HiveLoader,
  },
};

export default component;
