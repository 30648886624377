import Vue from 'vue/dist/vue.esm';

const PAYMENT_OPTIONS = ['monthly', 'annual'];
const THERMOSTAT_PRESENCE_OPTIONS = [true, false];

const mutations = {
  setHeatingPlusSkuMappings(state, heatingPlusSkuMappings) {
    Vue.set(state, 'heatingPlusSkuMappings', { ...heatingPlusSkuMappings });
  },
  setPaymentType(state, option) {
    if (!PAYMENT_OPTIONS.includes(option)) throw new Error(`Invalid payment option: ${option}`);

    Vue.set(state, 'paymentType', option);
  },
  setThermostatPresence(state, option) {
    if (!THERMOSTAT_PRESENCE_OPTIONS.includes(option)) throw new Error(`Invalid thermostat presence option: ${option}`);

    Vue.set(state, 'thermostatPresence', option);
  },
  setHubSku(state, hubSku) {
    Vue.set(state, 'hubSku', hubSku);
  },
  setFrameSku(state, frameSku) {
    Vue.set(state, 'frameSku', frameSku);
  },
  setFreeFrameVoucher(state, freeFrameVoucher) {
    Vue.set(state, 'freeFrameVoucher', freeFrameVoucher);
  },
};

const moduleGetters = {
  sku({
    paymentType, heatingPlusSkuMappings,
  }) {
    if (paymentType !== null) {
      return heatingPlusSkuMappings[paymentType];
    }

    return null;
  },
  basketPayload(state, { sku }) {
    const { hubSku, frameSku, freeFrameVoucher } = state;
    const skus = [{ sku, quantity: 1 }];
    const productBuy = { product: { sku: skus } };

    if (hubSku !== null) {
      skus.push({ sku: hubSku, quantity: 1 });
    }

    if (frameSku && freeFrameVoucher) {
      skus.push({ sku: frameSku, quantity: 1 });
      productBuy.voucherCode = freeFrameVoucher;
    }

    return { productBuy, quantity: 1 };
  },
  thermostatPresence({ thermostatPresence }) {
    return thermostatPresence;
  },
  paymentType({ paymentType }) {
    return paymentType;
  },
};

const makeActions = () => ({
  init({ commit }, {
    heatingPlusSkuMappings,
    hubSku,
    freeFrameVoucher,
  }) {
    commit('setHeatingPlusSkuMappings', heatingPlusSkuMappings);
    commit('setHubSku', hubSku);
    if (freeFrameVoucher) commit('setFreeFrameVoucher', freeFrameVoucher);
  },
  setPaymentType({ commit }, selected) {
    commit('setPaymentType', selected);
  },
  setThermostatPresence({ commit }, selected) {
    commit('setThermostatPresence', selected);
  },
  setHubSku({ commit }, hubSku) {
    commit('setHubSku', hubSku);
  },
  setFrameSku({ commit }, frameSku) {
    commit('setFrameSku', frameSku);
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      heatingPlusSkuMappings: {},
      sku: 12,
      paymentType: PAYMENT_OPTIONS[1],
      thermostatPresence: true,
      hubSku: null,
      frameSku: null,
      freeFrameVoucher: null,
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
