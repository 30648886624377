const component = {
  props: {
    playbackType: String,
    playbackTier: Number,
  },
  methods: {
    onSelect(selected) {
      this.$emit('change-storage', selected);
    },
  },
};

export default component;
