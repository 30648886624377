import ScrollToTop from 'mixins/scroll_to_top';

const component = {
  mixins: [ScrollToTop],
  data() {
    return {
      item: null,
    };
  },
  methods: {
    onModalOpen(ev) {
      const { item } = ev.params;

      this.item = item;
    },
    removeItem() {
      const { $store, item } = this;

      $store.dispatch('basket/removeFromBasket', { ...item, errorCallback: this.scrollToTop });
      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('basket-line-item-remove-item-popup');
    },
  },
};

export default component;
