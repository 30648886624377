/* eslint-disable */

import Utils from './utils';

const AddToBasketQuantitySelectorClass = (window.AddToBasketQuantitySelector = class AddToBasketQuantitySelector {
  static initClass() {
    this.selectors = {
      container: '.add-to-basket-quantity-selector',
      add_to_basket: '.js-add-to-basket',
      quantity: '.js-product-quantity-num',
      increase: '.js-quantity-increase',
      decrease: '.js-quantity-decrease'
    };
  }

  constructor(scope) {
    this.decreaseQuantity = this.decreaseQuantity.bind(this);
    this.increaseQuantity = this.increaseQuantity.bind(this);
    this.quantity = this.quantity.bind(this);
    this.setButtonsDisabled = this.setButtonsDisabled.bind(this);
    this.onQuantityError = this.onQuantityError.bind(this);
    this.setQuantityRemote = this.setQuantityRemote.bind(this);
    this.setWidgetState = this.setWidgetState.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.scope = $(scope).parents(AddToBasketQuantitySelector.selectors.container);
  }

  decreaseQuantity() {
    this.quantity({decrease: true});
    this.setButtonsDisabled();
    this.setQuantityRemote();
  }

  increaseQuantity() {
    this.quantity({increase: true});
    this.setButtonsDisabled();
    this.setQuantityRemote();
  }

  quantity(opts) {
    if (opts == null) { opts = {}; }
    const el = this.scope.find(AddToBasketQuantitySelector.selectors.quantity);
    if (opts.decrease) { el.val(parseInt(el.val(), 10) - 1); }
    if (opts.increase) { el.val(parseInt(el.val(), 10) + 1); }
    return el;
  }

  setButtonsDisabled() {
    const max = parseInt(this.quantity().data('max'), 10);
    const min = parseInt(this.quantity().data('min'), 10);
    const val = parseInt(this.quantity().val(), 10);
    if (val >= max) {
      this.scope.find(this.constructor.selectors.increase).addClass('disabled');
    } else {
      this.scope.find(this.constructor.selectors.increase).removeClass('disabled');
    }
    if (val <= min) {
      this.scope.find(this.constructor.selectors.decrease).addClass('disabled');
    } else {
      this.scope.find(this.constructor.selectors.decrease).removeClass('disabled');
    }
  }

  onQuantityError() {
    // Set quantity to last known value
    const scope = this.quantity();
    const lastQuantity = scope.data('last-quantity') || 1;
    scope.val(lastQuantity);
    this.setButtonsDisabled.call(scope);
  }

  setQuantityRemote() {
    const scope = this.quantity();
    let active = parseInt(scope.data('request-count') || 0, 10);
    const href = scope.data('href');
    let quantity = parseInt(scope.val(), 10);
    const max = parseInt(scope.data('max'), 10);
    const min = parseInt(scope.data('min'), 10);

    if (isNaN(quantity)) {
      this.onQuantityError();
      return false;
    }

    if (quantity > max) {
      quantity = max;
    } else if (quantity < min) {
      quantity = min;
    }

    if (active) {
      // Request in flight, queue this request
      scope.data('request-count', ++active);
      return;
    }

    $.ajax({
      url: Utils.changeParam(href, 'quantity', quantity),
      method: 'PATCH',
      dataType: 'script',
      beforeSend: () => {
        scope.val(quantity);
        scope.data('request-count', ++active);
        this.setWidgetState();
      },
      complete: () => {
        // Re-start queued request
        active = parseInt(scope.data('request-count'), 10);
        if (--active) {
          active = 0;
          setTimeout(this.setQuantityRemote, 50);
        }
        scope.data('request-count', active);

        window.specEvents.push('addToBasketQuantitySelector.quantitySet.complete');
      },
      success: () => {
        scope.data('last-quantity', quantity);
      },
      error: this.onQuantityError
    });
  }

  setWidgetState() {
    const quantity = parseInt(this.quantity().val(), 10);
    if (quantity === 0) {
      this.scope.addClass('state-not-in-basket');
    } else {
      this.scope.removeClass('state-not-in-basket');
    }
  }

  addToBasket() {
    this.quantity({increase: true});
    this.setButtonsDisabled();
    return this.setWidgetState();
  }

  static setupEventListeners() {
    $(document).on('click', `${AddToBasketQuantitySelector.selectors.container} ${AddToBasketQuantitySelector.selectors.increase}`, function(e) {
      const o = new AddToBasketQuantitySelector(e.currentTarget);
      o.increaseQuantity();
      e.preventDefault();
      return false;
    });

    $(document).on('click', `${AddToBasketQuantitySelector.selectors.container} ${AddToBasketQuantitySelector.selectors.decrease}`, function(e) {
      const o = new AddToBasketQuantitySelector(e.currentTarget);
      o.decreaseQuantity();
      e.preventDefault();
      return false;
    });

    $(document).on('click', `${AddToBasketQuantitySelector.selectors.container} ${AddToBasketQuantitySelector.selectors.add_to_basket}`, function(e) {
      if ($(e.currentTarget).parents('form').is('[data-remote]')) {
        const o = new AddToBasketQuantitySelector(e.currentTarget);
        o.addToBasket();
      }
    });

    return $(document).on('change', `${AddToBasketQuantitySelector.selectors.container} ${AddToBasketQuantitySelector.selectors.quantity}`, function(e) {
      const o = new AddToBasketQuantitySelector(e.currentTarget);
      o.setButtonsDisabled();
      o.setQuantityRemote();
    });
  }
});
AddToBasketQuantitySelectorClass.initClass();


AddToBasketQuantitySelector.setupEventListeners();

export default AddToBasketQuantitySelector;
