import { createNamespacedHelpers } from 'vuex';
import ZuoraHostedPage from 'components/views/zuora_hosted_page';
import ZuoraPaymentMethods from 'components/views/zuora_payment_methods';
import Notifications from 'components/base/notifications';
import MyAccountHeader from 'components/base/my_account_header';
import Multipage from 'mixins/multipage';
import Deferred from 'utils/deferred';
import webview from 'utils/webview';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');

const {
  mapState: mapCustomerState,
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const {
  mapState: mapZuoraState,
  mapGetters: mapZuoraGetters,
  mapActions: mapZuoraActions,
} = createNamespacedHelpers('zuora');

const component = {
  mixins: [Multipage],
  inject: ['dataLayer'],
  data() {
    return {
      currentPage: 'list',
      waitingForZuora: false,
      zuoraHostedPageComponentKey: 0,
    };
  },
  created() {
    this.currentPage = this.hasPaymentMethods ? 'list' : 'empty';
    this.reloadZuoraHostedPageComponent();
    webview.ready();
  },
  mounted() {
    this.notifyDefaultPaymentNearExpiry();
  },
  computed: {
    ...mapZuoraState([
      'paymentMethods',
    ]),
    ...mapZuoraGetters([
      'hasPaymentMethods',
      'defaultPaymentMethod',
    ]),
    ...mapCustomerState([
      'billing',
      'actions',
    ]),
    requireNewDefaultPaymentMethod() {
      return this.defaultPaymentMethod && this.requireNewPaymentMethod(this.defaultPaymentMethod);
    },
  },
  methods: {
    ...mapZuoraActions([
      'savePaymentMethodForLater',
      'setDefaultPaymentMethod',
    ]),
    ...mapGlobalActions([
      'showNotice',
      'showError',
    ]),
    ...mapCustomerActions(['getBilling', 'getActions']),
    showDefaultError() {
      this.showError('Something went wrong');
    },
    onZuoraLoad() {
      this.waitForZuora.resolve();
    },
    showFormOnceZuoraLoaded() {
      this.waitingForZuora = true;

      this.dataLayer.appendEvent({ event: 'module.interact.manual.AddNewCard' });

      this.waitForZuora.promise.then(() => {
        this.showForm();
        this.waitingForZuora = false;
        this.$scrollTo('#new-payment-method');
      });
    },
    processPaymentMethod(paymentMethodId) {
      this.showLoading();

      this.savePaymentMethodForLater({ id: paymentMethodId })
        .then(() => {
          if (this.requireNewDefaultPaymentMethod) {
            // // Set newly added payment method as default if current default is near expiry
            this.setDefaultPaymentMethod({ id: paymentMethodId })
              .then(() => {
                this.getBilling()
                  .then(() => { this.showNotice('Payment method has been added and set as default'); })
                  .catch(() => { this.showDefaultError(); });
              })
              .catch(() => { this.showDefaultError(); });
          } else {
            this.dataLayer.appendEvent({ event: 'module.interact.manual.AddNewCard_success' });
            this.showNotice('Payment method has been added');
          }
        })
        .catch(() => { this.showDefaultError(); })
        .finally(() => {
          this.showList();
          this.reloadZuoraHostedPageComponent();
        });
    },
    defaultPaymentMethodUpdated() {
      this.notifyDefaultPaymentNearExpiry();

      this.getActions()
        .catch(() => { this.showDefaultError(); });
    },
    notifyDefaultPaymentNearExpiry() {
      if (this.requireNewDefaultPaymentMethod) this.showError('Please add a new payment method');
    },
    // Re-render ZuoraHostedPage componenet by incrementing the Vue key and
    // reset the promise that is used in `showFormOnceZuoraLoaded` method
    reloadZuoraHostedPageComponent() {
      this.zuoraHostedPageComponentKey += 1;
      this.waitForZuora = new Deferred();
    },
    requireNewPaymentMethod(paymentMethod) {
      return this.billing.hasFuturePayments && paymentMethod.requiresReplacement;
    },
  },
  components: {
    ZuoraHostedPage,
    ZuoraPaymentMethods,
    Notifications,
    MyAccountHeader,
  },
};

export default component;
