const reservedKey = '__meta__';

// TODO extends to a list of reserved keywods
function hasReservedKey(data) {
  return !!data[reservedKey];
}

/**
 *
 * Returns the initial state.
 *
 * @throws TypeError, if state is not json or a reserved keyword is used in properties
 * @throws Error, if <script id="front-end-init-state"> not found in document
 *
 */
function getInitState(id = 'front-end-init-state') {
  const node = document.querySelector(`script[id=${id}]`);
  if (!node) {
    throw new Error(`Missing <script id="${id}" type="application/json">.`);
  }
  const data = JSON.parse(node.textContent);
  if (hasReservedKey(data)) {
    throw new TypeError('Cannot use reserved key __meta__ in init state.');
  }
  return data;
}

export default getInitState;
