import * as Cookies from 'tiny-cookie';

import { createNamespacedHelpers } from 'vuex';

import HoneyCameraColours from 'components/views/honey/camera_colours';
import HoneyTrustPilot from 'components/views/honey_trust_pilot';
import HoneyVideoPlayback from 'components/views/honey/video_playback';
import HoneyOptionSelector from 'components/views/honey/option_selector';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import FormattedSaving from 'mixins/formatted_saving';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('cameraBuyPage');

const component = {
  inject: ['config'],
  mixins: [FormattedSaving, AddToBasketButton],
  props: {
    baseFixedDiscount: Number,
    initialSku: String,
    packSku: String,
    annualPlaybacks: Object,
    monthlyPlaybacks: Object,
    isMarketScopeUk: Boolean,
    isSeasonalOffer: Boolean,
    baseInstallationPrice: Number,
    additionalInstallationPrice: Number,
    playbackPrices: Object,
    isBarclaysOffer: Boolean,
    namespace: { // required by the installation_widget
      type: String,
      default: '_unused',
    },
    baseInstallationOption: String,
  },
  data() {
    return {
      buyAllThreeButtonLoading: false,
      basketInterval: null,
    };
  },
  computed: {
    ...mapState([
      'quantity',
      'playbackType',
      'installationOption',
      'sku',
      'isPriceLoading',
    ]),
    ...mapState({
      displayPercentageSaving(state) {
        return state.pricing.savingDisplayType === 'percentage';
      },
      saving(state) {
        return state.pricing.saving;
      },
    }),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'cameraDiscountPercentage',
      'totalDiscount',
      'playbackTier',
      'basketPayload',
      'quantity',
      'playbackSku',
      'sku',
    ]),
    discountBadge() {
      if (!this.hasDiscount) {
        return '';
      }

      let offerBadgeValue;

      if (this.displayPercentageSaving) offerBadgeValue = `${this.cameraDiscountPercentage}%`;
      else if (this.isBarclaysOffer) {
        offerBadgeValue = this.formattedAmount(
          this.baseFixedDiscount * this.quantity
        );
      } else offerBadgeValue = this.formattedAmount(this.saving);

      return `Save ${offerBadgeValue}`;
    },
    professionalInstallationPrice() {
      return this.baseInstallationPrice + this.additionalInstallationPrice * (this.quantity - 1);
    },
    playbackPrice() {
      return this.playbackPrices[this.playbackSku];
    },
    playbackPriceText() {
      if (this.isMarketScopeUk && this.playbackType === 'monthly' && this.playbackTier === 3) return 'HomeShield Plus';

      return 'Video Playback';
    },
  },
  created() {
    const {
      initialSku,
      annualPlaybacks,
      monthlyPlaybacks,
      baseInstallationOption,
    } = this;
    const params = {
      sku: initialSku,
      annualPlaybacks,
      monthlyPlaybacks,
    };
    this.setMultipackQtyValue();

    if (baseInstallationOption) {
      params.installationOption = baseInstallationOption;
    }

    this.init(params);
  },
  beforeDestroy() {
    clearInterval(this.basketInterval);
  },
  methods: {
    ...mapActions([
      'decrement',
      'increment',
      'setQuantity',
      'init',
      'setCamera',
      'setPlaybackType',
      'setInstallationOption',
    ]),
    setMultipackQtyValue() {
      const cookieName = 'redirected_from_sku_for_preselect';
      const packSku = Cookies.get(cookieName) || this.packSku;
      const packSkus = ['HCD00381', 'HCD00188', 'HCD00192', 'HCD00377'];

      if (packSkus.includes(packSku)) {
        this.setQuantity(2);
        Cookies.remove(cookieName);
      }
    },
  },
  components: {
    HoneyTrustPilot,
    TrustPilotPopup,
    HoneyCameraColours,
    HoneyVideoPlayback,
    HoneyOptionSelector,
  },
};

export default component;
