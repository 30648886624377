/**
 * The `v-fadein="value"` directive shows an element with fadeid effect,
 * if `value` is true. Otherwise, the element is hidden.
 */
const directive = {
  bind(el, binding) {
    const isVisible = binding.value;
    if (!isVisible) {
      $(el).hide();
    }
  },
  update(el, binding) {
    const isVisible = binding.value;
    if (isVisible) {
      $(el).fadeIn();
    } else {
      $(el).hide();
    }
  },
};

export default directive;
