import Survey from 'mixins/survey';
import EmitRequirements from 'mixins/emit_requirements';

import { createNamespacedHelpers } from 'vuex';
import get from 'lodash/get';

const { mapState } = createNamespacedHelpers('productBuy');

const component = {
  mixins: [Survey, EmitRequirements],
  props: ['storageName'],
  data() {
    return {
      selectedProducts: null,
    };
  },
  computed: {
    ...mapState({
      products(state) {
        return get(state, `widgets['${this.widgetId}'].products`, null);
      },
      stages(state) {
        return get(state, `widgets['${this.widgetId}'].surveys`, null);
      },
    }),
    widgetId: () => 'thermostat-compatibility',
    complete() {
      return this.selectedProducts !== null;
    },
    journeyWithHeaderImage() {
      return [1, 3, 4].includes(this.journeyId);
    },
  },
  mounted() {
    const storedData = localStorage.getItem(this.storageName);
    if (storedData) {
      try {
        Object.assign(this.$data, JSON.parse(storedData));
      } catch (e) {
        localStorage.removeItem(this.storageName);
      }
    }
    this.updateRequirement();
  },
  methods: {
    updateRequirement() {
      this.emitRequirementChange({
        hasThermostatCompatibilityRequirement: this.complete,
        thermostatSkus: this.selectedProducts,
      });
    },
    completeJourney(type) {
      this.selectedProducts = this.products[type];

      this.dataToLocalStorage();
      this.updateRequirement();
    },
    dismiss() {
      this.resetHistory();

      if (this.selectedProducts !== null) {
        this.selectedProducts = null;
        this.updateRequirement();
      }
    },
    dataToLocalStorage() {
      const parsed = JSON.stringify(this.$data);
      localStorage.setItem(this.storageName, parsed);
    },
  },
};

export default component;
