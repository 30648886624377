import ContactForm from 'mixins/contact_form';
import AddressAutocomplete from 'components/views/address_autocomplete';

const component = {
  mixins: [ContactForm],
  components: {
    AddressAutocomplete,
  },
};

export default component;
