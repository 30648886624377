import { NEXT_STEP_YES_POWER_SYCLE, NEXT_STEP_NO_GO_TO_LIVE_CHAT, TYPE_QUESTION } from '../../constants';

const THERMOSTAT_OFFLINE = [{
  id: 1,
  text: 'Does the Thermostat light up when any button is pressed?',
  ...TYPE_QUESTION,
  ...NEXT_STEP_YES_POWER_SYCLE,
  nextStepNo: 2,
},
{
  id: 2,
  text: 'Please re-seat the batteries, is the thermostat now on?',
  ...TYPE_QUESTION,
  ...NEXT_STEP_YES_POWER_SYCLE,
  nextStepNo: 3,
},
{
  id: 3,
  text: 'Please change the batteries, is the thermostat now on?',
  ...TYPE_QUESTION,
  ...NEXT_STEP_YES_POWER_SYCLE,
  ...NEXT_STEP_NO_GO_TO_LIVE_CHAT,
}];

export default THERMOSTAT_OFFLINE;
