
/**
 * A `either` is a component that allows to choose between two exclusive alternatives.
 */
const component = {
  props: {
    selected: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      currentSelection: this.selected,
    };
  },
  computed: {
    isSecond() {
      return this.currentSelection === 2;
    },
    isFirst() {
      return this.currentSelection === 1;
    },
  },
  methods: {
    selectSecond() {
      this.currentSelection = 2;
    },
    selectFirst() {
      this.currentSelection = 1;
    },
  },
};

export default component;
