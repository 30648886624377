// Change the language based on locale.
// Affected templates
// Template `app/views/shared/_language_changer_inline.html.erb` (only CA)

// note that since redirect depends on CSRF token
// we cannot replace Ruby `link_to` with an html link controlled by Vue
// Ideally, we would like to externalize redirection logic as we did for `country_changer.js`

const component = {
  // props are properties initialised by parent component
  // we use them to pass data from Ruby to Javascript
  // See also [here](https://vuejs.org/v2/guide/components-props.html)
  props: {
    locale: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
  },
  // this is the local state
  // we can reference the local state inside HTML template
  // using proper [Vue directives](https://vuejs.org/v2/api/#Directives)
  // Vue automatically updates the HTML when state changes
  data() {
    return {
      // init state is initialized with props
      selectedLocale: this.locale,
    };
  },
  // methods are called inside template and are triggered by dom events
  methods: {
    setLocale(locale) {
      this.selectedLocale = locale;
    },
    isSelectedLocale(locale) {
      return this.selectedLocale === locale;
    },
  },
};

export default component;
