import ZuoraHostedPage from 'components/views/zuora_hosted_page';
import ZuoraPaymentMethods from 'components/views/zuora_payment_methods';
import AddressAutocomplete from 'components/views/address_autocomplete';
import ValidatedForm from 'mixins/validated_form';
import ZuoraForm from 'mixins/zuora_form';
import PaymentForm from 'mixins/payment_form';

const component = {
  name: 'payment-form',
  mixins: [ValidatedForm, ZuoraForm, PaymentForm],
  inject: ['dataLayer', 'client'],
  props: {
    dmpgSubmitEvent: String,
    showNewCardForm: {
      type: Boolean,
      default: false,
    },
    useExistingAddress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newCardForm: this.showNewCardForm,
      isUseExistingAddress: this.useExistingAddress,
    };
  },
  computed: {
    isAddressFormInvalid() {
      const {
        validated,
        invalid,
      } = this.validation;

      return !this.isUseExistingAddress && validated && invalid;
    },
  },
  methods: {
    onDevPopulate(data) {
      Object.assign(this, data);
    },
    setZuoraAddress(address) {
      if (!this.isUseExistingAddress) {
        this.$refs.zuoraHostedPage.setAddress(address);
      }
    },
    beforeSubmit() {
      this.dataLayer.triggerCartEvent(this.dmpgSubmitEvent);
    },
    onSubmit(event) {
      event.preventDefault();

      this.validation.passes(() => {
        if (this.isShowNewCardForm) {
          event.preventDefault();
          if (!this.isUseExistingAddress) {
            this.$refs.addressAutocomplete.setAddress();
          }
          this.$refs.zuoraHostedPage.submit();
        } else {
          this.paymentMethodsDisabled = true;
          this.submitting = true;
          this.beforeSubmit(); // for new cards beforeSubmit is called in onZuoraSuccess method
          event.target.submit();
        }
      });
    },
    onZuoraSuccess(paymentMethodId, response) {
      this.dataLayer.triggerCartEvent(this.dmpgCartStateEvent);
      this.dataLayer.triggerCartEvent(this.dmpgSubmitEvent);

      this.beforeSubmit();
      this.submitZuoraForm({
        order_zuora_payment_method_id: paymentMethodId,
        order_zuora_auth_transaction_id: response.AuthTransactionId,
      });
    },
    updateDataLayerTransactionObject() {
      this.client.getDmpgDataLayerTransaction().then((data) => {
        this.dataLayer.transactionFailure(data);
      });
    },
    onZuoraDecline() {
      this.onZuoraLoad();
      this.updateDataLayerTransactionObject();
    },
    onZuoraError(_key, _code, errorMessage) {
      this.onZuoraLoad();
      this.dataLayer.updateError(
        this.dmpgOkStatusCode,
        this.dmpgPaymentFormError,
        this.dmpgOkStatusMsg,
        errorMessage
      );
      this.updateDataLayerTransactionObject();
    },
  },
  components: {
    ZuoraHostedPage,
    ZuoraPaymentMethods,
    AddressAutocomplete,
  },
};

export default component;
