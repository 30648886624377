import { TYPE_CONFIRM } from '../constants';

const SLT2 = [
  {
    id: 1,
    text: 'Turn the receiver off by switching the isolation off. To confirm, all the lights should have gone off.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5sUIazFUCA0BjFx3EEqOX2/56a921555591432c7bd5d5d43c25f826/image1-31.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 2,
  },
  {
    id: 2,
    text: `Press the two buttons on the sides of the thermostat and slide it up and off the wall. 
           Then take the thermostat over to the boiler/hot water tank.`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/3t2HtZbvR9FEJvOcq5wB3p/b49ee3672a0e25c33e7d91a9e82a5e15/image2-33.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 3,
  },
  {
    id: 3,
    text: 'Remove the batteries.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/38zIQjumGIUUM4mWgDOHG6/86c3636e783b78ad3fefa4d45b98746e/image5-39.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
  {
    id: 4,
    text: `Turn the receiver back on and confirm what colour it flashes before going green:
      - If you have a hub, it should flash amber (connected mode).
      - If you do not have a hub, it should flash pink/white (standalone mode).`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/6rHDNlsjtx5j0xNUM59W1H/5ac1230d3787afa5345cd04a680b888c/image4-36.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 5,
  },
  {
    id: 5,
    text: 'Put the batteries back in the thermostat.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/38zIQjumGIUUM4mWgDOHG6/86c3636e783b78ad3fefa4d45b98746e/image5-39.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 6,
  },
  {
    id: 6,
    text: 'Place the thermostat back on the wall. It should then display the temperature and letters “DISP” in the top right corner.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/7p1a53Z99Sbpwpd73jMmIc/aa04d6796f50404fea32c47791271e08/image6-41.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 7,
  },
  {
    id: 7,
    text: 'Now press the + button to turn the heating on, raising the temperature above ambient',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/RuWwAtR7m1wOGgW5XGQSd/2c026e4ee3072b4fcc664130d1e92dc0/image7-43.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 8,
  },
  {
    id: 8,
    text: 'If successful a fire image will be displayed, and the receiver demand light will turn green.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/1hPrl7A86gpS8llseFkZyS/0a9df115e86ee72c9caad3ff56fb1810/fire_.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 9,
  },
];

export default SLT2;
