import Vue from 'vue/dist/vue.esm';

const MODAL_SCREENS = {
  QUIZ: 'quiz',
  LOGIN: 'login',
  AUTHENTICATED: 'authenticated',
  CONFIRM_EXIT: 'confirm',
};

const vuexModule = {
  namespaced: true,
  state() {
    return { current: MODAL_SCREENS.QUIZ };
  },
  mutations: {
    goTo(state, newState) {
      if (!Object.values(MODAL_SCREENS).includes(newState)) {
        throw new Error('unknown state');
      }

      Vue.set(state, 'current', newState);
    },
  },
  getters: {
    isActive({ current }) {
      return value => current === value;
    },
  },
  actions: {
    goTo({ commit }, newState) {
      commit('goTo', newState);

      if (newState === MODAL_SCREENS.AUTHENTICATED) {
        setTimeout(() => commit('goTo', MODAL_SCREENS.QUIZ), 2000);
      }
    },
  },
};

export { MODAL_SCREENS, vuexModule as default };
