/*
Contains shared methods for pages with multiple sections
*/

const mixin = {
  data() {
    return {
      currentPage: 'loading',
      reloading: false,
    };
  },
  methods: {
    isPage(...pages) {
      return pages.indexOf(this.currentPage) > -1;
    },
    showPage(page) {
      this.currentPage = page;
      if (this.scrollToTop) { this.scrollToTop(); }
    },
    showForm() {
      this.showPage('form');
    },
    showLoading() {
      this.showPage('loading');
    },
    showList() {
      this.showPage('list');
    },
    reloadPage() {
      this.reloading = true;
      window.location.reload();
    },
  },
};

export default mixin;
