import AddToBasketButton from 'mixins/add_to_basket_button';

const PRO_SELECTION = 'pro';
const component = {
  mixins: [AddToBasketButton],
  props: ['active', 'sku', 'proceedToCheckout'],
  data() {
    return {
      selected: this.active,
      modalTarget: 'installation-diy-confirmation-modal',
    };
  },
  computed: {
    basketPayload() {
      const { quantity } = 1;
      const product = {
        product: {
          sku: this.sku,
          proceedToCheckout: this.proceedToCheckout,
        },
      };

      return { productBuy: product, quantity };
    },
  },
  methods: {
    close() {
      this.$emit('close-action-card', this.id);
    },
    open() {
      this.$emit('open-action-card', this.id);
    },
    onOptionClick(variant) {
      this.selected = variant;
    },
    onAddToBasket() {
      if (this.selected === PRO_SELECTION) this.onButtonClick();
      else this.$modal.show(this.modalTarget);
    },
    selectPro() {
      this.selected = PRO_SELECTION;
      this.$modal.hide(this.modalTarget);
    },
    closeModal() {
      this.$modal.hide(this.modalTarget);
    },
  },
};

export default component;
