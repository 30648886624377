import { TYPE_CONFIRM } from '../constants';

const SLT6 = [
  {
    id: 1,
    text: 'Turn the receiver off by switching the isolation off and confirm all the lights have gone off.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5sUIazFUCA0BjFx3EEqOX2/56a921555591432c7bd5d5d43c25f826/image1-31.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 2,
  },
  {
    id: 2,
    text: 'Remove the thermostat from the wall and take it to the boiler/hot water tank.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/130S1oiOsUa61gM0AnbAze/26ac8b4a0c5359ae76300f69241b3ce0/image5-38.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 3,
  },
  {
    id: 3,
    text: 'Remove the batteries.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/7w3YATpk4qQ81Awr7vtNoe/17d9797386f4fc9bd5bfa84a7decf4d8/image3-34.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
  {
    id: 4,
    text: `Turn the receiver back on and confirm what colour it flashes before going green:
          If you have a hub, it should flash amber (connected mode).
          If you do not have a hub, it should flash pink/white (standalone mode).`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/6rHDNlsjtx5j0xNUM59W1H/5ac1230d3787afa5345cd04a680b888c/image4-36.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 5,
  },
  {
    id: 5,
    text: 'Put the batteries back in the thermostat.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/7w3YATpk4qQ81Awr7vtNoe/17d9797386f4fc9bd5bfa84a7decf4d8/image3-34.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 6,
  },
  {
    id: 6,
    text: `Place the thermostat back on the wall.
           Then raise the temperature above ambient by pressing the ^ icon on the thermostat.`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/130S1oiOsUa61gM0AnbAze/26ac8b4a0c5359ae76300f69241b3ce0/image5-38.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 7,
  },
  {
    id: 7,
    text: 'If successful, the receiver demand light will turn green.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/VNnjlkCHehStt845uHUKV/f38a144dfd5100b195d97ad4bcb4a4ba/image6-40.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 8,
  },
];

export default SLT6;
