<template>
  <div
    v-show="show"
    v-click-outside="hide"
    class="address-autocomplete-result"
  >
    <ul class="address-autocomplete-list list-group w-100">
      <li v-if="error" class="address-autocomplete-item list-group-item p-0 address-autocomplete-group-items p-2 text-red">
        {{ error }}
      </li>
      <li
        v-for="result in results"
        v-else
        v-bind:key="result.id"
        class="address-autocomplete-item list-group-item p-0 address-autocomplete-group-items"
      >
        <a href="#" v-on:click.stop.prevent="getAddress(result.id, result.type)">
          <span class="d-block py-1 px-2 list-group-item-action clearfix">
            <span class="address-autocomplete-address text-gray clearfix">{{result.address}}</span>
            <span class="address-autocomplete-city text-muted small ms-2 float-end">{{result.description}}</span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { buildAddress, processResponse } from 'utils/pca_processors';
import debounce from 'lodash/debounce';

const component = {
  inject: ['client', 'config'],
  props: ['address'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      results: [],
      show: false,
      skipReload: false,
      error: '',
    };
  },
  created() {
    this.debouncedSearch = debounce(this.search, 500);
  },
  watch: {
    address() {
      this.debouncedSearch();
    },
  },
  methods: {
    // return pca promise
    pcaFind(container) {
      return this.client.pcaFind(
        {
          key: this.config.pcaPredict.key,
          text: this.address,
          limit: '8',
          countries: this.config.marketScope,
          container,
        },
      ).then(
        ({ Items: items }) => {
          this.results = processResponse(items).map(item => ({
            id: item.Id,
            address: item.Text,
            description: item.Description,
            type: item.Type,
          }));
          this.show = true;
        },
      ).catch(
        (e) => {
          this.show = true;
          this.error = e.message;
        },
      );
    },
    search() {
      this.error = '';
      if (this.skipReload) {
        this.skipReload = false;
        return false;
      }

      if (this.address === undefined || this.address.length < 3) {
        this.hide();
        return false;
      }

      return this.pcaFind();
    },
    getContainerById(container) {
      this.error = '';
      this.results = [];
      return this.pcaFind(container);
    },
    getAddress(id, type) {
      let promise;
      if (type === 'Address') {
        promise = this.client.pcaRetrieve({ id, key: this.config.pcaPredict.key })
          .then(({ Items: items }) => {
            const processedItems = processResponse(items);
            this.skipReload = true;
            this.hide();
            this.$emit('update-address', buildAddress(processedItems));
          })
          .catch((e) => {
            this.error = e.message;
          });
      } else {
        promise = this.getContainerById(id);
      }

      return promise;
    },
    hide() {
      this.show = false;
    },
  },
};

export default component;
</script>
