const LEAK_PLAN_SKU = 'SUB00589';

const component = {
  inject: ['client'],
  data() {
    return {
      error: null,
      loading: false,
    };
  },
  methods: {
    addSkuAndRedirect() {
      const payload = {
        line_item: {
          sku: LEAK_PLAN_SKU,
          quantity: 1,
          product: { basket_requirements: [] },
        },
      };

      this.loading = true;

      this.client.addToBasket({}, payload)
        .then(() => {
          this.client.goTo(`/other-smart-products?sku=${LEAK_PLAN_SKU}`);
        })
        .catch(() => {
          this.error = 'Something went wrong... Please try again.';
        });
    },
  },
};

export default component;
