import VimeoPlayer from '@vimeo/player';

const component = {
  data() {
    return {
      player: null,
    };
  },
  methods: {
    loadPlayer() {
      if (window.screen.width <= 760) {
        return;
      }

      this.player = new VimeoPlayer(this.$refs.iframe);
      this.player.play();
    },
    async closeModal() {
      const { player, $refs } = this;

      if (player !== null) {
        await player.pause();
        await player.destroy();
      }

      this.player = null;

      $refs.modal.visible = false;
    },
  },
};

export default component;
