const component = {
  inject: ['dataLayer'],
  props: {
    offerId: String,
  },
  watch: {
    offerId(val) {
      this.dataLayer.addPromotion(val);
    },
  },
  mounted() {
    this.dataLayer.addPromotion(this.offerId);
  },
  render() {
    return this.$slots.default;
  },
};

export default component;
