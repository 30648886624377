const component = {
  props: ['url'],
  mounted() {
    const s = document.createElement('script');
    s.setAttribute('src', this.url);
    document.body.appendChild(s);
  },
};

export default component;
