/*
Contains shared methods for pages with payment methods
and the ability to add a new card
*/

const mixin = {
  data() {
    return {
      paymentMethodId: null,
      newCardForm: false,
      zuoraFormStatus: 'loading',
      paymentMethodsDisabled: false,
    };
  },
  computed: {
    isShowNewCardForm() {
      return !this.paymentMethodId && this.newCardForm;
    },
    isZuoraLoading() {
      return this.zuoraFormStatus === 'loading';
    },
    isSubmitButtonDisabled() {
      const {
        validated,
        invalid,
        dirty,
        pending,
      } = this.validation;
      const validatedOrDirty = validated || dirty;

      return this.submitting
        || (!this.paymentMethodId && (!this.newCardForm || this.zuoraFormStatus === 'loading'))
        || pending
        || (validatedOrDirty && invalid);
    },
  },
  methods: {
    addNewCard() {
      if (this.isShowNewCardForm) { return; }

      this.zuoraFormStatus = 'loading';
      this.paymentMethodId = null;
      this.newCardForm = true;
    },
    onZuoraLoad() {
      this.zuoraFormStatus = 'form';
      this.paymentMethodsDisabled = false;
      this.submitting = false;
    },
    onZuoraSubmit() {
      this.paymentMethodsDisabled = true;
      this.submitting = true;
    },
  },
};

export default mixin;
