<template>
  <div class="border rounded">
    <div v-if="isQuestion" class="promo-banner alert bg-white p-3">
      <h4>{{ text }}</h4>
      <div class="d-flex flex-wrap justify-content-center">
        <button
          class="hive-product-button-pdp"
          v-bind:class="{ 'active': isActiveAnswer(answer_yes), 'disabled': disabledButton }"
          v-bind:disabled="disabledButton"
          v-on:click="currentQuestionYesAction"
        >
          Yes
        </button>
        <button
          class="hive-product-button-pdp"
          v-bind:class="{ 'active': isActiveAnswer(answer_no), 'disabled': disabledButton }"
          v-on:click="currentQuestionNoAction"
          v-bind:disabled="disabledButton"
        >
          No
        </button>
      </div>
    </div>

    <div v-if="isConfirmation" class="promo-banner alert bg-white p-3">
      <div class="text-center h6" v-html="text"></div>
      <div v-if="imageUrl">
        <hires-image v-bind:image="image" classes="pb-2 img-fluid" />
      </div>
      <div class="d-flex flex-wrap justify-content-center">
        <button
          class="hive-product-button-pdp"
          v-bind:class="{ 'active': isActiveAnswer(answer_next), 'disabled': disabledButton }"
          v-on:click="currentQuestionConfirmAction"
          v-bind:disabled="disabledButton"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>

    <div v-if="isStoppedJourney || displayReportIssue" class="text-red text-center p-3">
      Something went wrong! Please click the Report Issue button below to log a case with Hive
      <button class="btn btn-secondary mt-1" v-on:click="reportIssue">
        Report Issue
      </button>
    </div>

    <div v-if="isLoading">
      Loading...
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'rotate'" v-bind:width="'40'" v-bind:height="'40'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><rect width="24" height="24"/><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2" stroke="#33495B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
    </div>
  </div>
</template>

<script>

import DeviceList from './device_list.vue';
import { QUESTION, CONFIRM, FUNCTION } from './steps/constants';

const component = {
  inject: ['client', 'dataLayer'],
  props: {
    id: Number,
    text: String,
    yesAction: Function,
    noAction: Function,
    nextStepYes: {
      type: [Number, Function],
    },
    nextStepNo: {
      type: [Number, Function],
    },
    nextStep: {
      type: [Number, Function],
    },
    selectedAnswer: String,
    type: String,
    componentName: String,
    imageUrl: String,
    action: Function,
    button: String,
    displayReportIssue: Boolean,
  },
  data() {
    return {
      answer_yes: 'Yes',
      answer_no: 'No',
      answer_next: 'Done',
      isLoading: false,
      isStoppedJourney: false,
    };
  },
  computed: {
    isFunction() {
      return this.type === FUNCTION;
    },
    isQuestion() {
      return this.type === QUESTION;
    },
    isConfirmation() {
      return this.type === CONFIRM;
    },
    image() {
      return {
        src: this.imageUrl,
        width: '',
        height: '',
        alt: '',
      };
    },
    nextStepForOkButton() {
      return this.nextStep;
    },
    disabledButton() {
      return this.selectedAnswer || this.isStoppedJourney || this.displayReportIssue;
    },
    buttonText() {
      return this.button || 'Done';
    },
  },
  methods: {
    currentQuestionYesAction() {
      this.completeStep(this.answer_yes, this.nextStepYes);
    },
    currentQuestionNoAction() {
      this.completeStep(this.answer_no, this.nextStepNo);
    },
    currentQuestionConfirmAction() {
      this.completeStep(this.answer_next, this.nextStepForOkButton);
    },
    isActiveAnswer(val) {
      return this.selectedAnswer === val;
    },
    completeStep(answer, nextStep) {
      if (this.selectedAnswer) {
        // this.resetStep();
        return;
      }
      this.$emit('answer', nextStep, answer);
    },
    stopJourney() {
      this.isStoppedJourney = true;
      this.$emit('stop-journey');
    },
    reportIssue() {
      this.$emit('report-issue');
    },
    callFunction() {
      // this.$nextTick(() => {
      if (this.isFunction) {
        this.isLoading = true;
        this.action(this) // rename callback function to method or something like that
          .then(() => {
            if (!this.isStoppedJourney) {
              this.currentQuestionConfirmAction();
            }

            this.isLoading = false;
          }).catch(() => {
            this.stopJourney();
          });
      }
      // });
    },
  },
  created() {
    if (this.selectedAnswer === null && this.isFunction) {
      this.callFunction();
    }
  },
  components: {
    DeviceList,
  },
};

export default component;

</script>