<template>
  <form
    ref="form"
    action="/smart-home-security/hive-homeshield/custom-home-pack"
    method="post"
  >
    <input
      type="hidden"
      name="authenticity_token"
      v-bind:value="authenticityToken"
    >
    <input
      type="hidden"
      name="pack[hive_security_siren]"
      v-bind:value="sirenQty"
    >
    <input
      type="hidden"
      name="pack[motion_sensor]"
      v-bind:value="motionSensorsQty"
    >
    <input
      type="hidden"
      name="pack[window_or_door_sensor]"
      v-bind:value="contactSensorsQty"
    >
    <input
      type="hidden"
      name="pack[hive_view_outdoor]"
      v-bind:value="outdoorCameraQty"
    >
    <input
      v-if="!blackIndoorCameraSelected"
      type="hidden"
      name="pack[hive_view_white]"
      v-bind:value="indoorCameraWhiteQty"
    >
    <input
      v-if="blackIndoorCameraSelected"
      type="hidden"
      name="pack[hive_view]"
      v-bind:value="indoorCameraBlackQty"
    >
    <input
      type="hidden"
      name="pack[hive_security_keypad]"
      v-bind:value="keypadQty"
    >
    <input
      type="hidden"
      name="pack[hive_security_keypad]"
      v-bind:value="keypadQty"
    >
    <input
      type="hidden"
      name="pack[hub]"
      value="1"
    >
    <input
      type="hidden"
      name="pack[pet_friendly_motion_sensor]"
      v-bind:value="petFriendlyMotionSensorsQty"
    >

    <input
      type="hidden"
      name="pack[pets]"
      v-bind:value="hasPets"
    >
  </form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('quiz');

const component = {
  inject: ['config'],
  computed: {
    ...mapGetters([
      'sirenQty',
      'motionSensorsQty',
      'contactSensorsQty',
      'outdoorCameraQty',
      'indoorCameraWhiteQty',
      'indoorCameraBlackQty',
      'keypadQty',
      'petFriendlyMotionSensorsQty',
      'hasPets',
    ]),
    authenticityToken() {
      return this.config.authenticityToken;
    },
    blackIndoorCameraSelected() {
      return this.indoorCameraBlackQty > 0;
    },
  },
  methods: {
    submit() {
      this.$refs.form.submit();
    },
  },
};

export default component;
</script>
