import Vue from 'vue/dist/vue.esm';

const HOME_TYPE = {
  FLAT: 'flat',
  BUNGALOW: 'bungalow',
  DETACHED: 'detached',
  TERRACE: 'terrace',
  SEMI_DETACHED: 'semi-detached',
};

const vuexModule = {
  namespaced: true,
  state() {
    return {
      selected: null,
    };
  },
  mutations: {
    reset(state) {
      Vue.set(state, 'selected', null);
    },
    select(state, value) {
      const parsedValue = String(value).toLowerCase();

      if (Object.values(HOME_TYPE).includes(parsedValue)) {
        Vue.set(state, 'selected', parsedValue);
      } else {
        throw new Error(`homeType ${value} is invalid`);
      }
    },
  },
  getters: {
    sirenQty({ selected }) {
      return selected === HOME_TYPE.FLAT ? 0 : 1;
    },
  },
};

export default vuexModule;
