<template>
  <quiz-chat-step v-bind:step="step">
    <template v-slot:question>
      What type of home do you have?
    </template>

    <template v-slot:buttons="{ onSelect }">
      <div class="quiz-hometype-buttons row">
        <div class="col-4 px-0">
          <quiz-chat-home-type-button v-on:select="onSelect('terrace')">
            <template v-slot:label>
              Terrace
            </template>
            <template v-slot:icon>
              <svg width="64" height="57" viewBox="0 0 64 57" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'45'" v-bind:height="'45'" v-bind:class="'mx-auto'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M62 30C62 13.431 48.569 0 32 0 15.431 0 2 13.431 2 30h60z" fill="#3D61E8" opacity=".2"/><path d="M1.846 18.513l10.907-7.704a2 2 0 012.295-.009l11.094 7.714A2 2 0 0127 20.156V49a2 2 0 01-2 2H3a2 2 0 01-2-2V20.147a2 2 0 01.846-1.634zM37.846 18.513l10.907-7.704a2 2 0 012.295-.009l11.094 7.714A2 2 0 0163 20.156V49a2 2 0 01-2 2H39a2 2 0 01-2-2V20.147a2 2 0 01.846-1.634z" stroke="#5C6D7C" stroke-width="1.5" fill="#E0E4E6"/><path d="M18.234 16.166A3 3 0 0017 18.59V53a3 3 0 003 3h24a3 3 0 003-3V18.61a3 3 0 00-1.253-2.438L33.64 7.496a3 3 0 00-3.513.013l-11.893 8.657z" stroke="#33495B" stroke-width="2" fill="#FFF"/><g transform="translate(24 23)" fill="#33495B"><rect width="5" height="5" rx="1"/><rect x="11" width="5" height="5" rx="1"/><rect y="11" width="5" height="5" rx="1"/><rect x="11" y="11" width="5" height="5" rx="1"/></g><path d="M30 45h4a1 1 0 011 1v10h-6V46a1 1 0 011-1zM38 6h2a1 1 0 011 1v7l-4-3.515V7a1 1 0 011-1z" fill="#33495B"/></g></svg>
            </template>
          </quiz-chat-home-type-button>
        </div>
        <div class="col-4 px-0">
          <quiz-chat-home-type-button v-on:select="onSelect('semi-detached')">
            <template v-slot:label>
              Semi-detached
            </template>
            <template v-slot:icon>
              <svg width="64" height="53" viewBox="0 0 64 53" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'45'" v-bind:height="'45'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M62 26C62 11.64 48.569 0 32 0 15.431 0 2 11.64 2 26h60z" fill="#3D61E8" opacity=".2"/><path d="M31.25 52.25V18.62a3.25 3.25 0 00-1.483-2.728l-12.11-7.843a3.25 3.25 0 00-3.552.013L2.214 15.886A3.25 3.25 0 00.75 18.6V49A3.25 3.25 0 004 52.25h27.25z" stroke="#5C6D7C" stroke-width="1.5" fill="#E0E4E6"/><path d="M31 52h29a3 3 0 003-3V18.69a3 3 0 00-1.439-2.56L48.444 8.132a3 3 0 00-3.14.011L32.42 16.122A3 3 0 0031 18.672V52z" stroke="#33495B" stroke-width="2" fill="#FFF"/><g transform="translate(38 27)" fill="#33495B"><rect width="6" height="6" rx="1"/><rect x="12" width="6" height="6" rx="1"/></g><path d="M39 41h4a1 1 0 011 1v9h-6v-9a1 1 0 011-1zM56 6h3a1 1 0 011 1v8l-5-3.954V7a1 1 0 011-1z" fill="#33495B"/></g></svg>
            </template>
          </quiz-chat-home-type-button>
        </div>

        <div class="col-4 px-0">
          <quiz-chat-home-type-button v-on:select="onSelect('detached')">
            <template v-slot:label>
              Detached
            </template>
            <template v-slot:icon>
              <svg width="64" height="53" viewBox="0 0 64 53" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-bind:svg-inline="''" v-bind:width="'45'" v-bind:height="'45'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><rect id="a" x="0" y="0" width="12" height="35"/><rect id="c" x="0" y="0" width="12" height="35"/></defs><g fill="none" fill-rule="evenodd"><path d="M54 21C54 9.402 44.15 0 32 0S10 9.402 10 21h44z" fill="#3D61E8" opacity=".2"/><g transform="translate(0 18)"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path d="M-13.69 10.921a1.25 1.25 0 00-.56 1.043V33c0 .69.56 1.25 1.25 1.25H4c.69 0 1.25-.56 1.25-1.25V11.973a1.25 1.25 0 00-.568-1.047l-8.574-5.582a1.25 1.25 0 00-1.372.005l-8.425 5.572z" stroke="#5C6D7C" stroke-width="1.5" fill="#E0E4E6" mask="url(#b)"/></g><g transform="translate(52 18)"><mask id="d" fill="#fff"><use xlink:href="#c"/></mask><path d="M8.31 10.921a1.25 1.25 0 00-.56 1.043V33c0 .69.56 1.25 1.25 1.25h17c.69 0 1.25-.56 1.25-1.25V11.973a1.25 1.25 0 00-.568-1.047l-8.574-5.582a1.25 1.25 0 00-1.372.005l-8.425 5.572z" stroke="#5C6D7C" stroke-width="1.5" fill="#E0E4E6" mask="url(#d)" transform="matrix(-1 0 0 1 35 0)"/></g><path d="M12.705 16.259A3 3 0 0011 18.965V49a3 3 0 003 3h36a3 3 0 003-3V18.982a3 3 0 00-1.722-2.714l-18.16-8.554a3 3 0 00-2.573.008l-17.84 8.537z" stroke="#33495B" stroke-width="2" fill="#FFF"/><g transform="translate(17 26)" fill="#33495B"><rect width="6" height="6" rx="1"/><rect x="24" width="6" height="6" rx="1"/><rect x="12" width="6" height="6" rx="1"/><rect y="13" width="6" height="6" rx="1"/><rect x="24" y="13" width="6" height="6" rx="1"/></g><path d="M30 39h4a1 1 0 011 1v11h-6V40a1 1 0 011-1zM44 7h3a1 1 0 011 1v6.398l-5-2.352V8a1 1 0 011-1z" fill="#33495B"/></g></svg>
            </template>
          </quiz-chat-home-type-button>
        </div>

        <div class="col-4 px-0">
          <quiz-chat-home-type-button v-on:select="onSelect('bungalow')">
            <template v-slot:label>
              Bungalow
            </template>
            <template v-slot:icon>
              <svg width="54" height="46" viewBox="0 0 54 46" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'45'" v-bind:height="'45'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M52 21C52 9.402 40.807 0 27 0S2 9.402 2 21h50z" fill="#3D61E8" opacity=".2"/><path d="M2.637 20.444A3 3 0 001 23.116V42a3 3 0 003 3h46a3 3 0 003-3V23.134a3 3 0 00-1.654-2.681L28.148 8.803a3 3 0 00-2.71.008L2.637 20.444z" stroke="#33495B" stroke-width="2" fill="#FFF"/><g transform="translate(10 28)" fill="#33495B"><rect width="6" height="6" rx="1"/><rect x="28" width="6" height="6" rx="1"/></g><path d="M25 32h4a1 1 0 011 1v11h-6V33a1 1 0 011-1zM39 8h3a1 1 0 011 1v6.398l-5-2.352V9a1 1 0 011-1z" fill="#33495B"/></g></svg>
            </template>
          </quiz-chat-home-type-button>
        </div>

        <div class="col-4 px-0">
          <quiz-chat-home-type-button v-on:select="onSelect('flat')">
            <template v-slot:label>
              Flat
            </template>
            <template v-slot:icon>
              <svg width="56" height="64" viewBox="0 0 56 64" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'45'" v-bind:height="'45'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M54 30C54 13.431 42.36 0 28 0S2 13.431 2 30h52z" fill="#3D61E8" opacity=".2"/><path d="M3 17h22a2 2 0 012 2v38a2 2 0 01-2 2H3a2 2 0 01-2-2V19a2 2 0 012-2zM31 17h22a2 2 0 012 2v38a2 2 0 01-2 2H31a2 2 0 01-2-2V19a2 2 0 012-2z" stroke="#5C6D7C" stroke-width="1.5" fill="#E0E4E6"/><path d="M16 11a3 3 0 00-3 3v46a3 3 0 003 3h24a3 3 0 003-3V14a3 3 0 00-3-3H16z" stroke="#33495B" stroke-width="2" fill="#FFF"/><g transform="translate(20 33)" fill="#33495B"><rect width="5" height="5" rx="1"/><rect x="11" width="5" height="5" rx="1"/><rect y="11" width="5" height="5" rx="1"/><rect x="11" y="11" width="5" height="5" rx="1"/></g><g transform="translate(20 22)" fill="#33495B"><rect width="5" height="5" rx="1"/><rect x="11" width="5" height="5" rx="1"/><rect y="11" width="5" height="5" rx="1"/><rect x="11" y="11" width="5" height="5" rx="1"/></g><path d="M26 55h4a1 1 0 011 1v6h-6v-6a1 1 0 011-1z" fill="#33495B"/></g></svg>
            </template>
          </quiz-chat-home-type-button>
        </div>
      </div>
    </template>
  </quiz-chat-step>
</template>

<script>
import QuizChatStep from './chat_step.vue';
import QuizChatHomeTypeButton from './chat_home_type_button.vue';

const component = {
  props: {
    step: String,
  },
  components: {
    QuizChatStep,
    QuizChatHomeTypeButton,
  },
};

export default component;
</script>
