const getAddToBasketUpdate = (basket, newItems) => {
  const { ...lineItemsBySku } = basket.lineItemsBySku;

  [newItems].flat().forEach((lineItem) => {
    lineItemsBySku[lineItem.product.product.sku] = lineItem;
  });

  return { ...basket, lineItemsBySku };
};

const getRemoveFromBasketUpdate = (basket, sku) => {
  const { ...lineItemsBySku } = basket.lineItemsBySku;

  delete lineItemsBySku[sku];

  return { ...basket, lineItemsBySku };
};

const getUpdateQuantityUpdate = (basket, { quantity, sku }) => {
  const { ...lineItemsBySku } = basket.lineItemsBySku;

  const lineItem = lineItemsBySku[sku];

  if (!lineItem) return basket;

  if (quantity > 0) {
    lineItemsBySku[sku] = { ...lineItem, quantity };
  } else {
    delete lineItemsBySku[sku];
  }

  return { ...basket, lineItemsBySku };
};

export default {
  getAddToBasketUpdate,
  getRemoveFromBasketUpdate,
  getUpdateQuantityUpdate,
};
