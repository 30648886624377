import { createNamespacedHelpers } from 'vuex';
import INSTALLATION_TYPE from 'store/modules/product_buy/constants';

const { mapState, mapActions } = createNamespacedHelpers('productBuy');

// HAH install choice
const component = {
  props: {
    widgetId: String,
    storageName: String,
    isStoppedInstallation: Boolean,
    initialChoice: {
      type: String,
      validator: value => [INSTALLATION_TYPE.SELF, INSTALLATION_TYPE.PRO].indexOf(value) !== -1,
    },
  },
  computed: {
    // Expose the constants to template.
    INSTALLATION_TYPE() {
      return { ...INSTALLATION_TYPE };
    },
    ...mapState({
      complete({ options }) {
        return options.hasProductInstallationRequirement || false;
      },
      selected({ options }) {
        return options.heatingProductInstallationType || null;
      },
    }),
  },
  methods: {
    ...mapActions(['selectHahInstallationType', 'changeOptions']),
    isSelected(value) {
      return this.complete && this.selected === value;
    },
    async onSelect(selected) {
      const { widgetId, storageName } = this;

      await this.selectHahInstallationType({ widgetId, selected });

      localStorage.setItem(storageName, selected);
      window.specEvents.push(`productWidgets.${widgetId}.complete`);
    },
    onReset() {
      const { widgetId, storageName, initialChoice } = this;
      const selected = initialChoice;
      const complete = false;

      this.selectHahInstallationType({ widgetId, selected, complete });
      localStorage.removeItem(storageName);
    },
  },
  beforeMount() {
    const { widgetId, storageName, initialChoice } = this;
    const savedChoice = localStorage.getItem(storageName);

    const complete = this.isStoppedInstallation ? true : (savedChoice !== null);
    const savedOrInitial = savedChoice || initialChoice;
    const selected = this.isStoppedInstallation ? INSTALLATION_TYPE.SELF : savedOrInitial;

    if (selected) this.selectHahInstallationType({ widgetId, selected, complete });
    else {
      this.changeOptions(
        {
          widgetId,
          requirements: { hasProductInstallationRequirement: complete },
        }
      );
    }
  },
};

export default component;
