/**
 * A `switcher` is a component that allows to select one of n available options.
 */
const component = {
  props: {
    defaultSelection: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      currentSelection: this.defaultSelection || 0,
    };
  },
  methods: {
    select(index) {
      this.currentSelection = index;
    },
    isSelected(index) {
      return index === this.currentSelection;
    },
    nextSelection(maxSize) {
      if (this.currentSelection < maxSize - 1) {
        this.currentSelection += 1;
      } else {
        this.currentSelection = 0;
      }
    },
  },
};

export default component;
