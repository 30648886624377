import Vue from 'vue/dist/vue.esm';
import tierByQuantity from 'utils/playback_tier';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const PLAYBACK_TYPES = ['annual', 'monthly', 'free'];
const PROFESSIONAL_INSTALLATION = 'professional';


const mutations = {
  ...baseMutations,
  setPlaybacks(state, { annualPlaybacks, monthlyPlaybacks }) {
    Vue.set(state, 'annualPlaybacks', { ...annualPlaybacks });
    Vue.set(state, 'monthlyPlaybacks', { ...monthlyPlaybacks });
  },
  setCamera(state, sku) {
    Vue.set(state, 'sku', sku);
  },
  setPlaybackType(state, playbackType) {
    if (!PLAYBACK_TYPES.includes(playbackType)) throw new Error(`Invalid playback type: ${playbackType}`);

    Vue.set(state, 'playbackType', playbackType);
  },
  setInstallationOption(state, { installationOption }) {
    Vue.set(state, 'installationOption', installationOption);
  },
};

const moduleGetters = {
  ...baseGetters,
  playbackTier({ quantity }) {
    return tierByQuantity(quantity);
  },
  playbackSku({ annualPlaybacks, monthlyPlaybacks, playbackType }, { playbackTier }) {
    switch (playbackType) {
      case 'annual': return annualPlaybacks[playbackTier];
      case 'monthly': return monthlyPlaybacks[playbackTier];
      default: return null;
    }
  },
  sku({ sku }) {
    return sku;
  },
  quantity({ quantity }) {
    return quantity;
  },
  cameraDiscountPercentage({ pricing }) {
    // Pricing may contain multiple camera items (e.g. single camera + 2 pack),
    // but all of them should have same discount applied, so we just pick the first camera item.
    const cameraPricing = pricing.items.find(({ productType }) => productType.match(/^hive-view/)) || {};

    return cameraPricing.discountPercentage || 0;
  },
  pricingPayload({ sku, quantity }, { playbackSku }) {
    const products = [{ sku, quantity }];

    if (playbackSku) products.push({ sku: playbackSku, quantity: 1 });

    return { products, sku };
  },
  basketPayload(state) {
    const {
      sku,
      quantity,
      playbackType,
      installationOption,
    } = state;

    // TODO: get skus from the pricing response instead of using basket req-ts?
    const options = { videoPlaybackType: playbackType };

    // outdoor only
    // we can't calculate installation price for cameras using api
    // so use old approach
    if (installationOption === PROFESSIONAL_INSTALLATION) {
      options.installationType = 'pro';
    }

    const productBuy = {
      product: {
        sku: [{ sku, quantity }],
      },
      options,
    };

    return { productBuy, quantity: 1 };
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  init(
    {
      commit,
      dispatch,
    },
    {
      sku,
      annualPlaybacks,
      monthlyPlaybacks,
      installationOption,
    }
  ) {
    commit('setCamera', sku);
    commit('setPlaybacks', { annualPlaybacks, monthlyPlaybacks });
    if (installationOption) commit('setInstallationOption', { installationOption });

    return dispatch('loadPricing');
  },
  setCamera({ commit, dispatch, state }, sku) {
    if (state.sku === sku) return Promise.resolve(false);

    commit('setCamera', sku);

    return dispatch('loadPricing');
  },
  setPlaybackType({ commit, dispatch, state }, playbackType) {
    if (state.playbackType === playbackType) return Promise.resolve(false);

    commit('setPlaybackType', playbackType);

    return dispatch('loadPricing');
  },
  setInstallationOption({ commit, dispatch, state }, { selected, specEvent }) {
    if (state.installationOption === selected) return Promise.resolve(false);

    commit('setInstallationOption', { installationOption: selected });
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      quantity: 1,
      sku: null,
      annualPlaybacks: {},
      monthlyPlaybacks: {},
      playbackType: 'annual',
      installationOption: null,
      installationSku: null,
      pricing: {},
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
