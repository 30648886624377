/**
 * Init the module with a state
 *
 * @param {*} module
 * @param {*} state
 * @param {*} property
 * @param {*} flat
 */
function initModuleWithState(module, state, property, flat) {
  // Tim M: Using `{ [property]: state[property] }` during state building leads
  // to the following stucture
  //
  //   {
  //     mySection: {
  //       mySection: {
  //         key: 'value'
  //       }
  //     }
  //   }
  //
  // It looks like the duplication in the output should be ommited.
  // Passing `true` as the last argument generates the following output
  //
  //   {
  //     mySection: {
  //       key: 'value'
  //     }
  //   }
  //
  // and it should be used for all existing modules.
  // But refactoring that at once might lead to some regressions so it is better
  // to handle that as part of a standalone task.
  const initState = flat ? state[property] : { [property]: state[property] };

  return {
    ...module,
    state: {
      ...module.state,
      ...initState,
    },
  };
}

export default initModuleWithState;
