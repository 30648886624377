import TrustPilotPopup from 'components/views/trust_pilot_popup';
import SelectBoilerAndCounty from 'components/views/select_boiler_and_county';
import OwlCarousel from 'components/views/carousel';
import productBuyPageMethods from 'components/base/product_buy_page/methods';

const component = {
  inject: ['dataLayer'],
  components: {
    'owl-carousel': OwlCarousel,
    'trust-pilot-popup': TrustPilotPopup,
    'select-boiler-and-county': SelectBoilerAndCounty,
  },
  props: {
    dataLayerProductLoad: Boolean,
    initialCustomiseState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customise: this.initialCustomiseState,
    };
  },
  methods: {
    ...productBuyPageMethods,
    setCustomise(val) {
      this.customise = val;
      if (this.customise) {
        setTimeout(() => this.$scrollTo('#customise'), 100);
      }
    },
  },
  mounted() {
    // TODO Can we do this in the store?
    if (this.dataLayerProductLoad) {
      this.dataLayer.productLoaded();
    }
  },
};

export default component;
