import ScrollToTop from 'mixins/scroll_to_top';

const component = {
  mixins: [ScrollToTop],
  props: {
    basket: Object,
    updating: Boolean,
  },
  computed: {
    isMonthlyPayment() {
      return this.basket.requestedToBeBundled;
    },
    isOneOffPayment() {
      return !this.basket.requestedToBeBundled;
    },
    isVisible() {
      if (this.basket === undefined) {
        return false;
      }

      return this.basket.canChoosePaymentMethod;
    },
    showMonthlyPaymentOption() {
      return this.basket.showMonthlyPaymentOption;
    },
  },
  methods: {
    selectOneOff() {
      this.$store.dispatch(
        'basket/selectPaymentMethod',
        { paymentMethod: 'one-off', errorCallback: this.scrollToTop }
      );
      // TODO: implement a proper way to toggle caveats from Vue components.
      const caveatsToHide = $('#caveats-container .js-caveat-to-hide');
      caveatsToHide.addClass('hidden');
      if (caveatsToHide.length === $('#caveats-container .js-caveat').length) {
        $('#caveats-container .js-caveat-container-to-hide').addClass('hidden');
      }
    },
    selectMonthly() {
      this.$store.dispatch(
        'basket/selectPaymentMethod',
        { paymentMethod: 'monthly', errorCallback: this.scrollToTop }
      );
      // TODO: implement a proper way to toggle caveats from Vue components.
      this.showHiddenCaveats();
    },
    showHiddenCaveats() {
      $('#caveats-container .js-caveat-container-to-hide').removeClass('hidden');
      $('#caveats-container .js-caveat-to-hide').removeClass('hidden');
    }
  },
  mounted() {
    if (this.isMonthlyPayment) {
      this.showHiddenCaveats();
    }
  }
};

export default component;
