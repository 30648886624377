<template>
  <div>
    <h4 class="text-gray-dark font-weight-normal">
      Start quiz
    </h4>

    <h6 class="text-gray mb-4">
      Answer a few simple questions and we’ll create a custom made security pack for your home.
    </h6>

    <div class="container-fluid">
      <div id="step-intro">
        <quiz-chat-step-intro
          v-bind:step="STEPS.INITIAL"
          v-on="$listeners"
        />
      </div>

      <div
        v-if="!isAuthenticated"
        id="step-login"
        v-bind:ref="STEPS.LOGIN"
      >
        <quiz-chat-step-login v-bind:step="STEPS.LOGIN" />
      </div>

      <div
        id="step-home-type"
        v-bind:ref="STEPS.HOME_TYPE"
      >
        <quiz-chat-step-home-type v-bind:step="STEPS.HOME_TYPE" />
      </div>

      <div
        id="step-floors"
        v-bind:ref="STEPS.FLOORS"
      >
        <quiz-chat-step-quantity-range
          v-bind:step="STEPS.FLOORS"
          v-bind:min="1"
          v-bind:max="4"
        >
          How many floors are there in your home?
        </quiz-chat-step-quantity-range>
      </div>

      <div
        id="step-entry-doors"
        v-bind:ref="STEPS.ENTRY_DOORS"
      >
        <quiz-chat-step-quantity-range
          v-bind:step="STEPS.ENTRY_DOORS"
          v-bind:max="4"
        >
          How many Entry/Exit doors at your home?
        </quiz-chat-step-quantity-range>
      </div>

      <div
        id="step-ground-floor-rooms"
        v-bind:ref="STEPS.GROUND_FLOOR_ROOMS"
      >
        <quiz-chat-step-quantity-range
          v-bind:step="STEPS.GROUND_FLOOR_ROOMS"
          v-bind:max="6"
        >
          How many rooms on the ground floor do you have (excluding bathrooms)?
        </quiz-chat-step-quantity-range>
      </div>

      <div
        id="step-first-floor-rooms"
        v-bind:ref="STEPS.FIRST_FLOOR_ROOMS"
      >
        <quiz-chat-step-quantity-range
          v-bind:step="STEPS.FIRST_FLOOR_ROOMS"
          v-bind:max="6"
        >
          Do you want to protect any rooms on the 1st floor?
        </quiz-chat-step-quantity-range>
      </div>

      <div
        id="step-windows"
        v-bind:ref="STEPS.WINDOWS"
      >
        <quiz-chat-step-quantity-range
          v-bind:step="STEPS.WINDOWS"
          v-bind:max="6"
        >
          How many windows would you like to protect?
          (Be mindful of any windows on the ground floor,
          or any windows on the 1st that are next to a flat roof).
        </quiz-chat-step-quantity-range>
      </div>

      <div
        id="step-front-door"
        v-bind:ref="STEPS.FRONT_DOOR"
      >
        <quiz-chat-step-binary v-bind:step="STEPS.FRONT_DOOR">
          Would you like to see who comes to the front door?
        </quiz-chat-step-binary>
      </div>

      <div
        id="step-back-garden"
        v-bind:ref="STEPS.BACK_GARDEN"
      >
        <quiz-chat-step-binary v-bind:step="STEPS.BACK_GARDEN">
          Do you want to cover a back garden?
        </quiz-chat-step-binary>
      </div>

      <div
        id="step-outer-buildings-presence"
        v-bind:ref="STEPS.OUTER_BUILDINGS_PRESENCE"
      >
        <quiz-chat-step-binary v-bind:step="STEPS.OUTER_BUILDINGS_PRESENCE">
          Do you have outbuildings, for example, a garage or shed you’d like to keep an eye on?
        </quiz-chat-step-binary>
      </div>

      <div
        id="step-outer-buildings-quantity"
        v-bind:ref="STEPS.OUTER_BUILDINGS_QUANTITY"
      >
        <quiz-chat-step-quantity-range
          v-bind:step="STEPS.OUTER_BUILDINGS_QUANTITY"
          v-bind:max="4"
        >
          How many outbuildings?
        </quiz-chat-step-quantity-range>
      </div>

      <div
        id="step-indoor-camera-presence"
        v-bind:ref="STEPS.INDOOR_CAMERA_PRESENCE"
      >
        <quiz-chat-step-binary v-bind:step="STEPS.INDOOR_CAMERA_PRESENCE">
          Do you wish to monitor the inside of the home at night or when you are away?
        </quiz-chat-step-binary>
      </div>

      <div
        id="step-indoor-camera-colour"
        v-bind:ref="STEPS.INDOOR_CAMERA_COLOUR"
      >
        <quiz-chat-step-camera-colour v-bind:step="STEPS.INDOOR_CAMERA_COLOUR" />
      </div>


      <div
        id="step-pet-presence"
        v-bind:ref="STEPS.PET_PRESENCE"
      >
        <quiz-chat-step-binary v-bind:step="STEPS.PET_PRESENCE">
          Finally, do you have any pets in your property?
        </quiz-chat-step-binary>
      </div>

      <div v-bind:ref="STEPS.FINISH">
        <quiz-chat-progress
          v-if="isCurrentStep(STEPS.FINISH)"
          v-on:finish="$refs.form.submit()"
        />
        <quiz-chat-form ref="form" />
      </div>
    </div>
  </div>
</template>

<script>
  import {createNamespacedHelpers} from 'vuex';
  import {STEPS} from 'store/modules/quiz';
  import QuizChatForm from './chat_form.vue';
  import QuizChatProgress from './chat_progress.vue';
  import QuizChatStepIntro from './chat_step_intro.vue';
  import QuizChatStepHomeType from './chat_step_home_type.vue';
  import QuizChatStepLogin from './chat_step_login.vue';
  import QuizChatStepCameraColour from './chat_step_camera_colour.vue';
  import QuizChatStepBinary from './chat_step_binary.vue';
  import QuizChatStepQuantityRange from './chat_step_quantity_range.vue';

  const { mapGetters } = createNamespacedHelpers('quiz');

const component = {
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'isCurrentStep',
      'currentStep',
      'transitionsCount',
    ]),
    authenticityToken() {
      return this.config.authenticityToken;
    },
    STEPS() {
      return { ...STEPS };
    },
  },
  methods: {
    scrollToCurrentStep() {
      const opts = {
        container: '#quiz-container',
        easing: 'ease-in',
      };

      const duration = 300;

      // nextTick prevents scrolling past current step
      // when the user goes back and answers one of the previous questions.
      this.$nextTick(() => {
        const element = this.$refs[this.currentStep];
        if (element) this.$scrollTo(element, duration, opts);
      });
    },
  },
  mounted() {
    this.scrollToCurrentStep();
  },
  watch: {
    transitionsCount() {
      this.scrollToCurrentStep();
    },
  },
  components: {
    QuizChatForm,
    QuizChatProgress,
    QuizChatStepIntro,
    QuizChatStepHomeType,
    QuizChatStepLogin,
    QuizChatStepCameraColour,
    QuizChatStepBinary,
    QuizChatStepQuantityRange,
  },
};

export default component;
</script>
