<template>
  <quiz-chat-step v-bind:step="step">
    <template v-slot:question>
      Would you like to log in so we can personalise your system around your existing devices?
    </template>

    <template v-slot:buttons="{ onSelect, isCurrentStep }">
      <quiz-chat-button-animation v-bind:enabled="isCurrentStep">
        <div class="row justify-content-end pb-2">
          <a
            v-bind:href="loginUrl"
            class="btn btn-secondary btn-sm"
          >
            Yes
          </a>
        </div>
      </quiz-chat-button-animation>

      <quiz-chat-button
        v-bind:animate="isCurrentStep"
        v-on:select="onSelect(false)"
      >
        No
      </quiz-chat-button>
    </template>
  </quiz-chat-step>
</template>

<script>
import QuizChatStep from './chat_step.vue';
import QuizChatButton from './chat_button.vue';
import QuizChatButtonAnimation from './chat_button_animation';

const component = {
  inject: ['loginUrl'],
  props: {
    step: String,
  },
  components: {
    QuizChatStep,
    QuizChatButton,
    QuizChatButtonAnimation,
  },
};

export default component;
</script>
