export const REQUIRED_PHOTOS_COUNT = 10;

export const MAX_PHOTOS_COUNT = 10;
export const SKIPPABLE_PHOTOS_STEPS = [7, 8, 9];

export const LAST_EDITOR_STEP = 2;

export const BRUSH_COLOR = '#FF0000'; // red

export const CANVAS_HEIGHT = 498;

export const CANVAS_WIDTH = 664;

export const MAX_PHOTO_SIZE_MESSAGE = 'Photo should be under 15mb';

export const SUCCESS_MESSAFE = 'Upload Successfull';

export const ERROR_MESSAGE = 'Upload failed... Please try again or contact us.';

export const EXAMPLE_PHOTO_URLS = [
  '//images.ctfassets.net/mijf9lz5yt3u/44s3yut93idPUVv0I6okur/702d816098d2bf8fd2a62da42b82aeef/real_1.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/7roSmKun8qdq11n45Qnw3b/8f33197822c7053aba335bfb50687e01/real_2.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/5sU3gLEpkvYUi3dXNSJhll/1d6b5ead661d8a8358a2016880d0dcd3/real_3.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/1kgjUkopI99eCUUFRKM3cE/f744bba71ed6c31bb6f0f51f1dd8efea/real_4.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/5FswBMKYPuh10gzLp9jqAt/0e6617cc503fc0c97f22dff6440f926a/real_5.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/2tNafw3ctWmKWlM1mjZBjL/e63d3c43e54d45b681dfecd6de855f74/real_6.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/2MIKoxwxIPCW5Sotn37VSu/334afeb7182f8a06410ae220f1e65123/real_7.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/6Jf5Iigve0f6Z1PJ93DiuR/f8f2be1a2c417ebd3077197fc6e899cc/real_8.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/2mabwUstv8QiBFmrKL8FB5/68157bad9342aa1004b6400af9e0b669/real_9.jpg',
  '//images.ctfassets.net/mijf9lz5yt3u/2cFDxP1BV7EeQBuXooftms/b4e9d14aac702a9c125b84b48fc72829/real_10.jpg',
];

export const EV_PHOTO_NAMES = [
  'Your private off-street parking area',
  'EV charger location close-up',
  'Electricity meter and surrounding area',
  'Cut-out (Main Fuse) ',
  'Fuse box and surrounding area',
  'Fuse box close-up',
  'Additional fuse box',
  'Your water bonding',
  'Your gas bonding',
  'Floor plan with expected cable route',
];

export const SKIPPABLE_PHOTOS_BUTTON_TEXT = {
  7: {
    positive: 'Yes',
    negative: 'No'
  },
  8: {
    positive: 'Take / Choose photo',
    negative: 'Unable to locate'
  },
  9: {
    positive: 'Take / Choose photo',
    negative: 'Skip'
  },
}

export const PHOTO_TITLES = [
  'Your private off-street parking area',
  'EV charger location close-up',
  'Electricity meter and surrounding area',
  'Cut-out (Main Fuse)',
  'Fuse box and surrounding area',
  'Fuse box close-up',
  'Do you have any other fuse box?',
  'Your water bonding',
  'Your gas bonding',
  'Floor plan with expected cable route',
];

export const PHOTO_INSTRUCTIONS = [
  'Please send us a photo which shows either your private off-street parking or garage, from a distance.',
  'Please upload a photo of the specific location where you want your EV charger to be installed.',
  'Please upload a photo of your electricity meter installation, clearly showing any wiring, switches and/or any digits marked on devices.',
  'Please upload a photo of your cut-out box, clearly showing the wiring around. This will most likely be a black box if your home was built before 1990, or grey if it’s a newer build. We will need to clearly see any digits.',
  'Please upload a photo of your fuse box and anything on the wall surrounding it, so we can check the earthing.',
  'Now we need a close-up of your fuse box, where we can clearly read all the ratings on the switches (e.g. B32, B16, B6, C40) and the labels (sockets/lighting circuit(s).',
  'This could be in your garage or other buildings - please check. If so, we`ll need to see a close-up photo.',
  'Please upload a photo of any green/yellow earthing wire attached to your water pipe, usually next to your water stop cock.',
  'Please upload a photo of any green/yellow earthing wire attached to your gas pipe meter area. This could be internal or outside area.',
  'We need a simple drawn floor plan, with expected cable length (if possible) between your EV charger to your fuse box, then onto your electricity meter.',
];

export const PHOTO_TIPS = [
  'Tip: If you’re taking this photo on a dark day, please use your camera flash to help illuminate the space.',
  'Tip: This will most likely be on an outside wall or inside your garage. You can edit your photo to mark up the position of the charger.',
  'Tip: Use the flash on your phone to highlight your electricity meter if it’s placed in a dimly lit area.',
  'Tip: DO NOT TOUCH Cut-out (Main Fuse) box. Use the flash on your phone, as it should make it easier for us to read any fuse ratings.',
  'Tip: You’ll probably need to be about 2-3 feet away from your fuse box so we can get a good idea of the area where it’s placed.',
  'Tip: Use the flash on your phone to highlight the close-up to make absolutely sure we can see all the details.',
  '',
  '',
  'If you do not have a gas meter, then you can skip this photo step.',
];

export const CONFIRM_PHOTO_TITLES = [
  'Please confirm off-street parking area',
  'Confirm EV charger location close-up',
  'Please confirm electricity meter and surrounding area',
  'Please confirm electricity meter close-up',
  'Please confirm fuse box and surrounding area',
  'Please confirm fuse box close-up',
  'Confirm photo for additional fuse box',
  'Please confirm water stop cock photo',
  'Please confirm gas meter photo',
  'Please confirm floor plan photo',
];

export const CONFIRM_PHOTO_INSTRUCTIONS = [
  'Please confirm that the photo below shows that your private off-street parking is part of your property.',
  'Please make sure your photo shows any metallic objects nearby that might be earthed.',
  'Please upload a photo of your electricity meter installation, clearly showing any wiring and/or switches.',
  'Please be sure we’ll be able to read the main fuse rating.',
  'Please check your photo includes the location of the fuse box and surrounding area',
  'Are you satisfied we’ll be able to read all the switches on your fuse box? If so, then you’re almost done!',
  'Please check that all the switches and any labels on your additional fuse box are clearly legible.',
  'Please upload a photo of your water stop cock, showing the green/yellow earthing wire if you have metal pipes.',
  'Please upload a photo of your gas meter, showing the green/yellow earthing wire if you have metal pipes.',
  "We need a photo of your floor plan, with expected lengths between your EV charger and both your fusebox and electricity meter. If you'd like your charger to be fitted in a detached garage, please include the height.",
];

export const CONFIRM_PHOTO_TIPS = [
  'Tip: use your finger or a mouse to mark a specific location directly on your above photo',
  'Tip: Before you confirm your photo, please use your finger or a mouse to mark the specific location where you want your charger to go.',
];

export const EV_ACTIONS = {
  PHOTOS_REQUIRED: 'PhotosRequired',
  PHOTOS_RECEIVED: 'PhotosReceived',
  DNO_APP_IN_PROGRESS: 'DNOAppInProgress',
  DNO_APP_WORKS_IN_PROGRESS: 'DNOWorksInProgress',
  BOOK_VHC_LIGHT: 'BookVHCLight',
  BOOK_FULL_VHC: 'BookFullVHC',
  VHC_MISSED: 'VHCMissed',
  VHC_BOOKED: 'VHCBooked',
  BOOK_PHC: 'BookPHC',
  PHC_MISSED: 'PHCMissed',
  PHC_BOOKED: 'PHCBooked',
  BOOK_INSTALLATION: 'BookInstallation',
  BOOK_INSTALLATION_NO_VHC: 'BookInstallationNoVHC',
  INSTALL_BOOKED: 'InstallBooked',
  INSTALL_BOOKED_NO_VHC: 'InstallBookedNoVHC',
  COMPLETE: 'Complete',
  VHC: 'VHC',
};

export const EV_PROGRESS_BAR_TYPES = {
  VHC: 'VHC',
  NO_VHC: 'NoVHC',
  PHC: 'PHC',
  DNO_APP_AFTER_VHC: 'DNOAppAfterVHC',
  DNO_APP_AFTER_PHC: 'DNOAppAfterPHC',
  DNO_WORKS_AFTER_VHC: 'DNOWorksAfterVHC',
};
