import Vue from 'vue/dist/vue.esm';

export const reload = type => (state, items) => Vue.set(state, type, items);

export const markAsPending = (type, refName) => (state, ref) => Vue.set(
  state,
  type,
  state[type].map(item => ({
    ...item,
    pending: item.pending || item[refName] === ref,
  })),
);

export const resetPending = (type, refName) => (state, ref) => Vue.set(
  state,
  type,
  state[type].map(item => ({
    ...item,
    pending: (item.pending && item[refName] === ref) ? false : (item.pending || false),
  })),
);
