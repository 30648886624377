const component = {
  props: {
    playbackTier: Number,
  },
  methods: {
    changeTier(tier) {
      this.$emit('change-tier', tier);
    },
  },
};

export default component;
