import ActionCard from 'components/views/action_card';
import WaveEffect from 'mixins/wave_effect';

const component = {
  mixins: [WaveEffect],
  data() {
    return { activeAction: null };
  },
  methods: {
    isActiveAction(action) {
      return !!this.activeAction && action.toString() === this.activeAction.toString();
    },
    setActiveAction(action) {
      this.activeAction = action;
      this.scrollToCard(`#active-card-${action}`);
    },
    closeAction(action) {
      this.activeAction = null;
      this.scrollToCard(`#action-card-${action}`);
    },
    scrollToCard(actionSelector) {
      // we need to use nextTick here for waiting when component appears after model changing
      this.$nextTick(() => this.$scrollTo(actionSelector, 800, { offset: -150 }));
    },
  },
  components: {
    'action-card': ActionCard,
  },
  mounted() {
    this.$nextTick(() => window.specEvents.push('hive-actions.mounted'));
  },
};

export default component;
