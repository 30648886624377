import { createNamespacedHelpers } from 'vuex';
import RequiredProductChoice from 'components/base/required_product_choice';

const { mapGetters } = createNamespacedHelpers('productBuy');
const mapBasketState = createNamespacedHelpers('basket').mapState;

// Hub Hubless Choice
const component = {
  extends: RequiredProductChoice,
  props: {
    initialSku: {
      required: true,
      type: String,
    },
    requirement: {
      required: false,
      default: 'hasProductHubHublessRequirement',
    },
  },
  computed: {
    ...mapGetters({
      available: 'hubHublessAvailable',
      displayPrice: 'hubDisplayPrice',
      displayPriceWithoutSavings: 'hubDisplayPriceWithoutSavings',
    }),
    ...mapBasketState({
      currency: state => state.basket.currency,
      locale: state => state.basket.locale,
    }),
    showDisplayPriceWithoutSavings() {
      return this.displayPrice !== this.displayPriceWithoutSavings;
    },
  },
  data() {
    return {
      sku: this.initialSku,
    };
  },
  methods: {
    choiceEventData() {
      return { sku: this.sku, callback: this.emitCompleteSpecEvent };
    },
    afterUpdateChanges() {
      if (this.belongsToCombo) {
        this.dataToLocalStorage();
        this.updateRequirement();
      } else {
        this.requiredProductChoiceAfterUpdateChanges();
      }
    },
  },
};

export default component;
