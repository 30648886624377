import { extend as importRule } from 'vee-validate';
import makeValidators from 'utils/validators';

const initVueValidators = (client) => {
  const validators = makeValidators(client);

  Object.keys(validators).forEach((ruleName) => {
    const validator = validators[ruleName];

    // https://logaretm.github.io/vee-validate/guide/basic-validation.html#importing-validation-rules
    importRule(ruleName, validator);
  });
};

export default initVueValidators;
