/**
 *
 * Add a jQuery tooltip to an HTML element
 *
 * Based on https://stackoverflow.com/questions/37078423/how-can-add-bootstrap-tooltip-inside-vue-js
 */
const directive = {
  bind(el, binding) {
    const jqel = $(el);
    if (jqel.tooltip instanceof Function) {
      jqel.tooltip({
        title: binding.value,
        placement: binding.arg,
        trigger: 'hover',
      });
    }
  },
};

export default directive;
