<template>
  <div v-if="isStepVisible(step)">
    <quiz-chat-speech-bubble v-bind:animate="isCurrentStep(step)">
      <slot name="question" />
    </quiz-chat-speech-bubble>

    <slot
      name="buttons"
      v-bind:on-select="onSelect"
      v-bind:is-current-step="isCurrentStep(step)"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import QuizChatSpeechBubble from './chat_speech_bubble.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('quiz');

const component = {
  props: {
    step: String,
  },
  computed: {
    ...mapGetters([
      'isStepVisible',
      'isCurrentStep',
    ]),
  },
  methods: {
    ...mapActions([
      'select',
    ]),
    onSelect(value) {
      const { step } = this;

      this.select({ step, value });
    },
  },
  components: {
    QuizChatSpeechBubble,
  },
};

export default component;
</script>
