import {
  TYPE_QUESTION,
  TYPE_CONFIRM,
  NEXT_STEP_RENDER_DEVICE_LIST,
  TYPE_FUNCTION,
} from './constants';

const BASE_STEPS = [
  {
    id: 1,
    text: 'Are you at home?',
    ...TYPE_QUESTION,
    nextStepYes: 3,
    nextStepNo: 2,
  },
  {
    id: 2,
    text: 'Please restart the troubleshooting when you are back at home',
    ...TYPE_CONFIRM,
    button: 'Close',
    nextStep() {
      window.location.reload();
    },
  },
  {
    id: 3,
    text: 'Have you tried repositioning your Hive Hub?',
    ...TYPE_QUESTION,
    nextStepYes: 5,
    nextStepNo: 4,
  },
  {
    id: 4,
    text: `<div>
          <h4>Your Hub position is key to you having the optimum possible signal.</h4>
          <p class="text-start">Please can you ensure</p>
          <ul class="text-start">
            <li>Your Hub isn’t on a floor.</li>
            <li>Your Hub isn’t behind a smart TV.</li>
            <li>Your Hub isn’t directly next to your router (ideally over 2ft apart).</li>
            <li>Your Hub isn’t next to any other wireless devices. This may include cordless base units for home phones, baby monitors, wireless speakers, satellite/cable tuners and wireless printers.</li>
          </ul>
        </div>`,
    ...TYPE_CONFIRM,
    nextStep: 5,
  },
  {
    id: 5,
    text: `
      Please Note: Hive devices connect through a technology called Zigbee instead of Wi-Fi.
      Zigbee devices have a range of approximately 10 sq m. But this is reduced by distance to a nearby device,
      electrical interference and thickness and structure of walls. If this is a possible issue then you may need
      a Signal Booster.
      `,
    button: 'Next',
    ...TYPE_CONFIRM,
    nextStep: 6,
  },
  {
    id: 6,
    text: 'Have you installed a Signal Booster?',
    ...TYPE_QUESTION,
    nextStepYes: 10,
    nextStepNo: 7,
  },
  {
    id: 7,
    text: 'Do you want to purchase a Hive Signal Booster?',
    ...TYPE_QUESTION,
    nextStepYes: 8,
    nextStepNo: 10,
  },
  {
    id: 8,
    ...TYPE_FUNCTION,
    action() {
      const fakePromise = new Promise((resolve) => {
        window.open('/shop/connectivity/hive-signal-booster', '_blank');

        resolve();
      });
      return fakePromise;
    },
    nextStep: 9,
  },
  {
    id: 9,
    text: 'While you wait on the delivery of your booster, please continue to troubleshoot to get your devices back online',
    ...TYPE_CONFIRM,
    nextStep: 10,
  },
  {
    id: 10,
    text: `We are going to perform some system checks to
            determine what the next steps are to
            bring your system back online.`,
    button: 'Next',
    ...TYPE_CONFIRM,
    ...NEXT_STEP_RENDER_DEVICE_LIST,
  },
];

export default BASE_STEPS;
