/**
 * Global state for the whole app
 */
import Vue from 'vue/dist/vue.esm';
import spliceByProperty from 'utils/splice_by_property';

export const SET_ERROR = 'SET_ERROR';
const SHOW_HIVE_LOADER = 'SHOW_HIVE_LOADER';
const HIDE_HIVE_LOADER = 'HIDE_HIVE_LOADER';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_COLLAPSED = 'SET_COLLAPSED';

let uuid = 0;

const globalModule = {
  namespaced: true,
  state: {
    error: {},
    isLoading: false,
    notifications: [],
    upsell: {
      lastProductSku: null,
    },
    navCollapsed: false,
  },
  mutations: {
    [SET_ERROR](state, error) {
      Vue.set(state, 'error', error);
    },
    [SHOW_HIVE_LOADER](state) {
      Vue.set(state, 'isLoading', true);
    },
    [HIDE_HIVE_LOADER](state) {
      Vue.set(state, 'isLoading', false);
    },
    [ADD_NOTIFICATION]({ notifications }, notification) {
      uuid += 1;
      notifications.push({ ...notification, id: uuid });
    },
    [REMOVE_NOTIFICATION]({ notifications }, id) {
      spliceByProperty(notifications, 'id', id);
    },
    [SET_COLLAPSED](state, value) {
      Vue.set(state, 'navCollapsed', value);
    },
  },
  actions: {
    setError({ commit }, errorValues) { // { message, stack, noWarning }
      commit(SET_ERROR, errorValues);
    },
    showHiveLoader({ commit }) {
      commit(SHOW_HIVE_LOADER);
    },
    hideHiveLoader({ commit }) {
      commit(HIDE_HIVE_LOADER);
    },
    // Notifications API
    showNotice({ commit }, text) {
      commit(ADD_NOTIFICATION, { text, error: false });
    },
    showError({ commit }, errorValues) { // { message, stack, noWarning }
      let message;

      if (typeof errorValues === 'object') {
        commit(SET_ERROR, errorValues);
        ({ message } = errorValues);
      } else {
        message = errorValues;
      }

      commit(ADD_NOTIFICATION, { text: message, error: true });
    },
    hideNotification({ commit }, id) {
      commit(REMOVE_NOTIFICATION, id);
    },
    setCollapsed({ commit }, value) {
      commit(SET_COLLAPSED, value);
    },
  },
};

export default globalModule;
