const { dataLayer } = window.Packs.shared_with_legacy;

// Add to basket
const QUANTITY_FIELD = '.line_item_quantity, .js-product-quantity-num';

const updateQuantity = (target, increment) => {
  const targetElem = $(target).closest('div').find(QUANTITY_FIELD);
  const targetElemInd = $(QUANTITY_FIELD).index(targetElem);
  const qtyOld = parseInt($(target).closest('div').find(QUANTITY_FIELD).val(), 10);
  dataLayer.productCartChange(qtyOld, qtyOld + increment, window.hdl.products[targetElemInd]);
};

$(document).on('click', '.js-add-to-basket, .js-choose-and-customise, .js-frame-switch-button-add, .js-add-stand-button, .js-upgrade-hub, .js-initially-visible-cta-add-to-basket', () => {
  dataLayer.productAddToCart({ sku: $(this).attr('data-sku') });
});

// Remove from basket
$(document).on('click', '.js-item-delete, .js-frame-switch-button-remove, .js-remove-stand-button, .js-remove-hub, .js-remove-from-basket', () => {
  dataLayer.productRemoveFromCart({ sku: $(this).attr('data-sku') });
});

// Quantity increase
$(document).on('click', '.js-quantity-increase, .js-cta-quantity-increase', ({ target }) => updateQuantity(target, 1));

// Quantity decrease
$(document).on('click', '.js-quantity-decrease, .js-cta-quantity-decrease', ({ target }) => updateQuantity(target, -1));

// Quantity change on text field or dropbox
$(document).on('focusin', QUANTITY_FIELD, () => {
  $(this).data('previous-quantity', $(this).val());
}).on('change', QUANTITY_FIELD, () => {
  dataLayer.productQtyChange({
    sku: $(this).data('sku'),
    quantity: $(this).val(),
    oldQuantity: $(this).data('previous-quantity'),
  });
});


const updateError = () => {
  const $item = $('.alert.alert-error .text');
  if ($item.length < 1) return;

  const firstErrorMessage = $.trim($item[0].innerText);

  dataLayer.updateError(null, 'unknown', null, firstErrorMessage);
};

updateError();

// Handle flash errors
$(document).on('error-appended', updateError);

// TODO: This seems to be not used, see app/helpers/data_layer_helper.rb:10. Can we remove it?
$(document).on('submit', 'form[data-dmpg-id]', () => {
  // Do nothing if window.hdl does not exist
  if (window.hdl !== undefined) {
    // Update the Datalayer with the submitted form data
    const form = $(this);
    dataLayer.appendEvent({
      event: 'form.interact.manual.submit.formSubmit',
      form: {
        id: form.data('dmpg-id'),
        name: form.data('dmpg-name'),
        description: form.data('dmpg-description'),
        category: form.data('dmpg-category'),
        target: form.attr('action'),
      },
    });
  }
});
