<template>
  <div>
    <quiz-chat-speech-bubble v-bind:animate="animate">
      Hello, let's find the perfect fit for your home.
    </quiz-chat-speech-bubble>

    <quiz-chat-speech-bubble
      v-bind:animate="animate"
      delay="1.5s"
    >
      Shall we start the quiz or would you like to watch our expert advice video?
    </quiz-chat-speech-bubble>

    <quiz-chat-button-animation
      v-bind:enabled="animate"
      delay="1.7s"
    >
      <div class="row justify-content-end pb-2">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          v-on:click="start"
        >
          Start Quiz
        </button>
      </div>
    </quiz-chat-button-animation>

    <quiz-chat-button-animation
      v-bind:enabled="animate"
      delay="1.8s"
    >
      <div class="row justify-content-end pb-2">
        <!-- FIXME -->
        <button
          type="button"
          class="btn btn-secondary btn-sm quiz-btn-play"
          v-on:click="$emit('play-video')"
        >
          What to cover
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:class="'quiz-btn-play-icon'" v-bind:width="'26'" v-bind:height="'26'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#33495b" d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12zm9.429 2.24a1 1 0 001.447.894l4.571-2.286a1 1 0 00.039-1.769l-4.572-2.54a1 1 0 00-1.485.875z"/></svg>
        </button>
      </div>
    </quiz-chat-button-animation>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import QuizChatSpeechBubble from './chat_speech_bubble.vue';
import QuizChatButtonAnimation from './chat_button_animation';

const { mapGetters, mapActions } = createNamespacedHelpers('quiz');

const component = {
  props: {
    step: String,
  },
  computed: {
    ...mapGetters([
      'isCurrentStep',
    ]),
    animate() {
      return this.isCurrentStep(this.step);
    },
  },
  methods: {
    ...mapActions([
      'start',
    ]),
  },
  components: {
    QuizChatSpeechBubble,
    QuizChatButtonAnimation,
  },
};

export default component;
</script>
