import Vue from 'vue/dist/vue.esm';
import { MODAL_SCREENS } from 'store/modules/quiz/router';
import logIn from './steps/log_in';
import homeType from './steps/home_type';
import floors from './steps/floors';
import entryDoors from './steps/entry_doors';
import groundFloorRooms from './steps/ground_floor_rooms';
import firstFloorRooms from './steps/first_floor_rooms';
import windows from './steps/windows';
import frontDoor from './steps/front_door';
import backGarden from './steps/back_garden';
import outerBuildingsPresence from './steps/outer_buildings_presence';
import outerBuildingsQuantity from './steps/outer_buildings_quantity';
import indoorCameraPresence from './steps/indoor_camera_presence';
import indoorCameraColour from './steps/indoor_camera_colour';
import petPresence from './steps/pet_presence';
import router from './router';

export const STEPS = {
  INITIAL: 'initial',
  LOGIN: 'logIn',
  HOME_TYPE: 'homeType',
  INDOOR_CAMERA_PRESENCE: 'indoorCameraPresence',
  INDOOR_CAMERA_COLOUR: 'indoorCameraColour',
  FLOORS: 'floors',
  ENTRY_DOORS: 'entryDoors',
  GROUND_FLOOR_ROOMS: 'groundFloorRooms',
  FIRST_FLOOR_ROOMS: 'firstFloorRooms',
  WINDOWS: 'windows',
  FRONT_DOOR: 'frontDoor',
  BACK_GARDEN: 'backGarden',
  OUTER_BUILDINGS_PRESENCE: 'outerBuildingsPresence',
  OUTER_BUILDINGS_QUANTITY: 'outerBuildingsQuantity',
  PET_PRESENCE: 'petPresence',
  FINISH: 'finish',
};


const INITIAL_STEPS = {
  current: STEPS.INITIAL,
  completed: [],
  transitions: 0,
};

const completedSteps = (completed, currentStep, nextStep) => {
  const stepIndex = completed.indexOf(nextStep);

  if (stepIndex === -1) return [...completed, currentStep];

  return completed.slice(0, stepIndex);
};

export const mutations = {
  goToStep(state, step) {
    if (!state.stepNames.includes(step)) {
      throw new Error(`step name ${step} is invalid`);
    }

    const { current, completed, transitions } = state.steps;

    Vue.set(state, 'steps', {
      current: step,
      transitions: transitions + 1,
      completed: completedSteps(completed, current, step),
    });
  },
  authenticate(state) {
    Vue.set(state, 'authenticated', true);
  },
  reset(state) {
    Vue.set(state, 'steps', { ...INITIAL_STEPS });
  },
};

const actions = {
  start({ commit, dispatch }) {
    commit('reset');
    dispatch('goToNextStep');
  },
  select({ commit, getters, dispatch }, { step, value }) {
    const { currentStep } = getters;

    if (step !== currentStep) commit('goToStep', step);

    commit(`${step}/select`, value);

    dispatch('goToNextStep');
  },
  goToNextStep({ commit, getters }) {
    const { nextStep } = getters;

    if (nextStep === null) return;

    commit('goToStep', nextStep);
  },
  reset({ dispatch, commit }) {
    commit('reset');
    dispatch('router/goTo', MODAL_SCREENS.QUIZ);
  },
  confirmExit({ dispatch }) {
    dispatch('router/goTo', MODAL_SCREENS.CONFIRM_EXIT);
  },
};

const getters = {
  isAuthenticated({ authenticated }) {
    return authenticated;
  },
  nextStep(_state, otherGetters) {
    const {
      hasOuterBuildings,
      hasIndoorCamera,
      currentStep,
      isAuthenticated,
    } = otherGetters;

    const stateMachine = {
      [STEPS.INITIAL]: () => {
        if (isAuthenticated) return STEPS.HOME_TYPE;

        return STEPS.LOGIN;
      },
      [STEPS.LOGIN]: STEPS.HOME_TYPE,
      [STEPS.HOME_TYPE]: STEPS.FLOORS,
      [STEPS.FLOORS]: STEPS.ENTRY_DOORS,
      [STEPS.ENTRY_DOORS]: STEPS.GROUND_FLOOR_ROOMS,
      [STEPS.GROUND_FLOOR_ROOMS]: STEPS.FIRST_FLOOR_ROOMS,
      [STEPS.FIRST_FLOOR_ROOMS]: STEPS.WINDOWS,
      [STEPS.WINDOWS]: STEPS.FRONT_DOOR,
      [STEPS.FRONT_DOOR]: STEPS.BACK_GARDEN,
      [STEPS.BACK_GARDEN]: STEPS.OUTER_BUILDINGS_PRESENCE,
      [STEPS.OUTER_BUILDINGS_PRESENCE]: () => {
        if (hasOuterBuildings) return STEPS.OUTER_BUILDINGS_QUANTITY;

        return STEPS.INDOOR_CAMERA_PRESENCE;
      },
      [STEPS.OUTER_BUILDINGS_QUANTITY]: STEPS.INDOOR_CAMERA_PRESENCE,
      indoorCameraPresence: () => {
        if (hasIndoorCamera) return STEPS.INDOOR_CAMERA_COLOUR;

        return STEPS.PET_PRESENCE;
      },
      [STEPS.INDOOR_CAMERA_COLOUR]: STEPS.PET_PRESENCE,
      [STEPS.PET_PRESENCE]: STEPS.FINISH,
    };

    const newStep = stateMachine[currentStep] || null;

    return typeof newStep === 'function' ? newStep() : newStep;
  },
  transitionsCount({ steps }) {
    return steps.transitions;
  },
  currentStep({ steps }) {
    return steps.current;
  },
  isCurrentStep({ steps }) {
    return step => steps.current === step;
  },
  isStepVisible({ steps }) {
    return step => steps.current === step || steps.completed.includes(step);
  },
  hasPets({ petPresence: presense }) {
    return presense.selected === true;
  },
  hasOuterBuildings({ outerBuildingsPresence: presense }) {
    return presense.selected === true;
  },
  hasIndoorCamera({ indoorCameraPresence: presense }) {
    return presense.selected === true;
  },
  sumQty({ steps }, _getters) {
    return getterName => steps.completed
      .map(step => _getters[`${step}/${getterName}`])
      .filter(quantity => quantity !== undefined)
      .reduce((sum, quantity) => sum + quantity, 0);
  },
  sirenQty(state, { sumQty }) {
    return sumQty('sirenQty');
  },
  motionSensorsQty(state, { sumQty, hasPets }) {
    return hasPets ? 0 : sumQty('motionSensorsQty');
  },
  petFriendlyMotionSensorsQty(state, { sumQty, hasPets }) {
    return hasPets ? sumQty('motionSensorsQty') : 0;
  },
  contactSensorsQty(state, { sumQty }) {
    return sumQty('contactSensorsQty');
  },
  outdoorCameraQty(state, { sumQty }) {
    return sumQty('outdoorCameraQty');
  },
  keypadQty(state, { sumQty }) {
    return sumQty('keypadQty');
  },
  indoorCameraWhiteQty(state, { sumQty }) {
    return sumQty('indoorCameraWhiteQty');
  },
  indoorCameraBlackQty(state, { sumQty }) {
    return sumQty('indoorCameraBlackQty');
  },
};

const makeModule = (steps = {}) => ({
  namespaced: true,
  state() {
    return {
      stepNames: Object.values(STEPS),
      authenticated: false,
      steps: { ...INITIAL_STEPS, ...steps },
    };
  },
  actions,
  modules: {
    logIn,
    homeType,
    indoorCameraPresence,
    indoorCameraColour,
    floors,
    entryDoors,
    groundFloorRooms,
    firstFloorRooms,
    windows,
    frontDoor,
    backGarden,
    outerBuildingsPresence,
    outerBuildingsQuantity,
    petPresence,
    router,
  },
  getters,
  mutations,
});

export default makeModule;
