import TrustpilotAverageRating from 'mixins/trustpilot_average_rating';

const component = {
  mixins: [TrustpilotAverageRating],
  inject: ['client'],
  props: {
    apiKey: { type: String, required: true },
    skus: { type: String, required: true },
    starIconPaths: { type: Array, required: true },
  },
  data() {
    return {
      target: 'trust-pilot-mini-review',
      reviews: [],
      locale: 'en-GB',
      total: 0,
      roundedStarsAverage: 0,
      starLabels: ['Bad', 'Poor', 'Average', 'Great', 'Excellent'],
    };
  },
  methods: {
    starIconPath(rating) {
      return this.starIconPaths[rating - 1];
    },
  },
};

export default component;
