// This is a bridge between backend and frontend.
// Triggers will be available in Packs.applications and can be called from specs
// or other places where we need to call Vue methods.
// Handlers, on the other hand, should be integrated into Vue components where
// we want to emulate calling of some method
// See app/javascript/components/views/zuora_hosted_page.js
import eventBus from 'instances/event_bus';
import kebabCase from 'lodash/kebabCase';

const isDev = ['test', 'development'].includes(process.env.NODE_ENV);
const triggers = {};
const handlers = {};

// Add more keys here
[
  'emulateZuoraSuccessCallback',
  'emulateZuoraErrorCallback',
  'populateAddress',
].forEach((key) => {
  const kebabCased = kebabCase(key);

  if (isDev) {
    triggers[key] = (...rest) => { eventBus.$emit(kebabCased, ...rest); };
  }
  handlers[key] = (callback) => { eventBus.$on(kebabCased, callback); };
});

export { triggers, handlers };
