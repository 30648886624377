import RequiredProductChoice from 'components/base/required_product_choice';

const component = {
  extends: RequiredProductChoice,
  inject: ['eventBus'],
  props: ['initialSku', 'availableKey', 'requirementKey', 'redirectedFromSku', 'activeButtonSku'],
  computed: {
    available() {
      const key = 'productBuy/'.concat(this.availableKey);

      return this.$store.getters[key];
    },
  },
  data() {
    return {
      sku: this.initialSku,
      activeButtonIndex: this.initialSku,
    };
  },
  methods: {
    choiceEventData() {
      return {
        sku: this.sku,
        spec: this.spec,
        switchKey: this.switchKey,
        callback: this.emitCompleteSpecEvent,
      };
    },
    requiredProductChoiceAfterUpdateChanges() {
      this.emitProductChoice();
      this.updateRequirement();
    },
    afterUpdateChanges() {
      if (this.belongsToCombo) this.comboCalls();
      else if (this.emitProductOption) this.productOptionCalls();
      else this.requiredProductChoiceAfterUpdateChanges();
    },
    comboCalls() {
      if (this.redirectedFromSku) {
        localStorage.removeItem(this.storageName);
      } else {
        this.dataToLocalStorage();
      }
      this.updateRequirement();
    },
    productOptionCalls() {
      this.publishProductOption();
      this.dataToLocalStorage();
      this.updateRequirement();
    },
    publishProductOption() {
      const { option, sku } = this;
      this.eventBus.publishMultipackUpdate({ option, initialSku: sku });
    },
    afterMountedHook() {
      if (this.$el.style.display === 'none') return;

      if (this.redirectedFromSku) {
        const { sku, initialSwitchKey } = this;
        this.onButtonClick(this.sku, { sku, spec: initialSwitchKey, switchKey: initialSwitchKey });
      }
    },
    isButtonActive(index) {
      if (this.activeButtonSku) return this.activeButtonSku === index;

      return this.activeButtonIndex === index;
    },
  },
};

export default component;
