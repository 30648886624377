import Vue from 'vue/dist/vue.esm';

const vuexModule = {
  namespaced: true,
  state() {
    return {
      selected: null,
    };
  },
  mutations: {
    reset(state) {
      Vue.set(state, 'selected', null);
    },
    select(state, value) {
      const parsedValue = parseInt(value, 10);
      Vue.set(state, 'selected', parsedValue);
    },
  },
};

export default vuexModule;
