import baseConfig from './shared/quantity_select';

const vuexModule = {
  ...baseConfig,
  getters: {
    contactSensorsQty({ selected }) {
      if (selected === null) return 0;

      if (selected >= 4) return 4;

      return selected;
    },
    keypadQty({ selected }) {
      if (selected === null) return 0;

      if (selected >= 4) return 2;

      return 1;
    },
  },
};

export default vuexModule;
