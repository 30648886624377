/**
 * It starts a countdown and updates its state when it is over.
 * It is useful to hide elements after some seconds.
 *
 * @param time, expireation time in milliseconds
 *
 */
const component = {
  data() {
    return {
      isExpired: false,
    };
  },
  props: {
    time: {
      required: true,
      type: Number,
    },
  },
  mounted() {
    this.timeout = setTimeout(() => {
      this.isExpired = true;
    }, this.time);
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
};

export default component;
