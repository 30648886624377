<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex justify-content-start">
      <div class="d-flex align-items-center me-1">
        <hires-image v-bind:image="item.image" />
      </div>
      <div v-bind:class="{'d-flex align-items-center': !isShowQuantity && !isShowPrice}">
        <div class="font-weight-bold">
          <slot v-bind:item="item">
            {{ item.name }}
          </slot>
        </div>
        <div v-if="isShowQuantity || isShowPrice" class="text-muted small mt-1">
          <div v-if="isShowQuantity">
            Quantity: {{ quantity }}
          </div>
          <order-item-price v-if="isShowPrice" v-bind:price="item.priceData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderItemPrice from 'components/base/order_item_price';

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default() { return {}; },
    },
    customQuantity: {
      type: Number,
      required: false,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
    hideQuantity: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShowPrice() {
      return !this.hidePrice;
    },
    isShowQuantity() {
      return !this.hideQuantity;
    },
    quantity() {
      return this.customQuantity || this.item.quantity;
    }
  },
  components: {
    OrderItemPrice,
  },
};
</script>
