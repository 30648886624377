/**
 *
 * In this file we isolate and encapsulate some legacy communication logic.
 *
 * Legacy API return erb.js files whose content is a javascript script to be executed on client.
 *
 * In the long run, we will replace those with proper data-only JSON API.
 *
 * If you add a new function, please remeber to encapsulate logic in a function with meaningul name.
 *
 */

import Ajax from 'utils/ajax';
import * as Cookies from 'tiny-cookie';

/**
 * Update current order adding or removing a product.
 *
 * This is legacy API. It will be removed when shopping cart is in place.
 *
 * Note that we should not encode url in templates as we do here.
 * However, this function will be replaced soon and it could be challenging
 * to get the correct url from Ruby in another way
 *
 * @param {*} url
 * @param {*} actionType
 * @param {*} sku
 */
const updateCurrentOrder = (url, actionType, sku, viewedSku) => Ajax.send(url, {
  method: 'patch',
  params: {
    action_type: actionType,
    viewed_sku: viewedSku,
    sku,
  },
  format: 'js',
});

/**
 * goTo
 *
 * Before redirecting
 * it sets disable_geoip_redirects cookie to 1 in order to disable geo ip redirect.
 */
const goTo = (url, disableGeoip = false) => {
  if (disableGeoip) {
    Cookies.set('redirect_from_country_changer', 1);
    Cookies.set('disable_geoip_redirects', 1);
  }
  window.location.href = url;
};

/**
 * Add list of products to basket
 *
 * params: {
 *  sku[123]: 1
 *  sku[321]: 2
 * }
 *
 * @param {*} url
 * @param {*} params
 */
const populateOrder = (url, params) => Ajax.send(url, {
  params,
  method: 'post',
  format: 'js',
});

export default {
  updateCurrentOrder,
  populateOrder,
  goTo,
};
