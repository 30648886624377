import randomEmail from 'random/email';
import ValidatedForm from 'mixins/validated_form';

const component = {
  mixins: [ValidatedForm],
  data() {
    return {
      isFormVisible: false,
      userName: null,
      subject: null,
      email: null,
      message: null,
    };
  },
  computed: {
    isSubmitDisabled() {
      const { submitting, validation } = this;

      return submitting || validation.invalid;
    },
  },
  methods: {
    devPopulate() {
      this.userName = 'John Doe';
      this.subject = 'Please help me!';
      this.email = randomEmail();
      this.message = 'Help!';
    },
    showForm() {
      this.isFormVisible = true;
    },
  },
};

export default component;
