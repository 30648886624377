import debounce from 'lodash/debounce';

const eventBusMethods = {
  onProductOptionsUpdate(asyncCall, { optionKey, option, initialSku }) {
    this.updateProductOption(optionKey, option);
    this.makeApiCall(
      asyncCall,
      {
        options: this.optionsToParams(optionKey),
        sku: initialSku,
      },
      (response) => {
        this.dataLayer.replace(response.dataLayer);
        this.dataLayer.productLoaded();
        this.$store.dispatch('productBuy/change', response.productBuy);
      },
    );
  },
  onBulbFittingUpdate(asyncCall) {
    return ({ option, initialSku }) => {
      const optionKey = 'bulbFitting';
      this.onProductOptionsUpdate(asyncCall, { optionKey, option, initialSku });
    };
  },
  onMultipackUpdate(asyncCall) {
    return ({ option, initialSku }) => {
      const optionKey = 'multipackSize';
      this.onProductOptionsUpdate(asyncCall, { optionKey, option, initialSku });
    };
  },
  updateProductOption(optionKey, option) {
    this.productOptions[optionKey] = option;
  },
  makeApiCall: debounce(
    (asyncCall, params, processResponse) => asyncCall(params).then(processResponse),
    50,
  ),
  optionsToParams(exceptOption) {
    const { [exceptOption]: _, ...requestParams } = this.productOptions;
    return Object.keys(requestParams)
      .filter(param => requestParams[param]) // reject blank params
      .reduce( // build final params object
        (acc, param) => ({ ...acc, [param]: requestParams[param] }),
        {},
      );
  },
};

const methods = {
  onAddSku(skuAndQuantity) {
    // tell store to add an additional --sku to the list
    this.$store.dispatch('productBuy/addSku', skuAndQuantity);
  },
  onRemoveSku(sku) {
    // tell store to remove an additional --sku from list
    this.$store.dispatch('productBuy/removeSku', sku);
  },
  onChangedSku: debounce(function _onChangedSku(opts) {
    // tell store to change the primary sku in the list
    this.$store.dispatch('productBuy/changeSku', opts);
  }, 50),
  onChangedProduct({ sku, ...others }) {
    const payload = sku === undefined ? others : { product: { sku }, ...others };
    // tell store to change the selected product
    this.$store.dispatch('productBuy/change', payload);
  },
  onChangedPurchasingOptions({ widgetId, requirements }) {
    this.$store.dispatch('productBuy/changeOptions', { widgetId, requirements });
  },
  onUpdateImageSavingsBadge(savings) {
    this.$store.dispatch('productBuy/updateImageSavingsBadge', savings);
  },
  onHideWidget(widgetId) {
    this.$store.dispatch(
      'productBuy/resetWidget',
      { widgetRequirement: this.requirements[widgetId], widgetId },
    );
    this.hide[widgetId] = true;
  },
  onShowWidget(widgetId) {
    this.$store.dispatch(
      'productBuy/restoreWidget',
      { widgetRequirement: this.requirements[widgetId], widgetId },
    );
    this.hide[widgetId] = false;
  },
  ...eventBusMethods,
};

export default methods;
