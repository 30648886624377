import { NumberFormat } from 'utils/intl';

const mixin = {
  methods: {
    priceComponents(base, notDiscounted = false) {
      let total = base;
      try {
        const additional = (this.basket_requirement || {}).price || 0.0;
        total = parseFloat(total) + parseFloat(additional);

        let additionalHeatingZone;
        if (notDiscounted) {
          additionalHeatingZone = this.options.additionalHeatingZonePriceNotDiscounted
            || this.options.additionalHeatingZonePrice;
        } else {
          additionalHeatingZone = this.options.additionalHeatingZonePrice;
        }

        total = parseFloat(total) + parseFloat(additionalHeatingZone || 0.0);
      } catch (err) {
        // Swallow any error if we fail to calculate additional price components
        // this could be if localStorage has an old value, or a widget outputs
        // invalid data, failed to load, etc.
        //
        // return the base price in this case (avoid displaying NaN)
      }

      return total;
    },
    formattedAmount(amount) {
      return new NumberFormat(
        this.config.locale,
        {
          style: 'currency',
          currencyDisplay: 'symbol',
          currency: this.config.currency.code,
          minimumFractionDigits: 2,
        }
      ).format(amount);
    },
    formattedSaving(quantity) {
      const priceMethod = this.product.subscription ? 'monthlyPrice' : 'price';
      const price = this.priceComponents(this.product[priceMethod], true);
      const discountPrice = this.priceComponents(this.product.displayPrice);
      const saving = (price - discountPrice) * quantity;
      return this.formattedAmount(saving);
    },
  },
};

export default mixin;
