import NumericOnly from 'directives/numeric_only';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('productBuy');

const component = {
  name: 'quantity-selector',
  props: {
    initialQuantity: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    maxQuantity: {
      type: Number,
      required: false,
      default: 0,
    },
    minQuantity: {
      type: Number,
      required: false,
      default: 1,
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    ...mapGetters(['buyButtonAvailable']),
    decrementDisabled() {
      return this.quantity >= this.minQuantity;
    },
    incrementDisabled() {
      return this.maxQuantity !== 0 && this.quantity >= this.maxQuantity;
    },
  },
  methods: {
    decreaseQuantity() {
      this.quantity -= this.step;
    },
    increaseQuantity() {
      this.quantity += this.step;
    },
  },
  data() {
    return {
      quantity: this.initialQuantity,
      updating: false,
    };
  },
  mounted() {
    this.$emit('update-quantity', this.quantity);
  },
  watch: {
    quantity() {
      this.$emit('update-quantity', this.quantity);
    },
  },
  directives: {
    'numeric-only': NumericOnly,
  },
};

export default component;
