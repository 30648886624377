import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('heatingPlusBuyPage');

const component = {
  props: {
    registerInterestModalId: String,
  },
  computed: {
    ...mapGetters([
      'thermostatPresence',
    ]),
  },
  methods: {
    ...mapActions([
      'setThermostatPresence',
    ]),
    setThermostatPresenceAsFalse() {
      this.setThermostatPresence(false);
      this.$modal.show(this.registerInterestModalId);
    },
  },
};

export default component;
