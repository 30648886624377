<template>
  <div>
    <slot>
      <h6 v-if="title" class="mb-0">
        {{ title }}
      </h6>
    </slot>
    <address v-if="isAddressPresent">
      <div v-if="address.address1">
        {{ address.address1 }}
      </div>
      <div v-if="address.address2">
        {{ address.address2 }}
      </div>
      <div v-if="address.city" v-bind:class="{ 'd-inline': compact }">
        {{ address.city }}{{ compact ? ',' : '' }}
      </div>
      {{ address.postcode || address.territory }}
    </address>
    <div v-if="isShowFallback" class="text-muted small">
      Address has been deleted from the Address Book.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    compact: {
      type: Boolean,
      default: true,
    },
    fallback: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isAddressPresent() {
      return this.address && this.address.address1;
    },
    isShowFallback() {
      return this.fallback && !this.isAddressPresent;
    },
  },
};
</script>
