import ValidatedForm from 'mixins/validated_form';

/*
A component for dynamic validation.
Designed for forms without specific validation requirements.
Form fields should be defined with `init-form` prop and even empty values should be there

<base-form init-form="{ field1: '', field2: 'something' }" inline-template>
  <validated-field rules="required">
    <template v-slot="{ inputClass }">
      <%= f.text_field :field1,
        class: 'form-control',
        placeholder: 'Field1',
        'v-bind:class': 'inputClass',
        'v-model': 'form.field1'
      %>
    </template>
    <template v-slot:error-required>
      REQUIRED!!!!
    </template>
  </validated-field>
</base-form>
*/

const component = {
  mixins: [ValidatedForm],
  props: {
    initForm: Object,
  },
  data() {
    return {
      form: this.initForm,
    };
  },
};

export default component;
