<template>
  <div class="other-smart-products-totals">
    <div class="container">
      <div class="d-flex justify-content-center align-items-center">
        <div class="d-flex flex-row w-100 align-items-center py-1">
          <div class="w-50">
            <div class="text-sm-end">
              <div v-if="oneOffVisible">
                <strong>
                  One-off: <formatted-money v-bind:value="basket.oneOffTotal" />
                </strong>
              </div>
              <div v-if="bundleProductTotalVisible" class="d-lg-inline">
                <formatted-money v-bind:value="bundleProductTotal" />
                <span>
                  per month for <span v-text="months" /> months
                </span>
              </div>
              <div v-if="marketScopeIE && monthlyPlanSubscriptionsTotal > 0" class="d-lg-inline">
                <formatted-money v-bind:value="monthlyPlanSubscriptionsTotal" />
                <span>
                  per month for <span v-text="months" /> months
                </span>
              </div>
              <div v-if="subscriptions.length > 0">
                <div
                  v-for="item in subscriptions"
                  v-bind:key="item.sku"
                  class="d-lg-inline small"
                >
                  <div>
                    <span v-if="!basket.containsOnlyServiceSubscriptions">Plus</span>
                    <span class="font-weight-bold">
                      <subscription-price v-bind:subscription="item" />
                    </span>
                    <div v-text="item.basketName" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex w-50">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('basket');

const component = {
  name: 'prices-with-go-to-basket',
  inject: ['config'],
  computed: {
    ...mapState(['basket']),
    subscriptions() {
      return this.basket.visibleSubscriptions;
    },
    oneOffVisible() {
      const {
        byoRequestedToBeBundled,
        oneOffTotal,
      } = this.basket;

      if (byoRequestedToBeBundled) return false;

      return oneOffTotal > 0;
    },
    bundleProductTotalVisible() {
      const { byoRequestedToBeBundled, bundleProductTotal } = this.basket;

      if (!byoRequestedToBeBundled) return false;

      return bundleProductTotal > 0;
    },
    months() {
      return this.basket.monthlyPaymentRecurringLength;
    },
    marketScopeIE() {
      return this.basket.marketScope === 'ie';
    },
    monthlyPlanSubscriptionsTotal() {
      return this.basket.monthlyPlanSubscriptionsTotalPrice;
    },
  },
};

export default component;
</script>
