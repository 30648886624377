const component = {
  props: {
    countiesWithAvailableInstallation: Array,
    boilerType: String,
    hubOption: String,
    emitEvent: String,
    hasInstallationAvailable: Boolean,
  },
  data() {
    return {
      selectedCounty: undefined,
      isCountySelectorOpen: false,
    };
  },
  methods: {
    selectCounty(county) {
      this.selectedCounty = county;
      this.isCountySelectorOpen = false;

      const { countiesWithAvailableInstallation } = this;
      this.$emit(this.emitEvent, countiesWithAvailableInstallation.includes(county));
    },
    toggle() {
      this.isCountySelectorOpen = !this.isCountySelectorOpen;
    },
  },
};

export default component;
