import { createNamespacedHelpers } from 'vuex';

import FloatingBasket from 'components/views/floating_basket';

const { mapState, mapGetters } = createNamespacedHelpers('basket');

const component = {
  props: {
    menuKey: String,
  },
  computed: {
    ...mapGetters(['isBasketEmpty']),
    ...mapState({
      badge(state) {
        if (state.basket) {
          return state.basket.itemCountForBadge;
        }

        return null;
      },
    }),
    active() {
      return !this.isBasketEmpty;
    },
  },
  components: {
    'floating-basket': FloatingBasket,
  },
  methods: {
    onMouseEnter() {
      this.$emit('submenu-show', this.menuKey);
    },
    onMouseLeave() {
      this.$emit('submenu-hide', this.menuKey);
    },
    handleClick(event) {
      window.location = event.currentTarget.href;
    },
  },
};
export default component;
