import logger from 'utils/logger';
import HiresImage from 'components/base/hires_image';
import ThermostatCompatibilityCard from 'components/views/thermostat_compatibility_card';

const component = {
  // HWS-7001: this 'name' is important for DMPG analytics
  name: 'slide-carousel',
  props: {
    slides: {
      required: false,
    },
    findId: {
      required: false,
      type: String,
    },
    options: {
      required: false,
      type: String,
    },
    selectedCard: { type: Object },
  },
  components: {
    'hires-image': HiresImage,
    'thermostat-compatibility-card': ThermostatCompatibilityCard,
  },
  methods: {
    selectCarouselSlide(slide) {
      this.$emit('selected', slide);
    },
  },
  mounted() {
    const carouselId = `#${this.findId}`;
    try {
      const options = JSON.parse(this.options);
      this.carousel = $(carouselId).owlCarousel(options);
    } catch (e) {
      // this should not happen, inform devs in case
      logger.error(`Cannot initialise carousel ${carouselId}: malformed options.`, this.options);
    }
  },
  beforeDestroy() {
    if (this.carousel) {
      this.carousel.owlCarousel('destroy');
    }
  },
};

export default component;
