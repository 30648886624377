import Notifications from 'components/base/notifications';
import EvOrderHeader from 'components/base/ev_order_header';
import ProgressBar from 'components/base/progress_bar';
import EvDefaultCtas from 'components/base/ev_default_ctas';
import * as c from 'components/pages/my/constants';

const EVProgressBarSteps = {
  PHC: [
    'Your photos',
    'Engineer visit',
    'Install',
  ],
  VHC: [
    'Your photos',
    'Engineer call',
    'Install',
  ],
  NoVHC: [
    'Your photos',
    'Install',
  ],
  DNOAppAfterVHC: [
    'Your photos',
    'Engineer call',
    'DNO application',
    'Install',
  ],
  DNOAppAfterPHC: [
    'Your photos',
    'Engineer visit',
    'DNO application',
    'Install',
  ],
  DNOWorksAfterVHC: [
    'Your photos',
    'Engineer call',
    'DNO upgrades',
    'Install',
  ],
  DNOWorksAfterPHC: [
    'Your photos',
    'Engineer visit',
    'DNO upgrades',
    'Install',
  ],
};

const mixin = {
  inject: ['dataLayer'],
  props: {
    currentOrder: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.EV_ACTIONS = c.EV_ACTIONS;
    this.EV_PROGRESS_BAR_TYPES = c.EV_PROGRESS_BAR_TYPES;
  },
  computed: {
    evOrderStatuses() {
      return EVProgressBarSteps[this.currentOrder.progressBarType];
    },
  },
  components: {
    Notifications,
    EvOrderHeader,
    ProgressBar,
    EvDefaultCtas,
  },
};

export default mixin;
