/**
 *
 * Seen during seasonal offer at the top of page in global header.
 *
*/

const zeroPadded = (number) => {
  if (number < 10) return `0${number}`;

  return number;
};

const component = {
  props: ['startTime', 'endTime', 'expiredText'],
  data() {
    return {
      message: {},
      now: new Date(this.startTime).getTime(),
      end: new Date(this.endTime).getTime(),
      interval: '',
      days: '',
      minutes: '',
      hours: '',
      seconds: '',
      isExpired: false,
      delay: 1000,
    };
  },
  mounted() {
    this.timerInit();
    this.interval = setInterval(() => {
      this.now += this.delay;
      this.timerInit();
    }, this.delay);
  },
  methods: {
    timerInit() {
      const active = this.end - this.now;

      if (active <= 0) this.setExpiredAndStopTimer();
      else this.calculatedTime(active);
    },
    setExpiredAndStopTimer() {
      this.calculatedTime(0);
      this.isExpired = true;
      clearInterval(this.interval);
    },
    calculatedTime(dist) {
      this.days = zeroPadded(Math.floor(dist / (1000 * 60 * 60 * 24)));
      this.hours = zeroPadded(Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      this.minutes = zeroPadded(Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60)));
      this.seconds = zeroPadded(Math.floor((dist % (1000 * 60)) / 1000));
    },
    firstNumber(num) {
      return String(num)[0];
    },
    secondNumber(num) {
      return String(num)[1];
    },
  },
};

export default component;
