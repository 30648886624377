const template = `
<div v-on:mouseleave="onMouseLeave" v-on:mouseenter="onMouseEnter">
  <slot v-bind:is-mouse-over="isMouseOver"></slot>
</div>
`;

const component = {
  template,
  data() {
    return {
      isMouseOver: false,
    };
  },
  methods: {
    // NOTE: `isMouseOver = !isMouseOver` has non-deterministic behaviour since Vue updates
    // data props asynchronously and it might set `isMouseOver` to `true` when `false` is expected
    // and vice versa.
    // In order to prevent such kind of behaviour we are explicitly updating it to true/false.
    // https://vuejs.org/v2/guide/reactivity.html#Async-Update-Queue
    onMouseEnter() {
      this.isMouseOver = true;
    },
    onMouseLeave() {
      this.isMouseOver = false;
    },
  },
};

export default component;
