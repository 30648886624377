<template>
  <my-account-header class="mb-5">
    Order ref: {{ order.subscriptionNumber }}
    <br class="d-md-none">
    <span class="d-none d-md-inline"> - </span>
    EV Charging
  </my-account-header>
</template>

<script>
import MyAccountHeader from 'components/base/my_account_header';

const component = {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    MyAccountHeader,
  },
};

export default component;
</script>
