import FloatingBasket from 'components/views/floating_basket';

const component = {
  TIMEOUT: 3000,
  data() {
    return {
      isTimeoutRunning: false,
      menuKey: 'basket',
    };
  },
  props: {
    activeSubmenu: String,
    isMouseOver: Boolean,
  },
  computed: {
    isSelected() {
      return this.menuKey === this.activeSubmenu;
    },
    isVisible() {
      return this.isSelected || this.isMouseOver || this.isTimeoutRunning;
    },
  },
  destroy() {
    // free timeouts
    if (this.handle) {
      clearTimeout(this.handle);
    }
  },
  components: {
    'floating-basket': FloatingBasket,
  },
};

export default component;
