import RequiredProductChoice from 'components/base/required_product_choice';

const component = {
  extends: RequiredProductChoice,
  inject: ['eventBus'],
  props: ['initialSku', 'availableKey', 'requirementKey'],
  computed: {
    available() {
      const key = 'productBuy/'.concat(this.availableKey);

      return this.$store.getters[key];
    },
  },
  data() {
    return {
      sku: this.initialSku,
      requirement: this.requirementKey,
    };
  },
  methods: {
    choiceEventData() {
      return { sku: this.sku, callback: this.emitCompleteSpecEvent };
    },
    afterUpdateChanges() {
      if (this.belongsToCombo) this.comboCalls();
      else if (this.emitProductOption) this.productOptionCalls();
      else this.requiredProductChoiceAfterUpdateChanges();
    },
    publishProductOption() {
      const { option, sku } = this;
      this.eventBus.publishBulbFittingUpdate({ option, initialSku: sku });
    },
    comboCalls() {
      this.dataToLocalStorage();
      this.updateRequirement();
    },
    productOptionCalls() {
      this.publishProductOption();
      this.dataToLocalStorage();
    },
  },
};

export default component;
