const mixin = {
  props: {
    // See ValidationObserver docs:
    // https://logaretm.github.io/vee-validate/guide/validation-observer.html#scoped-slot-data
    validation: Object,
  },
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    beforeSubmit() {
      // Override this in components.
    },
    // Generic handler for a regular non-ajax form submit.
    onSubmit(event) {
      this.validation.passes(() => {
        this.beforeSubmit();

        this.submitting = true;

        const form = event.target;

        // <input name="submit"> will replace the form's submit() method with input reference.
        if (typeof form.submit !== 'function') {
          const message = `${form.submit} is expected to be a function. `
            + 'Make sure there is no input/button with name="submit".';
          throw new Error(message);
        }

        form.submit();
      });
    },
  },
};

export default mixin;
