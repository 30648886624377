import { createNamespacedHelpers } from 'vuex';

import HiveLoader from 'components/base/hive_loader';

const { mapState } = createNamespacedHelpers('global');

/**
 *
 * This component is a global `hive-loader` connected to VueX.
 * It listens to VueX state and show a centered loader if the state is loading.
 *
 * We need it mainly for legacy code since the loader is controlled programmatically there.
 *
 * If you need a new `hive-loader`, please consider to use the local version, where
 * the loader is defined in a declarative way.
 * You can check [here](/app/javascript/base/slot_button.js) for an example of usage.
 *
 */
const component = {
  computed: mapState({
    isLoading: state => state.isLoading,
  }),
  components: {
    'hive-loader': HiveLoader,
  },
};

export default component;
