<template>
  <div>
    <div v-if="error" class="form-group">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
    <div
      id="zuora_payment"
      v-bind:class="{ 'direct-debit': directDebit, 'custom-submit': customSubmit }"
    />
    <div v-if="directDebit" class="mb-5">
      <address-autocomplete
        ref="addressAutocomplete"
        v-on:address-update="setAddress"
      />
    </div>

    <slot name="above-custom-submit" />

    <button
      v-if="displaySubmit"
      type="button"
      class="btn btn-primary w-100"
      v-bind:disabled="submiting"
      v-on:click="submit"
    >
      {{ submitLabel }}
    </button>
  </div>
</template>

<script>
  import logger from 'utils/logger';
  import {createNamespacedHelpers} from 'vuex';
  import isObject from 'lodash/isObject';
  import AddressAutocomplete from 'components/views/address_autocomplete';
  import {handlers as testHandlers} from 'utils/bridge';

  const { mapState } = createNamespacedHelpers('zuora');
  const mapBasketState = createNamespacedHelpers('basket').mapState;

// To map Zuora error add a Zuora message as a key and desired message as a value
// To render an inline error, specify an object with `key` and `message`.
export const ERROR_MESSAGES = {
  '[GatewayTransactionError]&nbsp; Transaction declined.430285 - Not authorised': 'The transaction was declined, please try again.',
  '[GatewayTransactionError]&nbsp; Transaction declined.21000220 - FIELD_LENGTH_INVALID: cardPaymentMethodSpecificInput.card.cvv': {
    key: 'cardSecurityCode',
    message: 'Invalid security code',
  },
  '[GatewayTransactionError]&nbsp; Transaction declined.validation_failed - account_number is the wrong length (should be 8 characters)': {
    key: 'bankAccountNumber',
    message: 'Invalid account number',
  },
  '[GatewayTransactionError]&nbsp; Transaction declined.validation_failed - account_number does not match sort code': {
    key: 'bankAccountNumber',
    message: 'Invalid account number',
  },
  defaultCreditCardError: 'Card validation failed, please check all details and try again.',
  defaultDirectDebitError: 'Form validation failed, please check all details and try again.',
  notProcessedError: 'Your payment has not been processed due to: ',
};

const component = {
  inject: ['client', 'config'],
  props: {
    submitLabel: {
      type: String,
      default: '',
    },
    customSubmit: {
      type: Boolean,
      default: false,
    },
    directDebit: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.injectScript();
  },
  created() {
    testHandlers.emulateZuoraSuccessCallback(this.successCallback);
    testHandlers.emulateZuoraErrorCallback(this.errorCallback);
  },
  data() {
    return {
      submiting: false,
      error: '',
    };
  },
  computed: {
    ...mapState([
      'hostedScriptUrl',
      'hostedPageParams',
      'hostedPagePrepopulateFields',
    ]),
    ...mapBasketState(['basket']),
    displaySubmit() {
      return this.submitLabel && !this.customSubmit;
    },
    submitEnabled() {
      return !this.submitLabel && !this.customSubmit;
    },
    defaultErrorMessage() {
      const key = this.directDebit ? 'DirectDebit' : 'CreditCard';

      return ERROR_MESSAGES[`default${key}Error`];
    },
    zuoraApi() {
      return window.Z;
    },
  },
  methods: {
    injectScript() {
      const script = document.createElement('script');

      script.src = this.hostedScriptUrl;
      script.onload = this.loadHostedPage;

      document.head.appendChild(script);
    },
    showError(message) {
      this.error = message;
    },
    sendToZuoraIframe(command) {
      if (!this.zuoraApi || !this.zuoraApi.post) { return; }

      this.zuoraApi.post('z_hppm_iframe', command);
    },
    loadHostedPage() {
      this.zuoraApi.renderWithErrorHandler(
        {
          ...this.hostedPageParams,
          submitEnabled: this.submitEnabled,
          style: 'inline',
        },
        this.hostedPagePrepopulateFields,
        this.successCallback.bind(this),
        this.errorCallback.bind(this),
      );

      this.zuoraApi.runAfterRender(() => {
        if (!this.submitEnabled) {
          // There is no way in Zuora to render custom submit buttom that triggers
          // callbacks instead of redirecting to the redirectUrl.
          // So after rendering custom submit button we then need to set `submitEnabled`
          // to `true` so `Z.submit` function will work as per regular Zuora form
          // with the default submit buttom.
          this.sendToZuoraIframe('setField(submitEnabled:true)');
        }
        this.emitAndTriggerSpecEvent('load');
      });
    },
    successCallback(response) {
      return this.client.trackZuoraResults({}, response)
        .catch(() => { this.$emit('track-error', 'results'); })
        // we need to process the response in any case
        .finally(() => {
          const success = response.success === 'true';
          if (success || response.AuthorizeResult === 'Approved') {
            // refId is present only if success is true
            // so we need to use threedPaymentMethodId if it's false.
            const paymentMethodId = success ? response.refId : response.threedPaymentMethodId;
            this.emitAndTriggerSpecEvent('success', paymentMethodId, response);
          } else {
            this.showError(`${ERROR_MESSAGES.notProcessedError}${response.errorMessage}`);
            this.emitAndTriggerSpecEvent('decline', response);
          }

          this.submiting = false;
        });
    },
    errorCallback(key, code, message) {
      logger.debug('Zuora error', key, code, message);

      return this.client.trackZuoraError({}, { key, code, message })
        .catch(() => { this.$emit('track-error', 'error'); })
        // we need to process the response in any case
        .finally(() => {
          let errorMessage = message;
          let errorKey = key;
          let showError = true;

          if (key === 'error') {
            if (ERROR_MESSAGES[message]) {
              if (isObject(ERROR_MESSAGES[message])) {
                errorKey = ERROR_MESSAGES[message].key;
                errorMessage = ERROR_MESSAGES[message].message;
                showError = !errorKey;
              } else {
                errorMessage = ERROR_MESSAGES[message];
              }
            } else {
              errorMessage = this.defaultErrorMessage;
            }

            if (showError) { this.showError(errorMessage); }
          }

          this.zuoraApi.sendErrorMessageToHpm(errorKey, errorMessage);

          this.emitAndTriggerSpecEvent('error', key, code, message);

          this.submiting = false;
        });
    },
    submit() {
      if (this.submiting) { return; }

      if (this.basket.installationAmount > 0) {
        this.client.validateOrderReservation().then(({ valid }) => {
          if (valid) {
            this.submitPayment();
          } else {
            this.client.goTo('/checkout/appointment');
          }
        })
      } else {
        this.submitPayment();
      }

    },
    submitPayment() {
      this.showError('');
      this.$emit('submit');
      this.submiting = true;
      if (this.directDebit) { this.$refs.addressAutocomplete.validate(); }

      this.zuoraApi.setAgreement('External', 'Recurring', 'Visa,MasterCard', this.customerId)

      this.zuoraApi.submit();
    },
    // set address field in Zuora iframe
    setAddress({
      address1,
      city,
      postcode,
      territory,
    }) {
      if (this.directDebit) {
        this.sendToZuoraIframe(`setField(streetName:${address1})`);
        this.sendToZuoraIframe(`setField(city:${city})`);
        this.sendToZuoraIframe(`setField(postalCode:${postcode})`);
        this.sendToZuoraIframe(`setField(state:${territory || city})`);
      } else {
        this.sendToZuoraIframe(`setField(creditCardAddress1:${address1})`);
        this.sendToZuoraIframe(`setField(creditCardCity:${city})`);
        this.sendToZuoraIframe(`setField(creditCardPostalCode:${postcode})`);
        this.sendToZuoraIframe(`setField(creditCardState:${territory || city})`);
      }
    },
    setAuthorizationAmount(amount) {
      this.sendToZuoraIframe(`setField(authorizationAmount:${amount})`);
    },
    triggerSpecEvent(event) {
      if (window.specEvents) window.specEvents.push(`zuoraHostedPage.${event}`);
    },
    emitAndTriggerSpecEvent(...args) {
      this.$emit(...args);
      this.triggerSpecEvent(args[0]);
    },
  },
  components: {
    AddressAutocomplete,
  },
};

export default component;
</script>
