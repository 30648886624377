import initModuleWithState from 'store/utils/init_module_with_state';

function initBasketModule(module, state) {
  const initState = {
    basket: {
      currency: 'GBP',
      locale: 'en-GB',
      marketScope: 'uk',
      lineItemsBySku: {},
      ...state.basket,
    },
  };
  return initModuleWithState(module, initState, 'basket');
}

export default initBasketModule;
