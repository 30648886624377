import HiresImage from 'components/base/hires_image';

const imageTypeToAspectRatioMapping = {
  leadingImage: '73by47',
  normalImage: '35by19',
  wideImage: '51by19',
};

const normaliseImageType = type => `${type}Image`;

const component = {
  template: `
    <div>
      <a v-bind:href="article.path">
        <div class="embed-responsive mb-1 d-none d-sm-block" v-bind:class="['embed-responsive-' + aspectRatio]">
          <hires-image v-bind:image="image" classes="embed-responsive-item mod-centrated-image"></hires-image>
        </div>
        <div class="mb-1 d-block d-sm-none">
          <hires-image v-bind:image="mobileImage"></hires-image>
        </div>
      </a>
      <h4 class="m-2 mt-1">
        <a v-bind:href="article.path" class="text-gray">
          {{ article.title }}
        </a>
      </h4>
    </div>
  `,
  props: {
    article: {
      type: Object,
      required: true,
    },
    imageType: {
      type: String,
      required: true,
      validator(value) {
        return Object.keys(imageTypeToAspectRatioMapping).indexOf(normaliseImageType(value)) !== -1;
      },
    },
  },
  computed: {
    normalisedImageType() { return normaliseImageType(this.imageType); },
    aspectRatio() { return imageTypeToAspectRatioMapping[this.normalisedImageType]; },
    image() { return this.article[this.normalisedImageType]; },
    mobileImage() { return this.article.mobileImage; },
  },
  components: {
    'hires-image': HiresImage,
  },
};

export default component;
