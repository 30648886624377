import { createNamespacedHelpers } from 'vuex';

import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HoneyProductRegularBuyThermostatPresence from 'components/views/honey/product_regular_buy_thermostat_presence';
import HoneyProductRegularBuyPaymentType from 'components/views/honey/product_regular_buy_payment_type';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('heatingPlusBuyPage');

const component = {
  mixins: [AddToBasketButton],
  props: {
    annualPrice: Number,
    monthlyPrice: Number,
    heatingPlusSkuMappings: Object,
    moneyBackGuarantee: Boolean,
    hhpBgEnergyOffer: String,
    hhpHiveEnergyOffer: String,
    hhpRedemptionOffer: Boolean,
    hhpPartnerOffer: String,
    hhpFlashOffer: Boolean,
    freeFrameVoucher: String,
    heatingPlusAnnualPriceDiscount: String,
    heatingPlusMonthlyPriceDiscount: String,
    heatingPlusAnnualPriceDiscountPercentage: String,
    accountNumberValidationRequired: Boolean,
  },
  data() {
    return {
      showMonthlyAndAnnual: true,
      accountNumberValid: false,
      accountNumberValidated: false,
      accountNumber: '',
    };
  },
  computed: {
    ...mapState([
      'isPriceLoading',
      'frameSku',
    ]),
    ...mapGetters([
      'thermostatPresence',
      'sku',
      'basketPayload',
      'paymentType',
    ]),
    showHhpOffer() {
      const {
        hhpBgEnergyOffer,
        hhpHiveEnergyOffer,
        freeFrameVoucher,
        hhpPartnerOffer,
        hhpFlashOffer,
      } = this;

      return hhpBgEnergyOffer || hhpHiveEnergyOffer || freeFrameVoucher || hhpPartnerOffer
        || hhpFlashOffer;
    },
    buttonDisabled() {
      return !this.thermostatPresence
        || (this.accountNumberValidationRequired && !this.accountNumberValid);
    },
    showDiscountBadge() {
      if (this.paymentType !== 'annual' && this.heatingPlusMonthlyPriceDiscount) return true;

      if (this.paymentType === 'annual' && this.heatingPlusAnnualPriceDiscount) return true;

      return false;
    },
    discountBadge() {
      if (this.moneyBackGuarantee) {
        return 'Money-Back Guarantee';
      }

      if (this.freeFrameVoucher) {
        return 'Free frame included';
      }

      if (this.hhpPartnerOffer) {
        if (this.hhpPartnerOffer === 'Trading Partner') {
          return 'Unlock 3-months free';
        }
        return `Unlock 3-months free with ${this.hhpPartnerOffer}`;
      }

      if (this.paymentType === 'annual') {
        if (this.hhpFlashOffer) return `Save ${this.heatingPlusAnnualPriceDiscountPercentage}`;

        if (this.heatingPlusAnnualPriceDiscount) return `Save ${this.heatingPlusAnnualPriceDiscount}`;

        if (this.hhpBgEnergyOffer !== '' || this.hhpRedemptionOffer === true) {
          return '';
        }

        return 'Save 35%';
      } else if (this.paymentType === 'monthly' && this.heatingPlusMonthlyPriceDiscount) {
        return `Save ${this.heatingPlusMonthlyPriceDiscount}`;
      }

      if (this.hhpRedemptionOffer === true) {
        return 'FREE 6 month trial - start now<sup>[1]</sup>';
      }

      if (this.hhpBgEnergyOffer === '6_months_free') {
        return 'Unlock 6-months free with British Gas';
      } if (this.hhpBgEnergyOffer === '3_months_free') {
        return 'Unlock 3-months free with British Gas';
      } if (this.hhpHiveEnergyOffer === '12_months_free' || this.hhpBgEnergyOffer === '12_months_free') {
        return 'Unlock 12-months free with British Gas';
      }

      return 'FREE 1 month trial - start now<sup>[1]</sup>';
    },
    hhpBgMonthsFree() {
      if (!this.hhpBgEnergyOffer) return '';

      const offer = this.hhpBgEnergyOffer.split('_');

      return `${offer[0]}-${offer[1]} ${offer[2]}`;
    },
    totalPrice() {
      return 1;
    },
    totalDiscountedPrice() {
      return 1;
    },
    totalDiscount() {
      return 0;
    },
    hasDiscount() {
      return false;
    },
    showAccountNumberValidation() {
      const {
        accountNumberValidationRequired,
        accountNumberValid,
        accountNumberValidated,
      } = this;

      return accountNumberValidationRequired && accountNumberValidated && !accountNumberValid;
    },
  },
  created() {
    const { heatingPlusSkuMappings, freeFrameVoucher } = this;
    const params = { heatingPlusSkuMappings, hubSku: null };

    if (freeFrameVoucher) params.freeFrameVoucher = freeFrameVoucher;

    this.init(params);
  },
  methods: {
    ...mapActions([
      'init',
      'setFrameSku',
    ]),
    validateAccountNumber() {
      const accountNumberFormat = /^85[1,0]{1}0[0-9a-zA-Z]{8}$|^85100[0-9a-zA-Z]{7}$|^A[0-9a-zA-Z]{5,8}$|^BGX[0-9]{4}$|^BGX[0-9]{5}$/;
      const isValid = accountNumberFormat.test(this.accountNumber.trim());
      this.accountNumberValidated = true;
      if (isValid) {
        setTimeout(() => {
          this.accountNumberValid = true;
        }, 1000);
      } else {
        this.accountNumberValid = false;
      }
    },
  },
  components: {
    TrustPilotPopup,
    HoneyProductRegularBuyThermostatPresence,
    HoneyProductRegularBuyPaymentType,
  },
};

export default component;
