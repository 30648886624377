import { createNamespacedHelpers } from 'vuex';

import HdlPromotion from 'components/views/hdl_promotion';
import BasketVoucher from 'components/views/basket_voucher';
import BasketItem from 'components/views/basket_item';
import BasketSummary from 'components/views/basket_summary';
import BasketSummaryTable from 'components/views/basket_summary_table';
import BasketFraudProtectionLimit from 'components/views/basket_fraud_protection_limit';
import BasketPaymentMethod from 'components/views/basket_payment_method';
import ViewportChecker from 'components/base/viewport_checker';

const { mapGetters, mapState } = createNamespacedHelpers('basket');
const { mapState: mapCheckoutState } = createNamespacedHelpers('checkout');

const HOMESHIELD_SKU = 'SUB00550';

const component = {
  inject: ['cookies'],
  components: {
    'hdl-promotion': HdlPromotion,
    'basket-voucher': BasketVoucher,
    'basket-item': BasketItem,
    'basket-summary': BasketSummary,
    'basket-summary-table': BasketSummaryTable,
    'basket-fraud-protection-limit': BasketFraudProtectionLimit,
    'basket-payment-method': BasketPaymentMethod,
    'viewport-checker': ViewportChecker,
  },
  computed: {
    ...mapGetters([
      'isBasketEmpty',
      'lineItems',
    ]),
    ...mapState({
      updating: 'updating',
      basket: 'basket',
      isCheckoutButtonVisible: ({ basket }) => !basket.totalAboveFraudProtectionLimit,
    }),
    ...mapCheckoutState(['paymentMethod']),
    homeshieldProduct() {
      return this.lineItems.find(i => i.product.product.sku === HOMESHIELD_SKU);
    },
    homeshieldTitle() {
      return this.homeshieldProduct ? this.cookies.get('homeshield_pack_name') : '';
    },
    lineItemsOrdered() {
      let res = [...this.lineItems];
      res = res.filter(i => i.product.product.sku !== HOMESHIELD_SKU);
      res.unshift(this.homeshieldProduct);

      return res.filter(n => n);
    },
    payingToday() {
      return this.basket.upfrontTotal;
    },
  },
};

export default component;
