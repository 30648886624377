const mixin = {
  data() {
    return {
      currentStage: 0,
    };
  },
  methods: {
    nextStage() {
      this.currentStage += 1;
      this.scrollToTheTop();
    },
    prevStage() {
      this.currentStage -= 1;
      this.scrollToTheTop();
    },
    isCurrentStage(stage) {
      return this.currentStage === stage;
    },
    scrollToTheTop() {
      window.scrollTo(0, 0);
    },
    setStage(value) {
      this.currentStage = value;
    },
  },
};

export default mixin;
