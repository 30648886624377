// Apply Vue dynamics to the following components
import FooterSection from 'components/sections/footer_section';

const instance = {
  el: '#vue-footer-instance',
  name: 'footer',
  components: {
    'footer-section': FooterSection,
  },
};

export default instance;
