import { createNamespacedHelpers } from 'vuex';

import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HoneyOptionSelector from 'components/views/honey/option_selector';
import AmountDiscountBadge from 'mixins/amount_discount_badge';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('pdpPackBuyPage');

const component = {
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    packSkuMappings: Object,
  },
  data() {
    return {
      basketInterval: null,
      namespace: 'pdpPackBuyPage',
    };
  },
  computed: {
    ...mapState([
      'installationType',
      'hubOption',
      'channelOption',
      'fittingType',
      'isPriceLoading',
    ]),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
      'sku',
    ]),
    withHub() {
      return this.hubOption === 'hub';
    },
  },
  created() {
    const { packSkuMappings } = this;

    this.init({ packSkuMappings });
  },
  beforeDestroy() {
    clearInterval(this.basketInterval);
  },
  methods: {
    ...mapActions([
      'setHubOption',
      'setChannelOption',
      'setInstallationType',
      'setFittingType',
      'init',
    ]),
  },
  components: {
    TrustPilotPopup,
    HoneyOptionSelector,
  },
};

export default component;
