export const NEXT_STEP_YES_POWER_SYCLE = {
  nextStepYes(that) {
    that.displayPowercycleResetSteps();
  },
};

export const NEXT_STEP_NO_CREATE_CASE = {
  nextStepNo(that) {
    that.displayReportIssueButton();
  },
};

export const NEXT_STEP_NO_GO_TO_LIVE_CHAT = {
  nextStepNo(that) {
    that.goToChat();
  },
};

export const NEXT_STEP_GO_TO_LIVE_CHAT = {
  nextStep(that) {
    that.goToChat();
  },
};

export const NEXT_STEP_YES_RENDER_DEVICE_LIST = {
  nextStepYes(that) {
    that.displayDeviceList();
  },
};

export const NEXT_STEP_RENDER_DEVICE_LIST = {
  nextStep(that) {
    that.displayDeviceList();
  },
};

export const NEXT_STEP_RENDER_DEVICE_LIST_AFTER_POWER_CYCLE = {
  nextStep(that) {
    that.displayDeviceList({ powerCycled: true });
  },
};

export const NEXT_STEP_RENDER_DEVICE_LIST_AFTER_FACTORY_RESET = {
  nextStep(that) {
    that.displayDeviceList({ factoryReset: true });
  },
};

export const ACTION_RESET_DEVICE = {
  action(that) {
    return that.client.resetDevice()
      .then((resp) => {
        if (!resp.deleted) { // !resp.deleted for prod
          that.stopJourney();
        }
      });
  },
};

export const ACTION_TO_UP_MODE = {
  action(that) {
    return that.client.setToUpMode()
      .then((resp) => {
        if (!resp.up_mode) {
          that.stopJourney();
        }
      });
  },
};

export const ACTION_SET_TO_DISCOVERY_MODE = {
  action(that) {
    return that.client.setToDiscoveringMode()
      .then((resp) => {
        if (!resp.discovering_mode) {
          that.stopJourney();
        }
      });
  },
};

export const QUESTION = 'question';
export const CONFIRM = 'confirm';
export const FUNCTION = 'function';

export const TYPE_QUESTION = {
  type: QUESTION,
};

export const TYPE_CONFIRM = {
  type: CONFIRM,
};

export const TYPE_FUNCTION = {
  type: FUNCTION,
};
