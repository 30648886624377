import MyEVPage from 'mixins/my_ev_page';

const component = {
  mixins: [MyEVPage],
  computed: {
    currentOrderStatus() {
      return 'Engineer visit';
    },
  },
};

export default component;
