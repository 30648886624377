import camelCase from 'lodash/camelCase';
import { createNamespacedHelpers } from 'vuex';
import { createPopper } from '@popperjs/core';
import { eventEmitter } from 'utils/run';
import * as c from '../pages/my/constants';

const { mapState } = createNamespacedHelpers('customer');

export default {
  data() {
    return {
      manuelPopovers: {
        my_orders: true,
      },
    };
  },
  created() {
    this.EV_ACTIONS = c.EV_ACTIONS;
  },
  mounted() {
    const parent = this.$refs.manuelPopoverHandler;
    const popover = this.$refs.manuelPopover;
    const instance = createPopper(parent, popover, {
      placement: 'bottom-start',
    });

    eventEmitter.$on('navOpenMobileMenu', () => {
      instance.update();
    });
  },
  computed: {
    ...mapState(['actions']),
    mySubscriptionsBadge() {
      return this.actions && this.actions.hasOutstandingPositiveBalance ? 1 : 0;
    },
    myOrdersBadge() {
      const actionsWithBadge = [
        c.EV_ACTIONS.PHOTOS_REQUIRED,
        c.EV_ACTIONS.BOOK_VHC_LIGHT,
        c.EV_ACTIONS.BOOK_FULL_VHC,
        c.EV_ACTIONS.BOOK_PHC,
        c.EV_ACTIONS.DNO_APP_IN_PROGRESS,
        c.EV_ACTIONS.DNO_APP_WORKS_IN_PROGRESS,
        c.EV_ACTIONS.BOOK_INSTALLATION,
        c.EV_ACTIONS.BOOK_INSTALLATION_NO_VHC,
        c.EV_ACTIONS.VHC_MISSED,
        c.EV_ACTIONS.PHC_MISSED,
      ];

      return this.actions && actionsWithBadge.includes(this.actions.nextEVOrderAction);
    },
    myOrdersBadgeClass() {
      return 'blue-dot';
    },
    myPaymentMethodsBadge() {
      if (!this.actions) return false;

      return this.actions.hasExpiredOrExpiringCard ? 1 : 0;
    },
  },
  methods: {
    badge(menuItemId) {
      const getter = `${camelCase(menuItemId)}Badge`;

      return this[getter] || 0;
    },
    badgeClass(menuItemId) {
      const getter = `${camelCase(menuItemId)}BadgeClass`;

      return this[getter];
    },
    isPopoverDisabled(menuItemId) {
      if (menuItemId === 'my_orders' && this.manuelPopovers[menuItemId]) return !this.myOrdersBadge;

      return true;
    },
    toggleManualPopover(menuItemId) {
      this.manuelPopovers[menuItemId] = !this.manuelPopovers[menuItemId];
    },
  },
};
