// TODO: We need to think about autoloading of things like components.
// Copy and pasting the same import and init lines is lame every time
// we add / modify component or other modules.

import Vue from 'vue/dist/vue.esm';

// components
import StarRating from 'components/views/star_rating';
import ConfigurableStarRating from 'components/views/configurable_star_rating';
import SinglePagePagination from 'components/base/single-page-pagination';
import Cookie from 'components/base/cookie';
import Toggler from 'components/base/toggler';
import EmbeddedVideo from 'components/views/embedded_video';
import CarouselPagination from 'components/views/carousel_pagination';
import CancellationSlider from 'components/views/my/cancellation_slider';
import Carousel from 'components/views/carousel';
import TrustPilotMiniReview from 'components/views/trust_pilot_mini_review';
import Either from 'components/base/either';
import Countdown from 'components/base/countdown';
import Redirect from 'components/base/redirect';
import Switcher from 'components/base/switcher';
import SalesforceChat from 'components/base/salesforce_chat';
import DevButton from 'components/base/dev/populate_button';
import HiveAppMobilePic from 'components/base/hive_app_mobile_pic';
import SlideUpDown from 'vue-slide-up-down';
import VueCarousel from 'vue-carousel';
import { ValidationObserver } from 'vee-validate';
import ValidatedField from 'components/base/validated_field';
import MouseToggle from 'components/base/mouse_toggle';
import MentionMe from 'components/views/mention_me';
import YoutubeModal from 'components/base/youtube_modal';
import VimeoModal from 'components/base/vimeo_modal';
import ProductPageNavbar from 'components/views/product_page_navbar';
import FormattedMoney from 'components/base/formatted_money';
import BaseForm from 'components/views/forms/base';
import HiresImage from 'components/base/hires_image';
import ConfirmationModal from 'components/base/confirmation_modal';
import SubscriptionPrice from 'components/base/subscription_price';
import OngoingPrice from 'components/base/ongoing_price';
import ContactUsButton from 'components/base/contact_us_button';
import { VPopover } from 'v-tooltip';
import VueCookies from 'vue-cookies';
import copyToClipboard from 'components/base/copy_to_clipboard';
import StickyBuyBar from 'components/views/honey/sticky_buy_bar';
import Container1Column from 'components/views/container1_column';
import ContainerHouseGraphic from 'components/views/container_house_graphic';
import HoheyProductCard from 'components/views/honey/product_card';

// directives
import Tooltip from 'directives/tooltip';
import Fadein from 'directives/fadein';
import ClickClass from 'directives/click_class';
import AddToCalendarButton from 'directives/add_to_calendar_button';

/**
 * Register here global components.
 *
 * Global components are available in every instance.
 *
 * @see https://vuejs.org/v2/guide/components-registration.html#Automatic-Global-Registration-of-Base-Components
 *
 */
Vue.component('star-rating', StarRating);
Vue.component('configurable-star-rating', ConfigurableStarRating);
Vue.component('single-page-pagination', SinglePagePagination);
Vue.component('cookie', Cookie);
Vue.component('toggler', Toggler);
Vue.component('embedded-video', EmbeddedVideo);
Vue.component('owl-carousel', Carousel);
Vue.component('trust-pilot-mini-review', TrustPilotMiniReview);
Vue.component('either', Either);
Vue.component('countdown', Countdown);
Vue.component('redirect', Redirect);
Vue.component('switcher', Switcher);
Vue.component('salesforce-chat', SalesforceChat);
Vue.component('populate-button', DevButton);
Vue.component('hive-app-mobile-pic', HiveAppMobilePic);
Vue.component('slide-up-down', SlideUpDown);
Vue.component('validated-field', ValidatedField);
Vue.component('validation-observer', ValidationObserver);
Vue.component('mouse-toggle', MouseToggle);
Vue.component('mention-me', MentionMe);
Vue.component('youtube-modal', YoutubeModal);
Vue.component('vimeo-modal', VimeoModal);
Vue.component('product-page-navbar', ProductPageNavbar);
Vue.component('formatted-money', FormattedMoney);
Vue.component('base-form', BaseForm);
Vue.component('hires-image', HiresImage);
Vue.component('confirmation-modal', ConfirmationModal);
Vue.component('subscription-price', SubscriptionPrice);
Vue.component('ongoing-price', OngoingPrice);
Vue.component('contact-us-button', ContactUsButton);
Vue.component('v-popover', VPopover);
Vue.component('carousel-pagination', CarouselPagination);
Vue.component('cancellation-slider', CancellationSlider);
Vue.component('copy-to-clipboard', copyToClipboard);
Vue.component('sticky-buy-bar', StickyBuyBar);
Vue.component('container1-column', Container1Column);
Vue.component('container-house-graphic', ContainerHouseGraphic);
Vue.component('honey-product-card', HoheyProductCard);

Vue.use(VueCarousel);
Vue.use(VueCookies);

/**
 * Register here directives.
 */
Vue.directive('tooltip', Tooltip);
Vue.directive('fadein', Fadein);
Vue.directive('click-class', ClickClass);
Vue.directive('add-to-calendar-button', AddToCalendarButton);
