<template>
  <span>
    <formatted-money v-if="isShowOneOffPrice" v-bind:value="price.oneOffPrice" />
    <span v-if="hasOneOffAndRecurring">and</span>
    <span v-if="isShowRecurringPrice">
      <formatted-money v-bind:value="price.recurringPrice" />{{ recurringPriceFrequencyLabel }}
      {{ recurringPricePeriodsLabel }}
      {{ recurringPriceDiscountLabel }}
    </span>
  </span>
</template>

<script>
  import FormattedMoney from 'components/base/formatted_money';

  const REQUIRED_PRICE_ATTRS = ['oneOffPrice', 'recurringPrice'];

const pluralizePeriod = (count, frequency) => {
  const period = frequency === 'month' ? 'month' : 'year';

  return `${period}${count > 1 ? 's' : ''}`;
};

const ensurePresent = (value, callback) => (value ? callback.call(null, value) : '');

export default {
  props: {
    hideAnnualPeriodsLabel: Boolean,
    hideAnnualDiscountLabel: Boolean,
    price: {
      type: Object,
      required: true,
      validator(value) {
        return REQUIRED_PRICE_ATTRS.some(
          attr => Object.prototype.hasOwnProperty.call(value, attr)
        );
      },
    },
  },
  computed: {
    isShowRecurringPrice() {
      return this.price.recurringPrice > 0;
    },
    isShowOneOffPrice() {
      return this.price.oneOffPrice > 0 || !this.isShowRecurringPrice;
    },
    hasOneOffAndRecurring() {
      return this.price.oneOffPrice > 0 && this.price.recurringPrice > 0;
    },
    recurringPriceFrequencyLabel() {
      switch (this.frequencyNormalized) {
        case 'month': return '/month';
        case 'annual':
        case 'annum': return '/annum';
        default: return '';
      }
    },
    recurringPricePeriodsLabel() {
      if (this.hideAnnualPeriodsLabel && this.frequencyNormalized !== 'month') return '';

      return ensurePresent(
        this.price.recurringPricePeriods,
        period => `for ${period} ${pluralizePeriod(period, this.frequencyNormalized)}`
      );
    },
    recurringPriceDiscountLabel() {
      if (this.hideAnnualDiscountLabel && this.frequencyNormalized !== 'month') return '';

      return ensurePresent(
        this.price.recurringPriceDiscountPeriods,
        period => `with ${period} ${pluralizePeriod(period, this.frequencyNormalized)} free`
      );
    },
    frequencyNormalized() {
      return String(this.price.recurringPriceFrequency).toLowerCase();
    },
  },
  components: {
    // Even that `FormattedMoney` is a global component we need it here to
    // make `order-item-price` testable.
    // Without this line `formatted-money` componenet won't be rendered
    // with the test utils `mount` helper
    FormattedMoney,
  },
};
</script>
