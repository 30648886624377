import { dragscroll } from 'vue-dragscroll';

const component = {
  props: {
    initialActiveCategory: {
      type: String,
      required: true,
    },
    metaData: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeCategory: this.initialActiveCategory,
    };
  },
  created() {
    this.setCategoryByUrl();
  },
  methods: {
    setActiveCategory(value) {
      // TODO: Replace with an actual Caveats vue component
      if (value === '/') {
        // All Products
        $('#caveats-container .js-caveat').removeClass('hidden');
      } else {
        // Some Category
        const groupKey = value.replace('/', '-');
        $('#caveats-container .js-caveat').addClass('hidden');
        $(`#caveats-container .js-caveat-group-${groupKey}`).removeClass('hidden');
      }

      const locale = this.locale ? `/${this.locale}` : '';
      const queryParamStr = window.location.search;
      this.activeCategory = value;

      window.history.pushState({}, null, `${locale}/shop/${value}${queryParamStr}`);
      this.scrollToTop();
    },
    scrollToTop() {
      this.$scrollTo('#shop-categories', 800, { offset: -150 });
    },
    setCategoryByUrl() {
      // an example: /shop/connects-with/alexa-and-google
      // /ie/shop/smart-heating
      const path = window.location.pathname;
      // get path items and filter blank string and locale part
      const pathParts = path.split('/').filter(item => item.length && item !== this.locale);

      if (pathParts.length <= 1) return; // ie ['shop'] or blank array

      const [, ...rest] = pathParts;
      this.setActiveCategory(rest.join('/'));
    },
  },
  directives: {
    dragscroll,
  },
  metaInfo() {
    const { title, description } = this.metaData[this.activeCategory];
    return {
      title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description,
        },
      ],
    };
  },
};

export default component;
