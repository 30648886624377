import findIndex from 'lodash/findIndex';
import matchesProperty from 'lodash/matchesProperty';
import isNumber from 'lodash/isNumber';

export default function sliceByProperty(array, propKey, propValue, newValue) {
  const index = findIndex(array, matchesProperty(propKey, propValue));
  const params = [index, 1];

  if (newValue) { params.push(newValue); }

  if (isNumber(index)) { array.splice(...params); }
}
