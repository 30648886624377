const component = {
  props: ['steps', 'initialStep'],
  data() {
    return {
      step: this.initialStep,
    };
  },
  methods: {
    currentStep(n) {
      this.step = n;
    },
  },
};

export default component;
