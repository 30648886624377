import { createNamespacedHelpers } from 'vuex';
import RequiredProductChoice from 'components/base/required_product_choice';

const { mapGetters } = createNamespacedHelpers('productBuy');

const CAMERA_OPTION_DOUBLE = 'two';
const CAMERA_OPTION_SINGLE = 'one';
const CAMERA_OPTION_INITIAL = null;

const SWITCH_KEY_SINGLE = null;
const SWITCH_KEY_DOUBLE = 'two_cameras';

const component = {
  extends: RequiredProductChoice,
  computed: {
    ...mapGetters({
      available: 'cameraCountAvailable',
      resetWidget: 'resetCameraCountWidget',
      productType: 'productSecurityPackType',
    }),
    isOutdoor() {
      return this.productType === 'outdoor';
    },
    isSingleCamera() {
      return !this.isOutdoor && this.selected === CAMERA_OPTION_SINGLE;
    },
    isDoubleCamera() {
      return !this.isOutdoor && this.selected === CAMERA_OPTION_DOUBLE;
    },
    isSingleCameraOutdoor() {
      return this.isOutdoor && this.selected === CAMERA_OPTION_SINGLE;
    },
    isDoubleCameraOutdoor() {
      return this.isOutdoor && this.selected === CAMERA_OPTION_DOUBLE;
    },
    complete() {
      return this.selected !== CAMERA_OPTION_INITIAL;
    },
  },
  data() {
    return {
      sku: null,
      requirement: 'hasCameraCountRequirement',
      selected: CAMERA_OPTION_INITIAL,
      switchKey: SWITCH_KEY_SINGLE,
    };
  },
  methods: {
    choiceEventData() {
      return { sku: this.sku, callback: this.emitCompleteSpecEvent };
    },
    chooseSingleCamera() {
      this.switchKey = SWITCH_KEY_SINGLE;
      this.onSelect(CAMERA_OPTION_SINGLE);
    },
    chooseDoubleCamera() {
      this.switchKey = SWITCH_KEY_DOUBLE;
      this.onSelect(CAMERA_OPTION_DOUBLE);
    },
    updateRequirement(forceComplete) {
      const { complete, switchKey, requirement } = this;
      const completeVal = forceComplete === undefined ? complete : forceComplete;
      const requirements = {
        [requirement]: completeVal,
        cameraCountSelection: switchKey,
      };

      this.emitRequirementChange(requirements);
    },
    afterUpdateChanges() {
      if (this.belongsToCombo) {
        this.dataToLocalStorage();
        this.updateRequirement();
      } else {
        this.requiredProductChoiceAfterUpdateChanges();
      }
    },
    reset() {
      this.switchKey = SWITCH_KEY_SINGLE;
      this.selected = CAMERA_OPTION_INITIAL;
    },
  },
  watch: {
    resetWidget(resetFired) {
      if (resetFired) {
        this.reset();
      }
    },
  },
};

export default component;
