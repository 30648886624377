// Vue components visible in footer
import CountryChangerMenu from 'components/views/country_changer_menu';
import LanguageChangerMenu from 'components/views/language_changer_menu';

const component = {
  components: {
    // it means that the Vue instance has a component with name <country-changer/>
    // you can change this name if you like
    'country-changer-menu': CountryChangerMenu,
    'language-changer-menu': LanguageChangerMenu,
  },
};

export default component;
