/* eslint-disable */

import Hammer from 'hammerjs';

class CarouselSwipes {
  constructor() {
    this.el = {
      carousel: '.js-swipe-carousel',
      indicator: '.carousel-indicators li'
    };

    this.el.carouselHero = `${this.el.carousel}-hero`;
  }

  move($component, eventType) {
    const $carousel = $component.hasClass('carousel') ? $component : $('.carousel', $component);
    if (!$carousel.length) { return; }

    const direction = (eventType === 'swipeleft') ? 'next' : 'prev';
    return $carousel.carousel(direction);
  }

  // For hero carousel .carousel('prev') or .carousel('next') is not working
  // not the best solution to use indicators click, but it works well

  moveHero($component, eventType) {
    let $goToIndicator;
    const $indicator = $component.find(this.el.indicator);
    const $activeIndicator = $indicator.filter('.active');

    if (eventType === 'swipeleft') {
      $goToIndicator = $activeIndicator.next() ? $activeIndicator.next() : $indicator.first();
    } else {
      $goToIndicator = $activeIndicator.prev() ? $activeIndicator.prev() : $indicator.last();
    }

    return $goToIndicator.click();
  }
}

const carouselSwipes = new CarouselSwipes;

// `Hammer.defaults.cssProps.userSelect` property adding `user-select: none` to the <html> element,
// as a result text selection is not working across all the site
// that property are optional and it's okay to delete it
delete Hammer.defaults.cssProps.userSelect;

const mc = new Hammer($('html')[0], {touchAction: "auto"});

mc.on('swipeleft swiperight', function(event) {
  let $component = $(event.target).closest(carouselSwipes.el.carousel);
  let method = 'move';

  if (!$component.length) {
    $component = $(event.target).closest(carouselSwipes.el.carouselHero);
    method = 'moveHero';
  }

  if ($component.length) {
    return carouselSwipes[method]($component, event.type);
  }
});
