import vClickOutside from 'v-click-outside';
import ActiveActionForm from 'components/views/active_action_form';

const component = {
  props: ['id', 'isActive'],
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    close() {
      // we need to check it because all action-cards react on clickOutside
      // however we need to close only active
      if (!this.isActive) return;

      this.$emit('close-action-card', this.id);
    },
    open() {
      this.$emit('open-action-card', this.id);
    },
  },
  components: {
    'active-action-form': ActiveActionForm,
  },
};

export default component;
