const mixin = {
  methods: {
    emitRequirementChange(requirements) {
      const { widgetId } = this;

      this.$emit('product-requirement', { widgetId, requirements });
    },
  },
};

export default mixin;
