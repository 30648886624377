import { createNamespacedHelpers } from 'vuex';
import RequiredProductChoice from 'components/base/required_product_choice';

const { mapGetters } = createNamespacedHelpers('productBuy');

// Camera product install choice
const component = {
  extends: RequiredProductChoice,
  props: {
    initialActiveButtonIndex: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  computed: {
    complete() { return this.activeButtonIndex !== -1; },
    isProInstall() {
      return this.installType === 'professional';
    },
    basketRequirement() {
      if (!this.isProInstall) {
        return { basket_requirement: {} };
      }

      const requirement = { name: 'outdoor_camera_pro_install', price: this.price, values: { type: 'pro' } };
      return { basket_requirement: requirement };
    },
    ...mapGetters({
      available: 'productInstallationAvailable',
      resetWidget: 'resetInstallationWidget',
    }),
  },
  data() {
    return {
      price: this.price,
      installType: this.installType,
      activeButtonIndex: this.initialActiveButtonIndex,
      requirement: 'hasProductInstallationRequirement',
    };
  },
  methods: {
    choiceEventData() {
      return { ...this.basketRequirement, callback: this.emitCompleteSpecEvent };
    },
  },
  watch: {
    resetWidget(resetFired) {
      if (resetFired) {
        this.reset();
        this.installType = '';
        this.emitProductChoice();
      }
    },
  },
};

export default component;
