import { SLOT_INTERVALS } from 'utils/calendar';

const mixin = {
  computed: {
    slot_intervals() {
      return { ...SLOT_INTERVALS, pm: this.pmTime || SLOT_INTERVALS.pm };
    },
    scheduleAppointmentLabel() {
      if (this.isNoSelectedSlot) { return 'Select a new date and time slot'; }

      const { year, month, day } = this.selectedSlot.date;

      return `${day}/${month}/${year} (${this.slot_intervals[this.selectedSlot.interval]})`;
    },
  },
};

export default mixin;
