const component = {
  props: {
    sku: String,
  },
  methods: {
    onSelect(selected) {
      this.$emit('change-colour', selected);
    },
  },
};

export default component;
