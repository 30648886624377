<template>
  <validation-provider
    ref="validationProvider"
    v-slot="validationProps"
    v-bind:immediate="immediate"
    v-bind:disabled="disabled"
    v-bind:name="name"
    v-bind:debounce="debounce"
    v-bind:rules="providerRules"
    slim
  >
    <div v-bind:class="[wrapperValidationClass(validationProps), wrapperClass]">
      <slot v-bind:inputClass="inputClass(validationProps)" />
      <div v-if="validationProps.invalid" v-bind:class="errorsWrapperClass">
        <label
          v-for="(message, rule) in errorMessages(validationProps)"
          v-bind:key="rule"
          class="error"
        >
          <slot v-if="!errorSlot(rule)" name="error">
            <span>{{ message }}</span>
          </slot>
          <slot v-bind:name="errorSlotName(rule)" />
        </label>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider, extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules'
import isEmpty from 'lodash/isEmpty';

extend('regex', {
  ...regex,
  message: 'Incorrect format',
});

const component = {
  props: {
    rules: String,
    errors: {
      type: Array,
      default: () => [],
    },
    immediate: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    wrapperClass: {
      type: String,
      default: 'form-group',
    },
    errorsWrapperClass: {
      type: String,
      default: 'form-control-feedback',
    },
    debounce: {
      type: Number,
      required: false,
      default: 100,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    'validation-provider': ValidationProvider,
  },
  mounted() {
    const { errors } = this;
    const { failedRules, setErrors } = this.$refs.validationProvider;

    if (isEmpty(failedRules) && !isEmpty(errors)) setErrors(errors || []);
  },
  computed: {
    // https://github.com/logaretm/vee-validate/issues/2570
    // Currently the `disabled` prop doesn't really block the field from being validated
    providerRules() {
      return this.disabled ? null : this.rulesNormalized;
    },
    rulesNormalized() {
      let result = {};

      // do we need to convert to object?
      if (this.rules?.indexOf('@') > -1){
        return this.rules;
      }

      this.rules?.split('|').forEach((validator) => {
        let [key, value] = validator.split(':');

        if(key === 'regex') {
          value = new RegExp(value);
        }

        result[key] = value || true;
      });

      return result;
    }
  },
  methods: {
    // TODO: make sure rule names are in lowercase, otherwise slots won't work!
    errorSlotName(rule) {
      return `error-${rule}`;
    },
    errorSlot(rule) {
      const slotName = this.errorSlotName(rule);

      return this.$scopedSlots[slotName];
    },
    errorMessages({ failedRules, errors }) {
      if (isEmpty(failedRules) && !isEmpty(errors)) {
        return { server: errors[0] };
      }

      return failedRules;
    },
    inputClass({ validated, valid, invalid }) {
      return [
        'form-control',
        {
          'form-control-success': validated && valid,
          'form-control-danger': validated && invalid,
        },
      ];
    },
    wrapperValidationClass({ validated, valid, invalid }) {
      return {
        'has-success': validated && valid,
        'has-danger': validated && invalid,
      };
    },
  },
};

export default component;
</script>
