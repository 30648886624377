import MultipackSelector from 'components/views/multipack_selector';
import HoneyProductRegularBuyHub from 'components/views/honey/product_regular_buy_hub';
import HoneyProductRegularBuyHubCompatibility from 'components/views/honey/product_regular_buy_hub_compatibility';
import AmountDiscountBadge from 'mixins/amount_discount_badge';

import TrustPilotPopup from 'components/views/trust_pilot_popup';

import { createNamespacedHelpers } from 'vuex';
import AddToBasketButton from 'mixins/add_to_basket_button';

import HoneyHeatingPlus from 'components/views/honey/heating_plus';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('radiatorValveBuyPage');

const component = {
  inject: ['config'],
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    initialSku: String,
    conditionOptionSkuMappings: Object,
    heatingPlusSkuMappings: Object,
    initialHeatingPlusType: String,
    freeMonths: String,
    isSeasonalOffer: Boolean,
  },
  data() {
    return {
      basketInterval: null,
    };
  },
  computed: {
    ...mapState(['quantity', 'sku', 'hubSku', 'isPriceLoading', 'hubSku', 'conditionOption', 'heatingPlusType']),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
      'annualPrice',
      'monthlyPrice',
      'recurringAnnualPrice',
    ]),
    isFreeDeliveryVisible() {
      return this.totalDiscountedPrice > this.config.deliveryThreshold;
    },
    discountBadge() {
      if (this.isSeasonalOffer) return `Save ${this.config.currency.symbol}${this.totalDiscount.toFixed(2)}`;

      if (Object.keys(this.conditionOptionSkuMappings).length > 0) {
        return 'Buy refurbished - Save 10%';
      }

      return '';
    },
    heatingPlusDiscountBadge() {
      if (this.totalDiscount === 0) return 'can save 10%';

      return `saved 10% (£${this.totalDiscount.toFixed(2)})`;
    },
  },
  created() {
    const {
      initialSku,
      conditionOptionSkuMappings,
      heatingPlusSkuMappings,
      initialHeatingPlusType,
    } = this;

    this.init({
      sku: initialSku,
      conditionOptionSkuMappings,
      heatingPlusSkuMappings,
      heatingPlusType: initialHeatingPlusType,
    });
  },
  beforeDestroy() {
    clearInterval(this.basketInterval);
  },
  methods: {
    ...mapActions([
      'setQuantity',
      'setHubSku',
      'setValveSku',
      'setConditionOption',
      'init',
      'increment',
      'decrement',
      'setHeatingPlusType',
    ]),
    onChangedSku(opts) {
      this.setValveSku(opts.sku);
    },
  },
  components: {
    TrustPilotPopup,
    MultipackSelector,
    HoneyProductRegularBuyHub,
    HoneyProductRegularBuyHubCompatibility,
    HoneyHeatingPlus,
  },
};

export default component;
