import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('radiatorValveBuyPage');

const component = {
  props: {
    hubSku: String,
  },
  methods: {
    ...mapActions(['setHubSku']),
    onUpgradeHub() {
      this.$modal.hide('hub-compatibility');
      this.setHubSku(this.hubSku);
    },
  },
};

export default component;
