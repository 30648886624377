import ActionCardProduct from 'components/views/action_card_product';
import { createNamespacedHelpers } from 'vuex';
import { QUANTITY_OPS } from 'store/modules/hive_actions';

const { mapState } = createNamespacedHelpers('hiveActions');

const getSkuQuantityMapping = products => products.reduce((acc, product) => {
  acc[`sku[${product.sku}]`] = product.quantity;
  return acc;
}, {});

const calculateTotal = products => products
  .map(product => product.price * product.quantity)
  .reduce((acc, price) => acc + price, 0);

const component = {
  props: ['url', 'basketLabel', 'basketPendingLabel', 'actionId'],
  inject: ['client'],
  data() {
    return {
      isCheckoutVisible: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState([
      'hiveActions',
    ]),
    actionProductList() {
      const { hiveActions = {} } = this;
      const { actionProducts = [] } = hiveActions;

      return actionProducts[this.actionId];
    },
    total() {
      return calculateTotal(this.actionProductList);
    },
  },
  methods: {
    commitQuantityChange(commitOp, sku) {
      this.$store.commit(commitOp, { sku, actionId: this.actionId });
    },
    increase(sku) {
      this.commitQuantityChange(QUANTITY_OPS.namespaced.INCREMENT, sku);
    },
    decrease(sku) {
      this.commitQuantityChange(QUANTITY_OPS.namespaced.DECREMENT, sku);
    },
    addToBasket() {
      this.isLoading = true;
      const params = getSkuQuantityMapping(this.actionProductList);
      return this.client.populateOrder(this.url, params)
        .then(() => {
          this.isLoading = false;
          this.isCheckoutVisible = true;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    productBySku(sku) {
      return this.actionProductList.find(actionProduct => actionProduct.sku === sku);
    },
  },
  components: {
    'action-card-product': ActionCardProduct,
  },
};
export default component;
