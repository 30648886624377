const component = {
  inject: ['dataLayer'],
  props: ['chatAvailable', 'chatConfiguration', 'enableTagging', 'tagId'],
  methods: {
    openModal() {
      if (!this.chatAvailable) return;

      if (this.enableTagging) {
        this.dataLayer.customManualInteraction('click', { value: 'contact-us', item: this.tagId });
      }

      this.$modal.show('contact-us-modal');
    },
    closeModal() {
      this.$modal.hide('contact-us-modal');
    },
    startChatSession() {
      if (window.embedded_svc) {
        const { directToAgentRouting: { buttonId } } = this.chatConfiguration;

        this.chatConfiguration.directToButtonRouting = () => buttonId;

        window.embedded_svc.liveAgentAPI.startChat(this.chatConfiguration);

        this.closeModal();

        if (this.enableTagging) {
          this.dataLayer.customManualInteraction('startChat', { value: 'contact-us', item: this.tagId });
        }
      } else {
        throw new Error('Embedded SVC is not available');
      }
    },
  },
};

export default component;
