import HeatingPlusBuyPage from 'components/pages/honey/heating_plus_buy_page';

const component = {
  extends: HeatingPlusBuyPage,
  created() {
    const { heatingPlusSkuMappings, hubSku } = this;

    this.init({ heatingPlusSkuMappings, hubSku });
  },
  computed: {
    isFreeDeliveryVisible() {
      return true;
    },
    hubSku() {
      return 'HCD00010';
    },
    discountBadge() {
      return 'Save &pound;30.00';
    },
    totalPrice() {
      return 60.0;
    },
    totalDiscountedPrice() {
      return 30.0;
    },
    hasDiscount() {
      return true;
    },
    totalDiscount() {
      return 30.0;
    },
  },
};

export default component;
