import ContactForm from 'mixins/contact_form';
import AddressAutocomplete from 'components/views/address_autocomplete';

const component = {
  props: {
    initialEditing: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [ContactForm],
  data() {
    return {
      editing: this.initialEditing,
    };
  },
  computed: {
    isSubmitButtonVisible() {
      return this.editing;
    },
    isEditButtonVisible() {
      return !this.editing;
    },
  },
  methods: {
    onEdit() {
      this.editing = true;
    },
    isMarketingPreferenceDisabled() {
      return !this.editing;
    },
  },
  components: {
    AddressAutocomplete,
  },
};

export default component;
