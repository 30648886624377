import { createNamespacedHelpers } from 'vuex';
import AddressAutocomplete from 'components/views/address_autocomplete';
import isEmpty from 'lodash/isEmpty';
import AppointmentSchedulerCalendar from 'components/views/appointment_scheduler_calendar';
import AppointmentScheduler from 'mixins/appointment_scheduler';
import {
  CalendarDate,
} from 'utils/calendar';

const { mapActions: mapBasketActions } = createNamespacedHelpers('basket');

const {
  mapState: mapCustomerState,
  mapGetters: mapCustomerGetters,
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const MODAL_NAME = 'add-new-address';
const component = {
  mixins: [AppointmentScheduler],
  inject: ['client'],
  props: ['url', 'batchSize', 'existingCustomer', 'defaultInstallationAddress', 'jobDate', 'jobInterval', 'eqJourney', 'defaultPmTime'],
  data() {
    const ranges = this.$store.state.jobSlots.jobSlots || [];

    return {
      ranges,
      isComplete: false,
      isLoading: false,
      error: false,
      newAddress: {},
      defaultAddress: true,
      forceHidePostcodeForm: false,
      selectedAddressReference: this.defaultInstallationAddress,
      confirmedAddress: false,
      currentSlots: ranges,
      loadingSlots: false,
      selectedSlot: {},
      isSelfInstallChecked: false,
    };
  },
  computed: {
    ...mapCustomerGetters([
      'installationAddress',
    ]),
    ...mapCustomerState(['addresses', 'recentlyAddedAddress']),
    isNewAddressPresent() {
      return !isEmpty(this.newAddress);
    },
    isModalSubmitButtonDisabled() {
      return !this.isNewAddressPresent;
    },
    isNoSelectedSlot() {
      return isEmpty(this.selectedSlot);
    },
    pmTime() {
      return this.defaultPmTime;
    },
  },
  methods: {
    ...mapBasketActions([
      'switchInstallation',
    ]),
    ...mapCustomerActions({
      createCustomerAddress: 'createAddress',
    }),
    selectSlot({ date, interval }) {
      this.isLoading = true;
      this.error = false;
      this.loadingSlots = true;

      return this.client.bookAppointment(
        {},
        { slot: { interval: interval.toUpperCase(), date: date.toISO8601() } },
      )
        .then(() => {
          this.selectedSlot = { date, interval };
          this.isComplete = true;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.isLoading = false;
          this.loadingSlots = false;
          this.$modal.hide('appointment-scheduler-calendar');
        });
    },
    showCalendar() {
      this.$modal.show('appointment-scheduler-calendar');
    },
    setNewAddress(address) {
      ['address1', 'city', 'territory', 'postcode'].forEach((prop) => {
        this.$set(this.newAddress, prop, address[prop]);
      });

      if (address.reference) {
        this.selectedAddressReference = address.reference;
        this.$set(this.newAddress, 'reference', address.reference);
      }

      this.defaultAddress = false;

      this.forceHidePostcodeForm = false;
    },
    setNewAddressAndGetRanges(address) {
      this.setNewAddress(address);
      this.getRanges();
    },
    createAddress() {
      return this.createCustomerAddress(
        { data: { ...this.newAddress, default_installation: true } }
      )
        .then(() => {
          if (this.recentlyAddedAddress) {
            this.selectedAddressReference = this.recentlyAddedAddress;
          }
          this.$modal.hide(MODAL_NAME);
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.resetNewAddressAndGetRanges();
        });
    },
    resetNewAddress() {
      if (this.defaultAddress) {
        return;
      }

      this.newAddress = {};
      this.defaultAddress = true;
      this.forceHidePostcodeForm = true;
    },
    resetNewAddressAndGetRanges() {
      this.resetNewAddress();
      this.getRanges();
    },
    showNewAddressModal() {
      if (this.submitting) { return; }

      this.$modal.show(MODAL_NAME);
      this.resetNewAddress();
    },
    getRanges() {
      this.isLoading = true;
      this.error = false;

      const address = this.defaultAddress ? this.installationAddress : this.newAddress;

      return this.client.setInstallationAddress({}, { address })
        .then((slots) => {
          this.ranges = slots;
          this.currentSlots = this.ranges;
          this.isComplete = true;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.error = true;
        });
    },
    setConfirmedAddress(value) {
      this.confirmedAddress = value;
    },
    onSwitchInstallation() {
      if (this.isLoading) return;

      this.isLoading = true;

      this.switchInstallation()
        .then(() => {
          this.isLoading = false;
          this.isSelfInstallChecked = !this.isSelfInstallChecked;
          this.isComplete = this.isSelfInstallChecked;
          window.specEvents.push('checkout.installation.update');
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    onCloseModal() {
      if (!this.isSelfInstallChecked) {
        this.onSwitchInstallation();
      }
    },
  },
  mounted() {
    if (this.jobDate) {
      this.selectedSlot = {
        date: new CalendarDate(this.jobDate), interval: this.jobInterval.toLowerCase(),
      };
    }
  },
  components: {
    AddressAutocomplete,
    AppointmentSchedulerCalendar,
  },
};

export default component;
