import $ from 'jquery';
import {} from 'jquery-ujs';

// TODO: replace Bootstrap plugins with Vue alternatives and import the remaining plugins
// individually.
// Note that it should be imported in the same file with jquery, otherwise it won't work.
import 'bootstrap';

window.jQuery = $;
window.$ = $;

// Bootstrap recommends waiting until at least jQuery 3.5.1 is released before upgrading,
// by which time Bootstrap 4.4.3 will probably be ready.
// https://github.com/twbs/bootstrap/issues/30692#issuecomment-621630526

// https://github.com/jquery/jquery/security/advisories/GHSA-gxr4-xjj5-5px2
// eslint-disable-next-line func-names
$.htmlPrefilter = function(html) {
  return html;
};
