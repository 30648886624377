import HoneyTrustPilot from 'components/views/honey_trust_pilot';
import HoneyVideoPlayback from 'components/views/honey/video_playback';
import HoneyPackItem from 'components/views/honey/pack_item';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HomeshieldPack from 'mixins/homeshield_pack';
import HoneyOptionSelector from 'components/views/honey/option_selector';

const component = {
  inject: ['cookies', 'client'],
  mixins: [HomeshieldPack],
  props: {
    baseSaving: Number,
    baseTotalPrice: Number,
    baseTotalDiscountedPrice: Number,
    baseMonthlyPrice: Number,
    namespace: { // required by the buy_homeshield_plus_widget
      type: String,
      default: '_unused',
    },
    sku: String,
    freeSku: String,
    baseHomeshieldOption: String,
    baseInstallationOption: String,
    baseProfessionalInstallationPrice: Number,
    baseProfessionalInstallationSku: String,
  },
  data() {
    return {
      saving: this.baseSaving || 0,
      totalPrice: this.baseTotalPrice,
      totalDiscountedPrice: this.baseTotalDiscountedPrice,
      monthlyPrice: this.baseMonthlyPrice,
      withHub: true,
      installationOption: this.baseInstallationOption,
      professionalInstallationSku: this.baseProfessionalInstallationSku,
      professionalInstallationPrice: this.baseProfessionalInstallationPrice,
    };
  },
  computed: {
    hasDiscount() {
      return this.saving > 0;
    },
    discountBadge() {
      if (this.hasDiscount) {
        const saving = this.saving % 1.0 === 0.0 ? this.saving : this.saving.toFixed(2);
        return `Save ${this.config.currency.symbol}${saving}`;
      }

      return '';
    },
    homeshieldOption() {
      return this.includedProducts[this.sku] === 1 ? 'homeshield_plus' : 'homeshield';
    },
  },
  methods: {
    async loadPrice() {
      this.quantityLocked = true;
      this.loadProfessionalInstallationPrice();
      const response = (
        // this is actually a post request to get a price on a custom HS pack
        await this.client.getCustomHomeshieldPackPrice({}, { pack: this.includedProducts })
      );

      this.quantityLocked = false;
      this.totalPrice = response.price;
      this.totalDiscountedPrice = response.discountedPrice;
      this.saving = response.saving;
      this.monthlyPrice = response.monthlyPrice;
      this.includedProducts = response.includedProducts;
    },
    assignQuantity(sku, quantity) {
      this.includedProducts[sku] = quantity;

      this.loadPrice();
    },
    swapSku(toSku, fromSku) {
      const { includedProducts } = this;

      this.includedProducts = {
        ...includedProducts,
        [fromSku]: 0,
        [toSku]: includedProducts[fromSku],
      };
    },
    async loadProfessionalInstallationPrice() {
      const response = await this.client.getInstallationPrice({}, { skus: this.includedProducts });

      this.professionalInstallationPrice = response.price;
      this.professionalInstallationSku = response.sku;
    },
    setHomeshieldOption({ selected, specEvent }) {
      this.$store.dispatch('packBuyPage/setHomeshieldOption', { selected, specEvent });
      this.includedProducts[this.sku] = (selected === 'homeshield_plus' ? 1 : 0);

      this.loadPrice();
    },
    setInstallationOption({ selected }) {
      this.installationOption = selected;
    },
  },
  mounted() {
    this.setHomeshieldOption({ selected: this.baseHomeshieldOption });

    this.loadProfessionalInstallationPrice();
  },
  components: {
    HoneyTrustPilot,
    TrustPilotPopup,
    HoneyVideoPlayback,
    HoneyPackItem,
    HoneyOptionSelector,
  },
};

export default component;
