import { createNamespacedHelpers } from 'vuex';

import FormattedSaving from 'mixins/formatted_saving';

const mapProductState = createNamespacedHelpers('productBuy').mapState;

const mixin = {
  mixins: [FormattedSaving],
  methods: {
    percentageSaving() {
      return `${this.product.saving}%`;
    },
  },
  computed: {
    ...mapProductState({
      options: state => state.options,
      basket_requirement: state => state.basket_requirement,
      product: state => state.product,
      displayPercentageSaving: ({ product: { savingDisplayType } }) => savingDisplayType === 'percentage',
    }),
    totalPrice() {
      return this.basePrice * this.quantity + this.installationPrice;
    },
    totalDiscountedPrice() {
      return this.baseDiscountedPrice * this.quantity + this.installationPrice;
    },
    totalDiscount() {
      if (this.hasDiscount) {
        return this.totalPrice - this.totalDiscountedPrice;
      }

      return null;
    },
    hasDiscount() {
      return this.totalDiscountedPrice && this.totalDiscountedPrice < this.totalPrice;
    },
    discountBadge() {
      return `Save ${this.percentageSaving()}`;
    },
  },
};

export default mixin;
