import ZuoraHostedPage from 'components/views/zuora_hosted_page';
import Multipage from 'mixins/multipage';

const component = {
  inject: ['client'],
  mixins: [Multipage],
  props: {
    amount: {
      type: Number,
      required: true,
    },
    source: {
      type: String,
    },
  },
  data() {
    return {
      remainingBalance: null,
    };
  },
  methods: {
    processMyPayment(paymentMethodId) {
      return this.client.processMyPayment(
        {},
        {
          payment: {
            amount: this.amount,
            method_id: paymentMethodId,
            source: this.source,
          },
        }
      );
    },
    onZuoraSuccess(paymentMethodId) {
      this.showLoading();
      return this.processMyPayment(paymentMethodId)
        .then((response) => {
          this.remainingBalance = response.remaining_balance;
          this.currentPage = 'success';
        })
        .catch(() => {
          this.currentPage = 'error';
        });
    },
  },
  components: {
    ZuoraHostedPage,
  },
};

export default component;
