import baseConfig from './shared/quantity_select';

const vuexModule = {
  ...baseConfig,
  getters: {
    contactSensorsQty({ selected }) {
      if (selected === null) return 0;

      if (selected > 5) return 6;

      return selected;
    },
  },
};

export default vuexModule;
