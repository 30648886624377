import Carousel from 'components/views/carousel';
import ThermostatCompatibilityCard from 'components/views/thermostat_compatibility_card';

/**
 * For survey like components
 */
const mixin = {
  data() {
    return {
      messageId: '',
      journeyId: '',
      currentPage: 0,
      nextStep: null,
      history: [],
      selectedCard: null,
    };
  },
  computed: {
    isMessage() {
      return this.messageId !== '';
    },
    isJourney() {
      return this.journeyId !== '';
    },
  },
  components: {
    'owl-carousel': Carousel,
    'thermostat-compatibility-card': ThermostatCompatibilityCard,
  },
  methods: {
    // without new liners
    filteredAnswers(answers) {
      return answers.filter(answer => answer.newLine !== true);
    },
    updateNextStep(answer) {
      this.selectedCard = answer;
      if (answer.journey) {
        this.nextStep = { id: answer.journey, type: 'journey' };
      } else if (answer.message) {
        this.nextStep = { id: answer.message, type: 'message' };
      } else if (answer.stage) {
        this.nextStep = { id: answer.stage, type: 'stage' };
      }
    },
    proceed() {
      let methodName;

      switch (this.nextStep.type) {
        case 'stage':
          methodName = 'goToPage';
          break;
        case 'journey':
          methodName = 'showJourney';
          break;
        case 'message':
          methodName = 'showMessage';
          break;
        default:
      }

      this[methodName](this.nextStep.id);
    },
    goToPage(page) {
      this.nextStep = null;
      this.messageId = '';
      // this.history.push(this.currentPage);
      this.currentPage = page;
      this.selectedCard = null;
    },
    showJourney(journey) {
      this.nextStep = null;
      this.journeyId = journey;
      this.messageId = '';
    },
    showMessage(message) {
      this.messageId = message;
      this.currentPage = message;
    },
    goBack() {
      // this.currentPage = this.history.pop();
      this.messageId = '';
    },
    resetHistory() {
      this.currentPage = 0;
      this.journeyId = '';
      this.history = [];
      this.messageId = '';
      this.selectedCard = null;
    },
    dismiss() {
      this.resetHistory();
    },
    isActive(page) {
      return this.currentPage === page;
    },
    isComplete(page) {
      return this.currentPage > page;
    },
    isLastPage() {
      return this.currentPage === this.stages.length - 1;
    },
    isFirstPage() {
      return this.currentPage === 0;
    },
    getStepNumber(index) {
      return index + 1;
    },
    isPageVisible(page) {
      return this.currentPage === page;
    },
    isJourneyVisible(journey) {
      return this.journeyId === journey;
    },
    isMessageVisible(message) {
      return this.messageId === message;
    },
  },
};

export default mixin;
