import Vue from 'vue/dist/vue.esm';

const hasAlreadyVotedFor = (state, name) => !!state.ratings[name];

const indexModule = {
  namespaced: true,
  state: {},
  actions: {
    vote({ commit, state }, payload) {
      const { name } = payload;
      if (!hasAlreadyVotedFor(state, name)) {
        commit('SET_VOTE', payload);
      }
    },
  },
  mutations: {
    SET_VOTE(state, data) {
      const { name, rating } = data;
      Vue.set(state, 'ratings', {
        ...state.ratings,
        [name]: rating,
      });
      localStorage.setItem('starRatings', JSON.stringify(state.ratings));
    },
  },
  getters: {
    hasAlreadyVotedFor: state => name => (
      state.ratings && state.ratings[name] && (state.ratings[name] > -1)
    ),
    getVoteFor: state => name => (state.ratings && state.ratings[name]) || -1,
  },
};

export default indexModule;
