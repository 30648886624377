import { createNamespacedHelpers } from 'vuex';
import Notifications from 'components/base/notifications';
import MyAccountHeader from 'components/base/my_account_header';
import AddressBlock from 'components/base/address_block';
import ProgressBar from 'components/base/progress_bar';
import OrderItem from 'components/base/order_item';
import UploadPhotos from 'components/pages/my/upload_photos';
import PhotosReceived from 'components/pages/my/photos_received';
import DnoApplication from 'components/pages/my/dno_application';
import DnoUpgrades from 'components/pages/my/dno_upgrades';
import BookPhc from 'components/pages/my/book_phc';
import BookVhc from 'components/pages/my/book_vhc';
import PhcBooked from 'components/pages/my/phc_booked';
import VhcBooked from 'components/pages/my/vhc_booked';
import BookInstallation from 'components/pages/my/book_installation';
import BookInstallationNoVhc from 'components/pages/my/book_installation_no_vhc';
import InstallBooked from 'components/pages/my/install_booked';
import OrderComplete from 'components/pages/my/order_complete';
import webview from 'utils/webview';
import Multipage from 'mixins/multipage';
import ScrollToTop from 'mixins/scroll_to_top';
import Tab from 'components/base/tab';
import Tabs from 'components/base/tabs';
import * as c from './constants';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');
const {
  mapState: mapCustomerState,
  mapGetters: mapCustomerGetters,
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const STATUSES = {
  placed: 'Placed',
  processing: 'Processing',
  shipped: 'Shipped',
};

const RETURN_STATUSES = {
  'Awaiting Receipt': 'Created',
  'Goods Received': 'In Progress',
  'Goods Received - Parts Missing': 'In Progress',
  'Goods Received - Non Hive Product': 'In Progress',
  'Goods Received - Wrong Item Sent Back': 'In Progress',
  'Exception': 'In Progress',
  'Unidentified': 'In Progress',
  'Duplicate': 'In Progress',
  'Completed': 'Refunded',
  'Cancelled': null,
};

const DEFAULT_ACTIVE_TAB = 'orders';

const component = {
  mixins: [Multipage, ScrollToTop],
  inject: ['dataLayer'],
  data() {
    return {
      STATUSES,
      activeTab: DEFAULT_ACTIVE_TAB,
      currentOrder: {},
      currentReturn: {},
      returnItems: {},
      returnItemsCount: 0,
      returnReason: null,
      returnReasonCodes: [],
      selectedReasonCode: null,
      createdReturnId: null,
      returnableItems: [],
      shownErrors: [],
      completeCreateReturnRunning: false,
    };
  },
  created() {
    this.EV_ACTIONS = c.EV_ACTIONS;

    this.showListOrEmpty();
    webview.ready();
  },
  computed: {
    ...mapCustomerState(['subscriptions']),
    ...mapCustomerGetters(['hasSubscriptions']),
    evOrderStatuses() {
      return Object.values(STATUSES);
    },
    returnSteps() {
      return ['Created', 'In Progress', 'Refunded'];
    },
    isCurrentOrderShipped() {
      return !!this.currentOrder.trackingNumber;
    },
    currentOrderStatus() {
      if (!this.currentOrder) { return STATUSES.placed; }

      if (this.isCurrentOrderShipped) { return STATUSES.shipped; }

      switch (this.currentOrder.status) {
        case 'In Progress': return STATUSES.placed;
        case 'Completed': return STATUSES.shipped;
        default: return STATUSES.placed;
      }
    },
    currentReturnStatus() {
      if (!this.currentReturn.status) { return this.returnSteps[0]; }

      return RETURN_STATUSES[this.currentReturn.status];
    },
    items() {
      if (!this.currentOrder.items) return [];

      return this.currentOrder.items.filter(item => !item.isDelivery && (
        // show only items that are being delivered for replacement orders
        !this.currentOrder.isReplacement || item.isBeingDelivered
      ));
    },
    isWebview() {
      return webview.isWebview();
    },
    canCancelReturn() {
      return this.currentReturn.status === 'Awaiting Receipt';
    },
    canReturnItems() {
      return !this.currentOrder.isReplacement && this.currentOrder.withinCoolOffPeriod;
    },
    canSelectReturnReason() {
      return this.returnItemsCount > 0;
    },
    disableCompleteCreateReturnButton() {
      return !this.canSelectReturnReason || this.completeCreateReturnRunning;
    },
    hasReturnableItems() {
      return this.filteredReturnableItems.length > 0;
    },
    filteredReturnableItems() {
      return this.returnableItems.filter((item) => {
        return item.quantity > 0;
      })
    },
    showReturnInstructions() {
      return RETURN_STATUSES[this.currentReturn.status] !== 'Refunded';
    },
    showReturnLiveChat() {
      return RETURN_STATUSES[this.currentReturn.status] === 'In Progress';
    },
  },
  methods: {
    ...mapGlobalActions([
      'showError', 'showNotice',
    ]),
    ...mapCustomerActions(['getOrder', 'getReturn', 'getReturnables', 'createReturn', 'cancelReturn']),
    showListOrEmpty() {
      this.showPage(this.hasSubscriptions ? 'list' : 'empty');
      this.currentOrder = {};
    },
    showReturnsList() {
      this.showPage('list');

      this.currentReturn = {};
    },
    async getCachedOrder(order) {
      if (!order.isEv && order.withDetails) { return order; }

      return this.getOrder(order.number);
    },
    orderDetailsPage(order) {
      const bookVhcNextActions = [
        this.EV_ACTIONS.BOOK_VHC_LIGHT,
        this.EV_ACTIONS.BOOK_FULL_VHC,
        this.EV_ACTIONS.VHC_MISSED,
      ];

      const bookPhcNextActions = [this.EV_ACTIONS.BOOK_PHC, this.EV_ACTIONS.PHC_MISSED];

      const installBookedNextActions = [
        this.EV_ACTIONS.INSTALL_BOOKED_NO_VHC,
        this.EV_ACTIONS.INSTALL_BOOKED,
      ];

      if (order.evJobOrders && order.evJobOrders[0] && order.evJobOrders[0].error) {
        return 'details'; // temporary fix for the /evjoborders endpoint returning an error
      } else if (order.nextAction === this.EV_ACTIONS.PHOTOS_REQUIRED) {
        return 'upload_photos';
      } else if (order.nextAction === this.EV_ACTIONS.PHOTOS_RECEIVED) {
        return 'photos_received';
      } else if (order.nextAction === this.EV_ACTIONS.DNO_APP_IN_PROGRESS) {
        return 'dno_application';
      } else if (order.nextAction === this.EV_ACTIONS.DNO_APP_WORKS_IN_PROGRESS) {
        return 'dno_upgrades';
      } else if (bookPhcNextActions.includes(order.nextAction)) {
        return 'book_phc';
      } else if (order.nextAction === this.EV_ACTIONS.BOOK_INSTALLATION) {
        return 'book_installation';
      } else if (order.nextAction === this.EV_ACTIONS.BOOK_INSTALLATION_NO_VHC) {
        return 'book_installation_no_vhc';
      } else if (order.nextAction === this.EV_ACTIONS.COMPLETE) {
        return 'order_complete';
      } else if (order.nextAction === this.EV_ACTIONS.PHC_BOOKED) {
        return 'phc_booked';
      } else if (installBookedNextActions.includes(order.nextAction)) {
        return 'install_booked';
      } else if (bookVhcNextActions.includes(order.nextAction)) {
        return 'book_vhc';
      } else if (order.nextAction === this.EV_ACTIONS.VHC_BOOKED) {
        return 'vhc_booked';
      }

      return 'details';
    },
    showOrderDetails(order) {
      this.getCachedOrder(order)
        .then((currentOrder) => {
          const page = this.orderDetailsPage(currentOrder);
          this.currentOrder = { ...currentOrder };
          this.showPage(page);
          this.dataLayer.manualClick({ moduleId: 'orders', moduleItemId: this.currentOrder.number });
        })
        .catch(({ code } = {}) => {
          if (code === 'PENDING_ITEM') { return; }
          if (this.shownErrors.includes(this.currentOrder.number)) { return; }

          this.shownErrors += this.currentOrder.number;
          this.showError("Couldn't fetch order");
        });
    },
    proxyShowOrderDetails(order) {
      return () => this.showOrderDetails(order);
    },
    showSubscriptionDetails(subscription) {
      if (this.hasMultipleOrders(subscription)) {
        this.$set(subscription, 'isHidden', !subscription.isHidden);
      } else {
        this.showOrderDetails(subscription.orders[0]);
      }
    },
    proxyShowSubscriptionDetails(subscription) {
      return () => this.showSubscriptionDetails(subscription);
    },
    subscriptionStatus(subscription) {
      if (!subscription.isCancelled) {
        return '';
      }

      return `- ${subscription.status}`;
    },
    hasMultipleOrders({ orders }) {
      return orders.length > 1;
    },
    isPureSubscription({ orders }) {
      return orders.length < 1;
    },
    getFirstOrder(subscription) {
      if (!subscription.orders || this.isPureSubscription(subscription)) { return {}; }

      return subscription.orders[0];
    },
    openReturnsPolicyModal() {
      this.$modal.show('returns-policy-modal');
    },
    openReturnsInstructionsModal() {
      this.$modal.show('returns-instructions-modal');
    },
    openCoolOffPeriodModal() {
      this.$modal.show('outside-cool-off-period');
    },
    showCreateReturn() {
      this.returnableItems = [];

      this.getReturnables(this.currentOrder.subscriptionNumber)
        .then((resp) => {
          this.returnableItems = resp.items;

          this.showPage('createReturn');
        })
        .catch((resp) => {
          this.showError("Couldn't fetch returnable items");
        });
    },
    showReturnDetails(id, showCreateMessage) {
      this.getReturn(id)
        .then((currentReturn) => {
          this.$refs.tabs.selectTab(1);
          this.currentReturn = { ...currentReturn };
          this.showPage('returnDetails');
          this.$modal.hide('return-created');

          if (showCreateMessage) {
            this.showNotice('Return item created');
          }
        })
        .catch((resp = {}) => {
          this.showError("Couldn't fetch return details");
        });
    },
    toggleReturnForItem(item) {
      if (item.productType) { // check for group
        if (this.returnItems[item.sku]) {
          this.returnItemsCount--;

          this.items_by_type(item).forEach((i) => {
            delete this.returnItems[i.sku];
          })
        } else {
          this.returnItemsCount++;

          this.items_by_type(item).forEach((i) => {
            this.returnItems[i.sku] = i.quantity;
          })
        }
      } else if (this.returnItems[item.sku]) {
        this.returnItemsCount--;

        delete this.returnItems[item.sku];
      } else {
        this.returnItemsCount++;

        this.returnItems[item.sku] = item.quantity;
      }

      this.$forceUpdate();
    },
    decrementDisabled(item) {
      if (!this.returnItems[item.sku]) return true;

      return this.returnItems[item.sku] < 2;
    },
    isInReturnItems(item) {
      return this.returnItems[item.sku];
    },
    incrementDisabled(item) {
      if (!this.returnItems[item.sku]) return true;

      return this.returnItems[item.sku] >= item.quantity;
    },
    decreaseQuantity(item) {
      if (item.productType) { // check for group
        this.decreaseForType(item);
      } else {
        this.returnItems[item.sku]--;
      }

      this.$forceUpdate();
    },
    increaseQuantity(item) {
      if (item.productType) { // check for group
        this.increaseForType(item);
      } else {
        this.returnItems[item.sku]++;
      }

      this.$forceUpdate();
    },
    getReturnQuantityForItem(item) {
      if (!this.returnItems[item.sku]) return item.quantity;

      return this.returnItems[item.sku];
    },
    getOriginalQuantity(sku) {
      return this.filteredReturnableItems.find(item => item.sku === sku).quantity;
    },
    openReturnReasonModal() {
      if (!this.canSelectReturnReason) return;

      this.$modal.show('return-reason');
    },
    completeReasonPick() {
      if (!this.returnReason) return;

      this.$modal.hide('return-reason');
      this.showPage('returnSummary');
    },
    completeReasonCodePick() {
      this.$modal.hide('return-reason-code');
    },
    completeCreateReturn() {
      if (!this.returnReason || this.completeCreateReturnRunning) return;
      this.completeCreateReturnRunning = true;

      const returnData = {
        subscriptionNumber: this.currentOrder.subscriptionNumber,
        orderNumber: this.currentOrder.number,
        reason: this.returnReason,
        reasonCode: this.selectedReasonCode,
        items: Object.keys(this.returnItems).map((sku) => {
          return {
            sku: sku,
            quantity: this.returnItems[sku],
            originalQuantity: this.getOriginalQuantity(sku),
          };
        }),
      };

      this.createReturn(returnData)
        .then((resp) => {
          this.createdReturnId = resp.returnReference;
          this.$modal.show('return-created');

          // clear state
          this.returnItems = {};
          this.returnItemsCount = 0;
          this.returnReason = null;
          this.selectedReasonCode = null;
          this.completeCreateReturnRunning = false;
        })
        .catch((error) => {
          this.showError("Couldn't create order return");
          this.completeCreateReturnRunning = false;
        });
    },
    selectReason(reason) {
      this.selectedReasonCode = null;

      if (Object.prototype.hasOwnProperty.call(reason, "reason_codes")) {
        this.returnReasonCodes = reason.reason_codes;
        this.$modal.show('return-reason-code');
      }

      this.returnReason = reason.title;
    },
    selectReasonCode(reasonCode) {
      this.selectedReasonCode = reasonCode;
    },
    openChat() {
      this.$modal.hide('return-reason');
      this.$modal.show('open-chat');
    },
    cancelOpenReturn() {
      if (!confirm('Are you sure you want to cancel this return?')) return;

      this.cancelReturn(this.currentReturn.id)
        .catch((resp) => {
          if (resp.status >= 200 && resp.status < 400) {
            this.showPage('list');
            this.$refs.tabs.selectTab(1);
            this.showNotice('Return successfully cancelled');
          } else {
            this.showError("Something went wrong. Please try again.");
          }
        })
    },
    cancelReturnCreate() {
      this.showPage('details');

      // clean up return
      this.returnItems = {};
      this.returnItemsCount = 0;
      this.returnReason = null;
      this.selectReasonCode = null;
    },
    mappedReturnStatus(status) {
      return RETURN_STATUSES[status];
    },
    nextAction(subscription) {
      const actions = Object.values(this.EV_ACTIONS);
      const order = subscription.orders.find(o => actions.includes(o.nextAction));
      return order ? order.nextAction : null;
    },
    openLiveChat() {
      if (window.embedded_svc) {
        window.embedded_svc.liveAgentAPI.startChat();
      } else {
        throw new Error('Embedded SVC is not available');
      }
    },
    getOriginalQuantityForItem(sku) {
      if(!this.currentOrder || !sku) return 0;

      const item = this.currentOrder.items.find(i => i.sku === sku);

      return item.quantity;
    },
    items_by_type(item) {
      return this.returnableItems.filter(i => i.productType === item.productType)
    },
    increaseForType(item) {
      this.items_by_type(item).forEach((i) => {
        this.returnItems[i.sku]++
      })
    },
    decreaseForType(item) {
      this.items_by_type(item).forEach((i) => {
        this.returnItems[i.sku]--
      })
    },
  },
  components: {
    Notifications,
    MyAccountHeader,
    AddressBlock,
    ProgressBar,
    OrderItem,
    UploadPhotos,
    Tab,
    Tabs,
    PhotosReceived,
    DnoApplication,
    DnoUpgrades,
    BookPhc,
    PhcBooked,
    BookInstallation,
    BookInstallationNoVhc,
    InstallBooked,
    OrderComplete,
    BookVhc,
    VhcBooked,
  },
};

export default component;
