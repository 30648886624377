import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const HUB_OPTIONS = ['hub', 'hubless'];
const DEFAULT_HUB_OPTION = HUB_OPTIONS[0];
const PET_FRIENDLY_OPTIONS = ['petless', 'pet'];
const DEFAULT_PET_FRIENDLY_OPTION = PET_FRIENDLY_OPTIONS[0];
const FREE_HOMESHIELD_OPTION = 'homeshield';
const HOMESHIELD_OPTIONS = ['homeshield_plus', FREE_HOMESHIELD_OPTION];
const DEFAULT_HOMESHIELD_OPTION = HOMESHIELD_OPTIONS[0];
const PROFESSIONAL_INSTALLATION = 'professional';

const mutations = {
  ...baseMutations,
  setPackSkuMappings(state, packSkuMappings) {
    Vue.set(state, 'packSkuMappings', { ...packSkuMappings });
  },
  setShowInstallationPageAfterPurchase(state, showInstallationPageAfterPurchase) {
    Vue.set(state, 'showInstallationPageAfterPurchase', showInstallationPageAfterPurchase);
  },
  setHubOption(state, option) {
    if (!HUB_OPTIONS.includes(option)) throw new Error(`Invalid hub option: ${option}`);

    Vue.set(state, 'hubOption', option);
  },
  setPetFriendlyOption(state, option) {
    if (!PET_FRIENDLY_OPTIONS.includes(option)) throw new Error(`Invalid pet option: ${option}`);

    Vue.set(state, 'petFriendlyOption', option);
  },
  setHomeshieldOption(state, option) {
    if (!HOMESHIELD_OPTIONS.includes(option)) throw new Error(`Invalid homeshield option: ${option}`);

    Vue.set(state, 'homeshieldOption', option);
  },
};

export const moduleGetters = {
  ...baseGetters,
  sku({
    hubOption, packSkuMappings, petFriendlyOption,
  }) {
    return packSkuMappings[petFriendlyOption][hubOption];
  },
  pricingPayload(
    {
      homeshieldSku,
      homeshieldOption,
    },
    { sku }
  ) {
    const products = [{ sku, quantity: 1 }];

    if (homeshieldSku && homeshieldOption !== FREE_HOMESHIELD_OPTION) {
      products.push({ sku: homeshieldSku, quantity: 1 });
    }

    return { products, sku };
  },
  basketPayload(
    {
      quantity,
      showInstallationPageAfterPurchase,
      homeshieldSku,
      freeHomeshieldSku,
      playbackSku,
      homeshieldOption,
      installationOption,
      professionalInstallationSku,
    },
    { sku },
  ) {
    const skus = [{ sku, quantity }];
    let selectedHomeshieldSku;

    if (homeshieldOption !== FREE_HOMESHIELD_OPTION) selectedHomeshieldSku = homeshieldSku;
    else selectedHomeshieldSku = freeHomeshieldSku;

    if (selectedHomeshieldSku) skus.push({ sku: selectedHomeshieldSku, quantity: 1 });

    if (installationOption === PROFESSIONAL_INSTALLATION) {
      skus.push({ sku: professionalInstallationSku, quantity: 1 });
    }

    if (playbackSku) {
      skus.push({ sku: playbackSku, quantity: 1 });
    }

    const product = { sku: skus };

    if (showInstallationPageAfterPurchase) {
      product.showInstallationPage = true;
    }

    const productBuy = { product };

    return { productBuy, quantity: 1 };
  },
  defaultHubOption() {
    return DEFAULT_HUB_OPTION;
  },
  defaultPetFriendlyOption() {
    return DEFAULT_PET_FRIENDLY_OPTION;
  },
  defaultHomeshieldOption() {
    return DEFAULT_HOMESHIELD_OPTION;
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  setHubOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setHubOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setPetFriendlyOption({ commit, dispatch }, selected) {
    commit('setPetFriendlyOption', selected);

    return dispatch('loadPricing');
  },
  setHomeshieldOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setHomeshieldOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setInstallationOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setInstallationOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  init(
    {
      commit,
      dispatch,
    },
    {
      packSkuMappings,
      showInstallationPageAfterPurchase,
      homeshieldSku,
      freeHomeshieldSku,
      playbackSku,
      installationOption,
      professionalInstallationSku,
    }
  ) {
    commit('setPackSkuMappings', packSkuMappings);
    commit('setShowInstallationPageAfterPurchase', showInstallationPageAfterPurchase);
    commit('setProductSku', { homeshieldSku });
    commit('setProductSku', { freeHomeshieldSku });
    commit('setProductSku', { playbackSku });
    if (installationOption) commit('setInstallationOption', installationOption);
    if (professionalInstallationSku) commit('setProfessionalInstallationSku', professionalInstallationSku);

    if (installationOption === PROFESSIONAL_INSTALLATION) {
      dispatch('loadProfessionalInstallationPrice');
    }

    return dispatch('loadPricing');
  },

});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      sku: null,
      packSkuMappings: {},
      hubOption: DEFAULT_HUB_OPTION,
      homeshieldOption: DEFAULT_HOMESHIELD_OPTION,
      isPriceLoading: false,
      homeshieldSku: '',
      freeHomeshieldSku: '',
      playbackSku: '',
      petFriendlyOption: PET_FRIENDLY_OPTIONS[0],
      showInstallationPageAfterPurchase: false,
      pricing: {},
      quantity: 1,
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
