import { isNumber as isValidPhone } from 'utils/phone';
import {
  required,
  email,
  confirmed,
  min,
  // eslint-disable-next-line camelcase
  min_value,
  max,
  // eslint-disable-next-line camelcase
  max_value,
  // eslint-disable-next-line camelcase
  is_not,
} from 'vee-validate/dist/rules';

const castString = value => String(value || '');

const makeValidators = client => ({
  email,
  required,
  confirmed,
  is_not,
  min,
  min_value,
  max,
  max_value,
  first_name: {
    validate: value => /^[a-zA-Z '.,-]{2,39}$/.test(castString(value)),
  },
  last_name: {
    validate: value => /^[a-zA-Z '.,-]{2,79}$/.test(castString(value)),
  },
  checked: {
    validate: value => !!value,
  },
  terms_and_conditions_checked: {
    params: ['emailPreference', 'textPreference', 'phonePreference', 'noContactPreference'],
    validate: (value, args) => {
      const {
        emailPreference,
        textPreference,
        phonePreference,
        noContactPreference,
      } = args;

      const validMarketingPreference = (
        !!emailPreference || !!textPreference || !!phonePreference || !!noContactPreference
      );

      return !!value && validMarketingPreference;
    },
  },
  unique_email: {
    validate: username => client.validateAccount({}, { account: { username } })
      .then(({ valid }) => valid)
      .catch(() => true),
  },
  password: {
    validate: value => castString(value).length >= 8,
  },
  password_extended: {
    validate: value => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/.test(castString(value)),
  },
  phone: {
    validate: value => isValidPhone(value, true),
  },
  work_reference_number: {
    validate: value => /^(1|4)[0-9]{7,9}$/.test(castString(value)),
  },
  leak_redemption_policy_number_format: {
    validate: value => /^(HIA|HBP|HAP|HIP|MHA|BRIT|COV|IFI|SBY|HHB|NHP|HHH|MOR|HLH|HEX|HDA)(\d{8}|\d{9})$/.test(castString(value)),
  },
  uk_postcode: {
    validate: value => /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i.test(castString(value)),
  },
});


export default makeValidators;
