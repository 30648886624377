import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('global');

const template = `
<div>
  <div v-if="hasError" class="alert alert-danger">
    <strong v-show="showWarning">Warning!</strong>
    <span v-text="message"></span>
    <pre v-text="stack" v-show="stack"></pre>
  </div>
  <slot v-if="isContentVisible"></slot>
</div>
`;

const component = {
  template,
  props: {
    hideContentOnError: Boolean,
  },
  computed: {
    ...mapState({
      hasError: ({ error }) => (error.hideGlobal === true ? false : !!error.message),
      message: ({ error }) => error.message,
      stack: ({ error }) => error.stack,
      showWarning: ({ error }) => !error.noWarning,
    }),
    isContentVisible() {
      const { hideContentOnError, hasError } = this;

      return !hasError || !hideContentOnError;
    },
  },
};

export default component;
