import { createNamespacedHelpers } from 'vuex';
import AddToBasketButton from 'components/base/add_to_basket_button';
import QuantitySelector from 'components/views/quantity_selector';
import get from 'lodash/get';

const mapBasketGetters = createNamespacedHelpers('basket').mapGetters;
const mapBasketState = createNamespacedHelpers('basket').mapState;
const mapProductState = createNamespacedHelpers('productBuy').mapState;

const component = {
  inject: ['eventBus'],
  props: {
    switchToSku: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      quantity: 1,
    };
  },
  computed: {
    ...mapBasketGetters(['getEffectiveProduct', 'isBasketEmpty']),
    ...mapBasketState({
      updatingBasket(state) {
        return state.updating;
      },
      isBasketReady(state) {
        return !state.initializing;
      },
    }),
    ...mapProductState({
      productSku(state) {
        return get(state.product, 'sku', null);
      },
    }),
    isInBasket() {
      const effectiveProduct = this.getEffectiveProduct(this.productSku);

      return !!effectiveProduct;
    },
    total() {
      const effectiveProduct = this.getEffectiveProduct(this.productSku) || {};

      return effectiveProduct.total || 0;
    },
  },
  methods: {
    onAddToBasket() {
      const { quantity, voucherCode } = this;
      const productPayload = this.buildProductPayload();

      this.$emit('add-to-basket', productPayload.product.sku);
      this.$store.dispatch('basket/addToBasket', { productBuy: productPayload, quantity, voucherCode });
    },
    onUpdateQuantity(quantity) {
      this.$emit('quantity-changed', { quantity });
      this.quantity = quantity;
    },
    buildProductPayload() {
      if (this.switchToSku) {
        return {
          product: {
            sku: this.switchToSku,
            proceedToCheckout: true,
          },
        };
      }

      return this.$store.state.productBuy;
    },
  },
  components: {
    'add-to-basket-button': AddToBasketButton,
    'quantity-selector': QuantitySelector,
  },
  created() {
    this.eventBus.subscribeToQuantityUpdate(quantity => this.onUpdateQuantity(quantity));
  },
};

export default component;
