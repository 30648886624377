import AddressAutocomplete from 'components/views/address_autocomplete';
import ContactForm from 'mixins/contact_form';

const component = {
  mixins: [ContactForm],
  data() {
    return {
      emailConfirmation: this.initEmailConfirmation,
      addressValid: false,
    };
  },
  computed: {
    customSubmitButtonValidation() {
      return this.addressValid;
    },
  },
  methods: {
    validateAddress({
      address1, city, postcode, territory,
    }) {
      this.addressValid = address1 && city && (postcode || territory);
    },

    onDevPopulate(data) {
      Object.assign(this, data);
      this.$refs.termsAndConditions.click();
      this.$refs.addressAutocomplete.updateAddress(data);
      if (this.$refs.noContactPreference && !this.initialNoContactPreference) {
        this.$refs.noContactPreference.click();
      }
    },
  },
  components: {
    AddressAutocomplete,
  },
};

export default component;
