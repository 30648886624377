import Vue from 'vue/dist/vue.esm';

import flattenDeep from 'lodash/flattenDeep';
import uniqBy from 'lodash/uniqBy';
import remove from 'lodash/remove';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';

export const CHANGE_PRODUCT = {
  SUCCESS: 'CHANGE_PRODUCT_SUCCESS',
  PENDING: 'CHANGE_PRODUCT_PENDING',
  FAILURE: 'CHANGE_PRODUCT_FAILURE',
};

export const ADD_SKU = {
  SUCCESS: 'ADD_SKU_SUCCESS',
};

export const REMOVE_SKU = {
  SUCCESS: 'REMOVE_SKU_SUCCESS',
};

export const CHANGE_SKU = {
  SUCCESS: 'CHANGE_SKU_SUCCESS',
  PENDING: 'CHANGE_SKU_PENDING',
  FAILURE: 'CHANGE_SKU_FAILURE',
};

export const UPDATE_IMAGE_SAVINGS_BADGE = {
  SUCCESS: 'UPDATE_IMAGE_SAVINGS_BADGE_SUCCESS',
};

// purchasing options stored only on client
export const CHANGE_PURCHASING_OPTIONS = {
  SUCCESS: 'CHANGE_PURCHASING_OPTIONS_SUCCESS',
};

export const CHANGE_WIDGET_SWITCH_KEY = {
  SUCCESS: 'CHANGE_WIDGET_SWITCH_KEY_SUCCESS',
};

export const RESET_WIDGET = {
  SUCCESS: 'RESET_WIDGET_SUCCESS',
};

export const RESTORE_WIDGET = {
  SUCCESS: 'RESTORE_WIDGET_SUCCESS',
};

const updateStateWithProps = (state, obj) => {
  Object.keys(obj).forEach(
    (prop) => {
      const updateSubstate = state[prop] && isPlainObject(obj[prop]) && !isEmpty(obj[prop]);
      // update existing substate in cases where obj contains partial data
      // otherwise push obj[prop] on state
      if (updateSubstate) updateStateWithProps(state[prop], obj[prop]);
      else Vue.set(state, prop, obj[prop]);
    }
  );
};

const mutations = {
  [CHANGE_PRODUCT.SUCCESS](state, productData) {
    Vue.set(state, 'updating', false);
    updateStateWithProps(state, productData);
  },
  [CHANGE_PRODUCT.FAILURE](state, error) {
    Vue.set(state, 'updating', false);
    Vue.set(state, 'error', error);
  },
  [CHANGE_PRODUCT.PENDING](state) {
    Vue.set(state, 'updating', true);
  },
  [CHANGE_PURCHASING_OPTIONS.SUCCESS](state, options) {
    updateStateWithProps(state, { options: { ...state.options, ...options } });
  },
  [ADD_SKU.SUCCESS](state, skuAndQuantity) {
    const newSku = uniqBy(flattenDeep([state.product.sku, skuAndQuantity]), (skuOrLineItem) => {
      if (typeof skuOrLineItem === 'object') {
        return skuOrLineItem.sku;
      }

      return skuOrLineItem;
    });

    updateStateWithProps(state, { product: { ...state.product, sku: newSku } });
  },
  [CHANGE_SKU.SUCCESS](state, productData) {
    // TODO: Set other properties?
    const skuList = [].concat(state.product.sku);
    skuList[0] = productData.product.sku;

    const { basket_requirement, options } = state; // eslint-disable-line camelcase
    const productsIncludedWidget = productData.widgets['v3-products-included'];
    const freeEchoDotWidget = productData.widgets['v3-free-echo-dot'];

    let { widgets } = state;

    if (productsIncludedWidget) {
      widgets = {
        ...widgets,
        'v3-products-included': productsIncludedWidget,
      };
    }

    if (freeEchoDotWidget) {
      widgets = {
        ...widgets,
        'v3-free-echo-dot': freeEchoDotWidget,
      };
    }

    updateStateWithProps(
      state,
      {
        ...productData,
        product: {
          ...productData.product,
          sku: skuList,
        },
        options,
        basket_requirement,
        widgets,
      }
    );
  },
  [CHANGE_SKU.PENDING](state) {
    Vue.set(state, 'updating', true);
  },
  [CHANGE_SKU.FAILURE](state, error) {
    Vue.set(state, 'updating', false);
    Vue.set(state, 'error', error);
  },
  [UPDATE_IMAGE_SAVINGS_BADGE.SUCCESS](state, savings) {
    const { 'v3-product-gallery': widgetData } = state.widgets;
    const updatedWidgets = {
      ...state.widgets,
      'v3-product-gallery': { ...widgetData, imageSavingsBadge: savings },
    };

    updateStateWithProps(state, { widgets: updatedWidgets });
  },
  [REMOVE_SKU.SUCCESS](state, sku) {
    const removeSkuList = [].concat(sku);
    const skuList = [].concat(state.product.sku);
    remove(skuList, (existingSkuOrLineItem) => {
      if (typeof existingSkuOrLineItem === 'object') {
        return removeSkuList.includes(existingSkuOrLineItem.sku);
      }

      return removeSkuList.includes(existingSkuOrLineItem);
    });

    updateStateWithProps(state, { product: { ...state.product, sku: skuList } });
  },
  [CHANGE_WIDGET_SWITCH_KEY.SUCCESS](state, { widgetId, switchKey }) {
    const { [widgetId]: widgetData } = state.widgets;
    const updatedWidgets = {
      ...state.widgets,
      [widgetId]: { ...widgetData, switchKey, resetWidget: false },
    };

    updateStateWithProps(state, { widgets: updatedWidgets });
  },
  [RESET_WIDGET.SUCCESS](state, { widgetId, widgetRequirement }) {
    const updatedOptions = {
      ...state.options,
      [widgetRequirement]: true,
    };
    const { [widgetId]: widgetData } = state.widgets;
    const updatedWidgets = {
      ...state.widgets,
      [widgetId]: { ...widgetData, resetWidget: true, switchKey: null },
    };

    updateStateWithProps(state, { options: updatedOptions, widgets: updatedWidgets });
  },
  [RESTORE_WIDGET.SUCCESS](state, { widgetId, widgetRequirement }) {
    const updatedOptions = {
      ...state.options,
      [widgetRequirement]: false,
    };
    const { [widgetId]: widgetData } = state.widgets;
    const updatedWidgets = {
      ...state.widgets,
      [widgetId]: { ...widgetData, resetWidget: false },
    };

    updateStateWithProps(state, { options: updatedOptions, widgets: updatedWidgets });
  },
};

export default mutations;
