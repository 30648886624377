// Change the language based on country.
// Affected templates
// Template `app/views/shared/_country_changer_inline.html.erb`

const component = {
  // props are properties initialised by parent component
  // we use them to pass data from Ruby to Javascript
  // See also [here](https://vuejs.org/v2/guide/components-props.html)
  props: {
    country: {
      type: String,
      required: true,
    },
  },
  // this is the local state
  // we can reference the local state inside HTML template
  // using proper [Vue directives](https://vuejs.org/v2/api/#Directives)
  // Vue automatically updates the HTML when state changes
  data() {
    return {
      // init state is initialized with props
      selectedCountry: this.country,
    };
  },
  // methods are called inside template and are triggered by dom events
  methods: {
    setCountry(country) {
      this.selectedCountry = country;
    },
    isSelectedCountry(country) {
      return this.selectedCountry === country;
    },
  },
};

export default component;
