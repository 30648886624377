const mixin = {
  computed: {
    showWidget() {
      return this.total > 0 && this.roundedStarsAverage > 0;
    },
    starLabel() {
      return this.starLabels[this.roundedStarsAverage - 1];
    },
  },
  created() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      return this.client.trustpilotReviewsSummary({
        apikey: this.apiKey,
        sku: this.skus,
        locale: this.locale,
      })
        .then((summary) => {
          this.starsAverage = Math.round(summary.starsAverage * 10) / 10;
          this.roundedStarsAverage = Math.round(summary.starsAverage);
          this.total = summary.numberOfReviews.total;
        });
    },
    starIconPath(rating) {
      return this.starIconPaths[rating - 1];
    },
  },
};

export default mixin;
