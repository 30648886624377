import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('global');

const component = {
  computed: {
    ...mapState(['navCollapsed']),
  },
};

export default component;
