import HeaderSection from 'components/sections/header_section';

const instance = {
  el: '#vue-header-instance',
  name: 'header',
  components: {
    'header-section': HeaderSection,
  },
};

export default instance;
