import { createNamespacedHelpers } from 'vuex';
import AddToBasketButton from 'mixins/add_to_basket_button';
import ScrollToTop from 'mixins/scroll_to_top';


const { mapState } = createNamespacedHelpers('basket');

const component = {
  mixins: [AddToBasketButton, ScrollToTop],
  props: {
    sku: String,
    noRedirect: Boolean,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState(['updating']),
    buttonsLoading() {
      return this.updating && this.isLoading;
    },
    basketPayload() {
      const { sku, noRedirect, successCallback } = this;
      const productBuy = {
        product: { sku: [{ sku, quantity: 1 }], noRedirect, successCallback },
      };

      return { productBuy, quantity: 1 };
    },
  },
  methods: {
    successCallback() {
      this.isLoading = false;
    },
    onButtonClick() {
      this.isLoading = true;
      this.addToBasket({ ...this.basketPayload, errorCallback: this.scrollToTop });
    },
  },
};

export default component;
