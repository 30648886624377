// import Vue from 'vue/dist/vue.esm';
import { makeBaseActions } from '../common_buy_page';

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      quantity: 1,
      pricing: {},
    };
  },
  actions: makeActions(injections),
});

export default makeModule;
