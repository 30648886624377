// A set of utility functions for webshop -> app communication

const postMessage = (...args) => {
  if (!window.ReactNativeWebView) { return; }

  window.ReactNativeWebView.postMessage(...args);
};

export default {
  isWebview() { return !!window.ReactNativeWebView; },

  ready() { postMessage('webviewReady'); },

  back() { postMessage('backButton'); },
};
