import logger from 'utils/logger';

import filter from 'lodash/fp/filter';

const ESCAPE_KEY = 27;

/**
 * This Vue component controls an area with an HTML video.
 *
 * Exactly one video is allowed inside this component.
 * It could have more `autoplay` video though.
 *
 * @see https://www.w3schools.com/html/html5_video.asp
 * @see https://www.w3schools.com/tags/ref_av_dom.asp
 *
 */
const component = {
  // HWS-7001: this 'name' is important for DMPG analytics
  name: 'product-overview-hero',
  props: {
    stopOnEsc: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isPlaying: false,
      isPaused: false,
      video: null,
    };
  },
  mounted() {
    // look for an emedded video inside this component
    // autoplay video are filtered out
    const videos = filter(video => !video.autoplay, this.$el.getElementsByTagName('video'));
    if (videos.length === 0) {
      logger.error('No embedded video found in component.');
    } else if (videos.length > 1) {
      logger.error('More than one video tag is not allowed.');
    } else if (this.video === null) {
      // if everything is fine, register the video for future reference
      // eslint-disable-next-line prefer-destructuring
      this.video = videos[0];
    }
    // listen to ESC keyup (close video), globally
    if (this.stopOnEsc) {
      document.addEventListener('keyup', this.handleKeyUp);
    }
  },
  beforeDestroy() {
    if (this.stopOnEsc) {
      // remove keyup listener
      document.removeEventListener('keyup', this.handleKeyUp);
    }
  },
  methods: {
    async play() {
      await this.video.play();
      this.isPlaying = true;
      this.isPaused = false;
    },
    stop() {
      // stop and rewind
      this.video.load();
      this.isPaused = true;
      this.isPlaying = false;
    },
    async unpause() {
      await this.video.play();
      this.isPaused = false;
    },
    pause() {
      this.video.pause();
      this.isPaused = true;
    },
    async toggle() {
      if (!this.isPlaying) return;
      if (this.isPaused) {
        await this.unpause();
      } else {
        this.pause();
      }
    },
    handleKeyUp(event) {
      if (event.keyCode === ESCAPE_KEY) {
        if (this.isPlaying) {
          this.stop();
        }
      }
    },
  },
};

export default component;
