import baseConfig from './shared/quantity_select';

const vuexModule = {
  ...baseConfig,
  getters: {
    outdoorCameraQty({ selected }) {
      if (selected === null) return 0;

      if (selected > 3) return 4;

      return selected;
    },
  },
};

export default vuexModule;
