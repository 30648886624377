const component = {
  inject: ['cookies'],
  props: {
    name: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      value: this.cookies.get(this.name),
    };
  },
  methods: {
    setValue(value) {
      this.cookies.set(
        this.name,
        value.toString(),
        { expires: 365 * 24 * 60 * 60 /* one year */ },
      );
      this.value = value.toString();
    },
    hasValue(value) {
      return this.value === value.toString();
    },
  },
};

export default component;
