import Vue from 'vue/dist/vue.esm';
import DmpgData from 'mixins/dmpg_data';

import logger from 'utils/logger';
import observe from './observe';

// Initialize global mixins
Vue.mixin(DmpgData);

export const eventEmitter = new Vue();
/**
 *
 * Run a Vue instance and listen to DOM changes.
 * If DOM changes and new components are inserted, Vue instance is reinitialized.
 *
 * @param {*} params, Vue constructor params
 * @param {*} options.monitor, reload
 * @returns handle to stop listening
 */
function run(params, options) {
  const { monitor } = options;
  // create an instance
  let instance = new Vue(params);
  let unsubscribe;

  if (monitor) {
    unsubscribe = observe(params.el, {
      onComponentsAdded(components) {
        logger.debug('Some inline templates have been injected into an instance.', components);
        instance.$destroy();
        instance = new Vue(params);
      },
      onInstanceRemoved() {
        // TODO understand when we can fire this event
        // see https://trello.com/c/EsI8Fbja/53-as-a-developer-i-want-to-know-if-a-vue-instance-is-removed-by-erbjs-by-mistake
        //
        // this should not happen, just let developers know
        logger.error('Some script has removed the Vue instance!');
        unsubscribe();
      },
    });
  }

  return unsubscribe;
}

export default run;
