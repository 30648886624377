import logger from 'utils/logger';

import initModuleWithState from 'store/utils/init_module_with_state';

/**
 * Init `ratings` module
 */
function initRatingsModule(module) {
  let data = {};
  const content = localStorage.getItem('starRatings');

  if (content) {
    try {
      data = JSON.parse(content);
    } catch (e) {
      logger.error('Cannot retrieve starRating from localStorage: parse error.');
    }
  }

  const initState = {
    ratings: data,
  };
  return initModuleWithState(module, initState, 'ratings');
}

export default initRatingsModule;
