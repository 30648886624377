import makeActions from './actions';
import mutations from './mutations';
import getters from './getters';

const makeModule = (injections) => {
  const actions = makeActions(injections);

  return {
    namespaced: true,
    state: {
      updating: false,
      error: undefined,
    },
    actions,
    mutations,
    getters,
  };
};

export default makeModule;
