<template>
  <modal
    v-bind:name="modalName"
    classes="hive-modal"
    height="auto"
    v-bind:scrollable="true"
    v-on:before-open="assignParams"
  >
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <button
          v-if="showCloseButton"
          type="button"
          class="close"
          v-on:click="close"
        >
          <img
            class="d-block mx-auto my-3"
            src="//images.ctfassets.net/mijf9lz5yt3u/6QQreMAlyUmAzXD8KMCIuv/9cbe38a38fca51cee4a4d4b0dfc752bb/icon-close.svg"
            width="32"
            height="32"
          >
        </button>
        <div class="modal-body px-5 text-center">
          <slot name="modal-icon">
            <img
              class="d-block mx-auto my-3"
              src="//images.ctfassets.net/mijf9lz5yt3u/2eHEpBdm9JI4Pt253mdJnD/e66a25dc89e61024c438122f25f840fe/icon-alert.svg"
              width="32"
              height="32"
            >
          </slot>
          <slot v-bind:params="modalParams">
            <h3>Are you sure?</h3>
          </slot>
          <slot name="description" v-bind:params="modalParams" />
        </div>
        <div class="modal-footer my-3">
          <button
            type="button"
            class="btn btn-secondary w-100 mb-3"
            v-on:click.stop.prevent="confirm"
          >
            <slot name="confirm-button">
              Yes
            </slot>
          </button>
          <button
            v-if="!customRejectButton"
            type="button"
            class="btn btn-secondary w-100"
            v-on:click.stop.prevent="close"
          >
            <slot name="reject-button">
              Not now
            </slot>
          </button>

          <slot name="custom-reject-button" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
let modalId = 1;

const component = {
  props: {
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    customRejectButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalParams: {},
    };
  },
  created() {
    this.modalName = `confirmation-modal-${modalId}`;
    modalId += 1;
  },
  methods: {
    open(params = {}) {
      this.$modal.show(this.modalName, params);
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    confirm() {
      this.$emit('confirm', this.modalParams);
      this.close();
    },
    assignParams(event) {
      this.modalParams = { ...event.params };
    },
  },
};

export default component;
</script>
