import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('pdpSensorBuyPage');

const component = {
  computed: {
    ...mapState(['productType']),
  },
  methods: {
    activeSensorType(sensorType) {
      return this.productType === sensorType;
    },
    changeSensorType(sensorType) {
      this.$emit('change-sensor-type', sensorType);
    },
  },
};

export default component;
