import MultipackSelector from 'components/views/multipack_selector';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HoneyProductRegularBuyHub from 'components/views/honey/product_regular_buy_hub';
import AmountDiscountBadge from 'mixins/amount_discount_badge';
import HoneyProductRegularBuyPetFriendly from 'components/views/honey/product_regular_buy_pet_friendly';

import { createNamespacedHelpers } from 'vuex';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('pdpSensorBuyPage');
const actions = ['setHubSku', 'setProductType', 'setSensorSku', 'init', 'increment', 'decrement', 'setQuantity'];

const component = {
  inject: ['config'],
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    initialSku: String,
    skusMapping: Object,
  },
  data() {
    return {
      basketInterval: null,
    };
  },
  computed: {
    ...mapState(['quantity', 'sku', 'hubSku', 'isPriceLoading', 'productType']),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
    ]),
    isFreeDeliveryVisible() {
      return this.totalDiscountedPrice > this.config.deliveryThreshold;
    },
    activeProductType() {
      return this.productType;
    },
  },
  created() {
    const { initialSku } = this;
    this.init({ sku: initialSku });
  },
  beforeDestroy() {
    clearInterval(this.basketInterval);
  },
  methods: {
    ...mapActions(actions),
    onChangedSku({ sku }) {
      this.setSensorSku(sku);
    },
    handleSensorTypeChange(newSensorType) {
      const newSensorTypeSku = this.getNewSensorTypeSku();
      this.setProductType(newSensorType);
      this.setSensorSku(newSensorTypeSku);
      this.$refs[newSensorType].activeButtonIndex = newSensorTypeSku;
    },
    getNewSensorTypeSku() {
      const { productType, sku } = this;
      if (productType === 'motion_sensor') return this.skusMapping[sku];

      return Object.keys(this.skusMapping).find(k => this.skusMapping[k] === sku);
    },
  },
  components: {
    TrustPilotPopup,
    MultipackSelector,
    HoneyProductRegularBuyHub,
    HoneyProductRegularBuyPetFriendly,
  },
};

export default component;
