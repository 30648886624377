const component = {
  props: {
    defaultSku: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentSku: this.defaultSku,
    };
  },
  methods: {
    switchColour(colourSku) {
      this.currentSku = colourSku;
      this.$emit('upsell-sku-changed', this.currentSku);
    },
    colourSkuSelected(colourSku) {
      return this.currentSku === colourSku;
    },
  },
};

export default component;
