<template>
  <div class="row">
    <div
      v-for="address in addresses"
      v-bind:key="address.reference"
      class="global-account-hover-card-wrapper col-md-6 mb-md-4"
      v-on:click.prevent="selectAddress(address)"
    >
      <div class="global-account-hover-card cursor-pointer">
        <div class="d-flex justify-content-between">
          <address-block
            v-bind:address="address"
            v-bind:compact="false"
            v-bind:fallback="false"
          />
          <a
            class="d-block d-md-none d-flex align-items-center pr-1"
            href="#"
            v-on:click.prevent="selectAddress(address)"
          >
            <img
              src="//images.ctfassets.net/mijf9lz5yt3u/14DzlszNhOHlM1jXXCDs3A/c4635fb849cd4406289dd0d9f6772a2d/icon-forward.svg"
              width="28"
              height="28"
            >
          </a>
        </div>
        <div v-show="isDefault(address)" class="mt-2 mt-md-1 font-weight-bold">
          {{ defaultLabel(address) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import AddressBlock from 'components/base/address_block';

const { mapState } = createNamespacedHelpers('customer');

const defaultAttrsMapping = {
  default_delivery: 'delivery',
  default_billing: 'billing',
  default_installation: 'installation',
};

const component = {
  computed: {
    ...mapState([
      'addresses',
    ]),
  },
  methods: {
    selectAddress(address) {
      this.$emit('select-address', address);
    },
    isDefault(address) {
      return Object.keys(defaultAttrsMapping).some(el => address[el]);
    },
    defaultLabel(address) {
      const reducer = (acc, key) => {
        if (address[key]) {
          return [...acc, defaultAttrsMapping[key]];
        }

        return acc;
      };
      const flags = Object.keys(defaultAttrsMapping).reduce(reducer, []);

      return `Default ${flags.join(', ')}`;
    },
  },
  components: {
    AddressBlock,
  },
};

export default component;
</script>
