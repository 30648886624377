import get from 'lodash/get';
import EmitRequirements from 'mixins/emit_requirements';

const component = {
  mixins: [EmitRequirements],
  props: {
    widgetId: String,
    initialSwitchKey: {
      type: String,
      required: false,
      default: null,
    },
    initialHeadline: String,
    description: String,
    initialActiveButtonIndex: Number,
    storageName: String,
    belongsToCombo: Boolean,
    emitProductOption: Boolean,
  },
  computed: {
    statusLine() { return this.body; },
    complete() { return this.activeButtonIndex !== -1; },
    isComboWidget() { return get(this.$store.state, `productBuy.widgets[${this.widgetId}].isComboWidget`, false); },
    isReset() { return get(this.$store.state, `productBuy.widgets[${this.widgetId}].isReset`, false); },
  },
  data() {
    return {
      activeButtonIndex: this.initialActiveButtonIndex,
      headline: this.initialHeadline,
      headlineAfterClick: '',
      body: '',
      switchKey: this.initialSwitchKey,
      showOnlyHeadlineAfterClick: false,
      spec: '',
    };
  },
  beforeMount() {
    if (localStorage.getItem(this.storageName)) {
      try {
        const storedData = JSON.parse(localStorage.getItem(this.storageName));
        Object.assign(this.$data, storedData);
        if (storedData) {
          if (this.belongsToCombo) {
            this.triggerChangeWidgetSwitchKeyAction();
            this.updateRequirement();
          } else if (this.emitProductOption) {
            this.publishProductOption();
            this.updateRequirement();
          } else {
            this.$nextTick(() => {
              this.emitProductChoice();
              this.updateRequirement();
            });
          }
        }
      } catch (e) {
        localStorage.removeItem(this.storageName);
      }
    }
  },
  mounted() {
    this.afterMountedHook();
  },
  methods: {
    choiceEventData() {
      return {}; // Should be overridden
    },
    afterMountedHook() {}, // Should be overridden when needed
    publishProductOption() {}, // Should be overridden
    dataToLocalStorage() {
      const parsed = JSON.stringify(this.$data);
      localStorage.setItem(this.storageName, parsed);
    },
    isButtonActive(index) {
      return this.activeButtonIndex === index;
    },
    onButtonClick(index, data) {
      this.activeButtonIndex = index;
      // TODO: we should not mutate the component object here.
      Object.assign(this, data);

      if (this.headlineAfterClick) {
        this.headline = this.headlineAfterClick;
      }

      if (this.headline === this.body || this.showOnlyHeadlineAfterClick) {
        this.body = '';
      }

      if (this.belongsToCombo) this.triggerChangeWidgetSwitchKeyAction();
      this.afterUpdateChanges();
    },
    onSelect(selected) {
      this.selected = selected;
      this.triggerChangeWidgetSwitchKeyAction();
      this.afterUpdateChanges();
    },
    onChangeButtonClick() {
      this.reset();
      this.afterUpdateChanges();
    },
    reset() {
      this.activeButtonIndex = this.initialActiveButtonIndex;
      this.headline = this.initialHeadline;
      this.body = null;
    },
    emitCompleteSpecEvent() {
      window.specEvents.push(`productWidgets.${this.widgetId}.complete`);
    },
    triggerChangeWidgetSwitchKeyAction() {
      const { widgetId, switchKey } = this;

      this.$store.dispatch(
        'productBuy/changeWidgetSwitchKey',
        {
          widgetId,
          switchKey,
          callback: this.emitCompleteSpecEvent,
        }
      );
    },
    afterUpdateChanges() {
      this.requiredProductChoiceAfterUpdateChanges();
    },
    requiredProductChoiceAfterUpdateChanges() {
      this.dataToLocalStorage();
      this.emitProductChoice();
      this.updateRequirement();
    },
    emitProductChoice() {
      this.$emit('product-choice', this.choiceEventData());
    },
    updateRequirement(forceComplete) {
      const completeVal = forceComplete === undefined ? this.complete : forceComplete;
      const requirements = {
        [this.requirement]: completeVal,
      };

      this.emitRequirementChange(requirements);
    },
  },
};

export default component;
