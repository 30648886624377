import ConnectToBasket from 'components/base/connect_to_basket';
import UpsellProductColours from 'components/base/upsell_product_colours';

const component = {
  inject: ['dataLayer'],
  props: {
    recommendationsForSku: {
      type: String,
      required: true,
    },
    defaultSku: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      quantity: 1,
      currentSku: this.defaultSku,
    };
  },
  components: {
    'connect-to-basket': ConnectToBasket,
    'upsell-product-colours': UpsellProductColours,
  },
  methods: {
    onRecommendationSelected(sku) {
      this.dataLayer.appendUpsellItemSelectedEvent(this.recommendationsForSku, sku);
    },
    onQuantityChanged({ quantity }) {
      this.quantity = quantity;
    },
    onSkuChanged(sku) {
      this.currentSku = sku;
    },
  },
};

export default component;
