import { WOW } from 'wowjs';

// Fix issues with SVG
// https://github.com/matthieua/WOW/pull/239/
WOW.prototype.resetAnimation = function resetAnimation(event) {
  let target;
  if (event.type.toLowerCase().indexOf('animationend') >= 0) {
    target = event.target || event.srcElement;
    if (target instanceof SVGElement) {
      return target.setAttribute('class', this.config.animateClass);
    }
    target.className = target.className.replace(this.config.animateClass, '').trim();
  }
  return target;
};

export default WOW;
