<template>
  <div>
    <h4 class="text-center mb-4">
      Select a new date and time slot
    </h4>
    <div class="d-flex justify-content-center">
      <div class="bg-gray-lightest p-1 rounded">
        <button
          v-for="(label, slotInterval) in SLOT_INTERVALS"
          v-bind:key="slotInterval"
          class="btn btn-sm"
          v-bind:class="{ 'btn-white': isSlotIntervalSelected(slotInterval) }"
          v-on:click="selectSlotInterval(slotInterval)"
        >
          {{ label }}
        </button>
      </div>
    </div>

    <div
      v-for="(pane, paneIndex) in calendar"
      v-show="isCalendarPaneVisible(paneIndex)"
      v-bind:key="pane.title"
    >
      <div class="d-flex align-items-center justify-content-between my-3">
        <a
          v-if="isCalendarPaneAvailable(paneIndex - 1)"
          href="#"
          v-on:click.prevent="showCalendarPane(paneIndex - 1)"
        >
          <img
            src="//images.ctfassets.net/mijf9lz5yt3u/6FKM1OSldIpUYap2EmeNxz/7f2f2821ac89017e7ae91fa3695de840/icon-left.svg"
            width="17"
            height="17"
          >
        </a>
        <img
          v-else
          src="//images.ctfassets.net/mijf9lz5yt3u/3HNiFQICxrQPpevFzkx0E5/9830d6cb7f7f872cd59cf4e3e6953bd9/icon-left-gray-lightest.svg"
          width="17"
          height="17"
        >
        <h5 class="mb-0">
          {{ pane.month }}
        </h5>
        <a
          v-if="isCalendarPaneAvailable(paneIndex + 1)"
          href="#"
          v-on:click.prevent="showCalendarPane(paneIndex + 1)"
        >
          <img
            src="//images.ctfassets.net/mijf9lz5yt3u/3gYtgcRMhplmNnI5OkQwAd/68c0d337c8ae71ee97a6a8749b218e28/icon-right.svg"
            width="17"
            height="17"
          >
        </a>
        <img
          v-else
          src="//images.ctfassets.net/mijf9lz5yt3u/45OK0nDlv5WNWOrSDUnoiD/316f14566359710edf0f9b22b5b31320/icon-right-gray-lightest.svg"
          width="17"
          height="17"
        >
      </div>
      <div class="row text-gray-dark mb-4 g-0">
        <div
          v-for="dayOfWeek in DAYS_OF_WEEK"
          v-bind:key="dayOfWeek"
          class="col text-center"
        >
          {{ dayOfWeek }}
        </div>
      </div>
      <div class="row g-0">
        <template v-for="entry in pane.entries">
          <div v-if="entry.isVisible" class="col text-center mb-3">
            <a
              href="#"
              class="h5 d-block mb-0 rounded-sm mx-md-2 appointment-scheduler-calendar-day"
              v-bind:class="paneEntryClasses(entry)"
              v-on:click.prevent="selectPaneEntry(entry)"
            >
              {{ entry.date.day }}
            </a>
            <div v-if="entry.date.isToday()" class="dot dot-xs bg-blue position-absolute" />
          </div>
          <div v-else class="col" />
          <div v-if="entry.date.isSunday()" class="w-100" />
        </template>
      </div>
    </div>
    <button
      type="button"
      class="mb-5 btn btn-primary btn-block"
      v-bind:disabled="!selectedDate"
      v-on:click="done"
    >
      Done
    </button>
  </div>
</template>

<script>
import last from 'lodash/last';
import {
  DAYS_OF_WEEK_SHORT, SLOT_INTERVALS, CalendarDate, buildCalendar,
} from 'utils/calendar';

const parseSlots = (slots) => {
  const parsed = {};

  slots
    .map(slot => ({
      ...slot,
      am: slot.hasAmSlot,
      pm: slot.hasPmSlot,
      date: new CalendarDate(slot.date),
    }))
    .sort((a, b) => a.date - b.date)
    .forEach((slot) => { parsed[slot.date] = slot; });

  return parsed;
};

const component = {
  props: {
    defaultPmTime: String,
    slots: Array,
    default() { return []; },
  },
  data() {
    return {
      calendarPaneIndex: 0,
      selectedSlotInterval: 'am',
      selectedDate: null,
    };
  },
  created() {
    this.SLOT_INTERVALS = { ...SLOT_INTERVALS, pm: this.defaultPmTime || SLOT_INTERVALS.pm };
    this.DAYS_OF_WEEK = DAYS_OF_WEEK_SHORT;

    this.parsedSlots = parseSlots(this.slots);

    const values = Object.values(this.parsedSlots);
    const firstSlot = values[0].date;
    const lastSlot = last(values).date;

    this.calendar = buildCalendar(firstSlot, lastSlot);
  },
  methods: {
    isSlotIntervalSelected(slotInterval) {
      return slotInterval === this.selectedSlotInterval;
    },
    selectSlotInterval(slotInterval) {
      this.selectedSlotInterval = slotInterval;
      if (this.selectedDate && !this.isSlotIntervalAvailable({ date: this.selectedDate })) {
        this.selectedDate = null;
      }
    },
    isCalendarPaneVisible(index) {
      return this.calendarPaneIndex === index;
    },
    showCalendarPane(index) {
      this.calendarPaneIndex = index;
    },
    isCalendarPaneAvailable(index) {
      return index >= 0 && index < this.calendar.length;
    },
    isSlotIntervalAvailable({ date }) {
      return this.parsedSlots[date] && this.parsedSlots[date][this.selectedSlotInterval];
    },
    isDateSelected(entry) {
      return this.selectedDate
        && this.selectedDate.eq(entry.date)
        && this.isSlotIntervalAvailable(entry);
    },
    paneEntryClasses(entry) {
      const selected = this.isDateSelected(entry);

      return {
        'text-gray-lightest': !this.isSlotIntervalAvailable(entry),
        'bg-gray-dark': selected,
        'text-white': selected,
      };
    },
    selectPaneEntry(entry) {
      if (!this.isSlotIntervalAvailable(entry)) { return; }

      this.selectedDate = entry.date;
    },
    done() {
      if (!this.selectedDate && !this.selectedSlotInterval) { return; }

      this.$emit('select', { date: this.selectedDate, interval: this.selectedSlotInterval });
    },
  },
};

export default component;
</script>
