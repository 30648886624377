import WaveEffect from 'mixins/wave_effect';
import HomepageTrustpilot from 'components/views/homepage_trustpilot';
import ModalContainer from 'components/base/modal_container';
import { dragscroll } from 'vue-dragscroll';

import HoneyRadiatorValveBuyPage from 'components/pages/honey/radiator_valve_buy_page';
import HoneyPdpLightsBuyPage from 'components/pages/honey/pdp_lights_buy_page';
import HoneyPdpSensorBuyPage from 'components/pages/honey/pdp_sensor_buy_page';
import HoneySimpleProductBuyPage from 'components/pages/honey/simple_product_buy_page';
import HoneyEvChargerBuyPage from 'components/pages/honey/ev_charger_buy_page';

const component = {
  mixins: [WaveEffect],
  data() {
    return {
      // show always action card
      isActive: false,
    };
  },
  components: {
    'homepage-trustpilot': HomepageTrustpilot,
    'modal-container': ModalContainer,
    HoneyRadiatorValveBuyPage,
    HoneyPdpLightsBuyPage,
    HoneySimpleProductBuyPage,
    HoneyPdpSensorBuyPage,
    HoneyEvChargerBuyPage,
  },
  directives: {
    dragscroll,
  },
};

export default component;
