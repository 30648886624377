<template>
  <quiz-chat-step v-bind:step="step">
    <template v-slot:question>
      <slot />
    </template>

    <template v-slot:buttons="{ onSelect, isCurrentStep }">
      <quiz-chat-button
        v-for="number in numbers"
        v-bind:key="number"
        v-bind:animate="isCurrentStep"
        v-on:select="onSelect(number)"
      >
        <span
          v-if="number < max"
          v-text="number"
        />
        <span v-else>More</span>
      </quiz-chat-button>
    </template>
  </quiz-chat-step>
</template>

<script>
import range from 'lodash/range';
import QuizChatButton from './chat_button.vue';
import QuizChatStep from './chat_step.vue';

const component = {
  props: {
    step: String,
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  computed: {
    numbers() {
      return range(this.min, this.max + 1);
    },
  },
  components: {
    QuizChatButton,
    QuizChatStep,
  },
};

export default component;
</script>
