
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const STATIC_SAVING_AMOUNT = 26;
const STATIC_SAVING_TREES = 50;
const DATA_NO_AVAILABLE = "Data not available";

const component = {
  inject: ['client'],
  props: {
    subscriptionNumber: {
      type: String,
      required: true,
    },
    sku: {
      type: String,
      required: true,
    },
    returnReasons: {
      type: Array,
      required: true,
    },
    hhPlusDiscountEligible: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      isReasonOptionsDisplayed: false,
      subscriptionCancelled: false,
      subscriptionRenewed: false,
      selectedReturnReason: null,
      cancellationInProgress: false,
      homeInfo: null,
      hhPlusOfferVisible: false,
    };
  },
  computed: {
    displayStaticSaving() {
      return this.homeInfo?.amtSavedYTD < 10 || this.homeInfo?.amtSavedYTD === DATA_NO_AVAILABLE;
    },
    savingAmount() {
      return this.displayStaticSaving ? STATIC_SAVING_AMOUNT : this.homeInfo?.amtSavedYTD;
    },
    savingTitle() {
      return this.displayStaticSaving ? 'Avg monthly saving' : 'As a household';
    },
    savingPeriod() {
      return this.displayStaticSaving ? 'October to May' : 'since January';
    },
    savedTrees() {
      return this.displayStaticSaving ? STATIC_SAVING_TREES : parseInt(this.homeInfo?.trees, 10);
    },
  },
  methods: {
    ...mapCustomerActions(['cancelSubscription', 'renewSubscription']),
    cancelSub() {
      const { subscriptionNumber, sku, selectedReturnReason } = this;
      this.$emit('block-cancel-button');
      this.cancellationInProgress = true;

      this.cancelSubscription({ subscriptionNumber, sku, selectedReturnReason })
        .finally(() => {
          this.subscriptionCancelled = true;
        });
    },
    renewSub() {
      const { subscriptionNumber, sku } = this;

      this.renewSubscription({ subscriptionNumber, sku })
        .finally(() => {
          this.subscriptionRenewed = true;
        });
    },
    displayHhPlusDiscountOffer() {
      if (this.hhPlusDiscountEligible) {
        this.hhPlusOfferVisible = true;
      } else {
        this.displayReasonOptions();
      }
    },
    displayReasonOptions() {
      this.hhPlusOfferVisible = false;
      this.isReasonOptionsDisplayed = true;
    },
    homeHeatingInfo() {
      this.client.homeHeatingSubscriptionInfo()
        .then((response) => {
          this.homeInfo = response;
        });
    },
  },
  mounted() {
    this.homeHeatingInfo();
  }
};

export default component;
