<template>
  <div class="d-flex flex-column">
    <div
      v-for="notification in notifications"
      v-bind:key="notification.id"
      class="global-account-alert mb-5 text-center"
      v-bind:class="bgClass(notification)"
    >
      {{ notification.text }}
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { ADD_NOTIFICATION } from 'store/modules/global';

const namespace = 'global';
export const NO_AUTOHIDE = -1;
const { mapState, mapActions } = createNamespacedHelpers(namespace);

export default {
  props: {
    hideDelay: {
      type: Number,
      default: NO_AUTOHIDE,
    },
  },
  computed: {
    ...mapState([
      'notifications',
    ]),
    isAutohide() {
      return this.hideDelay !== NO_AUTOHIDE;
    },
  },
  created() {
    if (this.isAutohide && this.$store) {
      this.$store.subscribe(({ type }) => {
        if (type === `${namespace}/${ADD_NOTIFICATION}`) {
          this.hideRecentNotification();
        }
      });
    }
  },
  methods: {
    ...mapActions([
      'hideNotification',
    ]),
    bgClass(notification) {
      return notification.error ? ['bg-red', 'text-white'] : 'bg-green';
    },
    hideRecentNotification() {
      const recentNotification = this.notifications[this.notifications.length - 1];

      if (recentNotification) {
        setTimeout(() => {
          this.hideNotification(recentNotification.id);
        }, this.hideDelay);
      }
    },
  },
};
</script>
