import { TYPE_CONFIRM, NEXT_STEP_RENDER_DEVICE_LIST_AFTER_POWER_CYCLE } from '../constants';

const SLT3 = [
  {
    id: 1,
    text: 'Remove the thermostat by pressing on the tab at the bottom of the thermostat bracket. This will then allow the thermostat to become detached from the wall.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/7jKs2NzsIsrQ4qFecbpoRw/ce13b172e7fab0e7fedfcfa72ca827b0/image1-31.jpeg?h=250',
    ...TYPE_CONFIRM,
    nextStep: 2,
  },
  {
    id: 2,
    text: 'Please remove one battery from the thermostat.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/7w3YATpk4qQ81Awr7vtNoe/17d9797386f4fc9bd5bfa84a7decf4d8/image3-34.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 3,
  },
  {
    id: 3,
    text: 'Please take your thermostat and the removed battery over to your receiver box located near your boiler/hot water tank',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/1dJ6pPZ75wC7sBJyhU9FSp/22c0b6841355ad7241ef7e50a06fb423/image3-35.jpeg?h=250',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
  {
    id: 4,
    text: 'Please turn the receiver off by switching the isolation off and confirm all the lights have gone off on your receiver box',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5sUIazFUCA0BjFx3EEqOX2/56a921555591432c7bd5d5d43c25f826/image1-31.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 5,
  },
  {
    id: 5,
    text: 'Place the battery back into the thermostat.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/7w3YATpk4qQ81Awr7vtNoe/17d9797386f4fc9bd5bfa84a7decf4d8/image3-34.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 6,
  },
  {
    id: 6,
    text: 'Turn the receiver back on and please watch what colour the light flashes before going green.',
    ...TYPE_CONFIRM,
    nextStep: 7,
  },
  {
    id: 7,
    text: `When you switch the receiver back on, it should flash Amber twice indicating it’s in connected mode and should then turn green in the top right to indicate it’s paired again.  
      Note: If you see it flash pink/white it’s been setup in standalone mode and will not work in this mode via the app or through
      a web browser. (If this is the case then we will need to reset and pair your system which will be done further
        on in the troubleshooting)`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5mczcTcbQgwbGSP3MOcRaY/8452852be872417eebe0c7f31e12f376/receiver_green.png?h=250',
    ...TYPE_CONFIRM,
    ...NEXT_STEP_RENDER_DEVICE_LIST_AFTER_POWER_CYCLE,
  },
];

export default SLT3;
