<template>
  <div>
    <ul class="ev-list">
      <li
        v-for="(photoName, index) in photoNames"
        v-bind:key="index"
        class="with-ticks"
        v-bind:class="{uploaded: isPhotoUploaded(index)}"
        v-text="photoName"
      />
    </ul>
  </div>
</template>

<script>

const component = {
  props: {
    photoNames: {
      type: Array,
      required: true,
      default() { return []; },
    },
    uploadsCount: {
      required: true,
    },
  },
  methods: {
    isPhotoUploaded(index) {
      return index < this.uploadsCount;
    },
  },
};

export default component;
</script>
