import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const HEATING_PLUS_OPTIONS = ['annual', 'monthly', 'no', 'always'];
// invert key=>value pairs to value=>key
const invert = obj => Object.fromEntries(Object.entries(obj).map(([k, v]) => [v, k]));

const mutations = {
  ...baseMutations,
  setValveSku(state, sku) {
    Vue.set(state, 'sku', sku);
  },
  setHubSku(state, sku) {
    Vue.set(state, 'hubSku', sku);
  },
  setConditionOption(state, option) {
    Vue.set(state, 'conditionOption', option);
  },
  setConditionOptionSkuMappings(state, conditionOptionSkuMappings) {
    Vue.set(state, 'newToRefurbishedSkuMappings', { ...conditionOptionSkuMappings });
    Vue.set(state, 'refurbishedToNewSkuMappings', { ...invert(conditionOptionSkuMappings) });
  },
  setHeatingPlusSkuMappings(state, heatingPlusSkuMappings) {
    Vue.set(state, 'heatingPlusSkuMappings', { ...heatingPlusSkuMappings });
  },
  setHeatingPlusType(state, heatingPlusType) {
    if (!HEATING_PLUS_OPTIONS.includes(heatingPlusType)) throw new Error(`Invalid heating plus type: ${heatingPlusType}`);

    Vue.set(state, 'heatingPlusType', heatingPlusType);
  },
};

export const moduleGetters = {
  ...baseGetters,
  pricingPayload({
    sku, hubSku, quantity, heatingPlusType, heatingPlusSkuMappings,
  }) {
    const products = [{ sku, quantity }];

    if (heatingPlusType !== 'no') {
      products.push({ sku: heatingPlusSkuMappings[heatingPlusType], quantity: 1 });
    }

    if (hubSku) products.push({ sku: hubSku, quantity: 1 });

    return { products, sku };
  },
  basketPayload(state) {
    const {
      sku,
      hubSku,
      quantity,
      heatingPlusType,
      heatingPlusSkuMappings,
    } = state;

    const skus = [{ sku, quantity }];

    if (hubSku) {
      skus.push({ sku: hubSku, quantity: 1 });
    }

    if (heatingPlusType !== 'no') {
      skus.push({ sku: heatingPlusSkuMappings[heatingPlusType], quantity: 1 });
    }

    const productBuy = { product: { sku: skus } };

    return { productBuy, quantity: 1 };
    // asd
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  init({ commit, dispatch }, {
    sku,
    hubSku,
    conditionOptionSkuMappings,
    heatingPlusSkuMappings,
    heatingPlusType,
  }) {
    commit('setValveSku', sku);
    commit('setHubSku', hubSku);
    commit('setConditionOptionSkuMappings', conditionOptionSkuMappings);
    commit('setHeatingPlusSkuMappings', heatingPlusSkuMappings);
    commit('setHeatingPlusType', heatingPlusType);

    return dispatch('loadPricing');
  },
  setHubSku({ commit, dispatch }, sku) {
    commit('setHubSku', sku);

    return dispatch('loadPricing');
  },
  setValveSku({ commit, dispatch }, sku) {
    commit('setValveSku', sku);

    return dispatch('loadPricing');
  },
  setConditionOption({ commit, dispatch, state }, selected) {
    const { sku, newToRefurbishedSkuMappings, refurbishedToNewSkuMappings } = state;
    // if selected is 'new' then prev state was refurbished
    // and we need to use refurbished mapping
    const newSku = selected === 'new' ? refurbishedToNewSkuMappings[sku] : newToRefurbishedSkuMappings[sku];

    commit('setConditionOption', selected);
    commit('setValveSku', newSku);
    window.specEvents.push(selected);

    return dispatch('loadPricing');
  },
  setHeatingPlusType({ commit, dispatch }, selected) {
    commit('setHeatingPlusType', selected);
    window.specEvents.push(selected);

    return dispatch('loadPricing');
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      quantity: 1,
      sku: 'HCD00487',
      hubSku: null,
      pricing: {},
      conditionOption: 'new',
      heatingPlusSkuMappings: {},
      heatingPlusType: 'annual',
      newToRefurbishedSkuMappings: {},
      refurbishedToNewSkuMappings: {},
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
