import { NumberFormat } from 'utils/intl';

const component = {
  inject: ['config'],
  props: {
    currency: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    locale: {
      type: String,
    },
    dropTrailingZeros: {
      type: Boolean,
      default: true,
    },
  },
  template: '<span v-html="formattedValue"></span>',
  computed: {
    formattedValue() {
      const {
        dropTrailingZeros,
        value,
        config,
        currency = config.currency.code,
        locale = config.locale,
      } = this;

      return new NumberFormat(locale, {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency,
        minimumFractionDigits: (dropTrailingZeros && value % 1 === 0) ? 0 : 2,
      }).format(value);
    },
  },
};
export default component;
