import DevTools from 'components/views/dev_tools';

const instance = {
  el: '#vue-sidebar-instance',
  name: 'sidebar',
  components: {
    'dev-tools': DevTools,
  },
};

export default instance;
