const component = {
  functional: true,
  props: ['show'],
  render(h, ctx) {
    // remove unnecessary text nodes
    const children = ctx.children.filter(vnode => vnode.tag);

    if (children.length !== 1) {
      throw new Error('with-root component accepts only one root node in its slot');
    }

    if (ctx.props.show) {
      return children[0];
    }

    return children[0].children;
  },
};

export default component;
