
import logger from 'utils/logger';

import run from './run';

/**
 * Bind an existing DOM element to a Vue instance
 *
 * @param {*} instance.selector, valid [CSS selector](https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Selectors)
 * @param {*} options.strict bind instance only if page has inline template
 * @param {*} options.monitor reload instance on dom changes
 *
 */
function bind(instance = {}, options = {}, store, provide) {
  const {
    el: selector,
    ...rest
  } = instance;

  const elements = document.querySelectorAll(selector);

  if (elements.length === 0) {
    logger.error(`No element matching ${selector}.`);
    return;
  }

  if (elements.length > 1) {
    logger.error(`Multiple elements match selector ${selector}.`);
    return;
  }

  const element = elements[0];

  if (options.strict) {
    const templates = element.querySelectorAll('[inline-template]');
    if (templates.length === 0) {
      // element does not have inline templates
      // we do not have to load the instance
      return;
    }
  }

  run({
    ...rest,
    store,
    provide,
    el: selector,
  }, options);

  // logger.debug(`A Vue instance has been created for ${selector}.`, options);
}

export default bind;
