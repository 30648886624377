import { createNamespacedHelpers } from 'vuex';

// Apply Vue dynamics to the following components
import Redirect from 'components/base/redirect';
import InstallationStep from 'components/views/installation_step';
import RegistrationForm from 'components/views/forms/registration';
import ContactForm from 'components/views/forms/contact';
import BasketSummary from 'components/views/basket_summary';
import BasketSummaryTable from 'components/views/basket_summary_table';
import ShippingForm from 'components/views/forms/shipping';
import PaymentForm from 'components/views/forms/payment';
import BasketPage from 'components/pages/basket_page';
import HubOptionForm from 'components/views/forms/hub_option';
import ChannelOptionForm from 'components/views/forms/channel_option';

const { mapState } = createNamespacedHelpers('basket');
const { mapState: mapCheckoutState, mapActions: mapCheckoutActions } = createNamespacedHelpers('checkout');

const component = {
  inject: ['client', 'dataLayer'],
  props: {
    dmpgCartEvent: String,
    dmpgCartStateEvent: String,
    dmpgSubmitEvent: String,
    dmpgOkStatusCode: String,
    dmpgOkStatusMsg: String,
    dmpgPaymentFormError: String,
    defaultPaymentMethod: String,
  },
  mounted() {
    this.dataLayer.triggerCartEvent(this.dmpgCartEvent);
    this.$scrollTo('#active-checkout-step', 800, { offset: -150 });
    if (this.defaultPaymentMethod) {
      this.setPaymentMethod(this.defaultPaymentMethod);
    }
  },
  computed: {
    ...mapState({
      updating: 'updating',
      basket: 'basket',
    }),
    ...mapCheckoutState(['paymentMethod']),
  },
  methods: {
    ...mapCheckoutActions(['setPaymentMethod']),
  },
  components: {
    redirect: Redirect,
    'installation-step': InstallationStep,
    'basket-summary': BasketSummary,
    'basket-summary-table': BasketSummaryTable,
    'registration-form': RegistrationForm,
    'shipping-form': ShippingForm,
    'contact-form': ContactForm,
    'payment-form': PaymentForm,
    'basket-page': BasketPage,
    'hub-option-form': HubOptionForm,
    'channel-option-form': ChannelOptionForm,
  },
};

export default component;
