import baseConfig from './shared/quantity_select';

const vuexModule = {
  ...baseConfig,
  getters: {
    motionSensorsQty({ selected }) {
      if (selected === null || selected < 5) return 0;

      return 1;
    },
  },
};

export default vuexModule;
