/* eslint-disable */

$(document).ready(function() {
  $('body').on("click", "[data-launch-safespace]", function (e) {
    e.stopPropagation();
    isSafeSpaceAvailable(this);
    $('body').keyup(function (e) {
      e.stopPropagation();
      if (e.keyCode == 27) {
        destroySafeSpaceWidget();
      }
    });
  });
});

function isSafeSpaceAvailable() {
  $.ajax({
    url: "https://safespaces.azurewebsites.net/Home/IsAlive",
    success: function (result) {
      launchSafeSpace();
    },
    error: function () {
      //handle code to show error message
    }
  });
}
function launchSafeSpace() {
  var targetId = $('[data-launch-safespace]').data("targetElement");
  var targetEl = document.getElementById(targetId);
  $.ajax({
    url: "https://safespaces.azurewebsites.net",
    success: function (result) {
      hostWidgetInContainer(result, targetEl);
    },
  });
}

function hostWidgetInContainer(result, targetEl) {
  var resultObj = $('<div/>').html(result).contents();
  var widgetObj = resultObj.find('div#safeSpaceWidget-Component');
  $(widgetObj).insertAfter($(targetEl).children().last());
  targetEl.style.overflow = "hidden";
}
function destroySafeSpaceWidget() {
  var widgetContainer = document.getElementById('safeSpaceWidget-Component');
  if (widgetContainer) {
    widgetContainer.parentNode.style.overflow = "";
    widgetContainer.parentNode.removeChild(widgetContainer);
  }
}

/* eslint-enable */
