import {
  TYPE_FUNCTION,
  TYPE_CONFIRM,
  TYPE_QUESTION,
  NEXT_STEP_NO_CREATE_CASE,
  NEXT_STEP_RENDER_DEVICE_LIST_AFTER_FACTORY_RESET,
  ACTION_RESET_DEVICE,
  ACTION_TO_UP_MODE,
  ACTION_SET_TO_DISCOVERY_MODE,
} from '../../constants';

const THERMOSTAT_OFFLINE = [
  {
    id: 1,
    ...TYPE_FUNCTION,
    ...ACTION_RESET_DEVICE,
    nextStep: 2,
  },
  {
    id: 2,
    ...TYPE_CONFIRM,
    text: 'You will now need to factory reset the device. Please follow the next steps',
    button: 'Next',
    nextStep: 3,
  },
  {
    id: 3,
    ...TYPE_CONFIRM,
    text: "Did you complete the FR steps and is your Thermostat now showing 'searching' status?",
    nextStep: 4,
  },
  {
    id: 4,
    ...TYPE_QUESTION,
    text: 'Thermostat in Searching mode?',
    nextStepYes: 5,
    ...NEXT_STEP_NO_CREATE_CASE,
  },
  {
    id: 5,
    text: 'Pairing device',
    ...TYPE_FUNCTION,
    ...ACTION_SET_TO_DISCOVERY_MODE,
    nextStep: 6,
  },
  {
    id: 6,
    ...TYPE_QUESTION,
    text: 'Hub in discovering mode?',
    nextStepYes: 7,
    ...NEXT_STEP_NO_CREATE_CASE,
  },
  {
    id: 7,
    ...TYPE_QUESTION,
    text: 'Themostat welcome message?',
    nextStepYes: 8,
    ...NEXT_STEP_NO_CREATE_CASE,
  },
  {
    id: 8,
    ...TYPE_FUNCTION,
    ...ACTION_TO_UP_MODE,
    nextStep: 9,
  },
  {
    id: 9,
    text: 'The device is now online! Check the list!',
    ...TYPE_CONFIRM,
    ...NEXT_STEP_RENDER_DEVICE_LIST_AFTER_FACTORY_RESET,
  },
];

export default THERMOSTAT_OFFLINE;
