import { createNamespacedHelpers } from 'vuex';
import Notifications from 'components/base/notifications';
import MyAccountHeader from 'components/base/my_account_header';
import EditableContactForm from 'components/views/forms/editable_contact';
import Multipage from 'mixins/multipage';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');

const component = {
  mixins: [Multipage],
  props: {
    validation: Object,
  },
  data() {
    return {};
  },
  methods: {
    ...mapGlobalActions([
      'showNotice',
      'showError',
    ]),
  },
  components: {
    'editable-contact-form': EditableContactForm,
    Notifications,
    MyAccountHeader,
  },
};

export default component;
