import logger from 'utils/logger';
import debounce from 'lodash/debounce';
import * as Cookies from 'tiny-cookie';

// HWS-7001
//
// Add extra data- attributes for tracking to to all link like elements
// on the page.
// This is a low dependency standalone class that inteligently decorates
// DOM, even when it changes. It only depends on strategic placement and
// generation of data-widget and data-vue-component-name attributes in
// templates.
//
// Debouncing and performance is taken into consideration.
//
// This is not something that lends itself to a Vue approach. To do this
// within Vue components would mean absolutely everything on the site would
// have to be interconnected Vue components, which is not realistic. Also it
// would mean this analytics stuff would be tighly integrated and mixed with
// the business and interface logic. We don't want that.
//
// JS snippets to use from within JS console for dev and QA to visually
// represent the data on the page:
//
//  dmpgLinkAttributes.showLabels();
//
//  dmpgLinkAttributes.hideLabels();
//
export default class DmpgLinkAttributes {
  constructor(doc = document) {
    this.document = doc;

    this.debugLabelEls = [];

    // Build the axis to use for computing analytics-placement
    this.pageDimensions = {
      halfWidth: this.document.body.clientWidth / 2,
      halfHeight: this.document.body.clientHeight / 2,
    };

    this.labelsVisibilityCookieName = '_dmpg_debug_labels_shown';
    this.labelsShown = Cookies.get(this.labelsVisibilityCookieName) === 'true';

    const instance = this;
    this.debouncedDecorate = debounce(
      instance.decorate.bind(instance),
      600,
    );
  }

  onPageLoad() {
    this.redecorateOnDomChanges();
    this.decorate();
  }

  updateLabelsCookie() {
    Cookies.set(
      this.labelsVisibilityCookieName,
      this.labelsShown.toString(),
      { expires: '7d' },
    );
  }

  showLabels() {
    this.labelsShown = true;
    this.updateLabelsCookie();
    this.decorate();
  }

  hideLabels() {
    this.labelsShown = false;
    this.updateLabelsCookie();
    this.decorate();
  }

  collectElements() {
    this.elements = this.document.querySelectorAll(
      'a,\
      button,\
      select,\
      input[type=submit],\
      .hive-product-button,\
      .custom-control-label',
    );
  }

  redecorateOnDomChanges() {
    this.observer = new MutationObserver(this.debouncedDecorate);
    this.resumeDomWatching();
  }

  resumeDomWatching() {
    if (this.observer) {
      // Starts listening for changes in the root HTML element of the page
      this.observer.observe(this.document.documentElement, {
        // Don't watch for el. visibility changes
        attributes: false,
        // attributeFilter: ['style', 'class'],
        // Watch for changes to DOM tree
        childList: true,
        subtree: true,
        // Rest of the stuff we don't care about
        characterData: false,
        attributeOldValue: false,
        characterDataOldValue: false,
      });
    }
  }

  pauseDomWatching() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  removeDebugLabels() {
    const instance = this;

    ['analytics-debug-container-outline', 'analytics-debug-link-outline'].forEach((className) => {
      instance.document.querySelectorAll(`.${className}`).forEach((el) => {
        el.classList.remove(className);
      });
    });

    this.debugLabelEls.forEach((debugLabelEl) => {
      debugLabelEl.parentNode.removeChild(debugLabelEl);
    });
    this.debugLabelEls = [];
  }

  decorate() {
    if (this.labelsShown) {
      logger.info('DmpgLinkAttributes: Decorating elements...');
    }

    // Turn off DOM observing, because decoration changes the DOM
    this.pauseDomWatching();

    // First clear any previous existing debug labels
    this.removeDebugLabels();

    this.collectElements();

    // We don't want to loose 'this', use for loop
    for (let i = 0; i < this.elements.length; i++) {
      this.currentEl = this.elements[i];

      // Assign the data-analytics- attributes,
      // DMPG will "most likely" capture these in some click event in
      // tag manager.
      this.currentEl.dataset.analyticsWidgetId = this.findContainerId();
      this.currentEl.dataset.analyticsCtaId = this.findCtaId();
      this.currentEl.dataset.analyticsCtaPlacement = this.findPlacement();

      // For those wishing to debug
      if (this.labelsShown) {
        this.generateVisualDebugLabel();
      }

      // Cleanup of the loop
      this.currentEl = null;
      this.currentParentEl = null;
      this.currentOffset = null;
    }

    // We can resume DOM watching since all DOM changes are done
    this.resumeDomWatching();

    if (this.labelsShown) {
      logger.info('DmpgLinkAttributes: Decorating done.');
    }
  }

  generateVisualDebugLabel() {
    // Outline the affected elements
    this.currentEl.classList.add('analytics-debug-link-outline');

    if (this.currentParentEl) {
      this.currentParentEl.classList.add('analytics-debug-container-outline');
    }

    if (this.currentEl
        && this.currentEl.dataset.analyticsWidgetId !== 'null'
        && this.currentEl.dataset.analyticsWidgetId !== 'megamenu'
        && this.currentOffset.top !== 0
        && this.currentOffset.left !== 0) {
      const debugLabel = this.document.createElement('div');

      // Keep a list of all the labels
      this.debugLabelEls.push(debugLabel);

      debugLabel.innerHTML = `${this.currentEl.dataset.analyticsWidgetId} /\
        ${this.currentEl.dataset.analyticsCtaId}\
        <br>\
        (${this.currentEl.dataset.analyticsCtaPlacement})`;

      debugLabel.className = 'analytics-debug-label';

      debugLabel.style.position = this.currentElLabelStylePosition();

      debugLabel.style.top = `${this.currentOffset.top - 15}px`;
      debugLabel.style.left = `${this.currentOffset.left}px`;

      this.document.body.appendChild(debugLabel);
    }
  }

  currentElLabelStylePosition() {
    if (this.isFixedEl()) {
      return 'fixed';
    }
    return 'absolute';
  }

  isFixedEl() {
    const fixedWidgets = ['sidebar', 'navbar', 'product-subnav'];
    return fixedWidgets.includes(this.currentEl.dataset.analyticsWidgetId);
  }

  findCtaId() {
    // Hardcoded id
    if (this.currentEl.dataset.analyticsCtaId) {
      return this.currentEl.dataset.analyticsCtaId;
    }

    // Fallback can be the parameterized button text
    if (this.currentEl.textContent) {
      return this.constructor.parameterize(this.currentEl.textContent);
    }

    // If child is an img, and there is and "alt" attr
    for (let i = 0; i < this.currentEl.childNodes.length; i++) {
      const el = this.currentEl.childNodes[i];
      if (el.tagName && el.tagName.toLowerCase() === 'img' && el.alt) {
        return this.constructor.parameterize(el.alt);
      }
    }

    // Fallback to href
    if (this.currentEl.href) {
      return this.constructor.parameterize(this.currentEl.href);
    }

    return '';
  }

  static parameterize(text) {
    return text
      .toLowerCase()
      // Replace some hardcoded/generated domain names
      .replace(`${window.location.protocol}//${window.location.hostname}:${window.location.port}`, '')
      .replace('www.hivehome.com', '')
      .replace('hivehome.com', '')
      .replace('https://', '')
      .replace('http://', '')
      .trim()
      // Remove all non alphanumeric chars except dash, plus sign
      .replace(/[^a-zA-Z0-9 \-\+]/, '')
      // Remove all splaces
      .replace(/\s/g, '-')
      // Remove duplicate dashes
      .replace(/-{2,}/g, '-')
      // Max key size is 50
      .substr(0, 50);
  }

  // Widgets generate `data-widget` containing it's name.
  // Vue components append `data-vue-component-name` during mounting.
  // We use these to generate everything automatically.
  findContainerId() {
    let node = this.currentEl.parentNode;

    while (node) {
      if (node.dataset && (node.dataset.widget || node.dataset.vueComponentName)) {
        // Allows us to know currentEl and it's parent at the same time elsewhere
        this.currentParentEl = node;

        if (node.dataset.widget) {
          return node.dataset.widget;
        }

        if (node.dataset.vueComponentName) {
          return node.dataset.vueComponentName;
        }
      }

      node = node.parentNode;
    }

    return 'page';
  }

  findPlacement() {
    let verticalPosition = 'top';
    let horizontalPosition = 'left';

    this.currentOffset = this.offset();

    if (this.currentOffset.top > this.pageDimensions.halfHeight) {
      verticalPosition = 'bottom';
    }

    if (this.currentOffset.left > this.pageDimensions.halfWidth) {
      horizontalPosition = 'right';
    }

    return [verticalPosition, horizontalPosition].join('-');
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
  offset() {
    const rect = this.currentEl.getBoundingClientRect();

    // Elements sitting in a fixed position are not affected by sroll
    if (this.isFixedEl()) {
      return {
        top: rect.top,
        left: rect.left,
      };
    }

    // getBoundingClientRect is relative to viewport, so we need
    // to add the scroll position for all other els
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
  }
}
