const template = `
<transition
  v-bind:appear="enabled"
  v-bind:enter-active-class="classes"
  v-on:after-enter="$emit('complete')"
>
  <div v-bind:style="{ 'animation-delay': delay, 'animation-duration': speed }">
    <slot></slot>
  </div>
</transition>
`;

const makeComponent = classes => ({
  template,
  props: {
    classes: { type: String, default: classes },
    enabled: { type: Boolean, default: true },
    speed: { type: String, default: '.8s' },
    delay: { type: String },
  },
});

export default makeComponent;
