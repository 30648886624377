<template>
  <div class="mt-2" v-cloak>
    <span
      v-for="starIndex in numberOfFullStars"
      v-bind:key="`full-star-${starIndex}`"
      class="star d-inline-block"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        v-bind:width="starWidth"
        v-bind:height="starHeight"
        viewBox="0 0 24 24"
      >
        <polygon
          fill="#FFB936"
          fill-rule="evenodd"
          points="12 18 4.947 21.708 6.294 13.854 .587 8.292 8.473 7.146 12 0
          15.527 7.146 23.413 8.292 17.706 13.854 19.053 21.708"
        />
      </svg>
    </span>
    <span
      v-for="starIndex in numberOfHalfStars"
      v-bind:key="`half-star-${starIndex}`"
      class="star d-inline-block"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        v-bind:width="starWidth"
        v-bind:height="starHeight"
        viewBox="0 0 24 24"
      >
        <g fill="none" fill-rule="evenodd">
          <polygon
            fill="#E0E4E6"
            points="12 18 4.947 21.708 6.294 13.854 .587 8.292 8.473 7.146 12 0 15.527 7.146
            23.413 8.292 17.706 13.854 19.053 21.708"
          />
          <path
            fill="#FFB936"
            d="M12,1.77635684e-15 L12,18 L4.94657697,21.7082039 L6.2936609,13.854102
            L0.587321804,8.29179607 L8.47328849,7.14589803 L12,1.77635684e-15 Z"
          />
        </g>
      </svg>
    </span>
    <span
      v-for="starIndex in numberOfEmptyStars"
      v-bind:key="`empty-star-${starIndex}`"
      class="star d-inline-block"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        v-bind:width="starWidth"
        v-bind:height="starHeight"
        viewBox="0 0 24 24"
      >
        <polygon
          fill="#E0E4E6"
          fill-rule="evenodd"
          points="12 18 4.947 21.708 6.294 13.854 .587 8.292 8.473 7.146 12 0
          15.527 7.146 23.413 8.292 17.706 13.854 19.053 21.708"
        />
      </svg>
    </span>
  </div>
</template>

<script>
import range from 'lodash/range';

const component = {
  props: {
    name: 'configurable-star-rating',
    rating: {
      required: true,
      type: Number, // one of 0, 0.5, 1, 1.5, ..., 5
      validator: value => range(0, 5, 0.5).includes(value),
    },
    size: {
      required: true,
      type: String,
    },
  },
  computed: {
    numberOfFullStars() {
      return Math.floor(this.rating);
    },
    numberOfHalfStars() {
      if (this.numberOfFullStars === this.rating) return 0;

      return 1;
    },
    numberOfEmptyStars() {
      return 5 - this.numberOfFullStars - this.numberOfHalfStars;
    },
    starWidth() {
      return this.size.split('x')[0];
    },
    starHeight() {
      return this.size.split('x')[1];
    },
  },
};

export default component;
</script>
