import camelCase from 'lodash/camelCase';
import Walker from 'mixins/walker';
import Carousel from 'components/views/carousel';
import Card from '../views/savings/card';

const SMALL_HOUSE_SIZE = 2; // house with more than 2 rooms is large

const component = {
  props: ['heroImages', 'benefitsImages', 'homeSizeImages'],
  mixins: [Walker],
  data() {
    return {
      heroProducts: null,
      intro2TopLevelGoal: null,
      intro5TieTogether: null,
      intro6UseCase: null,
      heroImageURL: null,
      animationClass: null,
      blocks: {},
      features: [],
      formInfo: {
        rooms: null,
        reason: null,
        resultsReason: null,
        answers: {
          savingMoney: [],
          control: [],
          peaceOfMind: [],
          enhanceHome: [],
        },
      },
    };
  },
  computed: {
    shortAnswers() {
      const selectedAnswers = this.formInfo.answers[this.formInfo.reason];
      if (this.formInfo.reason === null || selectedAnswers === []) {
        return [];
      }
      const shortAnswers = [];
      const { reason } = this.formInfo;

      selectedAnswers.forEach((item) => {
        shortAnswers.push(this.variants[reason][item].short);
      });

      return shortAnswers;
    },
    whatsRightForMeData() {
      return this.$store.getters.whatsRightForMe;
    },
    variants() {
      return this.whatsRightForMeData.variants;
    },
    productsInfo() {
      return this.whatsRightForMeData[this.formInfo.reason];
    },
    selectedProductsKey() {
      return camelCase(this.formInfo.answers[this.formInfo.reason].sort().join('_'));
    },
    infoForSelectedProduct() {
      return this.productsInfo.products[this.selectedProductsKey];
    },
  },
  methods: {
    setAsActive(key, value) {
      this.formInfo[key] = value;
    },
    isActive(key, value) {
      return this.formInfo[key] === value;
    },
    discover() {
      this.formInfo.reason = this.formInfo.resultsReason;
      this.formInfo.resultsReason = null;
      this.animationClass = 'animated';
      this.setStage(3);
      window.scrollTo(0, 0);
    },
    isLargerHouse() {
      return this.formInfo.rooms > SMALL_HOUSE_SIZE;
    },
    addHouseBlock() {
      let homeDescription = '';
      let homeImage = '';
      let homeTitle = '';

      if (this.isLargerHouse()) {
        homeTitle = this.whatsRightForMeData.largerHomeTitle;
        homeDescription = this.infoForSelectedProduct.largerHome;
        homeImage = 'Larger_Home';
      } else {
        homeTitle = this.whatsRightForMeData.smallerHomeTitle;
        homeDescription = this.productsInfo.smallerHome;
        homeImage = 'Smaller_Home';
      }

      this.blocks[homeTitle] = {
        description: homeDescription,
        image: this.homeSizeImages[homeImage],
      };
    },
    processResult() {
      this.heroProducts = this.infoForSelectedProduct.intro4Products;
      this.intro5TieTogether = this.infoForSelectedProduct.intro5TieTogether;
      this.intro6UseCase = this.infoForSelectedProduct.intro6UseCase;
      this.intro2TopLevelGoal = this.productsInfo.intro2TopLevelGoal;
      this.heroImageURL = this.heroImages[this.infoForSelectedProduct.heroImageUrl];

      this.blocks = {};

      this.infoForSelectedProduct.features.forEach((item) => {
        this.blocks[item] = {
          description: this.productsInfo.featureDescriptions[camelCase(item)],
          image: this.benefitsImages[item.replace(/ /g, '_')],
        };
      });

      this.addHouseBlock();
      this.setStage(6);
    },
  },
  components: {
    Card,
    'owl-carousel': Carousel,
  },
};

export default component;
