import IntlPolyfill from 'intl';
import 'intl/locale-data/jsonp/en';

// Intl is missing in Safari 9.
if (!global.Intl) {
  global.Intl = IntlPolyfill;
}

const { NumberFormat } = global.Intl;

export { NumberFormat };

export default global.Intl;
