import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapBasketActions } = createNamespacedHelpers('basket');

const component = {
  props: {
    initialChannelOption: String,
  },
  data() {
    return {
      channelOption: this.initialChannelOption,
      isLoading: false,
    };
  },
  methods: {
    ...mapBasketActions(['setChannelOption']),
    async onChangeChannelOption() {
      if (this.isLoading) return;

      this.isLoading = true;
      await this.setChannelOption(this.channelOption);

      window.specEvents.push('checkout-channel-option-changed');
      this.isLoading = false;
    },
  },
  created() {
    this.onChangeChannelOption();
  },
};

export default component;
