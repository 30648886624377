import CountdownTimer from 'components/base/countdown_timer';
import NavbarBasket from 'components/base/navbar_basket';
import SubnavBasket from 'components/base/subnav_basket';
import MyAccountSideMenu from 'components/views/my_account_side_menu';

// generic navbar item without badge
import NavbarItem from 'components/base/navbar_item';
import SubnavbarItem from 'components/base/subnavbar_item';
import { eventEmitter } from 'utils/run';
import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');

const component = {
  // `name` is useful for generating the correct data-component-name
  // for DMPG analytics purposes.
  name: 'navbar',
  data() {
    return {
      submenu: undefined,
      isTouchDevice: false,
      isCollapsed: false,
      isMobileNavOpen: false,
      isBelowHero: false,
      isMyAccountNavOpen: false,
    };
  },
  watch: {
    isCollapsed(newVal, oldVal) {
      if (newVal !== oldVal) this.setCollapsed(newVal);
    },
  },
  methods: {
    ...mapGlobalActions([
      'setCollapsed',
    ]),
    onSubmenuShow(submenu) {
      this.submenu = submenu;
    },
    onSubmenuHide() {
      this.submenu = undefined;
    },
    handleTouchStart() {
      // This is how we detect a touch device, so now we know we can't use mouseenter
      // to open menus, rather only click is available on touch device.
      this.isTouchDevice = true;
    },
    handleScroll() {
      if (window.scrollY > 200) {
        this.isCollapsed = true;
      } else if (window.scrollY < 60) {
        this.isCollapsed = false;
      }

      if (window.scrollY > 800) {
        this.isBelowHero = true;
      } else if (window.scrollY < 700) {
        this.isBelowHero = false;
      }
    },
    onMobileNavToggle() {
      if (this.isMobileNavOpen) {
        this.isMobileNavOpen = false;
      } else {
        this.isMyAccountNavOpen = false;
        this.isMobileNavOpen = true;
      }
    },
    openMobileMyAccountNav() {
      this.isMyAccountNavOpen = true;
      this.isMobileNavOpen = true;

      eventEmitter.$emit('navOpenMobileMenu');
    },
  },
  components: {
    'countdown-timer': CountdownTimer,
    'navbar-basket': NavbarBasket,
    'navbar-item': NavbarItem,
    'subnavbar-item': SubnavbarItem,
    'subnav-basket': SubnavBasket,
    MyAccountSideMenu,
  },
  mounted() {
    document.addEventListener('touchstart', this.handleTouchStart);
    window.addEventListener('scroll', this.handleScroll);
    this.$nextTick(() => window.specEvents.push('navbar-mounted'));
  },
  beforeDestroy() {
    document.removeEventListener('touchstart', this.handleTouchStart);
    window.removeEventListener('scroll', this.handleScroll);
  },
};

export default component;
