// Apply Vue dynamics to the following components
import HdlPromotion from 'components/views/hdl_promotion';
import MyAccountSideMenu from 'components/views/my_account_side_menu';
import Navbar from 'components/views/navbar';
import ProductShowcase from 'components/views/product_showcase';

import AccountPage from 'components/pages/account_page';
import AddressesPage from 'components/pages/my/addresses_page';
import AppointmentsPage from 'components/pages/my/appointments_page';
import BasketPage from 'components/pages/basket_page';
import CheckoutConfirmationPage from 'components/pages/checkout_confirmation_page';
import CheckoutPage from 'components/pages/checkout_page';
import ContactUsPage from 'components/pages/contact_us_page';
import DevNewPaymentMethodPage from 'components/pages/dev_new_payment_method_page';
import DevPage from 'components/pages/dev_page';
import DiscoverHiveHubPage from 'components/pages/discover_hive_hub_page';
import EditAppointmentPage from 'components/pages/my/edit_appointment_page';
import ErrorWrapper from 'components/base/error_wrapper';
import FooterSection from 'components/sections/footer_section';
import HiveActionsPage from 'components/pages/hive_actions_page';
import HomePage from 'components/pages/home_page';
import HoneyBgRewardsPackBuyPage from 'components/pages/honey/bg_rewards_pack_buy_page';
import HoneyCameraBuyPage from 'components/pages/honey/camera_buy_page';
import HoneyCategoryPage from 'components/pages/honey/category_page';
import HoneyCustomPackBuyPage from 'components/pages/honey/custom_pack_buy_page';
import HoneyEvChargerBuyPage from 'components/pages/honey/ev_charger_buy_page';
import HoneyHeatingBuyPage from 'components/pages/honey/heating_buy_page';
import HoneyPdpPackBuyPage from 'components/pages/honey/pdp_pack_buy_page';
import HoneyHeatingPlusBuyPage from 'components/pages/honey/heating_plus_buy_page';
import HoneyHubUpgradeOfferBuyPage from 'components/pages/honey/hub_upgrade_offer_buy_page';
import HoneyHubPage from 'components/pages/honey/hub_page';
import HoneyHomeshieldStarterHomePackPage from 'components/pages/honey/homeshield_starter_home_pack_page';
import HoneyInstallationRequirementsPage from 'components/pages/honey/installation_requirements_page';
import HoneyPackBuyPage from 'components/pages/honey/pack_buy_page';
import HoneyPdpLightsBuyPage from 'components/pages/honey/pdp_lights_buy_page';
import HoneyPdpSensorBuyPage from 'components/pages/honey/pdp_sensor_buy_page';
import HoneyPdpPlaybackBuyPage from 'components/pages/honey/pdp_playback_buy_page';
import HoneyRadiatorValveBuyPage from 'components/pages/honey/radiator_valve_buy_page';
import HoneyShopCategories from 'components/pages/honey/shop_categories_page';
import HoneySimpleProductBuyPage from 'components/pages/honey/simple_product_buy_page';
import HoneyBarclaysHsAdvancedPage from 'components/pages/honey/barclays_hs_advanced_page';
import HoneyBarclaysHsStarterPage from 'components/pages/honey/barclays_hs_starter_page';
import InstoreVoucherPage from 'components/pages/instore_voucher_page';
import InvoicesPage from 'components/pages/my/invoices_page';
import MyPaymentPage from 'components/pages/my_payment_page';
import MyPersonalInfoPage from 'components/pages/my/personal_info_page';
import NewPaymentMethodPage from 'components/pages/new_payment_method_page';
import OrdersPage from 'components/pages/my/orders_page';
import OtherSmartProductsPage from 'components/pages/other_smart_products_page';
import PaymentMethodsPage from 'components/pages/my/payment_methods_page';
import PricesWithGoToBasket from 'components/views/prices_with_go_to_basket';
import ProductBuyPage from 'components/pages/product_buy_page';
import ProductGuidesPage from 'components/pages/product_guides_page';
import ProductOverviewPage from 'components/pages/product_overview_page';
import ProductShowPage from 'components/pages/product_show_page';
import ReactivateHiveLeakPage from 'components/pages/reactivate_hive_leak_page';
import RedemptionPage from 'components/pages/redemption_page';
import SubscriptionsPage from 'components/pages/my/subscriptions_page';
import SupportArticlePage from 'components/pages/support_article_page';
import WhatsRightForMe from 'components/pages/whats_right_for_me_page';
import UploadPhotos from 'components/pages/my/upload_photos';
import HoneyRegisterInterestPage from 'components/pages/honey/register_interest_page';
import HoneyRedemptionPage from 'components/pages/honey/redemption_page';
import HeatingOfflineFlows from 'components/pages/heating_offline_flows';

const instance = {
  el: '#vue-page-instance',
  name: 'page',
  components: {
    'account-page': AccountPage,
    'product-guides-page': ProductGuidesPage,
    'product-buy-page': ProductBuyPage,
    'product-show-page': ProductShowPage,
    'prices-with-go-to-basket': PricesWithGoToBasket,
    'other-smart-products-page': OtherSmartProductsPage,
    'product-overview-page': ProductOverviewPage,
    'checkout-page': CheckoutPage,
    'hive-actions-page': HiveActionsPage,
    'hdl-promotion': HdlPromotion,
    'instore-voucher-page': InstoreVoucherPage,
    'footer-section': FooterSection,
    'whats-right-for-me-page': WhatsRightForMe,
    'basket-page': BasketPage,
    'redemption-page': RedemptionPage,
    'checkout-confirmation-page': CheckoutConfirmationPage,
    'home-page': HomePage,
    'product-showcase': ProductShowcase,
    'support-article-page': SupportArticlePage,
    'error-wrapper': ErrorWrapper,
    navbar: Navbar,
    'dev-page': DevPage,
    'discover-hive-hub-page': DiscoverHiveHubPage,
    'my-payment-page': MyPaymentPage,
    'honey-installation-requirements-page': HoneyInstallationRequirementsPage,
    HoneyRadiatorValveBuyPage,
    HoneyPackBuyPage,
    HoneyPdpPackBuyPage,
    HoneyBgRewardsPackBuyPage,
    HoneyCustomPackBuyPage,
    HoneyCameraBuyPage,
    HoneyEvChargerBuyPage,
    HoneyHeatingBuyPage,
    HoneyHeatingPlusBuyPage,
    HoneyHubUpgradeOfferBuyPage,
    HoneyPdpLightsBuyPage,
    HoneyPdpSensorBuyPage,
    HoneyPdpPlaybackBuyPage,
    HoneyShopCategories,
    HoneySimpleProductBuyPage,
    HoneyBarclaysHsAdvancedPage,
    HoneyBarclaysHsStarterPage,
    HoneyHomeshieldStarterHomePackPage,
    HoneyRegisterInterestPage,
    'honey-category-page': HoneyCategoryPage,
    'honey-hub-page': HoneyHubPage,
    'new-payment-method-page': NewPaymentMethodPage,
    'dev-new-payment-method-page': DevNewPaymentMethodPage,
    PaymentMethodsPage,
    AddressesPage,
    OrdersPage,
    AppointmentsPage,
    EditAppointmentPage,
    SubscriptionsPage,
    InvoicesPage,
    ContactUsPage,
    ReactivateHiveLeakPage,
    MyAccountSideMenu,
    UploadPhotos,
    'my-personal-info-page': MyPersonalInfoPage,
    HoneyRedemptionPage,
    HeatingOfflineFlows,
  },
};

export default instance;
