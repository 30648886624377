import BasketVoucher from 'components/views/basket_voucher';

const component = {
  components: {
    'basket-voucher': BasketVoucher,
  },
  props: {
    on: {
      type: Boolean,
    },
  },
  data() {
    return {
      isOn: !!this.on,
    };
  },
  methods: {
    toggle() {
      this.isOn = !this.isOn;
    },
  },
};

export default component;
