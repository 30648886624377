const component = {
  template: `\
    <svg v-if="isLoading" v-bind:class="{ 'hive-loader-small': small, 'hive-loader-screen-center': center, 'hive-loader-inline': inline }" class="hive-loader" version="1.1" viewBox="0 0 285 273" xmlns="http://www.w3.org/2000/svg">
      <g fill="none">
        <path class="hive-loader-path-1" d="m175 2.1c-21.7-2.5-43.4-2.5-65.1 0l-32.4 3.7c-3.5 0.4-8.4 0.6-11.7 1.7s-7.1 4.2-7.1 9.5c0 4.6 3 8.3 7.1 9.5 3.3 0.9 8.2 0.9 11.7 0.9h130c3.5 0 8.4 0.1 11.7-0.9 4.1-1.2 7.1-4.9 7.1-9.5 0-5.3-3.8-8.4-7.1-9.5-3.2-1-8.2-1.3-11.7-1.7l-32.5-3.7z"/>
        <path class="hive-loader-path-2" d="m246.3 82c3.5-0.2 8.4-0.4 11.7-1.4 4.2-1.2 7.1-5.1 7.1-9.5s-3-8.3-7.1-9.5c-3.3-1-8.2-0.9-11.7-0.9h-207.5c-3.5 0-8.4-0.1-11.7 0.9-4.2 1.2-7.1 5.1-7.1 9.5s3 8.3 7.1 9.5c3.3 0.9 8.2 1.2 11.7 1.4l92.2 6.6c7.6 0.5 15.4 0.5 22.9 0l92.4-6.6z"/>
        <path class="hive-loader-path-3" d="m265.5 132.1c6.1-0.9 8.4-1.2 11.7-2.4 4.2-1.4 7.1-4.8 7.1-9.5 0-4.5-3-8.3-7.1-9.5-3.3-1-8.2-0.2-11.7 0.1l-109 11c-9.2 0.9-18.7 0.9-27.9 0l-108.9-11c-3.5-0.4-8.4-1.2-11.7-0.1-4.2 1.2-7.1 5.1-7.1 9.5 0 4.7 3 8.1 7.1 9.5 3.2 1.1 5.6 1.4 11.7 2.4l104.2 16.7c12.3 1.9 25 1.9 37.3 0l104.3-16.7z"/>
        <path class="hive-loader-path-4" d="m121.1 208.9c14.1 3.5 28.7 3.5 42.9 0l94.6-23.2c6-1.4 8.4-1.9 11.7-3 4.2-1.4 7.1-4.9 7.1-9.5 0-4.5-3-8.4-7.1-9.6-3.3-0.9-8.2 0.3-11.7 0.9l-102.3 18.3c-9 1.6-18.5 1.6-27.6 0l-102.2-18.3c-3.5-0.6-8.4-1.9-11.7-0.9-4.2 1.2-7.1 5.1-7.1 9.6 0 4.6 3 8.2 7.1 9.5 3.3 1.1 5.7 1.6 11.7 3l94.6 23.2z"/>
        <path class="hive-loader-path-5" d="m230.5 247c6.5-2.2 8.7-3 11.7-4.4 4-1.8 7.1-4.6 7.1-9.7 0-4.5-3-8.4-7.1-9.6-3.8-1.1-8.3 0.5-11.7 1.4l-64 17.2c-15.7 4.3-32.3 4.2-47.9 0l-63.9-17.2c-3.4-0.9-7.9-2.5-11.7-1.4-4.2 1.2-7.1 5.1-7.1 9.6 0 5.1 3.1 7.9 7.1 9.7 3 1.4 5.2 2.2 11.7 4.4l60.7 21.1c17.6 6.1 36.7 6.1 54.3 0l60.8-21.1z"/>
      </g>
    </svg>\
  `,
  props: ['isLoading', 'small', 'center', 'inline'],
};

export default component;
