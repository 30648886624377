import SmoothScroll from 'legacy/smooth_scroll';

$(document).on('click', '[data-scroll-to]', (event) => {
  const smoothScroll = new SmoothScroll(event.target);
  if (smoothScroll.perform()) { event.preventDefault(); }
});

if (window.location.hash) {
  window.scrollTo(0, 0); // to prevent default browser scrolling
}
