import { createNamespacedHelpers } from 'vuex';
import AddToBasketButton from 'mixins/add_to_basket_button';
import AddPackToBasket from 'mixins/add_pack_to_basket';

const { mapActions } = createNamespacedHelpers('basket');
const mixin = {
  mixins: [AddToBasketButton, AddPackToBasket],
  props: {
    packTitle: String,
    showInstallationPageAfterPurchase: Boolean,
    baseIncludedProducts: {
      type: Object,
      required: true,
      validator: obj => Object.values(obj).every(quantity => typeof quantity === 'number'),
    },
  },
  data() {
    return {
      includedProducts: this.baseIncludedProducts,
      homeshieldProInstallPrice: 0,
      quantityLocked: false,
    };
  },
  methods: {
    ...mapActions(['addToBasket', 'setHomeshieldOption', 'setInstallationOption']),
    onButtonClick() {
      const {
        includedProducts,
        sku,
        freeSku,
        homeshieldOption,
        installationOption,
        professionalInstallationSku,
        showInstallationPageAfterPurchase,
        packTitle,
      } = this;

      const productBuy = this.generatePackPayload(
        includedProducts,
        showInstallationPageAfterPurchase,
        sku,
        freeSku,
        homeshieldOption,
        installationOption,
        professionalInstallationSku
      );
      this.cookies.set('homeshield_pack_name', packTitle);
      this.addToBasket(
        {
          productBuy,
          quantity: 1,
          errorCallback: this.scrollToTop,
        }
      );
    },
  },
};

export default mixin;
