/**
 *
 * Class that abstracts away the underlying library to make ajax call.
 *
 * At the moment ajax communication relies on jQuery.ajax. We are planning to move to axios
 * or another more modern framework.
 *
 * We cannot remove jQuery.ajax right now because some tests depend on it.
 * However, since we are collecting and isolating all the jQuery calls here,
 * getting rid of jQuery should not be too hard.
 *
 */
import logger from 'utils/logger';

const promiseFinally = require('promise.prototype.finally');

function getPathPart(href) {
  const elem = document.createElement('a');
  elem.href = href;
  return elem.pathname;
}

export default class Ajax {
  static send(path, params) {
    const ajax = new Ajax(path);
    const props = ['method', 'params', 'data', 'crossdomain', 'format'];

    props.forEach((prop) => {
      ajax[prop] = params[prop];
    });

    return ajax.send();
  }

  constructor(path) {
    this.path = path;

    this._data = {};
  }

  get queryString() {
    if (!this.params) { return ''; }

    return Object.keys(this.params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.params[key])}`)
      .join('&');
  }

  get url() {
    let { path: url } = this;
    const { queryString } = this;

    if (queryString) { url += `?${queryString}`; }

    return url;
  }

  set method(value) {
    this._method = value;
  }

  get method() {
    return (this._method || 'get').toUpperCase();
  }

  isGet() {
    return this.method === 'GET';
  }

  get contentType() {
    if (this.data instanceof FormData) { return false; }

    return this.format === 'js' ? 'text/javascript' : 'application/json';
  }

  get dataType() {
    return this.format === 'js' ? 'script' : 'json';
  }

  set data(value) {
    if (value && this.isGet()) {
      logger.info('data is ignored on GET requests.');
    }
    this._data = value;
  }

  get data() {
    // send a FormData object if it passed
    if (this._data instanceof FormData) { return this._data; }

    // Returning empty hash just not to send ?null as params
    // Since we always send JSON requests we can always use `JSON.stringify`
    return this._data === null ? '{}' : JSON.stringify(this._data);
  }

  fireSpecEvent() {
    if (window && window.specEvents) {
      const fullPath = getPathPart(this.path);
      window.specEvents.push(fullPath);
    }
  }

  send() {
    // In jQuery >= 3 ajax function returns Promise
    const settings = {
      type: this.method,
      contentType: this.contentType,
      dataType: this.dataType,
      processData: false,
      crossdomain: this.crossdomain,
    };

    if (!this.isGet()) {
      settings.data = this.data;
    }

    settings.beforeSend = (xhr, config) => {
      if (settings.dataType === 'script') {
        xhr.setRequestHeader('accept', `*/*;q=0.5, ${config.accepts.script}`);
      }

      if (settings.type !== 'GET') {
        const csrfToken = $('meta[name="csrf-token"]').attr('content');
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      }
    };

    // add `finally()`
    promiseFinally.shim();

    // wrap in Promise to make sure `finally()` is available
    return new Promise((resolve, reject) => {
      $.ajax(this.url, settings).done(resolve).fail(reject);
    }).finally(this.fireSpecEvent.bind(this));
  }
}
