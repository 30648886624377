import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapBasketActions } = createNamespacedHelpers('basket');

const component = {
  props: {
    initialHubOption: {
      type: String,
      default: 'hub',
    },
  },
  data() {
    return {
      hubOption: this.initialHubOption,
      isLoading: false,
    };
  },
  methods: {
    ...mapBasketActions(['switchHubOption']),
    async onChangeHubOption() {
      if (this.isLoading) return;

      this.isLoading = true;
      await this.switchHubOption(this.hubOption);

      window.specEvents.push('checkout-hub-option-changed');
      this.isLoading = false;
    },
  },
  created() {
    this.onChangeHubOption();
  },
};

export default component;
