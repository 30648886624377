import logger from 'utils/logger';

import getInitState from './get_init_state';

/**
 * Returns the state to initialize Vuex.
 * Some checks on the state health are done.
 */
function prepareState() {
  try {
    const initState = getInitState();
    return {
      ...initState,
      __meta__: {
        healthy: true,
      },
    };
  } catch (error) {
    logger.error('Cannot init VueX state: inline JSON is malformed', error);
    return {
      __meta__: {
        error,
        healthy: false,
      },
    };
  }
}

export default prepareState;
