/* eslint no-console:0 */
/* eslint-disable import/prefer-default-export */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// IMPORTANT: this file should be uploaded at the bottom of body when content is uploaded.
// views/layouts/application.html.erb

import init from 'init';
import bind from 'utils/bind';

import Footer from 'instances/footer';
import Header from 'instances/header';
import Page from 'instances/page';
import Modal from 'instances/modal';
import Sidebar from 'instances/sidebar';
import * as cookies from 'tiny-cookie';
import eventBus from 'instances/event_bus';

import 'owl.carousel';

// load animation effects
import 'utils/hive_animation';

// import globals
import 'components/global';

// imort legacy stuff
import '../legacy/add_to_basket_quantity_selector';
import '../legacy/animated_hero';
import '../legacy/carousel_swipes';
import '../legacy/collapse_with_chevron';
import '../legacy/collapsible_list';
import '../legacy/compatibility_checker';
import '../legacy/dmpg_bindings';
import '../legacy/hive_loader_bindings';
import '../legacy/product_frame_switcher';
import '../legacy/product_variant_switcher';
import '../legacy/safe_spaces';
import '../legacy/smooth_scroll_bindings';
import '../legacy/toggle_hidden_class';
import '../legacy/tooltip';
import '../legacy/video_playback_subscription';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// bind instances to existing dom only after init process is completed
init().then(({
  client, store, dataLayer, config,
}) => {
  const provide = () => ({
    client,
    cookies,
    dataLayer,
    jQuery: window.$,
    config,
    eventBus,
  });

  bind(Footer, {}, store, provide);
  bind(Header, {}, store, provide);
  bind(Modal, {}, store, provide);
  bind(Sidebar, {}, store, provide);
  bind(Page, {
    monitor: true, // reload instance on dom change (e.g. erb.js responses)
    // strict: true, // bind instance only if page has inline templates
  }, store, provide);
});

// Make test triggers available in Packs.application.__TestTriggers__
// See utils/test_mappings for more information
export { triggers as __TestTriggers__ } from 'utils/bridge';
