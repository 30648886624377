import { createNamespacedHelpers } from 'vuex';
import RequiredProductChoice from 'components/base/required_product_choice';

const { mapGetters } = createNamespacedHelpers('productBuy');

// Camera color choice
const component = {
  extends: RequiredProductChoice,
  props: ['initialSku'],
  computed: {
    ...mapGetters({
      available: 'productColorAvailable',
      resetWidget: 'resetColourSelectionWidget',
    }),
  },
  data() {
    return {
      sku: this.initialSku,
      requirement: 'hasProductColorRequirement',
    };
  },
  methods: {
    choiceEventData() {
      return { sku: this.sku, callback: this.emitCompleteSpecEvent };
    },
    afterUpdateChanges() {
      if (this.belongsToCombo) {
        this.dataToLocalStorage();
        this.updateRequirement();
      } else {
        this.requiredProductChoiceAfterUpdateChanges();
      }
    },
  },
  watch: {
    resetWidget(resetFired) {
      if (resetFired) {
        this.reset();
      }
    },
  },
};

export default component;
