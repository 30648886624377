const component = {
  props: {
    menuKey: String,
    active: Boolean,
    isClickDisabled: Boolean,
    modalDomId: String,
  },
  data() {
    return {
      badge: '',
    };
  },
  methods: {
    onMouseEnter() {
      this.$emit('submenu-show', this.menuKey);
    },
    onMouseLeave() {
      this.$emit('submenu-hide', this.menuKey);
    },
    handleClick(event) {
      if (this.isClickDisabled) return;

      if (this.modalDomId !== '') {
        this.$modal.show(this.modalDomId);
      } else {
        window.location = event.currentTarget.href;
      }
    },
  },
};
export default component;
