<template>
  <button
    type="button"
    class="close"
    v-on:click="confirmExit"
  >
    <svg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:width="'40'" v-bind:height="'40'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M42.828 40l12.586-12.586a2 2 0 00-2.828-2.828L40 37.172 27.414 24.586a2 2 0 00-2.828 2.828L37.172 40 24.586 52.586a2 2 0 002.828 2.828L40 42.828l12.586 12.586a2 2 0 002.828-2.828L42.828 40z" fill="#29424C" fill-rule="nonzero"/></svg>
    <span class="sr-only">Close</span>
  </button>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('quiz');

const component = {
  methods: {
    ...mapActions(['confirmExit']),
  },
};

export default component;
</script>
