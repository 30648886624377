import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const HUB_OPTIONS = ['hub', 'hubless'];
const CHANNEL_OPTIONS = ['single_channel', 'dual_channel'];
const INSTALL_OPTIONS = ['self_install', 'pro'];
const FITTING_OPTIONS = ['screw', 'bayonet'];

const mutations = {
  ...baseMutations,
  setPackSkuMappings(state, packSkuMappings) {
    if (!HUB_OPTIONS.every(key => key in packSkuMappings)) {
      throw new Error(`Invalid heating sku mappings: ${packSkuMappings}`);
    }

    Vue.set(state, 'packSkuMappings', { ...packSkuMappings });
  },
  setHubOption(state, option) {
    if (!HUB_OPTIONS.includes(option)) throw new Error(`Invalid hub option: ${option}`);

    Vue.set(state, 'hubOption', option);
  },
  setChannelOption(state, option) {
    if (!CHANNEL_OPTIONS.includes(option)) throw new Error(`Invalid channel option: ${option}`);

    Vue.set(state, 'channelOption', option);
  },
  setInstallationType(state, option) {
    if (!INSTALL_OPTIONS.includes(option)) throw new Error(`Invalid installation type: ${option}`);

    Vue.set(state, 'installationType', option);
  },
  setFittingType(state, option) {
    if (!FITTING_OPTIONS.includes(option)) throw new Error(`Invalid fitting option: ${option}`);

    Vue.set(state, 'fittingType', option);
  },
};

const moduleGetters = {
  ...baseGetters,
  sku({
    hubOption, channelOption, installationType, packSkuMappings, fittingType,
  }) {
    if (hubOption !== null && channelOption !== null
        && installationType !== null && fittingType !== null) {
      return packSkuMappings[hubOption][channelOption][installationType][fittingType];
    }

    return null;
  },
  pricingPayload({ quantity }, { sku }) {
    const products = [{ sku, quantity }];

    return { products, sku };
  },
  basketPayload({ quantity }, { sku }) {
    const skus = [{ sku, quantity }];

    const productBuy = { product: { sku: skus } };

    return { productBuy, quantity: 1 };
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  init({ commit, dispatch }, { packSkuMappings }) {
    commit('setPackSkuMappings', packSkuMappings);

    return dispatch('loadPricing');
  },
  setHubOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setHubOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setChannelOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setChannelOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setInstallationType({ commit, dispatch }, { selected, specEvent }) {
    commit('setInstallationType', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setFittingType({ commit, dispatch }, selected) {
    commit('setFittingType', selected);

    return dispatch('loadPricing');
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      quantity: 1,
      packSkuMappings: {},
      hubOption: 'hubless',
      channelOption: 'dual_channel',
      fittingType: 'screw',
      installationType: 'self_install',
      pricing: {},
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
