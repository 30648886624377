import initModuleWithState from 'store/utils/init_module_with_state';

/**
 * Init product module
 */

function widgetCompleteFlag(productState, widgetId, acceptedCompleteVal, rejectedCompleteVal) {
  let completeFlag;

  if (productState?.widgets && productState.widgets[widgetId]) {
    completeFlag = productState.widgets[widgetId].complete;
    if (completeFlag === undefined || completeFlag === null) completeFlag = acceptedCompleteVal;
  } else completeFlag = acceptedCompleteVal;

  return completeFlag !== rejectedCompleteVal;
}

function initProductBuyModule(module, state) {
  // here we build the state dependent on the rendered widgets
  // TODO we can do something smarter than this and do not assume we know the name of the widget
  const hasThermostatCompatibilityRequirement = widgetCompleteFlag(state.productBuy, 'thermostat-compatibility', true, false);
  const hasVideoPlaybackRequirement = widgetCompleteFlag(state.productBuy, 'v3-video-playback', true, false);

  const hasProductColorRequirement = widgetCompleteFlag(state.productBuy, 'v3-colour-selection', true, false);
  const hasProductBulbFittingRequirement = widgetCompleteFlag(state.productBuy, 'v3-bulb-fitting', true, false);
  const hasProductHubHublessRequirement = widgetCompleteFlag(state.productBuy, 'v3-hub-hubless', true, false);

  const hasProductInstallationRequirement = (
    widgetCompleteFlag(state.productBuy, 'v3-product-installation', true, false)
    && widgetCompleteFlag(state.productBuy, 'v3-camera-installation', true, false)
  );

  const hasSecurityPackTypeRequirement = widgetCompleteFlag(state.productBuy, 'v3-security-pack-type', true, false);
  const hasCameraCountRequirement = widgetCompleteFlag(state.productBuy, 'v3-camera-count', true, false);
  const hasBoilerAndCountyRequirement = widgetCompleteFlag(state.productBuy, 'v3-select-boiler-and-county', true, false);

  const initState = {
    productBuy: {
      product: {},
      ...state.productBuy,
      basket_requirement: {},
      options: {
        hasThermostatCompatibilityRequirement,
        hasProductInstallationRequirement,
        hasProductHubHublessRequirement,
        hasVideoPlaybackRequirement,
        hasProductColorRequirement,
        hasProductBulbFittingRequirement,
        hasSecurityPackTypeRequirement,
        hasCameraCountRequirement,
        hasBoilerAndCountyRequirement,
      },
    },
  };
  return initModuleWithState(module, initState, 'productBuy', true);
}

export default initProductBuyModule;
