<template>
  <div class="row">
    <div
      v-for="paymentMethod in sortedPaymentMethods"
      v-bind:key="paymentMethod.id"
      class="col-md-6 mb-4"
    >
      <div
        class="custom-control custom-radio global-checkout-card-select"
        v-bind:class="{
          loading: paymentMethod.pending,
          active: paymentMethod.isDefault,
          'border-2-red': requireNewPaymentMethod(paymentMethod)
        }"
      >
        <payment-method-details v-bind:payment-method="paymentMethod" />
        <input
          v-if="allowSelection"
          v-bind:id="paymentMethod.id"
          type="radio"
          class="custom-control-input"
          v-bind:disabled="disabled"
          v-bind:value="paymentMethod.id"
          v-bind:checked="isChecked(paymentMethod.id)"
          v-on:change="$emit('change', $event.target.value)"
        >
        <label
          v-if="allowSelection"
          class="custom-control-label"
          v-bind:for="paymentMethod.id"
        />
      </div>

      <div v-if="showControls" class="mt-2 font-weight-bold">
        <span
          v-if="paymentMethod.isDefault"
          class="me-2"
        >
          Default payment method
        </span>
        <span
          v-if="paymentMethod.isDefaultCandidate && !hasDirectDebit"
          class="me-2 text-underline cursor-pointer"
          data-spec="set_as_default"
          v-on:click="confirm('setDefaultModal', paymentMethod)"
        >
          Set as default
        </span>
        <span
          v-if="paymentMethod.isDeletable"
          class="me-2 text-underline cursor-pointer"
          data-spec="remove"
          v-on:click="confirm('removalModal', paymentMethod)"
        >
          Remove  {{ paymentMethod.isDirectDebit ? 'payment method' : 'card' }}
        </span>
      </div>
    </div>

    <confirmation-modal
      ref="setDefaultModal"
      v-on:confirm="setDefaultPaymentMethodAndEmit($event.paymentMethodId)"
    >
      <h3>Set as default?</h3>

      <template v-slot:description="{ params }">
        <p class="mt-3">
          Are you sure you want to set {{ params.paymentMethodName }} as default?
        </p>
      </template>
    </confirmation-modal>

    <confirmation-modal
      ref="removalModal"
      v-on:confirm="deletePaymentMethodAndEmit($event.paymentMethodId)"
    >
      <h3>Remove payment method?</h3>

      <template v-slot:description="{ params }">
        <p class="mt-3">
          Are you sure you want to remove {{ params.paymentMethodName }}?
        </p>
      </template>

      <template v-slot:confirm-button>
        <span class="text-danger">Yes, remove</span>
      </template>

      <template v-slot:reject-button>
        <span class="text-danger">Not now</span>
      </template>
    </confirmation-modal>
  </div>
</template>

<script>

  import PaymentMethodDetails from 'components/base/payment_method_details';
  import {createNamespacedHelpers} from 'vuex';

  const { mapState, mapGetters, mapActions } = createNamespacedHelpers('zuora');

const component = {
  model: {
    prop: 'paymentMethodId',
    event: 'change',
  },
  props: {
    paymentMethodId: String,
    disabled: Boolean,
    showControls: {
      type: Boolean,
      default: false,
    },
    allowSelection: {
      type: Boolean,
      default: true,
    },
    hasFuturePayments: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleting: false,
    };
  },
  computed: {
    ...mapState(['paymentMethods']),
    ...mapGetters(['sortedPaymentMethods', 'hasDirectDebit']),
  },
  methods: {
    ...mapActions([
      'deletePaymentMethod',
      'setDefaultPaymentMethod',
    ]),
    isChecked(value) {
      return this.paymentMethodId === value;
    },
    confirm(dialog, paymentMethod) {
      this.$refs[dialog].open({
        paymentMethodId: paymentMethod.id,
        paymentMethodName: `${paymentMethod.type} - ${paymentMethod.last4}`,
      });
    },
    setDefaultPaymentMethodAndEmit(id) {
      this.setDefaultPaymentMethod({ id })
        .then(() => { this.$emit('set-default-payment-method'); })
        .catch(() => { this.$emit('api-error'); });
    },
    deletePaymentMethodAndEmit(id) {
      this.deletePaymentMethod({ id })
        .then(() => { this.$emit('delete-payment-method'); })
        .catch(() => { this.$emit('api-error'); });
    },
    requireNewPaymentMethod(paymentMethod) {
      return this.hasFuturePayments && paymentMethod.requiresReplacement;
    },
  },
  components: {
    PaymentMethodDetails,
  },
  mounted() {
    window.specEvents.push('zuora.payment-methods.loaded');
    this.$nextTick(() => {
      window.specEvents.push('payment_methods.list.rendered');
    });
  },
};

export default component;
</script>
