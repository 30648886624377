<template>
<div class="row p-1">
  <div class="col-1 border-bottom-1-gray py-1" v-bind:class="offsetClass">
    <img
      svg-inline
      v-bind:src="imageUrl"
      height="60"
      width="60"
    >
  </div>
  <div class="col-9 text-start border-bottom-1-gray py-1">
    <div class="pb-1">
      Device name: <b>{{ deviceName }}</b>
    </div>
    <div class="pb-1">
      Device Type: <b>{{ deviceType }}</b>
    </div>
    <div class="pb-1">
      Status:
      <span v-bind:class="{ 'text-green': isOnline, 'text-red': !isOnline }">
        {{ status }}
      </span>
      <div v-if="!isHub">
        <button
          v-bind:disabled="isOnline"
          v-bind:class="{ 'disabled': isOnline }"
          class="hive-product-button-pdp d-inline"
          v-on:click="reportIssue"
        >
          Report issue
        </button>

        <button
          v-bind:disabled="isOnline"
          v-bind:class="{ 'disabled': isOnline }"
          class="hive-product-button-pdp d-inline"
          v-on:click="startDeviceCheck()"
        >
          {{ resetButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>

const component = {
  inject: ['dataLayer'],
  props: {
    powerCycleStepsCompleted: Boolean,
    deviceName: String,
    deviceType: String,
    status: String,
    id: String,
    offsetLevel: Number,
  },
  computed: {
    offsetClass() {
      return `offset-${this.offsetLevel}`;
    },
    isOnline() {
      return this.status === 'Online';
    },
    isHub() {
      return /NANO/.test(this.deviceType);
    },
    isReceiver() {
      return /SLR/.test(this.deviceType);
    },
    isThermostat() {
      return /SLT/.test(this.deviceType);
    },
    imageUrl() {
      const deviceTypeMap = {
        NANO: 'hub',
        SLR1: 'receiver_single',
        SLR2: 'receiver_dual',
        'SLT3c|SLT3d': 'slt_3c_3d',
        'SLT3|SLT3b|SLT5': 'slt_3_3b_slt5',
        SLT6: 'slt6',
      };

      const prefix = Object.entries(deviceTypeMap)
        .find(([key]) => new RegExp(key).test(this.deviceType)) ?.[1] || '';

      return require(`honey-icons/${prefix}.svg`);
    },
    resetButtonText() {
      return this.powerCycleStepsCompleted ? 'Reset' : 'Power check';
    },
  },
  methods: {
    startDeviceCheck() {
      this.dataLayer.addHeatingOfflineEvent(
        'heatingOffline.device.click',
        { 
          button: this.resetButtonText,
        }
      );

      this.$emit('device-check');
    },
    startApiReset() {
      this.$emit('api-reset');
    },
    reportIssue() {
      this.dataLayer.addHeatingOfflineEvent(
        'heatingOffline.device.click',
        { 
          button: 'Report issue',
        }
      );

      this.$emit('report-issue');
    },
  },
};

export default component;

</script>
