// Other Smart Products component listing product recommendations
// after basket add.
//
// So far it only handles tracking requirements from HWS-6970
//

import UpsellItem from 'components/views/upsell_item';

const OtherSmartProductsComponent = {
  inject: ['dataLayer'],
  props: {
    recommendationsForSku: {
      type: String,
      required: true,
    },
  },
  components: {
    'upsell-item': UpsellItem,
  },
  mounted() {
    this.dataLayer.appendUpsellPageLoadedEvent(this.recommendationsForSku);
  },
};

export default OtherSmartProductsComponent;
