import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('radiatorValveBuyPage');

const component = {
  props: {
    hubSku: String,
  },
  data() {
    return {
      currentHubSelection: null,
    };
  },
  watch: {
    stateHubSku(newVal) {
      if (newVal) {
        this.onYesHub();
        return;
      }
      this.onNoHub();
    },
  },
  computed: {
    ...mapState({ stateHubSku: 'hubSku' }),
    isHubSelected() {
      return !!this.currentHubSelection;
    },
    isNoHubSelected() {
      return this.currentHubSelection == null;
    },
  },
  methods: {
    onYesHub() {
      this.currentHubSelection = this.hubSku;
      this.$emit('change-hub', this.currentHubSelection);
    },
    onNoHub() {
      this.currentHubSelection = null;
      this.$emit('change-hub', null);
    },
  },
};

export default component;
