import AddressAutocompleteByPostcode from 'components/base/address_autocomplete_by_postcode';
import AddressAutocompleteByAddress from 'components/base/address_autocomplete_by_address';
import AddressAutocomplete from 'components/views/address_autocomplete';
import ValidatedForm from 'mixins/validated_form';
import { createNamespacedHelpers } from 'vuex';
import HiveLoader from 'components/base/hive_loader';
import isEmpty from 'lodash/isEmpty';

const { mapActions: mapBasketActions } = createNamespacedHelpers('basket');
const {
  mapState: mapCustomerState,
  mapGetters: mapCustomerGetters,
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const MODAL_NAME = 'add-new-address';

const component = {
  name: 'shipping-form',
  mixins: [ValidatedForm],
  inject: ['dataLayer', 'client'],
  props: {
    dmpgSubmitEvent: String,
    initShippingMethod: String,
    defaultDeliveryAddress: String,
  },
  data() {
    return {
      shippingMethod: this.initShippingMethod,
      newAddress: {},
      isLoading: false,
      selectedAddressReference: this.defaultDeliveryAddress,
    };
  },
  computed: {
    ...mapCustomerState(['addresses', 'recentlyAddedAddress']),
    ...mapCustomerGetters([
      'hasAddresses',
    ]),
    showShipAddressForm() {
      return !this.shipAddressAutocomplete;
    },
    isNewAddressPresent() {
      return !isEmpty(this.newAddress);
    },
    isNewAddressPresentOrSelected() {
      return this.selectedAddressReference || this.isNewAddressPresent;
    },
    isFormInvalid() {
      const {
        validated,
        invalid,
        dirty,
        pending,
      } = this.validation;
      const validatedOrDirty = validated || dirty;

      return this.submitting
        || pending
        || (validatedOrDirty && invalid);
    },
    isSubmitButtonDisabled() {
      return this.isLoading || this.isFormInvalid || !this.isNewAddressPresentOrSelected;
    },
    isModalSubmitButtonDisabled() {
      return this.isFormInvalid || !this.isNewAddressPresent;
    },
  },
  methods: {
    ...mapBasketActions(['changeShippingMethod', 'changeDeliveryAddress']),
    ...mapCustomerActions({
      createCustomerAddress: 'createAddress',
    }),
    onChangeShippingMethod() {
      const { shippingMethod } = this;

      this.isLoading = true;

      this.changeShippingMethod({ shippingMethod })
        .then(() => {
          window.specEvents.push('checkout.shipping.update');
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    beforeSubmit() {
      this.dataLayer.triggerCartEvent(this.dmpgSubmitEvent);
    },
    onSubmit(ev) {
      this.isLoading = true;
      this.submitting = true;
      this.beforeSubmit();
      setTimeout(() => ev.target.submit(), 150);
    },
    onDevPopulate(data) {
      this.shipAddressAutocomplete = false;
      Object.assign(this, data);
      this.$refs.addressAutocomplete.updateAddress(data);
    },
    changeShippingAddress({ reference }) {
      this.isLoading = true;
      this.submitting = true;

      this.changeDeliveryAddress({ deliveryAddress: reference })
        .then(() => {
          this.selectedAddressReference = reference;
        })
        .finally(() => {
          this.isLoading = false;
          this.submitting = false;
        });
    },
    setNewAddress(address) {
      ['address1', 'city', 'territory', 'postcode'].forEach((prop) => {
        this.$set(this.newAddress, prop, address[prop]);
      });
    },
    resetNewAddress() {
      this.newAddress = {};
      this.isLoading = false;
      this.submitting = false;
    },
    showNewAddressModal() {
      if (this.submitting) { return; }

      this.$modal.show(MODAL_NAME);
      this.resetNewAddress();
    },
    createAddress() {
      this.submitting = true;
      this.isLoading = true;

      return this.createCustomerAddress({ data: { ...this.newAddress, update_order: true } })
        .then(() => {
          if (this.recentlyAddedAddress) {
            this.selectedAddressReference = this.recentlyAddedAddress;
          }

          this.$modal.hide(MODAL_NAME);
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.resetNewAddress();
        });
    },
  },
  components: {
    HiveLoader,
    AddressAutocompleteByPostcode,
    AddressAutocompleteByAddress,
    AddressAutocomplete,
  },
};

export default component;
