/* eslint-disable */

import Utils from './utils';

class ProductFrameSwitcher {
  static initClass() {
    // constants
    this.prototype.prefix = '.js-frame-switch';
    this.prototype.class = {
      active: 'mod-active',
      disabled: 'mod-disabled',
      frameProduct: 'mod-frame-product',
      hidden: 'd-none'
    };
    this.prototype.attr = {
      color: 'data-color',
      colorName: 'data-color-name',
      sku: 'data-sku'
    };

    // defining getters and setters to class
    Object.defineProperties(this.prototype, {
      'frameColor': {
        get() { return $(this.el.visualiser).attr(this.attr.color); },
        set(color) { return $(this.el.visualiser).attr(this.attr.color, color); }
      },
      'activeTogglerColor': {
        get() { return $(this.el.activeToggler).attr(this.attr.color); }
      },
      'activeTogglerColorName': {
        get() { return $(this.el.activeToggler).attr(this.attr.colorName); }
      },
      'activeTogglerSku': {
        get() { return $(this.el.activeToggler).attr(this.attr.sku); }
      },
      'isDisabled': {
        get() { return $(this.el.component).hasClass(this.class.disabled); },
        set(val) { return $(this.el.component).toggleClass(this.class.disabled, val); }
      },
      'isFrameProductPage': {
        get() { return $(this.el.component).hasClass(this.class.frameProduct); }
      },
      'colorName': {
        set(colorName) { return $(this.el.colorName).text(colorName); }
      }
    }
    );
  }

  constructor() {
    this.el = {
      component: `${this.prefix}-component`,
      visualiser: `${this.prefix}-visualiser`,
      toggler: `${this.prefix}-toggler`,
      buttonAdd: `${this.prefix}-button-add`,
      buttonRemove: `${this.prefix}-button-remove`,
      formToBasket: '.js-add-to-basket-form',
      colorName: `${this.prefix}-color-name`
    };

    this.el.buttons = `${this.el.buttonAdd}, ${this.el.buttonRemove}`;
    this.el.activeToggler = `${this.el.toggler}.${this.class.active}`;
  }

  updateButtons(sku) {
    const replaceSku = function($el, attrName) {
      if (!$el.length) { return; }
      const attr = $el.attr(attrName);
      return $el.attr(attrName, Utils.changeParam(attr, 'sku', sku));
    };

    // update SKU in buttons href & data-sku attribute
    $.each($(this.el.buttons), function() {
      const $this = $(this);
      $this.attr('data-sku', sku);
      return replaceSku($this, 'href');
    });

    // update SKU in add to basket form
    if (this.isFrameProductPage) {
      return replaceSku($(this.el.formToBasket), 'action');
    }
  }

  toggleAddRemoveButtons() {
    return $(this.el.buttons).toggleClass(this.class.hidden);
  }

  updateTogglers($toggler) {
    $(this.el.activeToggler).removeClass(this.class.active);
    return $toggler.addClass(this.class.active);
  }

  updateDataLayer(color) {
    return window.hdl.assets.products[0].attributes.colour = color;
  }

  toggleColor($toggler) {
    // nothing if disabled for changes
    if (this.isDisabled) { return; }

    // else update depended on color elements and change frame color
    this.updateTogglers($toggler);
    this.updateButtons(this.activeTogglerSku);
    this.frameColor = this.activeTogglerColor;
    this.updateDataLayer(this.activeTogglerColor);

    if (this.isFrameProductPage) {
      return this.colorName = this.activeTogglerColorName;
    }
  }
}
ProductFrameSwitcher.initClass();

const productFrameSwitcher = new ProductFrameSwitcher;

$(document).on('click', productFrameSwitcher.el.toggler, function(e) {
  e.preventDefault();
  return productFrameSwitcher.toggleColor($(this));
});

$(document).on('click', productFrameSwitcher.el.buttonAdd, () => productFrameSwitcher.isDisabled = true);

$(document).on('ajax:success', productFrameSwitcher.el.buttonRemove, () => productFrameSwitcher.isDisabled = false);

$(document).on('ajax:success', productFrameSwitcher.el.buttons, () => productFrameSwitcher.toggleAddRemoveButtons());
