// TODO: We need to think about autoloading of things like components.
// Copy and pasting the same import and init lines is lame every time
// we add / modify component or other modules.

import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';

import makeBasket from './modules/basket';
import makeProductBuy from './modules/product_buy';
import whatsRightForMe from './modules/whats_right_for_me';
import hiveActions from './modules/hive_actions';
import jobSlots from './modules/job_slots';
import devTools from './modules/dev_tools';
import ratings from './modules/ratings';
import makeDiscoverHiveArticles from './modules/discover_hive_articles';
import makeZuora from './modules/zuora';
import makeCustomer from './modules/customer';
import global from './modules/global';
import makeCameraBuyPage from './modules/camera_buy_page';
import makeEvChargerBuyPage from './modules/ev_charger_buy_page';
import makeRadiatorValveBuyPage from './modules/radiator_valve_buy_page';
import makeHeatingBuyPage from './modules/heating_buy_page';
import makePdpPackBuyPage from './modules/pdp_pack_buy_page';
import makeHeatingPlusBuyPage from './modules/heating_plus_buy_page';
import makeBgRewardsPackBuyPage from './modules/bg_rewards_pack_buy_page';
import makePdpLightsBuyPage from './modules/pdp_lights_buy_page';
import makePackBuyPage from './modules/pack_buy_page';
import makeSimpleProductBuyPage from './modules/simple_product_buy_page';
import makePdpSensorBuyPage from './modules/pdp_sensor_buy_page';
import makePdpPlaybackBuyPage from './modules/pdp_playback_buy_page';
import makeHomeshieldStarterHomePackPage from './modules/homeshield_starter_home_pack_page';
import makeBarclaysHsPage from './modules/barclays_hs_page';
import makeRegisterInterestPage from './modules/register_interest_page';
import makeCheckout from './modules/checkout';
import makeRedemptionPage from './modules/redemption_page';


import init from './utils/prepare_state';

// we should init states for individual modules
// ... with a generic function
import initModuleWithState from './utils/init_module_with_state';
// ... or with some functions dependent on module
import initBasketModule from './modules/basket/init';
import initProductBuyModule from './modules/product_buy/init';
import initRatings from './modules/ratings/init';

Vue.use(Vuex);

const makeStore = (injections) => {
  const { __meta__, ...state } = init();

  return new Vuex.Store({
    modules: {
      global: initModuleWithState(global, state, 'global', true),
      devTools: initModuleWithState(devTools, state, 'devTools'),
      whatsRightForMe: initModuleWithState(whatsRightForMe, state, 'whatsRightForMe'),
      basket: initBasketModule(makeBasket(injections), state),
      checkout: makeCheckout(injections),
      productBuy: initProductBuyModule(makeProductBuy(injections), state),
      cameraBuyPage: makeCameraBuyPage(injections),
      radiatorValveBuyPage: makeRadiatorValveBuyPage(injections),
      heatingBuyPage: makeHeatingBuyPage(injections),
      pdpPackBuyPage: makePdpPackBuyPage(injections),
      heatingPlusBuyPage: makeHeatingPlusBuyPage(injections),
      bgRewardsPackBuyPage: makeBgRewardsPackBuyPage(injections),
      evChargerBuyPage: makeEvChargerBuyPage(injections),
      pdpLightsBuyPage: makePdpLightsBuyPage(injections),
      packBuyPage: makePackBuyPage(injections),
      pdpSensorBuyPage: makePdpSensorBuyPage(injections),
      pdpPlaybackBuyPage: makePdpPlaybackBuyPage(injections),
      simpleProductBuyPage: makeSimpleProductBuyPage(injections),
      homeshieldStarterHomePackPage: makeHomeshieldStarterHomePackPage(injections),
      hiveActions: initModuleWithState(hiveActions, state, 'hiveActions'),
      jobSlots: initModuleWithState(jobSlots, state, 'jobSlots'),
      ratings: initRatings(ratings, state),
      discoverHiveArticles: initModuleWithState(
        makeDiscoverHiveArticles(injections),
        state,
        'discoverHiveArticles',
        true,
      ),
      zuora: initModuleWithState(makeZuora(injections), state, 'zuora', true),
      customer: initModuleWithState(makeCustomer(injections), state, 'customer', true),
      barclaysHsPage: makeBarclaysHsPage(injections),
      registerInterestPage: makeRegisterInterestPage(injections),
      redemptionPage: makeRedemptionPage(injections),
    },
    // __meta__ contains info about the health status of the state
    state: { __meta__ },
  });
};

// export global function for back compatibility
// eslint-disable-next-line no-undef
window.toggleDeveloperTools = () => store.dispatch('devTools/toggle');

export default makeStore;
