/*
In order to use this mixin there should be `zuoraForm` ref set on a form that needs
to be submitted.
*/

const mixin = {
  methods: {
    submitZuoraForm(fields) {
      const form = this.$refs.zuoraForm;

      if (!form) {
        throw new Error('zuoraForm ref is not found');
      }

      Object.keys(fields).forEach((field) => {
        if (fields[field] && form[field]) {
          form[field].value = fields[field];
        }
      });

      form.submit();
    },
  },
};

export default mixin;
