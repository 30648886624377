import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const HUB_OPTIONS = ['hub', 'hubless'];
export const CHANNEL_OPTIONS = ['Combi', 'Conventional'];
const HEATING_ZONES_OPTIONS = ['Single', 'Multiple'];
const INSTALL_OPTIONS = ['self_install', 'pro'];
const HEATING_PLUS_OPTIONS = ['annual', 'monthly', 'no', 'always'];
const THERMOSTAT_TYPES = ['hah', 'mini'];
const THERMOSTAT_STAND_OPTIONS = ['yes', 'no'];
export const BOILER_TYPES = ['oil', 'gas'];

const mutations = {
  ...baseMutations,
  setHeatingSkuMappings(state, heatingSkuMappings) {
    let rootKeys = HUB_OPTIONS;
    if (state.newMultizoneSelection) rootKeys = rootKeys.concat(['additional']);

    if (!rootKeys.every(key => key in heatingSkuMappings)) {
      throw new Error(`Invalid heating sku mappings: ${heatingSkuMappings}`);
    }

    Vue.set(state, 'heatingSkuMappings', { ...heatingSkuMappings });
  },
  setThermostatStandSkuMappings(state, thermostatStandSkuMappings) {
    Vue.set(state, 'thermostatStandSkuMappings', { ...thermostatStandSkuMappings });
  },
  setColour(state, sku) {
    Vue.set(state, 'colourSku', sku);
  },
  setHubOption(state, option) {
    if (!HUB_OPTIONS.includes(option)) throw new Error(`Invalid hub option: ${option}`);

    Vue.set(state, 'hubOption', option);
  },
  setChannelOption(state, option) {
    if (!option) return;
    if (!CHANNEL_OPTIONS.includes(option)) throw new Error(`Invalid channel option: ${option}`);

    Vue.set(state, 'channelOption', option);
  },
  setThermostatStandOption(state, option) {
    if (!option) return;
    if (!THERMOSTAT_STAND_OPTIONS.includes(option)) throw new Error(`Invalid thermostat stand option: ${option}`);

    Vue.set(state, 'thermostatStandOption', option);
  },
  setHeatingZonesOption(state, option) {
    if (!HEATING_ZONES_OPTIONS.includes(option)) throw new Error(`Invalid heating zones option: ${option}`);

    Vue.set(state, 'heatingZonesOption', option);

    if (option === 'Multiple' && state.quantity < 2) {
      Vue.set(state, 'quantity', 2);
    } else if (option === 'Single') {
      Vue.set(state, 'quantity', 1);
    }
  },
  setInstallationType(state, installationType) {
    if (!INSTALL_OPTIONS.includes(installationType)) throw new Error(`Invalid installation type: ${installationType}`);

    Vue.set(state, 'installationType', installationType);
  },
  setBoilerType(state, boilerType) {
    if (!BOILER_TYPES.includes(boilerType)) throw new Error(`Invalid boiler type: ${boilerType}`);

    Vue.set(state, 'boilerType', boilerType);
  },
  setHeatingPlusType(state, heatingPlusType) {
    if (!HEATING_PLUS_OPTIONS.includes(heatingPlusType)) throw new Error(`Invalid heating plus type: ${heatingPlusType}`);

    Vue.set(state, 'heatingPlusType', heatingPlusType);
  },
  setThermostatType(state, thermostatType) {
    if (!THERMOSTAT_TYPES.includes(thermostatType)) throw new Error(`Invalid thermostat type: ${thermostatType}`);

    Vue.set(state, 'thermostatType', thermostatType);
  },
  setNewMultizoneSelection(state, value) {
    Vue.set(state, 'newMultizoneSelection', value);
  },
  setAdditionalTrvBundle(state, value) {
    Vue.set(state, 'trvBundleSku', value);
  },
  setHiveMiniOption(state, value) {
    Vue.set(state, 'hiveMiniOption', value);
  },
};

const moduleGetters = {
  ...baseGetters,
  sku({
    hubOption, channelOption, installationType, heatingSkuMappings,
  }) {
    if (hubOption !== null && channelOption !== null && installationType !== null) {
      return heatingSkuMappings[hubOption][channelOption][installationType];
    }

    // It is required for the initial pricing load
    if (hubOption !== null && installationType !== null && channelOption === null) {
      return heatingSkuMappings[hubOption][CHANNEL_OPTIONS[0]][installationType];
    }

    return null;
  },
  pricingPayload(state, { sku, basketPayload }) {
    return {
      products: basketPayload.productBuy.product.sku,
      sku,
    };
  },
  basketPayload(
    {
      colourSku,
      quantity,
      alternativeQuantity,
      heatingPlusType,
      heatingSkuMappings,
      channelOption,
      heatingZonesOption,
      installationType,
      newMultizoneSelection,
      thermostatStandOption,
      thermostatStandSkuMappings,
      thermostatType,
      trvBundleSku,
      hiveMiniOption,
    },
    { sku, newMultizoneSelectionProducts }
  ) {
    let skus = [
      { sku, quantity },
    ];

    if (hiveMiniOption === 'No') skus = [];

    if (newMultizoneSelection) {
      skus = [{ sku, quantity: 1 }].concat(newMultizoneSelectionProducts);
    } else if (installationType === INSTALL_OPTIONS[1] && heatingSkuMappings.installation) {
      skus.push({ sku: heatingSkuMappings.installation, quantity });
    }

    if (heatingSkuMappings.heating_plus && heatingPlusType !== 'no') {
      skus.push({ sku: heatingSkuMappings.heating_plus[heatingPlusType], quantity: 1 });
    }

    if (thermostatStandOption === 'yes') {
      skus.push({ sku: thermostatStandSkuMappings[thermostatType], quantity: alternativeQuantity });
    }

    if (trvBundleSku && trvBundleSku !== 'no_pack') skus.push({ sku: trvBundleSku, quantity: 1 });

    if (colourSku !== null) skus.push({ sku: colourSku, quantity: 1 });

    const productBuy = {
      product: { sku: skus },
      channel_option: channelOption,
      heating_zones_option: heatingZonesOption,
    };

    return { productBuy, quantity: 1 };
  },
  newMultizoneSelectionProducts(
    {
      quantity,
      heatingSkuMappings,
      installationType,
      heatingZonesOption,
      newMultizoneSelection,
    }
  ) {
    if (!newMultizoneSelection) return [];

    const products = [];

    if (heatingZonesOption === HEATING_ZONES_OPTIONS[1]) { // Multiple
      if (installationType === INSTALL_OPTIONS[1]) { // pro
        products.push({ sku: heatingSkuMappings.installation, quantity: 1 });
        products.push({ sku: heatingSkuMappings.additional.pro, quantity: quantity - 1 });
        products.push({ sku: heatingSkuMappings.additional.installation, quantity: quantity - 1 });
      } else { // self_install
        const p = products.find(({ sku }) => sku === heatingSkuMappings.additional.self_install);
        if (p) {
          p.quantity += quantity - 1;
        } else {
          products.push({
            sku: heatingSkuMappings.additional.self_install,
            quantity: quantity - 1,
          });
        }
      }
    } else if (installationType === INSTALL_OPTIONS[1]) { // Single pro_install
      products.push({ sku: heatingSkuMappings.installation, quantity });
    }

    return products;
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  init(
    {
      commit,
      dispatch,
    },
    {
      heatingSkuMappings,
      thermostatStandSkuMappings,
      hubOption,
      channelOption,
      heatingPlusType,
      thermostatType,
      heatingZonesOption,
      newMultizoneSelection,
      thermostatStandOption,
    }
  ) {
    commit('setNewMultizoneSelection', newMultizoneSelection);
    commit('setHeatingSkuMappings', heatingSkuMappings);
    commit('setThermostatStandSkuMappings', thermostatStandSkuMappings);
    commit('setHubOption', hubOption);
    commit('setChannelOption', channelOption);
    commit('setHeatingPlusType', heatingPlusType);
    commit('setThermostatType', thermostatType);
    commit('setHeatingZonesOption', heatingZonesOption);
    commit('setThermostatStandOption', thermostatStandOption);

    return dispatch('loadPricing');
  },
  setColour({ commit, dispatch }, sku) {
    commit('setColour', sku);

    return dispatch('loadPricing');
  },
  setHubOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setHubOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setChannelOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setChannelOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setBoilerType({ commit, dispatch }, { selected, specEvent }) {
    commit('setBoilerType', selected);
    window.specEvents.push(specEvent);

    if (selected === BOILER_TYPES[0]) {
      commit('setInstallationType', INSTALL_OPTIONS[0]);
    }

    return dispatch('loadPricing');
  },
  setThermostatStandOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setThermostatStandOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setHeatingZonesOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setHeatingZonesOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setInstallationType({ commit, dispatch }, { selected, specEvent }) {
    commit('setInstallationType', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
  setHeatingPlusType({ commit, dispatch }, selected) {
    commit('setHeatingPlusType', selected);

    return dispatch('loadPricing');
  },
  setAdditionalTrvBundle({ commit, dispatch }, selected) {
    commit('setAdditionalTrvBundle', selected);

    return dispatch('loadPricing');
  },
  setHiveMiniOption({ commit, dispatch }, { selected, specEvent }) {
    commit('setHiveMiniOption', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      quantity: 1,
      alternativeQuantity: 1,
      heatingSkuMappings: {},
      hubOption: 'hub',
      channelOption: null,
      heatingZonesOption: null,
      colourSku: null,
      installationType: INSTALL_OPTIONS[0],
      boilerType: null,
      heatingPlusType: 'annual',
      pricing: {},
      thermostatType: 'hah',
      thermostatStandOption: 'no',
      thermostatStandSkuMappings: {},
      newMultizoneSelection: false,
      hiveMiniOption: 'Yes',
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
