import ScrollToTop from 'mixins/scroll_to_top';

const component = {
  mixins: [ScrollToTop],
  props: ['item', 'imageSize', 'updating', 'maxTotalExceeded'],
  data() {
    return {
      incrementing: false,
      decrementing: false,
    };
  },
  watch: {
    updating() {
      if (!this.updating) {
        this.incrementing = false;
        this.decrementing = false;
      }
    },
  },
  computed: {
    imageSrcValue() {
      const val = this.imageSrc;
      if (!val) return null;
      return val.src;
    },
    imageSrc() {
      const { item, imageSize } = this;
      const { images } = item;

      // TODO: fallback image?
      if (!images) return null;
      if (!images[imageSize]) return null;
      if (!images[imageSize][1]) return null;

      return images[imageSize][1].data;
    },
    canChangeQuantity() {
      const { isPlan, disallowLineItemQuantityChange } = this.item;

      return !isPlan && !disallowLineItemQuantityChange;
    },
    currency() {
      return this.item.product.currency;
    },
    decrementDisabled() {
      const { decrementing, item } = this;

      return decrementing || item.quantity === 0;
    },
    incrementDisabled() {
      const { maxTotalExceeded, item, incrementing } = this;
      const { quantityInOrder, product } = item;

      return incrementing
             || quantityInOrder >= product.product.maxLineItemQuantity
             || maxTotalExceeded;
    },
  },
  methods: {
    confirmRemoval() {
      const { $modal, item } = this;

      $modal.show('basket-line-item-remove-item-popup', { item });
    },
    increaseQuantity() {
      const { $store, item } = this;

      this.incrementing = true;

      $store.dispatch('basket/incrementQuantity', { item, errorCallback: this.scrollToTop });
    },
    decreaseQuantity() {
      const { $store, item } = this;

      this.decrementing = true;

      $store.dispatch('basket/decrementQuantity', { item, errorCallback: this.scrollToTop });
    },
  },
};

export default component;
