import {
  TYPE_QUESTION,
  TYPE_CONFIRM,
  TYPE_FUNCTION,
  NEXT_STEP_YES_POWER_SYCLE,
  NEXT_STEP_GO_TO_LIVE_CHAT,
  NEXT_STEP_NO_GO_TO_LIVE_CHAT,
  NEXT_STEP_YES_RENDER_DEVICE_LIST,
} from '../../constants';

const THERMOSTAT_ON_RECEIVER_OFF = [
  {
    id: 1,
    ...TYPE_QUESTION,
    text: 'Are there lights on the receiver illuminated?',
    ...NEXT_STEP_YES_POWER_SYCLE,
    nextStepNo: 2,
  },
  {
    id: 2,
    ...TYPE_QUESTION,
    text: 'Is the boiler visibly powered on?',
    nextStepYes: 3,
    nextStepNo: 4,
  },
  {
    id: 3,
    ...TYPE_CONFIRM,
    text: 'Boiler engineer possibly required',
    ...NEXT_STEP_GO_TO_LIVE_CHAT,
  },
  {
    id: 4,
    ...TYPE_QUESTION,
    text: 'Is the Isolation switch on?',
    nextStepYes: 5,
    nextStepNo: 6,
  },
  {
    id: 5,
    ...TYPE_QUESTION,
    text: 'Is the consumer RCD (Fusebox) switched on?',
    nextStepYes: 8,
    nextStepNo: 7,
  },
  {
    id: 6,
    ...TYPE_CONFIRM,
    text: 'Turn isolation switch on',
    nextStep: 8,
  },
  {
    id: 7,
    ...TYPE_CONFIRM,
    text: 'Turn RCD on',
    nextStep: 8,
  },
  {
    id: 8,
    ...TYPE_QUESTION,
    text: 'Is the Boiler visibly powered on?',
    nextStepYes: 9,
    nextStepNo: 10,
  },
  {
    id: 9,
    ...TYPE_QUESTION,
    text: 'Are the lights on the receiver illuminated?',
    ...NEXT_STEP_YES_RENDER_DEVICE_LIST,
    ...NEXT_STEP_NO_GO_TO_LIVE_CHAT,
  },
  {
    id: 10,
    ...TYPE_CONFIRM,
    button: 'Next',
    text: 'Boiler engineer possibly required',
    nextStep: 11,
  },
  {
    id: 11,
    ...TYPE_FUNCTION,
    action() {
      window.location.href = 'https://www.britishgas.co.uk/apps/boilers-and-heating/boiler-repair/how-can-we-help/';
    },
  },
];

export default THERMOSTAT_ON_RECEIVER_OFF;
