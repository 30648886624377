<template>
  <quiz-chat-speech-bubble-animation
    v-bind:enabled="animate"
    v-bind:delay="delay"
  >
    <div class="row align-items-end pb-3 pt-3">
      <div class="col p-0 pr-1 quiz-hive-icon quiz-hive-icon-tailed">
        <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-bind:svg-inline="''" v-bind:width="'32'" v-bind:height="'32'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><circle id="a" cx="14" cy="14" r="14"/></defs><g fill="none" fill-rule="evenodd"><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><use fill="#ff7a00" xlink:href="#a"/><g mask="url(#b)"><g transform="translate(6 6)"><rect width="16" height="16"/><path d="M12.575 11.809l.11.016a.47.47 0 01.335.452c0 .237-.146.37-.336.455-.139.064-.244.101-.55.207l-2.858.994a3.882 3.882 0 01-2.556 0l-2.854-.994a5.357 5.357 0 01-.55-.207c-.19-.085-.336-.218-.336-.455 0-.21.139-.394.336-.452.176-.05.39.024.55.068l3.004.811c.736.197 1.514.2 2.254 0l3.01-.81c.16-.045.374-.12.55-.069zm1.332-2.812l.101.016a.47.47 0 01.336.451c0 .217-.139.384-.336.448-.156.051-.268.075-.55.143l-4.45 1.093a4.234 4.234 0 01-2.02 0l-4.446-1.093c-.282-.068-.394-.092-.55-.143a.466.466 0 01-.336-.448c0-.21.139-.394.336-.451.156-.045.387.013.55.044l4.806.859c.425.074.873.074 1.297 0l4.813-.86c.163-.03.394-.088.55-.043zm-.126-2.462c.166-.017.393-.055.55-.007a.466.466 0 010 .896c-.157.054-.265.068-.55.112l-4.905.788a5.636 5.636 0 01-1.755 0l-4.902-.788c-.285-.044-.397-.061-.55-.112a.466.466 0 010-.896c.153-.048.384-.01.55.007l5.122.516c.432.044.88.044 1.31 0zm-.766-2.358c.142 0 .299.006.416.041a.466.466 0 010 .897c-.156.044-.383.054-.55.064l-4.345.309a8.184 8.184 0 01-1.08 0L3.12 5.179c-.167-.01-.394-.02-.55-.064a.466.466 0 010-.897c.156-.047.383-.04.55-.04zM6.465 1.42a13.585 13.585 0 013.063 0l1.53.173c.163.017.398.03.55.078a.47.47 0 01.336.448c0 .217-.142.39-.336.448-.156.044-.387.04-.55.04h-6.25a1.72 1.72 0 01-.416-.04.464.464 0 01-.336-.448.47.47 0 01.336-.448c.152-.047.387-.061.55-.078z" fill="#FFF"/></g></g></g></svg>
      </div>
      <div class="pb-2 pt-3 bg-gray-white quiz-bubble" v-bind:class="classes">
        <slot />
      </div>
    </div>
  </quiz-chat-speech-bubble-animation>
</template>

<script>
import QuizChatSpeechBubbleAnimation from './chat_speech_bubble_animation';

const component = {
  props: {
    animate: Boolean,
    delay: { type: String, default: '0' },
    classes: { type: String, default: 'col-8' },
  },
  components: {
    QuizChatSpeechBubbleAnimation,
  },
};

export default component;
</script>
