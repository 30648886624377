import { TYPE_CONFIRM, NEXT_STEP_RENDER_DEVICE_LIST_AFTER_POWER_CYCLE } from '../constants';

const SLT5 = [
  {
    id: 1,
    text: `Remove the thermostat by pressing on the tab at the bottom of the thermostat bracket. 
          This will then allow the thermostat to become detached from the wall.`,
    imageUrl: '//images.ctfassets.net/mijf9lz5yt3u/5tYAUBb51ewnkwhoPJ0KJ0/63aac170a11b042f652afd30408be176/1.png',
    ...TYPE_CONFIRM,
    nextStep: 2,
  },
  {
    id: 2,
    text: `Lift your thermostat off the hooks that hold it against the wall 
          and remove the batteries and then reinsert them`,
    imageUrl: '//images.ctfassets.net/mijf9lz5yt3u/2rEO8CERQQczi6uJJY9Adp/0619209a21d299ed9bf1622c9e90134a/2.png',
    ...TYPE_CONFIRM,
    nextStep: 3,
  },
  {
    id: 3,
    text: 'Place the thermostat back on the wall, the thermostat should then display a \'Reconnecting\' message',
    imageUrl: '//images.ctfassets.net/mijf9lz5yt3u/1QV30ereJYRFiQP9VSZztx/1e2d64cc6f1c8842136b38cf62e01402/3.png',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
  {
    id: 4,
    text: `Once you see an inside temperature again, try to turn the heating on.
          Turn the dial clockwise to increase the target temperature.`,
    imageUrl: '//images.ctfassets.net/mijf9lz5yt3u/4Ke2RTullmIdfbBp6Pre7q/1ad119d4e955f643082ff6883068392e/4.png',
    ...TYPE_CONFIRM,
    nextStep: 5,
  },
  {
    id: 5,
    text: 'If the heating is on you will see an \'ON\' message on the thermostat display.',
    imageUrl: '//images.ctfassets.net/mijf9lz5yt3u/15J2gwZuNez7DX1KdtY2Vb/76c83f7b464c864bc4cdf9f3eb5bbe43/5.png',
    ...TYPE_CONFIRM,
    nextStep: 6,
  },
  {
    id: 6,
    text: 'If the heating is on you will see an “ON” message on the thermostat display.',
    ...TYPE_CONFIRM,
    ...NEXT_STEP_RENDER_DEVICE_LIST_AFTER_POWER_CYCLE,
  },
];

export default SLT5;
