<template>
  <quiz-chat-step v-bind:step="step">
    <template v-slot:question>
      <slot />
    </template>

    <template v-slot:buttons="{ onSelect, isCurrentStep }">
      <quiz-chat-button
        v-bind:animate="animate"
        v-on:select="onSelect(true)"
      >
        Yes
      </quiz-chat-button>

      <quiz-chat-button
        v-bind:animate="animate"
        v-on:select="onSelect(false)"
      >
        No
      </quiz-chat-button>
    </template>
  </quiz-chat-step>
</template>

<script>
import QuizChatButton from './chat_button.vue';
import QuizChatStep from './chat_step.vue';

const component = {
  props: {
    step: String,
    animate: Boolean,
  },
  components: {
    QuizChatButton,
    QuizChatStep,
  },
};

export default component;
</script>
