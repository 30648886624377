import { createNamespacedHelpers } from 'vuex';
import Notifications from 'components/base/notifications';
import MyAccountHeader from 'components/base/my_account_header';
import ProgressBar from 'components/base/progress_bar';
import EvList from 'components/base/ev_list';
import md5 from 'js-md5';

import { fabric } from 'fabric';
import {
  builPhotoMetaData,
  dataUrlToFile,
  isMobile,
  resumeStatus,
  uploaded,
} from './helpers';
import * as c from './constants';

const UPLOAD_STATUS_START = 0;
const UPLOAD_STATUS_RESUME = 1;
const UPLOAD_STATUS_COMPLETED = 2;

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');

const {
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const component = {
  inject: ['dataLayer'],
  props: {
    currentOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      evPhotoStep: null,
      photos: [],
      currentCanvasImage: null,
      canvas: null,
      confirmPromptShown: false,
      isLoading: false,
      currentPhotoUrl: null,
      canvasCheck: null,
    };
  },
  computed: {
    isMobileBrowser() {
      return isMobile();
    },
    canvasHeight() {
      if (isMobile()) return window.innerWidth - 40;

      return c.CANVAS_HEIGHT;
    },
    canvasWidth() {
      if (isMobile()) return window.innerWidth - 40;

      return c.CANVAS_WIDTH;
    },
    evOrderStatuses() {
      return [
        'Start Upload',
        resumeStatus(this.uploadsCount, this.requiredPhotosCount),
        'Upload Completed',
      ];
    },
    requiredPhotosCount() {
      return c.REQUIRED_PHOTOS_COUNT;
    },
    maxPhotosCount() {
      return c.MAX_PHOTOS_COUNT;
    },
    evPhotoNames() {
      return c.EV_PHOTO_NAMES;
    },
    uploads() {
      if (!this.currentOrder) return [];

      return uploaded(this.currentOrder);
    },
    uploadsCount() {
      return this.uploads.length;
    },
    currentOrderStatus() {
      if (this.uploadsCount === 0) return this.evOrderStatuses[UPLOAD_STATUS_START];

      if (this.uploadsCount >= this.requiredPhotosCount) {
        return this.evOrderStatuses[UPLOAD_STATUS_COMPLETED];
      }

      return this.evOrderStatuses[UPLOAD_STATUS_RESUME];
    },
    showProgressPage() {
      return !this.evPhotoStep || this.evPhotoStep > this.maxPhotosCount;
    },
    showInitPage() {
      return this.uploadsCount === 0;
    },
    showResumePage() {
      return this.uploadsCount >= 1 && this.uploadsCount < this.requiredPhotosCount;
    },
    showCompletedPage() {
      return (this.uploadsCount >= this.requiredPhotosCount
        || this.evPhotoStep >= this.maxPhotosCount);
    },
    showPhotoExample() {
      return !this.photos[this.evPhotoStep];
    },
    showEditor() {
      return !this.showPhotoExample && this.evPhotoStep <= c.LAST_EDITOR_STEP;
    },
    showPhotoPreview() {
      return !this.showPhotoExample && this.evPhotoStep > c.LAST_EDITOR_STEP;
    },
    showConfirmButton() {
      return !!this.photos[this.evPhotoStep];
    },
    photoTitle() {
      if (this.showEditor || this.showPhotoPreview) {
        return c.CONFIRM_PHOTO_TITLES[this.evPhotoStep - 1];
      }

      return c.PHOTO_TITLES[this.evPhotoStep - 1];
    },
    photoInstruction() {
      if (this.showEditor || this.showPhotoPreview) {
        return c.CONFIRM_PHOTO_INSTRUCTIONS[this.evPhotoStep - 1];
      }

      return c.PHOTO_INSTRUCTIONS[this.evPhotoStep - 1];
    },
    photoTip() {
      if (this.showEditor || this.showPhotoPreview) {
        return c.CONFIRM_PHOTO_TIPS[this.evPhotoStep - 1];
      }

      return c.PHOTO_TIPS[[this.evPhotoStep - 1]];
    },
    isSkippablePhoto() {
      return c.SKIPPABLE_PHOTOS_STEPS.includes(this.evPhotoStep);
    },
    skippablePhotoButtonText() {
      return c.SKIPPABLE_PHOTOS_BUTTON_TEXT[this.evPhotoStep] ?? '';
    },
    examplePhotoUrl() {
      return c.EXAMPLE_PHOTO_URLS[this.evPhotoStep - 1];
    },
  },
  methods: {
    ...mapGlobalActions([
      'showError',
      'showNotice',
    ]),
    ...mapCustomerActions(['createUpload']),
    async fetchUploadUrl(number, photoMetaData) {
      const uploadUrl = await this.getUploadUrl({ number, photoMetaData });
      return uploadUrl;
    },
    goBack() {
      this.$emit('go-back');
    },
    goToEvPhotoStep(step = null) {
      const currentStep = step || this.uploadsCount + 1;
      this.evPhotoStep = currentStep;
    },
    goToProgressPage() {
      this.evPhotoStep = null;
      this.$emit('show-current-order', this.currentOrder);
    },
    addPhoto() {
      this.$refs.fileUpload.click();
    },
    loadPhotoToEditor(photos = null) {
      const photo = (photos || this.photos)[this.evPhotoStep];
      const ref = this.$refs.editor;
      const canvas = this.canvas || new fabric.Canvas(ref);
      if (!this.canvas) this.canvas = canvas;

      canvas.clear();

      canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
      canvas.freeDrawingBrush.color = c.BRUSH_COLOR;
      canvas.freeDrawingBrush.width = 2;
      canvas.isDrawingMode = true;
      fabric.Image.fromURL(photo.url, (img) => {
        const image = img;
        this.currentPhoto = image;
        image.set('selectable', false);
        image.set('evented', false);
        image.set({
          scaleX: this.canvasWidth / image.width,
          scaleY: this.canvasHeight / image.width,
        });
        canvas.add(image);
        canvas.renderAll();
        this.canvasCheck = md5(this.$refs.editor.toDataURL());
      });
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (typeof file === 'undefined') {
        this.showError({
          message: c.ERROR_MESSAGE,
          hideGlobal: true,
        });
        return;
      }

      const fileSizeInMb = file.size / 1024 / 1024;
      if (fileSizeInMb > 15) {
        this.showError({
          message: c.MAX_PHOTO_SIZE_MESSAGE,
          hideGlobal: true,
        });
        return;
      }

      const photo = {
        file,
        name: file.name,
        url: URL.createObjectURL(file),
      };
      const photos = [...this.photos];
      photos[this.evPhotoStep] = photo;
      this.photos = photos;
      if (this.evPhotoStep > c.LAST_EDITOR_STEP) {
        this.currentPhotoUrl = photo.url;
      } else {
        this.loadPhotoToEditor(this.photos);
      }
    },
    isPhotoEdited() {
      return md5(this.$refs.editor.toDataURL()) !== this.canvasCheck;
    },
    confirmPhoto() {
      if (!this.confirmPromptShown) {
        this.$refs.confirmModal.open();
        this.confirmPromptShown = true;
        return;
      }
      this.isLoading = true;

      const photo = this.photos[this.evPhotoStep];

      if (this.showPhotoPreview || !this.isPhotoEdited()) {
        this.upload(photo);
      } else {
        this.savePhotoAndUpload(photo);
      }
    },
    savePhotoAndUpload(photo) {
      dataUrlToFile(
        this.$refs.editor.toDataURL(),
        photo.name,
        photo.file.type
      ).then((file) => {
        const editedPhoto = {
          file,
          name: file.name,
          url: URL.createObjectURL(file),
        };

        const photos = [...this.photos];
        photos[this.evPhotoStep] = editedPhoto;
        this.photos = photos;

        this.upload(editedPhoto);
      });
    },
    upload(photo, skipPhoto = false) {
      const { number } = this.currentOrder;
      const photoMetaData = builPhotoMetaData(photo, this.evPhotoStep, skipPhoto);
      this.createUpload({ number, photoMetaData, skipPhoto })
        .then(() => {
          this.showNotice(c.SUCCESS_MESSAFE);
          setTimeout(() => {
            this.isLoading = false;
            if (this.evPhotoStep === this.maxPhotosCount) {
              this.goToProgressPage();
            } else {
              this.evPhotoStep = this.evPhotoStep + 1;
            }
          }, 3000);
        })
        .catch(() => {
          this.showError({
            message: c.ERROR_MESSAGE,
            hideGlobal: true,
          });
          this.isLoading = false;
        });
    },
    skipPhoto() {
      this.isLoading = true;
      this.upload(null, true);
    },
    showHelpModal() {
      this.$refs.helpModal.open();
    },
    startChat() {
      const helpBtn = document.querySelector('.helpButton .message');
      if (!helpBtn || helpBtn.textContent !== 'Chat now') return;

      helpBtn.click();
    },
  },
  components: {
    Notifications,
    MyAccountHeader,
    ProgressBar,
    EvList,
  },
};

export default component;
