import { createNamespacedHelpers } from 'vuex';

import HoneyOptionSelector from 'components/views/honey/option_selector';
import HoneyTrustPilot from 'components/views/honey_trust_pilot';
import HoneyVideoPlayback from 'components/views/honey/video_playback';
import HoneyPackItem from 'components/views/honey/pack_item';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HomeshieldPack from 'mixins/homeshield_pack';
import AddPackToBasket from 'mixins/add_pack_to_basket';

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('bgRewardsPackBuyPage');

const component = {
  inject: ['cookies'],
  mixins: [HomeshieldPack, AddPackToBasket],
  props: {
    hubPrice: Number,
    hubDiscountedPrice: Number,
    hublessPrice: Number,
    hublessDiscountedPrice: Number,
    hubDiscountBadge: String,
    hublessDiscountBadge: String,
    packSkuMappings: Object,
  },
  data() {
    return {
      namespace: 'heatingBuyPage',
      withHub: true,
    };
  },
  computed: {
    ...mapState([
      'hubOption',
      'colour',
      'fittingType',
    ]),
    ...mapGetters([
      'sku',
    ]),
    hasDiscount() {
      return !!this.discountBadge.trim();
    },
    discountBadge() {
      return this.hubOption === 'hubless' ? this.hublessDiscountBadge : this.hubDiscountBadge;
    },
    totalDiscountedPrice() {
      return this.hubOption === 'hubless' ? this.hublessDiscountedPrice : this.hubDiscountedPrice;
    },
    totalPrice() {
      return this.hubOption === 'hubless' ? this.hublessPrice : this.hubPrice;
    },
  },
  created() {
    const { packSkuMappings } = this;

    this.init({ packSkuMappings });
  },
  methods: {
    ...mapActions([
      'setColour',
      'setHubOption',
      'setFittingType',
      'init',
    ]),
    swapSku() {
      // do nothing
    },
    assignQuantity() {
      // do nothing
    },
    onButtonClick() {
      const includedProducts = {};
      includedProducts[this.sku] = 1;

      const showInstallationPageAfterPurchase = false;
      const productBuy = this.generatePackPayload(
        includedProducts,
        showInstallationPageAfterPurchase
      );
      this.addToBasket(
        {
          productBuy,
          quantity: 1,
          errorCallback: this.scrollToTop,
        }
      );
    },
  },
  components: {
    HoneyTrustPilot,
    TrustPilotPopup,
    HoneyVideoPlayback,
    HoneyPackItem,
    HoneyOptionSelector,
  },
};

export default component;
