// SLT2/3/3b/3c/3d/6
// Thermostat: Offline + Receiver Online

import THERMOSTAT_OFFLINE from '../slt5/thermostat_offline';

const THERMOSTAT_OFF_RECEIVER_ON = [
  ...THERMOSTAT_OFFLINE,
];

export default THERMOSTAT_OFF_RECEIVER_ON;
