import MyEVPage from 'mixins/my_ev_page';
import EvBookingDate from 'components/views/ev_booking_date';

const component = {
  mixins: [MyEVPage],
  computed: {
    currentOrderStatus() {
      return 'Engineer visit';
    },
  },
  components: {
    EvBookingDate,
  },
};

export default component;
