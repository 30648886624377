import delay from 'lodash/delay';

const component = {
  inject: ['dataLayer'],
  props: {
    initialTopicId: String,
  },
  data() {
    return {
      currentSubjectId: null,
      currentSubjectTitle: null,
      currentTopicId: null,
      chatUnavailable: false,
    };
  },
  mounted() {
    this.chatUnavailableMessageTimer = null;

    if (this.initialTopicId) {
      this.currentTopicId = this.initialTopicId;
      [this.currentSubjectId] = this.initialTopicId.split('-');
    }
  },
  computed: {
    chat() {
      return document.querySelector('.helpButtonEnabled');
    },
  },
  methods: {
    selectSubject(subjectId, subjectTitle) {
      this.currentSubjectId = subjectId;
      this.currentSubjectTitle = subjectTitle;
      this.currentTopicId = null;
    },
    isSubjectSelected(subjectId) {
      return this.currentSubjectId && this.currentSubjectId === subjectId;
    },
    selectTopic(topicId, topicTitle) {
      this.currentTopicId = topicId;
      this.dataLayer.chooseTopic(this.currentSubjectTitle, topicTitle);
    },
    isTopicSelected(topicId) {
      return this.currentTopicId && this.currentTopicId === topicId;
    },
    openWebchat() {
      if (this.chat) {
        this.chat.click();
      } else {
        if (this.chatUnavailableMessageTimer) {
          clearTimeout(this.chatUnavailableMessageTimer);
        }
        this.chatUnavailable = true;
        this.chatUnavailableMessageTimer = delay(() => { this.chatUnavailable = false; }, 3000);
      }
    },
  },
};

export default component;
