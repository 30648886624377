export function buildAddress(items) {
  const address1 = new Array(2);
  address1[0] = items[0].Line1;
  address1[1] = items[0].Line2;

  const city = items[0].PostTown || items[0].City;
  const postcode = items[0].PostalCode;
  const unnormalisedState = items[0].ProvinceName;
  const territory = unnormalisedState && unnormalisedState.replace(/^County\s+/, '').replace('West Meath', 'Westmeath');

  return {
    address1: address1.join(', ').replace(/,\s*$/, ''),
    city,
    postcode,
    territory,
  };
}

export function processResponse(items) {
  const hasError = (items.length === 1 && typeof (items[0].Error) !== 'undefined')
    || (items.length === 0);

  if (hasError) {
    throw new Error(items.length === 0 ? '' : items[0].Cause);
  }

  return items;
}
