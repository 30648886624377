// SLT2/3/3b/3c/3d/6
// Thermostat: Online + Receiver Offline
// Thermostat: Offline + Receiver Offline

import {
  TYPE_QUESTION,
  TYPE_CONFIRM,
  TYPE_FUNCTION,
  NEXT_STEP_YES_POWER_SYCLE,
  NEXT_STEP_GO_TO_LIVE_CHAT,
  NEXT_STEP_NO_GO_TO_LIVE_CHAT,
  NEXT_STEP_YES_RENDER_DEVICE_LIST,
} from '../../constants';

const THERMOSTAT_OFF_RECEIVER_OFF = [
  {
    id: 1,
    ...TYPE_QUESTION,
    text: 'Are there any light on the receiver?',
    nextStepYes: 2,
    nextStepNo: 7,
  },
  {
    id: 2,
    ...TYPE_QUESTION,
    text: 'Does the Thermostat light up when any button is pressed?',
    ...NEXT_STEP_YES_POWER_SYCLE,
    nextStepNo: 3,
  },
  {
    id: 3,
    text: 'Re-seat the batteries?',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
  {
    id: 4,
    ...TYPE_QUESTION,
    text: 'Does the Thermostat light up when any button pressed?',
    ...NEXT_STEP_YES_RENDER_DEVICE_LIST,
    nextStepNo: 5,
  },
  {
    id: 5,
    ...TYPE_CONFIRM,
    text: 'Change the batteries',
    nextStep: 6,
  },
  {
    id: 6,
    ...TYPE_QUESTION,
    text: 'Does the Thermostat light up when any button pressed?',
    ...NEXT_STEP_YES_RENDER_DEVICE_LIST,
    ...NEXT_STEP_NO_GO_TO_LIVE_CHAT,
  },
  {
    id: 7,
    ...TYPE_QUESTION,
    text: 'Is the boiler visibly powered on?',
    nextStepYes: 8,
    nextStepNo: 9,
  },
  {
    id: 8,
    ...TYPE_CONFIRM,
    text: 'Boiler engineer possibly required',
    ...NEXT_STEP_GO_TO_LIVE_CHAT,
  },
  {
    id: 9,
    ...TYPE_QUESTION,
    text: 'Is the Isolation switch on?',
    nextStepYes: 10,
    nextStepNo: 11,
  },
  {
    id: 10,
    ...TYPE_QUESTION,
    text: 'Is the consumer RCD (Fusebox) switched on?',
    nextStepYes: 13,
    nextStepNo: 12,
  },
  {
    id: 11,
    ...TYPE_CONFIRM,
    text: 'Turn isolation switch on',
    nextStep: 13,
  },
  {
    id: 12,
    ...TYPE_CONFIRM,
    text: 'Turn RCD on',
    nextStep: 13,
  },
  {
    id: 13,
    ...TYPE_QUESTION,
    text: 'Is the Boiler visibly powered on?',
    nextStepYes: 14,
    nextStepNo: 15,
  },
  {
    id: 14,
    ...TYPE_QUESTION,
    text: 'Are the lights on the receiver illuminated?',
    nextStepYes: 2,
    ...NEXT_STEP_NO_GO_TO_LIVE_CHAT,
  },
  {
    id: 15,
    ...TYPE_CONFIRM,
    button: 'Next',
    text: 'Boiler engineer possibly required',
    nextStep: 16,
  },
  {
    id: 16,
    ...TYPE_FUNCTION,
    action(that) {
      that.dataLayer.addHeatingOfflineEvent(
        `heatingOffline.BoilerRepairPassedToBG`
      )

      window.location.replace('https://www.britishgas.co.uk/apps/boilers-and-heating/boiler-repair/how-can-we-help/');
    },
  },
];

export default THERMOSTAT_OFF_RECEIVER_OFF;
