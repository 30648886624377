import values from 'lodash/values';
import INSTALLATION_TYPE from 'store/modules/product_buy/constants';
import debounce from 'lodash/debounce';

import {
  ADD_SKU,
  REMOVE_SKU,
  CHANGE_SKU,
  CHANGE_PRODUCT,
  CHANGE_PURCHASING_OPTIONS,
  CHANGE_WIDGET_SWITCH_KEY,
  RESET_WIDGET,
  RESTORE_WIDGET,
  UPDATE_IMAGE_SAVINGS_BADGE,
} from './mutations';

const doAsync = (
  {
    updateFn,
    params,
    beforeUpdate,
    success,
    failure,
  }
) => {
  if (beforeUpdate) beforeUpdate();
  return updateFn(params).then(response => success(response)).catch(error => failure(error));
};

const debouncedGetProduct = debounce(asyncArgs => doAsync(asyncArgs), 50);

const defaultPrimarySku = (comboList, productMainSwitchKeys) => {
  const productMainSwitchKey = productMainSwitchKeys.sort().join('|');
  const sku = comboList[productMainSwitchKey] || null;

  return sku;
};

const comboPrimarySku = (state) => {
  if (!state) return null;

  const { widgets, comboOptions } = state;

  if (!widgets || !comboOptions) return null;

  const { productMainSwitchKey, comboList } = comboOptions;
  const bulbFittingCombo = ['screw', 'bayonet'];

  const switchKeys = values(widgets).filter(widget => widget
    && widget.isComboWidget
    && widget.switchKey !== null
    && widget.switchKey !== undefined
    && widget.switchKey.length > 0).map(widgetData => widgetData.switchKey);
  const productMainSwitchKeys = [].concat(productMainSwitchKey).filter(key => key);

  let comboKeyRaw = [...new Set(productMainSwitchKeys.concat(...switchKeys))];

  if (bulbFittingCombo.every(i => comboKeyRaw.includes(i))) {
    comboKeyRaw = comboKeyRaw.filter(e => !bulbFittingCombo.includes(e));
    comboKeyRaw.push('mixed');
  }

  const comboKey = comboKeyRaw.sort().join('|');

  if (!comboList[comboKey]) {
    // console.warn(`navigated to unknown product combo ${comboKey}`);
    return defaultPrimarySku(comboList, productMainSwitchKeys);
  }

  return comboList[comboKey];
};

const resolveAction = (callback) => {
  if (callback) callback();
  return Promise.resolve(false);
};

const makeActions = ({ client, dataLayer }) => ({
  addSku(store, sku) {
    store.commit(ADD_SKU.SUCCESS, sku);
  },
  removeSku(store, sku) {
    store.commit(REMOVE_SKU.SUCCESS, sku);
  },
  changeSku(store, { sku, callback }) {
    if (!sku) {
      return resolveAction(callback);
    }

    const asyncArgs = {
      updateFn: client.getProduct,
      params: { sku },
      beforeUpdate() {
        store.commit(CHANGE_SKU.PENDING);
      },
      success(response) {
        store.commit(CHANGE_SKU.SUCCESS, response.productBuy);
        if (typeof dataLayer !== 'undefined') {
          dataLayer.replace(response.dataLayer);
          dataLayer.productLoaded();
        }
        if (callback) callback();
      },
      failure({ responseJSON }) {
        store.commit(CHANGE_SKU.FAILURE, responseJSON);
      },
    };

    return debouncedGetProduct(asyncArgs);
  },
  updateImageSavingsBadge(store, savings) {
    store.commit(UPDATE_IMAGE_SAVINGS_BADGE.SUCCESS, savings);
  },
  change(store, { callback, ...productData }) {
    store.commit(CHANGE_PRODUCT.SUCCESS, productData);
    if (callback) callback();
  },
  changeOptions({ state, commit }, { widgetId, requirements }) {
    const { widgets = {} } = state;
    const widget = widgets[widgetId];

    if (!widget) return;

    const { isComboWidget = false, resetWidget = false } = widget;

    // skip updating requirement if it's a reset regular widget used by a combo product
    // ex: pro installation for security packs
    if (!(isComboWidget && resetWidget)) {
      commit(CHANGE_PURCHASING_OPTIONS.SUCCESS, requirements);
    }
  },
  changeWidgetSwitchKey({ commit, dispatch }, { callback, ...opts }) {
    commit(CHANGE_WIDGET_SWITCH_KEY.SUCCESS, opts);

    return dispatch('changeComboSku', callback);
  },
  changeComboSku({ state, dispatch }, callback) {
    const sku = comboPrimarySku(state);

    if (!sku) {
      return resolveAction(callback);
    }

    return dispatch('changeSku', { sku, callback });
  },
  resetWidget({ state, commit }, { widgetId, widgetRequirement }) {
    const { widgets = {} } = state;

    // Prevent conflicts between widget alternatives having same requirement names
    if (widgets[widgetId]) {
      commit(RESET_WIDGET.SUCCESS, { widgetId, widgetRequirement });
    }
  },
  restoreWidget({ state, commit }, { widgetId, widgetRequirement }) {
    const { widgets = {} } = state;
    const widget = widgets[widgetId] || null;

    if (widget && widget.resetWidget) {
      commit(RESTORE_WIDGET.SUCCESS, { widgetId, widgetRequirement });
    }
  },
  selectHahInstallationType({ commit, dispatch }, payload) {
    const { widgetId, selected, complete = true } = payload;
    const switchKey = selected === INSTALLATION_TYPE.PRO ? null : 'self_install';

    commit(CHANGE_PURCHASING_OPTIONS.SUCCESS, {
      heatingProductInstallationType: selected,
      hasProductInstallationRequirement: complete,
    });

    return dispatch('changeWidgetSwitchKey', { widgetId, switchKey });
  },
});

export default makeActions;
