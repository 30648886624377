const component = {
  inject: ['dataLayer'],
  props: {
    isVisible: Boolean,
    message: String,
  },
  watch: {
    isVisible(newValue) {
      if (newValue) {
        this.dataLayer.updateError(200, 'form-basket', 'ok', this.message);
      }
    },
  },
};

export default component;
