const component = {
  props: [
    'isTechSpecsActiveInitial',
    'isOverviewActiveInitial',
    'isBuyActiveInitial',
    'isFaqsActiveInitial',
    'isHowItWorksActiveInitial',
    'targetId',
  ],
  data() {
    return {
      isTechSpecsActive: this.isTechSpecsActiveInitial,
      isOverviewActive: this.isOverviewActiveInitial,
      isBuyActive: this.isBuyActiveInitial,
      isFaqsActive: this.isFaqsActiveInitial,
      isHowItWorksActive: this.isHowItWorksActiveInitial,
    };
  },
  methods: {
    scrollToFaq() {
      this.$scrollTo(`#${this.targetId}`, 800, { offset: -150 });
      this.highlightFaq();
    },
    scrollToOverview() {
      this.$scrollTo('#js-page-content', 800, { offset: -150 });
      this.highlightOverview();
    },
    highlightOverview() {
      this.isFaqsActive = false;
      this.isOverviewActive = true;
    },
    highlightFaq() {
      this.isFaqsActive = true;
      this.isOverviewActive = false;
    },
  },
  mounted() {
    if (window.location.href.includes(this.targetId)) {
      this.highlightFaq();
    }
  },
};

export default component;
