const component = {
  props: {
    isMouseOver: Boolean,
    activeSubmenu: String,
    menuKey: String,
    hasContent: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    isSelected() {
      return this.menuKey === this.activeSubmenu;
    },
    isVisible() {
      return (this.isSelected || this.isMouseOver) && this.hasContent;
    },
    styleObject() {
      const mainNav = document.querySelector('nav.navbar');
      const mainNavShrinkAmount = 24;
      const maxHeight = ['calc(100vh - ', (mainNav.offsetHeight - mainNavShrinkAmount), 'px)'];
      return { maxHeight: maxHeight.join('') };
    },
  },
};

export default component;
