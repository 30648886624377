import Vue from 'vue/dist/vue.esm';

const vuexModule = {
  namespaced: true,
  state() {
    return {
      selected: null,
    };
  },
  mutations: {
    reset(state) {
      Vue.set(state, 'selected', null);
    },
    select(state, value) {
      Vue.set(state, 'selected', !!value);
    },
  },
};

export default vuexModule;
