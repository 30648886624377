import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const mutations = {
  ...baseMutations,
  setPackSku(state, sku) {
    Vue.set(state, 'sku', sku);
  },
};

const moduleGetters = {
  ...baseGetters,
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  setPackSku({ commit }, sku) {
    commit('setPackSku', sku);
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      quantity: 1,
      sku: '',
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
