import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const mutations = {
  ...baseMutations,
  setPlaybackSku(state, sku) {
    Vue.set(state, 'sku', sku);
  },
  setPlaybackTier(state, tier) {
    Vue.set(state, 'playbackTier', tier);
  },
  setPlaybackType(state, type) {
    Vue.set(state, 'playbackType', type);
  },
};

export const moduleGetters = {
  ...baseGetters,
  pricingPayload({ sku }) {
    const products = [{ sku, quantity: 1 }];

    return { products, sku };
  },
  basketPayload({ sku }) {
    const products = [{ sku, quantity: 1 }];
    const productBuy = { product: { sku: products } };

    return { productBuy, quantity: 1 };
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  setPlaybackSku({ commit, dispatch }, sku) {
    commit('setPlaybackSku', sku);

    return dispatch('loadPricing');
  },
  setPlayback({ commit }, kind) {
    const key = Object.keys(kind)[0];
    const action = key === 'tier' ? 'setPlaybackTier' : 'setPlaybackType';

    commit(action, kind[key]);
  },
  init({ commit, dispatch }, { sku }) {
    commit('setPlaybackSku', sku);

    return dispatch('loadPricing');
  },

});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      sku: null,
      playbackTier: 1,
      playbackType: 'monthly',
      isPriceLoading: false,
      pricing: {},
      quantity: 1,
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
