// Change the language based on country.
// Affected templates
// Template `app/views/shared/_country_changer_inline.html.erb`

import PopupMenu from 'components/base/popup_menu';
import CountryChanger from 'components/base/country_changer';
import Redirect from 'components/base/redirect';

const component = {
  name: 'footer-country-changer',
  // subcomponents
  components: {
    'popup-menu': {
      ...PopupMenu,
      components: {
        'country-changer': {
          ...CountryChanger,
          components: {
            redirect: Redirect,
          },
        },
      },
    },
  },
};

export default component;
