import { createNamespacedHelpers } from 'vuex';
import ScrollToTop from 'mixins/scroll_to_top';

const { mapState, mapActions } = createNamespacedHelpers('basket');
const mixin = {
  mixins: [ScrollToTop],
  computed: {
    ...mapState({
      buttonsLoading: ({ updating }) => updating,
    }),
  },
  methods: {
    ...mapActions(['addToBasket']),
    isOutOfStock(sku) {
      const el = document.querySelector('meta[name="oos-skus"]');
      if (el) {
        const skus = el.content.split(' ');
        const normalSku = sku.substring(0, 8);
        if (skus.includes(sku) || skus.includes(normalSku)) {
          window.specEvents.push(`oos-${sku}`);

          return true;
        }
      }

      return false;
    },
    onButtonClick() {
      this.basketInterval = setInterval(() => {
        if (!this.isPriceLoading) {
          this.addToBasket({ ...this.basketPayload, errorCallback: this.scrollToTop });
          clearInterval(this.basketInterval);
        }
      }, 100);
    },
  },
};

export default mixin;
