/**
 * Initialize app global settings.
 *
 * Vue instances must be created after initializing
 *
 */

import './jquery';

import Vue from 'vue/dist/vue.esm';
import logger from 'utils/logger';
import SpecEvents from 'utils/spec_events';
import DmpgLinkAttributes from 'utils/dmpg_link_attributes';

import getInitState from 'store/utils/get_init_state';
import makeClient from 'api/client';
import makeStore from 'store';
import makeABTests from 'init/ab_test';

// plugins
import VueScrollTo from 'vue-scrollto';
import initVueValidators from 'init/validation';
import ErrorReporter from 'utils/error_reporter';
import VModal from 'vue-js-modal';
import VueTimeago from 'vue-timeago';
import Vue2TouchEvents from 'vue2-touch-events';
import VueMeta from 'vue-meta';

import WithRoot from 'components/base/functional/with_root';
import * as cookies from 'tiny-cookie';

import { VueReCaptcha } from 'vue-recaptcha-v3';

function initVueLoggers({ dispatch }) {
  // Prevent the production tip on Vue startup.
  // https://vuejs.org/v2/api/#productionTip
  Vue.config.productionTip = false;

  const airbrakeConfig = {
    ...window.Hive.airbrake,
    instrumentation: {
      // Do not change error source in console
      console: false,
      // Do not add `window.onerror` handler
      onerror: false,
      // Do not wrap ajax requests
      xhr: false,
    },
  };

  const errorReporter = new ErrorReporter(airbrakeConfig);

  Vue.config.errorHandler = (error, _vm, info) => {
    errorReporter.notify({ error, params: { info } });

    // Assign a handler for uncaught errors/warns during component render function and watchers.
    logger.error({
      message: error.toString(),
      stack: error.stack,
    });
  };

  Vue.config.warnHandler = (error, _vm, trace) => {
    const payload = { message: error.toString(), stack: trace };

    dispatch('global/setError', payload);
    logger.warn(payload);
  };
}

function initVuePlugins() {
  Vue.use(VueScrollTo);
  Vue.use(VModal);
  Vue.use(VueTimeago, {
    locale: 'en', // Default locale
  });

  // override VModal computed prop to prevent it replacing our styles
  // https://forum.vuejs.org/t/how-to-extend-3rd-party-plugin/934
  const vModalOverrides = {
    computed: {
      modalStyle() {
        return {};
      },
    },
  };

  const defaultOptions = Vue.options.components.Modal.options;
  Vue.options.components.Modal.options = Vue.util.mergeOptions(defaultOptions, vModalOverrides);
  // Workaround for scrolling issue when user swipe down on mobile and body is scrolled.
  // The issue is resolved by delaying the scroll unblocking.
  Vue.prototype.$modal.beforeOpen = () => document.body.classList.add('v--modal-block-scroll');
  Vue.prototype.$modal.beforeClose = () => setTimeout(() => { document.body.classList.remove('v--modal-block-scroll'); }, 100);

  Vue.component('with-root', WithRoot);

  Vue.use(Vue2TouchEvents, { disableClick: true });

  Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true,
  });

  Vue.use(VueReCaptcha, {
    siteKey: getInitState().siteKey,
    loaderOptions: {
      useEnterprise: true,
      autoHideBadge: true,
    },
  });
}

function initDmpgLinkAttributes() {
  // 'load' event is used specifically instead of DOMContentLoaded
  // because we want all the elements to be settled in their final
  // position (for example fully loaded images affect positions and layout).
  window.addEventListener('load', () => {
    window.dmpgLinkAttributes = new DmpgLinkAttributes();
    window.dmpgLinkAttributes.onPageLoad();
  });
}

function catchUnhandledExceptions() {
  if (window) {
    window.addEventListener('error', (event) => {
      const e = event.error;
      logger.error(e ? e.message : event.message);
      return false;
    });
    window.addEventListener('unhandledrejection', (event) => {
      // This will prevent the event bubbling to console,
      // as well as Airbrake. This is useful because some
      // 3rd party scripts polute Airbrake with these errors.
      event.preventDefault();

      // Use our own output
      logger.error(`Unhandled Promise rejection: ${event.reason}.`);
    });
  }
}

function clearLocalStorage() {
  if (window) {
    if (localStorage.length > 1000) {
      const keys = Object.keys(localStorage);

      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (key.includes('snapins')) {
          localStorage.removeItem(key);
        }
      }
    }
  }
}

function bindSpecEvents() {
  // JS on the site can use the SpecEvents API to notify
  // our integration test suite about certain things happening
  if (window) {
    window.specEvents = new SpecEvents();
  }
}

function waitForBoxever() {
  const { Boxever } = global;

  if (typeof Boxever === 'undefined' || Boxever.getID() === 'anonymous') {
    setTimeout(waitForBoxever, 100);
  } else {
    const cookieName = Boxever.getCookieName();
    const browserId = Boxever.getID();

    // For some reason Boxever doesn't always set the cookie.
    if (cookies.get(cookieName) === null) {
      cookies.set(cookieName, browserId);
    }
  }
}


export default () => {
  const apiEndpoints = getInitState('front-end-api-endpoints');
  const client = makeClient(apiEndpoints);
  const { dataLayer } = window.Packs.shared_with_legacy;
  const store = makeStore({ client, dataLayer });
  const config = window.Hive;

  // TODO export this via webpack
  window.store = store;

  global.AB = makeABTests(global.Hive.abTests);


  return Promise.all([
    waitForBoxever(),
    initVueLoggers(store),
    initVueValidators(client),
    initVuePlugins(),
    catchUnhandledExceptions(),
    bindSpecEvents(),
    dataLayer.updateViewSize(),
    initDmpgLinkAttributes(),
    clearLocalStorage(),
    // Put other init here
    // ...
  ]).then(() => ({
    client, store, dataLayer, config,
  }));
};
