import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const TETHER_OPTIONS = ['tethered', 'untethered'];
const INSTALL_OPTIONS = ['self_install', 'pro'];

const mutations = {
  ...baseMutations,
  setEvChargerSkuMappings(state, evChargerSkuMappings) {
    Vue.set(state, 'evChargerSkuMappings', { ...evChargerSkuMappings });
  },
  setPartnerVoucher(state, partnerVoucher) {
    Vue.set(state, 'partnerVoucher', partnerVoucher);
  },
  setTetherOption(state, option) {
    if (!TETHER_OPTIONS.includes(option)) throw new Error(`Invalid tethered option: ${option}`);

    Vue.set(state, 'tetherOption', option);
  },
  setInstallationType(state, installationType) {
    if (!INSTALL_OPTIONS.includes(installationType)) throw new Error(`Invalid installation type: ${installationType}`);

    Vue.set(state, 'installationType', installationType);
  },
};

const moduleGetters = {
  ...baseGetters,
  sku({
    tetherOption, evChargerSkuMappings, installationType,
  }) {
    if (tetherOption !== null) {
      return evChargerSkuMappings[tetherOption][installationType];
    }

    return null;
  },
  pricingPayload({ quantity }, { sku }) {
    return { products: [{ sku, quantity }], sku };
  },
  basketPayload(
    {
      quantity, partnerVoucher,
    }, { sku }
  ) {
    const skus = [
      { sku, quantity },
    ];

    const productBuy = { product: { sku: skus } };

    if (partnerVoucher !== '') {
      productBuy.voucherCode = partnerVoucher;
    }

    return { productBuy, quantity: 1 };
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  init({ commit, dispatch }, { evChargerSkuMappings, partnerVoucher }) {
    commit('setEvChargerSkuMappings', evChargerSkuMappings);
    commit('setPartnerVoucher', partnerVoucher);

    return dispatch('loadPricing');
  },
  setTetherOption({ commit, dispatch }, selected) {
    commit('setTetherOption', selected);
    window.specEvents.push(selected);

    return dispatch('loadPricing');
  },
  setInstallationType({ commit, dispatch }, { selected, specEvent }) {
    commit('setInstallationType', selected);
    window.specEvents.push(specEvent);

    return dispatch('loadPricing');
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      quantity: 1,
      evChargerSkuMappings: {},
      tetherOption: TETHER_OPTIONS[1],
      installationType: INSTALL_OPTIONS[1],
      pricing: {},
      partnerVoucher: '',
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
