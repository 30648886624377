<template>
  <h4 v-if="isVisible" class="global-account-header">
    <slot name="icon">
      <button v-if="isWebview" v-on:click="webviewBack" class="back-button">
        <img
          src="//images.ctfassets.net/mijf9lz5yt3u/3TTmTTkvXu8VNG619CHNAs/becde9cb7eefd76fff54bb8be99d8c04/icon-back.svg"
          width="28"
          height="28"
        >
      </button>
    </slot>
    <slot />
    <slot name="help-icon" />
  </h4>
</template>

<script>
import webview from 'utils/webview';

export default {
  props: {
    webviewOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isWebview() {
      return webview.isWebview();
    },
    isVisible() {
      return !this.webviewOnly || this.isWebview;
    },
  },
  methods: {
    webviewBack() {
      webview.back();
    },
  },
};
</script>
