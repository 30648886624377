import Ajax from 'utils/ajax';
import queryStringEncode from 'utils/url';
import template from 'lodash/template';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

/**
 *
 * Communication logic, legacy and new
 *
 */

import legacy from './legacy';

/**
 * API Client endpoint creation helpers
 */
function invokeEndpoint(method, resolvedPathTemplate, data) {
  return Ajax.send(resolvedPathTemplate, { method, data });
}

function resolvePathTemplate(pathTemplate, args) {
  const fn = template(pathTemplate);
  return fn(args);
}

const createPath = ({ path, required }) => (pathArgs = {}) => {
  const requiredPathArgs = pick(pathArgs, required);
  const queryStringArgs = omit(pathArgs, required);

  // TODO: Raise error if all required args not present
  const resolvedPathTemplate = resolvePathTemplate(path, requiredPathArgs);
  const queryString = queryStringEncode(queryStringArgs);

  let finalPath = resolvedPathTemplate;
  if (queryStringArgs && queryString.length > 0) {
    const joiner = finalPath.includes('?') ? '&' : '?';
    finalPath = [finalPath, queryString].join(joiner);
  }

  return finalPath;
};

const createEndpoint = ({ path, method, required }) => (pathArgs = {}, data = null) => {
  const finalPath = createPath({ path, required })(pathArgs);
  return invokeEndpoint(method, finalPath, data);
};

// export API

export default function makeClient(endpoints) {
  const endpointsType = typeof endpoints;
  if (endpointsType !== 'object') {
    throw new Error(`endpoints expected to be an object, got ${endpointsType}`);
  }
  const apiClient = Object.keys(endpoints).reduce((result, k) => {
    const pathKey = `${k}Path`;
    return {
      ...result,
      [k]: createEndpoint(endpoints[k]),
      [pathKey]: createPath(endpoints[k]),
    };
  }, {});

  return { ...apiClient, ...legacy };
}
