/* eslint-disable */

import Utils from './utils';

class ProductVariantSwitcher {
  static initClass() {
    this.components = {
      container: '.js-product-variant-switcher',
      toggler: '.toggler',
      addButton: '.add-button',
      removeButton: '.remove-button',
      image: '.image',
      title: '.title',
      no_prevent_default: 'no-prevent-default'
    };

    this.classes = {
      active: 'mod-active',
      hidden: 'd-none',
      disabled: 'mod-disabled'
    };
  }

  static containerFor($toggler){
    return $toggler.parents(ProductVariantSwitcher.components.container);
  }

  static updateImage($toggler){
    const $images = this.containerFor($toggler).find(ProductVariantSwitcher.components.image);
    $images.addClass(ProductVariantSwitcher.classes.hidden);

    const activeSku = $toggler.data('sku');
    return $images
      .filter(`[data-sku=${activeSku}]`)
      .removeClass(ProductVariantSwitcher.classes.hidden);
  }

  static setDisabled($toggler, isDisabled) {
    this.containerFor($toggler).toggleClass(ProductVariantSwitcher.classes.disabled, isDisabled);
    return this.containerFor($toggler).find(this.components.addButton).toggleClass('disabled', isDisabled);
  }

  static toggleColor($toggler, event) {
    if (!$toggler.hasClass(ProductVariantSwitcher.classes.no_prevent_default)) { event.preventDefault(); }

    const container = this.containerFor($toggler);
    if (container.hasClass(ProductVariantSwitcher.classes.disabled)) { return; }

    const buttons = [ProductVariantSwitcher.components.addButton, ProductVariantSwitcher.components.removeButton];
    container.find(ProductVariantSwitcher.components.toggler).removeClass(ProductVariantSwitcher.classes.active);

    $toggler.addClass(ProductVariantSwitcher.classes.active);
    container.find(ProductVariantSwitcher.components.title).text($toggler.data('title'));

    $.each(buttons, function() {
      const $button = container.find(this.toString());
      if ($button.length) {
        const href = $button.attr('href');
        return $button.attr('href', Utils.changeParam(href, 'sku', $toggler.data('sku')));
      }
    });

    return this.updateImage($toggler);
  }
}
ProductVariantSwitcher.initClass();

$(document).on('click', '.js-product-variant-switcher .toggler', function(e) {
  return ProductVariantSwitcher.toggleColor($(this), e);
});

$(document).on('click', '.js-product-variant-switcher .add-button', function() {
  return ProductVariantSwitcher.setDisabled($(this), true);
});

$(document).on('ajax:success', '.js-product-variant-switcher .remove-button', function() {
  return ProductVariantSwitcher.setDisabled($(this), false);
});
