import { createNamespacedHelpers } from 'vuex';
import debounce from 'lodash/debounce';
import * as clipboard from 'clipboard-polyfill/text';
import Multipage from 'mixins/multipage';
import ScrollToTop from 'mixins/scroll_to_top';
import PaymentForm from 'mixins/payment_form';
import webview from 'utils/webview';
import MyAccountHeader from 'components/base/my_account_header';
import Notifications from 'components/base/notifications';
import OrderItemPrice from 'components/base/order_item_price';
import ZuoraHostedPage from 'components/views/zuora_hosted_page';
import ZuoraPaymentMethods from 'components/views/zuora_payment_methods';
import CancellationModal from 'components/views/my/cancellation_modal';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');

const {
  mapState: mapCustomerState,
  mapGetters: mapCustomerGetters,
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');
const {
  mapGetters: mapZuoraGetters,
  mapActions: mapZuoraActions,
} = createNamespacedHelpers('zuora');

const UPGRADE_MODAL_NAME = 'upgrade-options-modal';
const HH_CANCEL = '#hh_cancel';

const component = {
  inject: ['config', 'client', 'dataLayer', 'cookies'],
  mixins: [Multipage, ScrollToTop, PaymentForm],
  props: {
    validation: Object,
    hasSubscriptions: {
      type: Boolean,
      default: false,
    },
    source: String,
    showMakeAPayment: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      featuresHiddenOnMobile: true,
      voucherCode: '',
      copiedToClipboard: false,
      selectedPlan: null,
      activeTab: 0,
      additionalDetailsLoaded: false,
      // make a payment page
      isShowPaymentDetails: false,
      paymentAmount: 0,
      submitting: false,
      remainingBalance: 0,
      saveForLater: false,
      saveAsDefault: false,
      cancellationModalButtonDisabled: false,
    };
  },
  watch: {
    paymentAmount() { this.debouncedSetAuthorizationAmount(); },
    saveForLater(newValue) {
      if (!newValue) { this.saveAsDefault = false; }
    },
  },
  created() {
    this.debouncedSetAuthorizationAmount = debounce(this.setAuthorizationAmount, 500);

    if (this.showMakeAPayment && this.hasOutstandingBalance) {
      this.showForm();
      this.initPaymentAmount();
    } else {
      this.showListOrEmpty();
    }
    webview.ready();
    this.loadAdditionalDetails();
  },
  mounted() {
    this.showPositiveBalanceError();
    window.specEvents.push('mySubscriptionsPage.mounted');

    if (window.location.hash === HH_CANCEL) {
      const viewHhbtn = document.querySelector('#hive_heating_plus');
      viewHhbtn.click();

      this.$nextTick(() => this.$modal.show('hh-cancellation'));
    }
  },
  computed: {
    ...mapCustomerState(['totalBalance', 'billing', 'invoices']),
    ...mapCustomerGetters(['hasOutstandingBalance']),
    ...mapZuoraGetters(['defaultPaymentMethod', 'isDirectDebitCustomer']),
    defaultPaymentMethodLabel() {
      if (!(this.defaultPaymentMethod || {}).last4) {
        return 'No payment method';
      }

      return [
        this.defaultPaymentMethod.paymentMethodType,
        this.defaultPaymentMethod.last4,
      ].join(' ');
    },
    balanceMaxValue() {
      return Math.min(this.totalBalance, this.config.fraudProtectionBasketTotalLimit);
    },
    balanceMaxValueError() {
      if (this.totalBalance > this.balanceMaxValue) {
        return 'Amount exceeds payment limit';
      }

      return 'Amount exceeds balance';
    },
    cancellationModalDisabled() {
      return this.cancellationModalButtonDisabled || this.cookies.get('cancellationIsIOnProgress');
    }
  },
  methods: {
    ...mapGlobalActions(['showError']),
    ...mapCustomerActions(['getInvoices', 'payInvoices', 'getBilling']),
    ...mapZuoraActions(['getPaymentMethods']),
    showSubscriptionDetails(sub) {
      this.dataLayer.manualClick({ moduleId: 'subscriptions', moduleItemId: sub.sku });

      this.showPage(sub.type);

      this.$nextTick(() => window.specEvents.push('my-subscriptions.subscription-details-shown'));
    },
    showListOrEmpty() {
      this.showPage(this.hasSubscriptions ? 'list' : 'empty');
    },
    toggleFeaturesOnMobile() {
      this.featuresHiddenOnMobile = !this.featuresHiddenOnMobile;
    },
    copyVoucherCode() {
      clipboard.writeText(this.voucherCode).then(() => {
        this.copiedToClipboard = true;
      });
    },
    openModal() {
      this.client.getLocalHeroesVouchers()
        .then((data) => {
          this.voucherCode = data[0].code;

          this.$modal.show('local-heroes-modal');

          window.specEvents.push('mySubscriptionsPage.openLocalHeroesModal');
        })
        .catch(() => this.showError("Couldn't fetch the voucher"));
    },
    openUpgradeOptionsModal() {
      this.$modal.show(UPGRADE_MODAL_NAME);

      window.specEvents.push('mySubscriptionsPage.openUpgradeModal');
    },
    addToBasketAndRedirect() {
      if (!this.selectedPlan) return;

      const payload = {
        line_item: {
          sku: this.selectedPlan,
          quantity: 1,
          product: { basket_requirements: [] },
        },
      };

      this.client.addToBasket({}, payload)
        .then(() => {
          const redirectToMarketScope = this.config.marketScope === 'uk' ? '' : `/${this.config.marketScope}`;
          const redirectTo = `${redirectToMarketScope}/basket`;
          this.client.goTo(redirectTo);
        })
        .catch(() => {
          this.$modal.hide(UPGRADE_MODAL_NAME);
          this.showError('Something went wrong. Please try again.');
        });
    },
    isActiveTab(index) {
      return index === this.activeTab;
    },
    setActiveTab(index) {
      this.activeTab = index;
    },
    loadAdditionalDetails() {
      let promises = [];

      if (!this.showMakeAPayment) {
        promises = [
          ...promises,
          this.getPaymentMethods({ zuoraHostedPage: true, paymentMethods: 'credit_cards' }),
          this.getInvoices('outstanding'),
        ];
      }

      Promise.allSettled(promises).then(() => {
        this.additionalDetailsLoaded = true;
        this.initPaymentAmount();
      });
    },
    initPaymentAmount() {
      this.paymentAmount = this.totalBalance;
    },
    togglePaymentDetails() {
      this.isShowPaymentDetails = !this.isShowPaymentDetails;
    },
    invoiceProducts(invoice) {
      return invoice.products.map(p => p.name).join(', ');
    },
    onZuoraLoad() {
      PaymentForm.methods.onZuoraLoad.call(this); // super
      this.setAuthorizationAmount();
    },
    addNewCard() {
      PaymentForm.methods.addNewCard.call(this); // super
      this.saveForLater = true;
      this.saveAsDefault = true;
    },
    setAuthorizationAmount() {
      if (!this.$refs.zuoraHostedPage) { return; }

      this.$refs.zuoraHostedPage.setAuthorizationAmount(this.paymentAmount);
    },
    payNow() {
      this.validation.passes(() => {
        if (this.isShowNewCardForm) {
          this.$refs.zuoraHostedPage.submit();
        } else {
          this.paymentMethodsDisabled = true;
          this.saveForLater = false;
          this.saveAsDefault = false;
          this.processPayment(this.paymentMethodId);
        }
      });
    },
    processPayment(paymentMethodId) {
      this.submitting = true;
      this.remainingBalance = this.totalBalance - this.paymentAmount;
      this.payInvoices({
        paymentMethodId,
        amount: this.paymentAmount,
        source: this.source,
        saveForLater: this.saveForLater,
        saveAsDefault: this.saveAsDefault,
      })
        .then(() => {
          this.initPaymentAmount();
          this.showPage('success');
          this.showPositiveBalanceError();
          this.newCardForm = false;
        })
        .catch(() => { this.showPage('error'); })
        .finally(() => {
          this.submitting = false;
          this.paymentMethodsDisabled = false;
          this.paymentMethodId = null;
        });
    },
    showPositiveBalanceError() {
      if (this.billing.hasOutstandingPositiveBalance) {
        this.showError('You have an outstanding balance');
      }
    },
    tagInteraction(interaction) {
      this.dataLayer.customManualInteraction(interaction);
    },
    blockCancel() {
      this.cookies.set('cancellationIsIOnProgress', true, { expires: '5m' });
      this.cancellationModalButtonDisabled = true;
    }
  },
  components: {
    Notifications,
    MyAccountHeader,
    OrderItemPrice,
    ZuoraHostedPage,
    ZuoraPaymentMethods,
    CancellationModal,
  },
};

export default component;
