/**
 * Observe DOM changes inside a Vue instance
 */

import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import compose from 'lodash/fp/compose';
import cond from 'lodash/fp/cond';
import filter from 'lodash/fp/filter';
import complement from 'lodash/fp/complement';
import isEmpty from 'lodash/fp/isEmpty';

const isNotEmpty = complement(isEmpty);

const isInlineTemplate = node => node.hasAttribute && node.hasAttribute('inline-template');

const getInlineTemplates = filter(isInlineTemplate);

// configuration for the observer
// see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
const config = {
  subtree: true,
  childList: true,
};

/**
 *
 * @param {*} selector where Vue instance is attached
 * @param {*} options.onComponentsAdded
 * @param {*} options.onInstanceRemoved (to be implemented)
 */
function observe(selector, options) {
  const {
    onComponentsAdded,
  } = options;

  const processAdded = compose(
    cond([
      [isNotEmpty, onComponentsAdded],
    ]),
    getInlineTemplates,
    get('addedNodes'),
  );

  // apply to each mutation
  const process = map(processAdded);

  // create an observer to process added nodes
  const observer = new MutationObserver(process);

  // we need to observe the parent of the instance
  // after the first reinit the element is probably recreated
  const element = document.querySelector(selector).parentNode;

  // start listening to dom changes
  observer.observe(element, config);

  // return handle to disconnect observer
  return () => observer.disconnect();
}

export default observe;
