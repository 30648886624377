import { TYPE_CONFIRM, NEXT_STEP_RENDER_DEVICE_LIST_AFTER_POWER_CYCLE } from '../constants';

const SLT2 = [
  {
    id: 1,
    text: 'Remove the thermostat by pressing the two buttons on the sides of the thermostat and slide it up and off the wall.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/3t2HtZbvR9FEJvOcq5wB3p/b49ee3672a0e25c33e7d91a9e82a5e15/image2-33.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 2,
  },
  {
    id: 2,
    text: 'Please remove one battery from the thermostat',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/38zIQjumGIUUM4mWgDOHG6/86c3636e783b78ad3fefa4d45b98746e/image5-39.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 3,
  },
  {
    id: 3,
    text: 'Please take your thermostat and the removed battery over to your receiver box located near your boiler/hot water tank',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/VNnjlkCHehStt845uHUKV/f38a144dfd5100b195d97ad4bcb4a4ba/image6-40.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 4,
  },
  {
    id: 4,
    text: 'Please turn the receiver off by switching the isolation off and confirm all the lights have gone off on your receiver box',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5sUIazFUCA0BjFx3EEqOX2/56a921555591432c7bd5d5d43c25f826/image1-31.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 5,
  },
  {
    id: 5,
    text: 'Place the battery back into the thermostat.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/38zIQjumGIUUM4mWgDOHG6/86c3636e783b78ad3fefa4d45b98746e/image5-39.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 6,
  },
  {
    id: 6,
    text: 'Turn the receiver back on and please watch what colour the light flashes before going green.',
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5sUIazFUCA0BjFx3EEqOX2/56a921555591432c7bd5d5d43c25f826/image1-31.png?h=250',
    ...TYPE_CONFIRM,
    nextStep: 7,
  },
  {
    id: 7,
    text: `When you switch the receiver back on, it should flash Amber twice indicating it’s in connected mode and should then turn green in the top right to indicate it’s paired again.  
      Note: If you see it flash pink/white it’s been setup in standalone mode and will not work in this mode via the app or through a web browser. 
      (If this is the case then we will need to reset and pair your system which will be done further on in the troubleshooting)`,
    imageUrl: 'https://images.ctfassets.net/mijf9lz5yt3u/5mczcTcbQgwbGSP3MOcRaY/8452852be872417eebe0c7f31e12f376/receiver_green.png?h=250',
    ...TYPE_CONFIRM,
    ...NEXT_STEP_RENDER_DEVICE_LIST_AFTER_POWER_CYCLE,
  },
];

export default SLT2;
