const mixin = {
  computed: {
    hasDiscount() {
      return false;
    },
  },
  methods: {
    swapSku() {
      // do nothing
    },
    assignQuantity() {
      // do nothing
    },
  },
};

export default mixin;
