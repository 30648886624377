import PopupMenu from 'components/base/popup_menu';
import LanguageChanger from 'components/base/language_changer';

const component = {
  // subcomponents
  components: {
    'popup-menu': {
      ...PopupMenu,
      components: {
        'language-changer': LanguageChanger,
      },
    },
  },
};

export default component;
