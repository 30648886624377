const component = {
  methods: {
    scrollToFaq(targetId) {
      this.$scrollTo(`#${targetId}`, 800, { offset: -150 });
      this.$emit('faq-link-clicked', { targetId });
    },
  },
};

export default component;
