import { createNamespacedHelpers } from 'vuex';

import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HoneyProductRegularBuyHub from 'components/views/honey/product_regular_buy_hub';
import AmountDiscountBadge from 'mixins/amount_discount_badge';
import HoneyOptionSelector from 'components/views/honey/option_selector';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('pdpLightsBuyPage');

const component = {
  inject: ['config'],
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    pdpLightsSkuMappings: Object,
    productMultipacks: Array,
    multipackTypeInitial: String,
    bulbTypeInitial: String,
    fittingTypeInitial: String,
    renderFitting: Boolean,
  },
  data() {
    return {
      basketInterval: null,
      namespace: 'pdpLightsBuyPage',
    };
  },
  computed: {
    ...mapState([
      'hubOption',
      'isPriceLoading',
    ]),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'fittingType',
      'bulbType',
      'multipackType',
      'sku',
      'basketPayload',
      'quantity',
      'hubSku',
      'pdpLights',
      'multipackHasDiscount',
      'multipackPrice',
      'multipackPriceBigger',
      'multipackPriceBiggest',
      'multipackSave',
      'multipackSaveBigger',
      'multipackSaveBiggest',
    ]),
    isFreeDeliveryVisible() {
      return this.totalDiscountedPrice > this.config.deliveryThreshold;
    },
    priceBase() {
      return false;
    },
  },
  created() {
    const {
      pdpLightsSkuMappings, productMultipacks, multipackTypeInitial,
      bulbTypeInitial, fittingTypeInitial,
    } = this;

    this.init({
      pdpLightsSkuMappings,
      productMultipacks,
      multipackTypeInitial,
      bulbTypeInitial,
      fittingTypeInitial,
    });
  },
  beforeDestroy() {
    clearInterval(this.basketInterval);
  },
  methods: {
    ...mapActions([
      'setFittingType',
      'setBulbType',
      'setMultipackType',
      'increment',
      'init',
      'decrement',
      'setHubSku',
      'setHubOption',
    ]),
    showEfficiencyLabelKey(key) {
      let CurrentValue = this.bulbType;
      if (this.renderFitting) {
        CurrentValue += `-${this.fittingType}`;
      }

      return key === CurrentValue;
    },
  },
  components: {
    TrustPilotPopup,
    HoneyProductRegularBuyHub,
    HoneyOptionSelector,
  },
};

export default component;
