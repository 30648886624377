const component = {
  props: {
    sku: String,
    product: {
      price: Number,
      quantity: Number,
      minQuantity: Number,
      maxQuantity: Number,
    },
  },
  computed: {
    total() {
      return this.product.price * this.product.quantity;
    },
  },
  methods: {
    increase() {
      this.$emit('handle-increase', this.sku);
      this.$nextTick(() => window.specEvents.push('hive-actions.product-increase'));
    },
    decrease() {
      this.$emit('handle-decrease', this.sku);
      this.$nextTick(() => window.specEvents.push('hive-actions.product-decrease'));
    },
  },
};

export default component;
