<template>
  <div class="position-relative my-3">
    <div class="form-control dashed">
      <div class="col-8 text-center">
        {{ code }}
      </div>
    </div>
    <div class="postocde-lookup-form-btn">
      <button
        type="button"
        class="btn btn-secondary btn-sm"
        v-on:click="copyToClipboard"
      >
        Copy code
      </button>
    </div>
  </div>
</template>

<script>
import * as clipboard from 'clipboard-polyfill/text';

const component = {
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copiedToClipboard: false,
    };
  },
  methods: {
    copyToClipboard() {
      clipboard.writeText(this.code).then(() => {
        this.copiedToClipboard = true;
      });
    },
  },
};

export default component;
</script>
