import { createNamespacedHelpers } from 'vuex';
import Notifications from 'components/base/notifications';
import MyAccountHeader from 'components/base/my_account_header';
import AddressBlock from 'components/base/address_block';
import AddressAutocomplete from 'components/views/address_autocomplete';
import CustomerAddresses from 'components/views/customer_addresses';
import webview from 'utils/webview';
import Multipage from 'mixins/multipage';
import ScrollToTop from 'mixins/scroll_to_top';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');
const {
  mapState: mapCustomerState,
  mapGetters: mapCustomerGetters,
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const component = {
  mixins: [Multipage, ScrollToTop],
  props: {
    validation: Object,
  },
  data() {
    return {
      currentPage: 'list',
      isEditMode: false,
      currentAddress: {},
    };
  },
  created() {
    this.showListOrEmpty();
    webview.ready();
  },
  computed: {
    ...mapCustomerState([
      'addresses',
    ]),
    ...mapCustomerGetters([
      'hasAddresses',
    ]),
    currentReference() {
      return this.currentAddress.reference;
    },
    isSaveButtonDisabled() {
      const { invalid, pending } = this.validation;
      const isPostcodeUndefined = typeof (this.currentAddress.postcode) === 'undefined';

      return pending || invalid || isPostcodeUndefined;
    },
    isWebview() {
      return webview.isWebview();
    },
  },
  methods: {
    ...mapGlobalActions([
      'showNotice',
      'showError',
    ]),
    ...mapCustomerActions({
      createCustomerAddress: 'createAddress',
      updateCustomerAddress: 'updateAddress',
      deleteCustomerAddress: 'deleteAddress',
    }),
    editAddress(address) {
      this.currentAddress = { ...address };
      this.isEditMode = true;
      this.showForm();
    },
    newAddress() {
      this.currentAddress = {};
      this.isEditMode = false;
      this.showForm();
    },
    showListOrEmpty() {
      this.showPage(this.hasAddresses ? 'list' : 'empty');
      this.currentAddress = {};
      this.isEditMode = false;
    },
    showDefaultError() {
      this.showError('Something went wrong');
    },
    updateCurrentAddress(address) {
      ['address1', 'city', 'territory', 'postcode'].forEach((prop) => {
        this.$set(this.currentAddress, prop, address[prop]);
      });
    },
    perform(action, notice) {
      const payload = {
        reference: this.currentReference,
        data: this.currentAddress,
      };

      this.scrollToTop();
      this.showLoading();

      return this[action](payload)
        .then(() => {
          this.showNotice(notice);
        })
        .catch(() => {
          this.showDefaultError();
        })
        .finally(() => {
          this.showListOrEmpty();
        });
    },
    saveAddress() {
      if (this.isEditMode) {
        this.perform('updateCustomerAddress', 'Address has been updated');
      } else {
        this.perform('createCustomerAddress', 'Address has been created');
      }
    },
    confirmAddressDeletion() {
      this.$refs.removalModal.open();
    },
    deleteAddress() {
      this.perform('deleteCustomerAddress', 'Address has been deleted');
    },
  },
  components: {
    Notifications,
    MyAccountHeader,
    AddressBlock,
    AddressAutocomplete,
    CustomerAddresses,
  },
};

export default component;
