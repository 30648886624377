const HOMESHIELD_PLUS = 'homeshield_plus';
const PROFESSIONAL_INSTALLATION = 'professional';

const mixin = {
  methods: {
    generatePackPayload(includedProducts,
      showInstallationPageAfterPurchase,
      sku,
      freeSku,
      homeshieldOption,
      installationOption,
      professionalInstallationSku) {
      const products = [];
      let selectedHomeshieldSku;

      Object.keys(includedProducts).forEach((includedProductSku) => {
        const quantity = includedProducts[includedProductSku];

        if (quantity > 0) products.push({ sku: includedProductSku, quantity });
      });

      if (homeshieldOption === HOMESHIELD_PLUS) {
        selectedHomeshieldSku = sku;
      } else {
        selectedHomeshieldSku = freeSku;
      }

      if (selectedHomeshieldSku) { products.push({ sku: selectedHomeshieldSku, quantity: 1 }); }

      if (installationOption === PROFESSIONAL_INSTALLATION) {
        products.push({ sku: professionalInstallationSku, quantity: 1 });
      }


      const productBuy = {
        product: {
          sku: products,
          showInstallationPage: showInstallationPageAfterPurchase,
        },
      };
      return productBuy;
    },
  },
};

export default mixin;
