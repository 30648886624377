import Vue from 'vue/dist/vue.esm';

export const STATUSES = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
};

export const getters = {
  isPending: ({ status }) => status === STATUSES.PENDING,
};

export const mutations = {
  [STATUSES.SUCCESS](state) {
    Vue.set(state, 'status', STATUSES.SUCCESS);
  },
  [STATUSES.FAILURE](state) {
    Vue.set(state, 'status', STATUSES.FAILURE);
  },
  [STATUSES.PENDING](state) {
    Vue.set(state, 'status', STATUSES.PENDING);
  },
};
