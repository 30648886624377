import isNumber from 'lodash/isNumber';

const SLOT_INTERVALS = { am: '8am - 1pm', pm: '12pm - 6pm' };
const DAYS_OF_WEEK_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];
const ADD_UNITS = {
  year: 0, years: 0, month: 1, months: 1, day: 2, days: 2,
};

// eslint-disable-next-line no-underscore-dangle, no-use-before-define
const _new = (...args) => new CalendarDate(...args); // shortcut

// OMST-10341: Below comment is not correct, Date objects carry
// a timezone. I notice that the date is parsed incorrectly for my
// timezone (GMT-4)

// A pure date. No time, no timezone.
// The month is not zero-based, so `1` stands for January
// Can be intialized in a few ways:
//
//   new CalendarDate(2020, 10, 10)
//   new CalendarDate(new Date(1010, 9, 10))
//   new CalendarDate('2020-10-10')
//
// All functions always return a new instance and don't mutate the state
class CalendarDate {
  static now() {
    return _new();
  }

  constructor(year = new Date(), month, day) {
    if (isNumber(year) && isNumber(month) && isNumber(day)) {
      this.date = new Date(year, month - 1, day);
    } else {
      this.date = new Date(year.date || year);
    }
    this.date.setHours(0);
    this.date.setMinutes(0);
    this.date.setSeconds(0);
    this.date.setMilliseconds(0);
  }

  get year() { return this.date.getFullYear(); }

  get month() { return this.date.getMonth() + 1; }

  get day() { return this.date.getDate(); }

  get wday() { return this.date.getDay(); }

  get monthName() { return MONTH_NAMES[this.month - 1]; }

  dup(modifyFn = null) {
    const newDate = new Date(this.date);

    if (modifyFn) { modifyFn(newDate); }

    return _new(newDate);
  }

  toString() { return `${this.year}-${this.month}-${this.day}`; }

  toISO8601() {
    const paddedMonth = String(this.month).padStart(2, '0');
    const paddedDay = String(this.day).padStart(2, '0');

    return `${this.year}-${paddedMonth}-${paddedDay}`;
  }

  valueOf() { return this.date.getTime(); }

  // There is no way to overload `==`/`===` in JS,
  // so functions like `eq`, `lte`, `gte` should be used instead
  eq(...args) { return this.valueOf() === _new(...args).valueOf(); }

  lte(...args) {
    const other = _new(...args);

    return this < other || this.eq(other);
  }

  gte(...args) {
    const other = _new(...args);

    return this > other || this.eq(other);
  }

  add(offset, unit = 'day') {
    const parts = [this.year, this.month, this.day];
    const index = ADD_UNITS[unit];

    if (index < 0) {
      throw new Error('Invalid unit');
    }

    parts[index] += offset;

    let newDate = _new(...parts);

    // OMST-10459
    // current date might be Jan 29 and Feb might end on the 28th
    // in that case 1 month later becomes March 1st, need to return Feb 28th instead
    if ((unit === 'month' || unit === 'months') && newDate.month > parts[ADD_UNITS.month]) {
      parts[ADD_UNITS.day] = 1;
      newDate = _new(parts).endOfMonth();
    }

    return newDate;
  }

  prev() { return this.add(-1, 'day'); }

  next() { return this.add(1, 'day'); }

  beginningOfMonth() { return _new(this.year, this.month, 1); }

  endOfMonth() { return _new(this.year, this.month + 1, 0); }

  beginningOfWeek() {
    return this.dup((date) => {
      date.setDate(this.day - this.wday + (this.wday === 0 ? -6 : 1));
    });
  }

  endOfWeek() {
    return this.beginningOfWeek().dup((date) => {
      date.setDate(date.getDate() + 6);
    });
  }

  diff(...args) {
    const other = _new(...args);

    const years = other.year - this.year;
    const months = years * 12 + other.month - this.month;

    return { years, months };
  }

  isToday() {
    return this.eq(this.constructor.now());
  }

  isSunday() {
    return this.wday === 0;
  }
}

const buildCalendarPane = (date, entryFn = null) => {
  const beginningOfMonth = date.beginningOfMonth();
  const endOfMonth = beginningOfMonth.endOfMonth();
  const entries = [];

  let currentDate = beginningOfMonth.beginningOfWeek();

  while (currentDate.lte(endOfMonth.endOfWeek())) {
    let entry = {
      date: currentDate,
      isVisible: currentDate.gte(beginningOfMonth) && currentDate.lte(endOfMonth),
    };

    if (entryFn) { entry = entryFn(entry); }

    entries.push(entry);

    currentDate = currentDate.next();
  }

  return { entries, month: beginningOfMonth.monthName };
};

const buildCalendar = (startDate, endDate, entryFn = null) => {
  const calendar = [];
  const numberOfPanes = startDate.diff(endDate).months + 1;

  for (let i = 0; i < numberOfPanes; i++) {
    const date = startDate.beginningOfMonth().add(i, 'months');

    calendar.push(
      buildCalendarPane(date, entryFn)
    );
  }

  return calendar;
};

export {
  SLOT_INTERVALS, DAYS_OF_WEEK_SHORT, MONTH_NAMES, CalendarDate, buildCalendarPane, buildCalendar,
};
