/* eslint-disable */

class CompatibilityChecker {
  static initClass() {
    this.prototype.enableLog = false;
    this.prototype.currentStage = '0';
    this.prototype.outcomeStage = 'stage-';
    this.prototype.outcomeJourney = 'journey-';
    this.prototype.outcomeMessage = 'message-';
  }

  constructor(surveyId, data, directLinks, stages, showElementOnSuccess = null) {
    this.surveyId = surveyId;
    this.survey = data;
    this.surveyStageCount = this.survey.length;
    this.directLinks = directLinks;
    this.stages = stages;
    this.showElementOnSuccess = showElementOnSuccess;
    this.setupAnswers();
    this.setupNavigation();
  }

  // Bind answer buttons
  setupAnswers() {
    this.log('setupAnswers');
    $(document).off('click', this.surveyId + ' .answer');
    return $(document).on('click', this.surveyId + ' .answer', e => {
      e.preventDefault();
      const answer = $(e.target).data('answer');
      this.record(answer);
      return this.selectButton($(e.target));
    });
  }

  // Bind navigation buttons
  setupNavigation() {
    $(document).off('click', this.surveyId + ' .next');
    $(document).on('click', this.surveyId + ' .next', e => {
      e.preventDefault();
      return this.next();
    });
    $(document).off('click', this.surveyId + ' .back');
    return $(document).on('click', this.surveyId + ' .back', e => {
      e.preventDefault();
      return this.back();
    });
  }

  // Set answer for current stage
  record(answer) {
    this.log(`record:${answer}`);
    this.survey[this.currentStage].answer = answer;
    if (this.directLinks) {
      return this.next();
    }
  }

  // Move to first stage
  back() {
    if (parseInt(this.currentStage) === 0) {
      // If we're on first stage, back button closes the modal
      return $(this.surveyId).modal('hide');
    } else {
      // In all other cases, just move to the saved 'back' stage
      let toStage = this.survey[this.currentStage].back;
      const attr = $('.journey-fail').attr('hidden');

      // If we're on failed message, back button move bask to stages
      if ($('.journey-fail').length && typeof attr === typeof undefined) {
        toStage = this.currentStage;
        $('.journey-fail').prop('hidden', true);
        $(this.surveyId + ' .stages').prop('hidden', false);
        $(this.surveyId + ' .breadcrumbs').prop('hidden', false);
      }

      return this.transition(toStage);
    }
  }

  // Move to next stage
  next() {
    const { answer } = this.survey[this.currentStage];
    if (answer > -1) {
      const { outcome } = this.survey[this.currentStage].answers[answer];
      this.log(`next ${outcome}`);
      switch (false) {
        case outcome.indexOf(this.outcomeStage) !== 0: return this.progressToStage(outcome);
        case outcome.indexOf(this.outcomeJourney) !== 0: return this.stopAndShowJourney(outcome);
        case outcome.indexOf(this.outcomeMessage) !== 0: return this.stopAndShowMessage(outcome);
      }
    }
  }

  // Transition stages, from currentStage to stage
  transition(stage) {
    this.log(`transition ${stage}`);
    this.hideContent(false);
    // Hide current stage
    $(this.surveyId + ' .stage.stage-' + this.currentStage).prop('hidden', true);
    // Show stage
    $(this.surveyId + ' .stage.stage-' + stage).prop('hidden', false);
    $(this.surveyId + ' .message').prop('hidden', true);
    // Remember prev stage when going forward
    if (this.currentStage < stage) {
      const backStage = this.currentStage;
      // Set back link
      this.survey[stage].back = backStage;
    }
    // Update current stage
    this.currentStage = stage;
    // Show/hide back button
    if (parseInt(this.currentStage) > 0) { // Hidden on first page
      $(this.surveyId + ' .back').prop('hidden', false);
    } else {
      $(this.surveyId + ' .back').prop('hidden', true);
    }
    // Show next button
    $(this.surveyId + ' .next').prop('hidden', false);

    window.specEvents.push('compatibility-checker.step-rendered');

    // Mark progress
    return this.markProgress();
  }

  markProgress() {
    this.log(`markProgress currentStage:${this.currentStage} count:${this.surveyStageCount}`);
    let stage = this.currentStage;
    if (this.currentStage >= this.stages) { stage = this.stages - 1; }

    $(this.surveyId + ' .breadcrumbs').find('a').removeClass('active');

    for (let completeStage = 0, end = stage-1, asc = 0 <= end; asc ? completeStage <= end : completeStage >= end; asc ? completeStage++ : completeStage--) {
      $(this.surveyId + ' .breadcrumbs').find(`a.breadcrumb-${completeStage}`).addClass('complete');
    }

    for (let incompleteStage = stage, end1 = this.surveyStageCount-1, asc1 = stage <= end1; asc1 ? incompleteStage <= end1 : incompleteStage >= end1; asc1 ? incompleteStage++ : incompleteStage--) {
      $(this.surveyId + ' .breadcrumbs').find(`a.breadcrumb-${incompleteStage}`).removeClass('complete');
    }

    return $(this.surveyId + ' .breadcrumbs').find(`a.breadcrumb-${stage}`).addClass('active');
  }


  hideContent(hideStages) {
    // Hide stages
    if (hideStages) {
      $(this.surveyId + ' .stages').prop('hidden', true);
      $(this.surveyId + ' .breadcrumbs').prop('hidden', true);
    }
    // Hide message
    $(this.surveyId + ' .message').prop('hidden', true);
    // Hide journey
    $(this.surveyId + ' .journey').prop('hidden', true);
    return $(this.surveyId + ' .intro-text').prop('hidden', true);
  }

  // Split out destination from outcome: 'stage-1' >> '1'
  getOutcomeDestination(outcome) {
    return outcome.split('-')[1];
  }

  progressToStage(outcome) {
    const toStage = parseInt(this.getOutcomeDestination(outcome), 10) - 1;
    this.log(`progressToStage ${toStage}`);
    if ((toStage > 0) && (toStage <= this.surveyStageCount)) {
      return this.transition(toStage);
    }
  }

  stopAndShowMessage(outcome) {
    this.log(`stopAndShowMessage ${outcome}`);
    // Show next button
    $(this.surveyId + ' .next').prop('hidden', false);
    // Show back button
    $(this.surveyId + ' .back').prop('hidden', false);
    // Hide journey
    this.hideContent(false);
    // Trigger event for the Data Layer
    $(document).trigger('error-appended');

    // Show outcome content
    return this.showOutcomeContent(outcome);
  }

  stopAndShowJourney(outcome) {
    this.log(`stopAndShowJourney ${outcome}`);
    // Hide back/next button
    $(this.surveyId + ' .footer').prop('hidden', true);
    // Hide stages
    this.hideContent(true);

    window.specEvents.push('compatibility-checker.step-rendered');

    // Show outcome content
    return this.showOutcomeContent(outcome);
  }

  showOutcomeContent(outcome, hideStages) {
    // Show content
    $(this.surveyId + ' .' + outcome).prop('hidden', false);
    if (this.showElementOnSuccess && (outcome === `${this.outcomeJourney}success`)) { return $(this.showElementOnSuccess).removeClass('hidden'); }
  }

  selectButton(button) {
    button.parent().siblings().find('a.answer').removeClass('active');
    return button.addClass('active');
  }

  reset() {
    // Reset collected data
    for (let stage = 0, end = this.surveyStageCount-1, asc = 0 <= end; asc ? stage <= end : stage >= end; asc ? stage++ : stage--) {
      this.survey[stage].answer = -1;
      this.survey[stage].back = -1;
    }
    // Unselect all answer buttons
    $(this.surveyId + ' .stages a.answer').removeClass('active');
    // Hide journey or message content
    this.hideContent(false);
    // Show stages, hide all .stage divs
    $(this.surveyId + ' .stages').prop('hidden', false);
    $(this.surveyId + ' .stages .stage').prop('hidden', true);
    // Show only first .stage div
    $(this.surveyId + ' .stage.stage-0').prop('hidden', false);
    // Initial stage
    this.currentStage = '0';
    // Hide back button
    $(this.surveyId + ' .back').prop('hidden', true);
    // Show next button
    $(this.surveyId + ' .next').prop('hidden', false);
    // Show footer
    return $(this.surveyId + ' .footer').prop('hidden', false);
  }


  log(message) {
    if (this.enableLog) {
      return console.log(message);
    }
  }
}
CompatibilityChecker.initClass();

const TC_IT_MODAL_ID = '#thermostat-compatibility-survey-italy';

$(document).on('shown.bs.modal', TC_IT_MODAL_ID, () => {
  const survey = $(TC_IT_MODAL_ID).data('survey-json');
  const thermostatCompatibilityItaly = new CompatibilityChecker(TC_IT_MODAL_ID, survey, false, 5);
  return thermostatCompatibilityItaly.reset();
});

const COMPATIBILITY_CHECKER_JOURNEY = '#compatibility-checker';
const survey = $(COMPATIBILITY_CHECKER_JOURNEY).data('survey-json');

if (survey) {
  new CompatibilityChecker(COMPATIBILITY_CHECKER_JOURNEY, survey, true, 3, '.js-login-form');
}
