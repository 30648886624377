const mixin = {
  inject: ['config'],
  computed: {
    discountBadge() {
      if (!this.hasDiscount) {
        return '';
      }

      return `Save ${this.config.currency.symbol}${this.totalDiscount.toFixed(2)}`;
    },
  },
};

export default mixin;
