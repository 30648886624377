import ABTest from 'utils/ab_test';
import camelCase from 'lodash/camelCase';
import zipObject from 'lodash/zipObject';

const makeABTests = (abTests) => {
  const keys = abTests.map(name => camelCase(name));
  const values = abTests.map(name => new ABTest(name));

  return zipObject(keys, values);
};

export default makeABTests;
