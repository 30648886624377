import WOW from 'vendors/patched_wow';

const wow = new WOW({
  boxClass: 'hive-animation', // wow = default
  animateClass: 'animated', // default
  offset: 0, // default
  mobile: true, // default
  // reason https://github.com/matthieua/WOW/issues/166
  live: false, // default
});

wow.init();
