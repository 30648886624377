<template>
  <div class="postocde-lookup-form">
    <div class="form-group mb-1">
      <div class="row g-0">
        <div class="col">
          <p class="mb-0 font-weight-bold">
            Postcode
          </p>
        </div>
        <div v-if="allowEnterManually" class="col text-end">
          <button
            type="button"
            class="btn btn-link btn-link-inherit text-underline font-weight-bold js-enter-manually"
            data-spec="enter_manually"
            v-on:click="enterManual"
          >
            Enter address manually
          </button>
        </div>
      </div>
    </div>
    <div class="form-group mb-0">
      <div class="position-relative">
        <input
          v-model="postcode"
          type="text"
          name="postcode"
          class="form-control lookup"
          maxlength="20"
          v-bind:disabled="disabled"
          v-on:keypress.enter.prevent
        >
        <div class="postocde-lookup-form-btn">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            v-bind:class="isLoading ? 'btn-loading' : ''"
            v-bind:disabled="!postcode || isLoading || disabled"
            v-on:click="search"
          >
            <span v-if="isLoading" class="btn-loading-icon" />
            <span v-else>Find Address</span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="hasError()" class="has-danger">
      <div class="form-control-feedback">
        <label class="error postocde-lookup-form-errors">
          <span v-if="hasError('NO_POSTCODE')">
            Sorry, we didn't find your postcode. Please try to enter your address manually.
          </span>
          <span v-else-if="hasError('NO_ADDRESS')">
            Sorry, no addresses found.
          </span>
          <span v-else-if="hasError()">
            {{ error }}
          </span>
        </label>
      </div>
    </div>

    <div v-show="hasResults" class="postocde-lookup-form-results dropdown">
      <p class="my-2 font-weight-bold ">
        Select an address
      </p>
      <div
        class="postocde-lookup-form-list-container overflow-auto dropdown-menu d-block w-100 shadow"
      >
        <div
          v-for="entry in results"
          v-bind:key="entry.id"
          class="dropdown-item postocde-lookup-form-item"
        >
          <a href="#" v-on:click.prevent="getAddressById(entry.id)">
            <h6>{{ entry.address }}</h6>
            <p class="text-gray mb-1 text-small">{{ entry.description }}</p>
            <hr class="mt-0 mb-0">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { buildAddress, processResponse } from 'utils/pca_processors';

const component = {
  inject: ['client', 'config'],
  props: {
    allowEnterManually: {
      type: Boolean,
      default: true,
    },
    initialPostcode: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      postcode: this.initialPostcode,
      results: [],
      isLoading: false,
      error: '',
    };
  },
  computed: {
    pcaCountry() {
      return this.config.marketScope === 'uk' ? 'GB' : this.config.marketScope.toUpperCase();
    },
    hasResults() {
      return this.results.length > 0;
    },
  },
  methods: {
    hasError(code) {
      if (code) { return this.error === code; }

      return !!this.error;
    },
    processAddressResponse(response) {
      const items = response.Items;
      if (items.length > 0) {
        this.results = processResponse(items).map(
          item => ({
            id: item.Id,
            address: item.Text,
            description: item.Description,
            type: item.Type,
          }),
        );
      } else this.error = 'NO_POSTCODE';
    },
    search() {
      this.isLoading = true;
      this.error = '';
      this.results = [];
      return this.client.pcaFind(
        {
          key: this.config.pcaPredict.key,
          text: this.postcode,
          countries: this.pcaCountry,
        },
      ).then((response) => {
        const items = response.Items;
        if (items.length > 0) {
          if (items[0].Type === 'Address') {
            this.processAddressResponse(response);
          } else {
            this.getContainerById(items[0].Id);
          }
        } else this.error = 'NO_POSTCODE';
      }).catch(
        (e) => { this.error = e.message || 'NO_POSTCODE'; },
      );
    },
    getContainerById(id) {
      this.error = '';
      this.results = [];
      return this.client.pcaFind(
        {
          key: this.config.pcaPredict.key,
          text: this.postcode,
          countries: this.pcaCountry,
          container: id,
        },
      ).then(this.processAddressResponse).catch(
        (e) => { this.error = e.message || 'NO_POSTCODE'; },
      ).then(() => { this.isLoading = false; });
    },
    getAddressById(id) {
      this.error = '';
      return this.client.pcaRetrieve(
        { id, key: this.config.pcaPredict.key },
      ).then(
        ({ Items: items }) => {
          if (items.length > 0) {
            const processedItems = processResponse(items);
            this.$emit('update-address', buildAddress(processedItems));
            this.$emit('enter-manually');
          } else this.error = 'NO_ADDRESS';
        },
      ).catch(
        (e) => { this.error = e.message || 'NO_ADDRESS'; },
      ).then(() => { this.isLoading = false; });
    },
    enterManual() {
      this.$emit('enter-manually');
    },
  },
};

export default component;
</script>
