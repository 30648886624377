import logger from 'utils/logger';

// enforce uniqueness of component name at run time
const instances = {};

const component = {
  props: {
    name: {
      required: true,
      type: String,
    },
  },
  computed: {
    hasVoted() {
      return this.$store.getters['ratings/hasAlreadyVotedFor'](this.name);
    },
    rating() {
      return this.$store.getters['ratings/getVoteFor'](this.name);
    },
  },
  methods: {
    vote(rating) {
      this.$store.dispatch('ratings/vote', { name: this.name, rating });
    },
  },
  created() {
    if (instances[this.name]) {
      logger.error(`<start_rating> with name ${this.name} already exists.`);
    }
    instances[this.name] = true;
  },
  destroyed() {
    instances[this.name] = false;
  },
};

export default component;
