const component = {
  props: {
    hotspots: Array,
  },
  data() {
    return {
      activeHotspot: this.hotspots[0],
    };
  },
  mounted() {
    this.setInfoHeight();
    window.addEventListener('resize', this.setInfoHeight);
  },
  methods: {
    showHotspot(hotspotReference) {
      this.activeHotspot = hotspotReference;
      this.setInfoHeight();
    },
    showNext() {
      const i = (this.hotspots.indexOf(this.activeHotspot) + 1) % this.hotspots.length;

      this.activeHotspot = this.hotspots[i];
      this.setInfoHeight();
    },
    showPrevious() {
      let i = this.hotspots.indexOf(this.activeHotspot) - 1;
      if (i < 0) i += this.hotspots.length;

      this.activeHotspot = this.hotspots[i];
      this.setInfoHeight();
    },
    setInfoHeight() {
      const height = this.$refs[`product-${this.activeHotspot}`].clientHeight;
      this.$refs.info.style.height = `${height + 64}px`;
    },
  },
};

export default component;
