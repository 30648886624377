/* eslint-disable */

class ToggleHiddenClass {
  static initClass() {
    this.prototype.hidden = 'd-none';
    this.prototype.attr = {
      toggleSelector: 'data-bs-toggle-hidden-class',
      scopeSelector: 'data-bs-toggle-scope',
      showSelector: 'data-show-hidden-class',
      onAjax: 'toggle-on-ajax:success'
    };
  }

  constructor() {
    this.el = {
      init: `[${this.attr.toggleSelector}]`, // get initial element by attribute
      form: '.js-toggle-hidden-class-form' // form needed to work with rails 'button_to'
    };
  }

  update(event, $initEl, onAjax) {
    const selector = $initEl.attr(this.attr.toggleSelector);
    const showSelector = $initEl.attr(this.attr.showSelector);
    const isAjax = (event.type === 'ajax:success') && onAjax;
    const isClick = (event.type === 'click') && !onAjax;

    if (!selector && !showSelector) {return;}

    if (isAjax || isClick) {
      event.preventDefault() && isClick;

      let $scope = $initEl.closest($initEl.attr(this.attr.scopeSelector));
      if (!$scope.length) { $scope = null; }

      this.toggle(selector, $scope);
      if (showSelector) {return this.show(showSelector);}
    }
  }

  show(selector) {
    // for now this no needed scope
    return $(selector).removeClass(this.hidden);
  }

  toggle(selector, $scope) {
    return $(selector, $scope).toggleClass(this.hidden);
  }
}
ToggleHiddenClass.initClass();

const toggleHiddenClass = new ToggleHiddenClass;

$(document).on('click ajax:success', toggleHiddenClass.el.init, function(e) {
  const onAjax = $(e.target).data(toggleHiddenClass.attr.onAjax);
  return toggleHiddenClass.update(e, $(e.target), onAjax);
});

$(document).on('ajax:success', toggleHiddenClass.el.form, function(e) {
  const $initEl = $(e.target).find(toggleHiddenClass.el.init);
  return toggleHiddenClass.update(e, $initEl, true);
});
