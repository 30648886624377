import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('heatingPlusBuyPage');

const component = {
  props: {
    reverseButtonsOrder: Boolean,
  },
  computed: {
    ...mapGetters([
      'paymentType',
      'thermostatPresence',
    ]),
  },
  methods: {
    ...mapActions([
      'setPaymentType',
    ]),
  },
  mounted() {
    if (this.reverseButtonsOrder) {
      this.setPaymentType('monthly');
    }
  },
};

export default component;
