import { createNamespacedHelpers } from 'vuex';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import AddToBasketButton from 'mixins/add_to_basket_button';
import AmountDiscountBadge from 'mixins/amount_discount_badge';
import HoneyOptionSelector from 'components/views/honey/option_selector';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('evChargerBuyPage');
const { mapState: mapBasketState } = createNamespacedHelpers('basket');

export const PERCENTAGE = 'percentage';

const component = {
  inject: ['config'],
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    evChargerSkuMappings: Object,
    defaultTetheredOption: String,
    defaultInstallationOption: String,
    partnerVoucher: String,
    tetheredProInstallPriceDifference: Number,
    untetheredProInstallPriceDifference: Number,
    v12Enabled: Boolean,
    v12NumberOfMonthlyPayment: Number,
    v12NumberOfMonthlyPaymentMappings: Object,
  },
  data() {
    return {
      buttonDisabled: false,
      namespace: 'evChargerBuyPage',
    };
  },
  computed: {
    ...mapState(['quantity', 'isPriceLoading', 'pricing', 'tetherOption', 'grantOption', 'installationType']),
    ...mapBasketState(['basket']),
    ...mapGetters([
      'deposit',
      'oneOffPriceLater',
      'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
      'sku',
    ]),
    isFreeDeliveryVisible() {
      return this.totalDiscountedPrice > this.config.deliveryThreshold;
    },
    installationTypeDisabled() {
      return false;
    },
    depositAvailable() {
      return this.deposit > 0;
    },
    v12NumberOfPayments() {
      return this.v12NumberOfMonthlyPaymentMappings[this.sku];
    },
    v12MonthlyPrice() {
      return this.totalPrice / this.v12NumberOfPayments;
    },
    v12Available() {
      return this.v12Enabled;
    },
    discountBadge() {
      if (this.totalDiscount > 0) {
        return `Save ${this.config.currency.symbol}${this.totalDiscount}`;
      }

      return '';
    },
  },
  created() {
    const {
      evChargerSkuMappings,
      partnerVoucher,
      defaultTetheredOption,
      defaultInstallationOption,
    } = this;

    this.init({
      evChargerSkuMappings,
      partnerVoucher,
    });

    if (this.setTetherOption !== defaultTetheredOption) {
      this.setTetherOption(defaultTetheredOption);
    }

    if (this.installationType !== defaultInstallationOption) {
      this.setInstallationType({ selected: defaultInstallationOption });
    }
  },
  methods: {
    ...mapActions([
      'init',
      'increment',
      'decrement',
      'setTetherOption',
      'setInstallationType',
    ]),
    onButtonClick() {
      this.addToBasket({ ...this.basketPayload, errorCallback: this.scrollToTop });
    },
    addToBasketButtonClicked() {
      this.addToBasket({ ...this.basketPayload, errorCallback: this.scrollToTop });
    },
  },
  components: {
    TrustPilotPopup,
    HoneyOptionSelector,
  },
};

export default component;
