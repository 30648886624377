import { createNamespacedHelpers } from 'vuex';
import HoneyTrustPilot from 'components/views/honey_trust_pilot';
import HoneyVideoPlayback from 'components/views/honey/video_playback';
import HoneyPackItem from 'components/views/honey/pack_item';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import AddToBasketButton from 'mixins/add_to_basket_button';
import AmountDiscountBadge from 'mixins/amount_discount_badge';
import HoneyOptionSelector from 'components/views/honey/option_selector';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('packBuyPage');

const component = {
  inject: ['cookies'],
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    packTitle: String,
    packSkuMappings: Object,
    showInstallationPageAfterPurchase: Boolean,
    homeshieldSku: String,
    freeHomeshieldSku: String,
    playbackSku: String,
    baseInstallationOption: String,
    baseProfessionalInstallationPrice: Number,
    baseProfessionalInstallationSku: String,
    baseIncludedProducts: {
      type: Object,
      required: true,
      validator: obj => Object.values(obj).every(quantity => typeof quantity === 'number'),
    },
  },
  data() {
    return {
      includedProducts: this.baseIncludedProducts,
      quantityLocked: false,
      namespace: 'packBuyPage',
      professionalInstallationSku: this.baseProfessionalInstallationSku,
      professionalInstallationPrice: this.baseProfessionalInstallationPrice,
    };
  },
  computed: {
    ...mapState([
      'hubOption',
      'petFriendlyOption',
      'homeshieldOption',
      'installationOption',
    ]),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'sku',
      'basketPayload',
      'defaultHubOption',
      'defaultPetFriendlyOption',
      'defaultHomeshieldOption',
      'defaultInstallationOption',
    ]),
    withHub() {
      return this.hubOption === this.defaultHubOption;
    },
    withoutPetFriendly() {
      return this.petFriendlyOption === this.defaultPetFriendlyOption;
    },
    withPetFriendly() {
      return !this.withoutPetFriendly;
    },
  },
  methods: {
    ...mapActions([
      'init',
      'setHubOption',
      'setPetFriendlyOption',
      'setHomeshieldOption',
      'setInstallationOption',
    ]),
    swapSku() {
      // do nothing
    },
    assignQuantity() {
      // do nothing
    },
  },
  created() {
    const {
      packSkuMappings,
      showInstallationPageAfterPurchase,
      homeshieldSku,
      freeHomeshieldSku,
      playbackSku,
      baseInstallationOption,
      baseProfessionalInstallationSku,
    } = this;

    this.init({
      packSkuMappings,
      showInstallationPageAfterPurchase,
      homeshieldSku,
      freeHomeshieldSku,
      playbackSku,
      installationOption: baseInstallationOption,
      professionalInstallationSku: baseProfessionalInstallationSku,
    });
  },
  components: {
    HoneyTrustPilot,
    TrustPilotPopup,
    HoneyVideoPlayback,
    HoneyPackItem,
    HoneyOptionSelector,
  },
};

export default component;
