import ModalContainer from 'components/base/modal_container';
import GlobalHiveLoader from 'components/sections/global_hive_loader';
import QuizModal from 'components/views/honey/quiz/modal.vue';
import BasketRemovalConfirmation from 'components/views/basket_removal_confirmation';
import BasketSummary from 'components/views/basket_summary';
import BasketSummaryTable from 'components/views/basket_summary_table';
import BuyHubCompatibilityModal from 'components/views/honey/buy_hub_compatibility_modal';
import HomeshieldStarterHomePackdModal from 'components/views/honey/homeshield_starter_home_pack_modal';

const instance = {
  el: '#vue-modal-instance',
  name: 'modal',
  components: {
    'basket-removal-confirmation': BasketRemovalConfirmation,
    'basket-summary': BasketSummary,
    'basket-summary-table': BasketSummaryTable,
    'buy-hub-compatibility-modal': BuyHubCompatibilityModal,
    'modal-container': ModalContainer,
    'global-hive-loader': GlobalHiveLoader,
    'quiz-modal': QuizModal,
    'homeshield-starter-home-pack-modal': HomeshieldStarterHomePackdModal,
  },
};

export default instance;
