/* eslint-disable */
// The only page where it seems to be used is /ca/packs/hive-close-to-home-pack-black/buy

import Utils from './utils';

class VideoPlaybackSubscription {
  constructor() {
    this.el =
      {addToCart: '.js-add-to-basket-form'};
  }

  updateBuyButtons(valueToChange, action) {
    return $(this.el.addToCart).toArray().map((i) =>
      this.updateBuyButton($(i), valueToChange, action));
  }

  updateBuyButton(el, valueToChange, action) {
    const url = el.attr('action');
    const oldValue = Utils.getUrlParamVal('sku', url);
    const value = action === 'add' ?
              oldValue + valueToChange
            :
              oldValue.replace(valueToChange, '');

    return el.attr('action', Utils.changeParam(url, 'sku', value));
  }
}

const videoPlaybackSubscription = new VideoPlaybackSubscription;

$(document).on('change', 'input.js-playback-radio', function() {
  const valueToChange = $(this).val();
  const { action } =  $(this).data();
  return videoPlaybackSubscription.updateBuyButtons(valueToChange, action);
});
