import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('basket');
const { mapState: mapCheckoutState } = createNamespacedHelpers('checkout');

const component = {
  computed: {
    ...mapState([
      'basket',
    ]),
    ...mapCheckoutState(['paymentMethod']),
    oneOffVisible() {
      const {
        byoRequestedToBeBundled,
        oneOffTotal,
      } = this.basket;

      if (byoRequestedToBeBundled) return false;

      return oneOffTotal > 0;
    },
    v12Selected() {
      return this.paymentMethod === 'v12';
    },
    payingToday() {
      return this.v12Selected ? 0 : this.basket.upfrontTotal;
    },
  },
};
export default component;
