<template>
  <quiz-chat-button-animation v-bind:enabled="animate">
    <div class="row justify-content-end pb-2">
      <button
        type="button"
        class="btn btn-sm"
        v-bind:class="classes"
        v-on:click="$emit('select')"
      >
        <slot />
      </button>
    </div>
  </quiz-chat-button-animation>
</template>

<script>
import QuizChatButtonAnimation from './chat_button_animation';

const component = {
  props: {
    animate: Boolean,
    classes: { type: String, default: 'btn-secondary' },
  },
  components: {
    QuizChatButtonAnimation,
  },
};

export default component;
</script>
