import INSTALLATION_TYPE from 'store/modules/product_buy/constants';

// flags to check what security pack widget state is
// ex: if the switchKey is mixed product will include both indoor and outdoor
// ex: if the switchKey is outdoor: product should not include indoor
// ex: if the switchKey is indoor: product should not include outdoor
// ex: if no switchKey exists assume some convenient value

// pack should be either mixed or outdoor - returns true by default
function securityPackHasOutdoor(productState) {
  const widget = productState.widgets['v3-security-pack-type'] || null;
  if (widget) {
    return (widget.switchKey || 'mixed') !== 'indoor';
  }
  return true;
}

// pack should be either mixed or indoor - returns true by default
function securityPackHasIndoor(productState) {
  const widget = productState.widgets['v3-security-pack-type'] || null;
  if (widget) {
    return (widget.switchKey || 'mixed') !== 'outdoor';
  }
  return true;
}

// pack should be mixed - returns true by default
function securityPackIsMixed(productState) {
  const widget = productState.widgets['v3-security-pack-type'] || null;
  if (widget) {
    return (widget.switchKey || 'mixed') === 'mixed';
  }
  // TODO(ebrooks): Verify that this change won't break other things
  return false;
}

const getters = {
  buyButtonAvailable: (state) => {
    const opts = state.options;
    const optionsThatMustBeTrue = [
      'hasProductInstallationRequirement',
      'hasVideoPlaybackRequirement',
      'hasThermostatCompatibilityRequirement',
      'hasSecurityPackTypeRequirement',
      'hasProductHubHublessRequirement',
      'hasCameraCountRequirement',
      'hasProductBulbFittingRequirement',
      'hasProductColorRequirement',
    ];

    if (opts.heatingProductInstallationType === INSTALLATION_TYPE.SELF) {
      optionsThatMustBeTrue.push('hasWaterTankRequirement');
    }

    if (opts.heatingProductInstallationType === INSTALLATION_TYPE.PRO) {
      optionsThatMustBeTrue.push('hasBoilerAndCountyRequirement');
    }

    let isAvailable = true;
    optionsThatMustBeTrue.forEach((name) => {
      const value = opts[name];
      isAvailable = isAvailable && (value === undefined ? false : value);
      // Uncomment to debug what prop makes the button disabled
      // console.log(`${name} => ${value}`);
      return isAvailable;
    });

    return isAvailable;
  },
  additionalHeatingZoneAvailable: ({ options }) => {
    const {
      heatingProductInstallationType,
      hasProductInstallationRequirement,
      hasWaterTankRequirement,
    } = options;

    return hasProductInstallationRequirement
      && (heatingProductInstallationType === INSTALLATION_TYPE.PRO || hasWaterTankRequirement);
  },
  productInstallationAvailable: (state) => {
    const opts = state.options;

    const proInstallProduct = opts.hasVideoPlaybackRequirement
      && opts.hasThermostatCompatibilityRequirement;

    const proInstallRequest = opts.hasSecurityPackTypeRequirement
      && opts.hasProductHubHublessRequirement
      && opts.hasCameraCountRequirement
      && opts.hasProductColorRequirement
      && opts.hasProductBulbFittingRequirement
      && securityPackHasOutdoor(state);

    return proInstallRequest || proInstallProduct;
  },
  hotWaterTankAvailable: ({ options }) => {
    const {
      heatingProductInstallationType,
      hasProductInstallationRequirement,
    } = options;

    return hasProductInstallationRequirement
      && heatingProductInstallationType !== INSTALLATION_TYPE.PRO;
  },
  hubHublessAvailable: ({ options }) => {
    const {
      heatingProductInstallationType,
      hasProductInstallationRequirement,
      hasSecurityPackTypeRequirement,
      hasWaterTankRequirement,
    } = options;

    const noInstallRequirement = hasProductInstallationRequirement === undefined;
    const proInstall = heatingProductInstallationType === INSTALLATION_TYPE.PRO;
    const selfInstall = heatingProductInstallationType === INSTALLATION_TYPE.SELF
      && hasWaterTankRequirement;
    const noInstall = heatingProductInstallationType === undefined;
    const installRequirementChosen = hasProductInstallationRequirement
      && (proInstall || selfInstall || noInstall);
    const noInstallOrInstallChosen = noInstallRequirement || installRequirementChosen;

    return hasSecurityPackTypeRequirement || noInstallOrInstallChosen;
  },
  hubDisplayPrice: (state) => {
    const widget = state.widgets['v3-hub-hubless'] || null;
    return widget ? widget.hubDisplayPrice : 0;
  },
  hubDisplayPriceWithoutSavings: (state) => {
    const widget = state.widgets['v3-hub-hubless'] || null;
    return widget ? widget.hubDisplayPriceWithoutSavings : 0;
  },
  cameraCountAvailable: (state) => {
    const opts = state.options;

    return opts.hasSecurityPackTypeRequirement
      && opts.hasProductHubHublessRequirement
      && !securityPackIsMixed(state);
  },
  hasTwinCameraSelection: ({ options }) => {
    const {
      cameraCountSelection,
      hasCameraCountRequirement,
    } = options;

    return hasCameraCountRequirement
      && cameraCountSelection === 'two_cameras';
  },
  productMultipackAvailable: ({ options }) => options.hasProductBulbFittingRequirement,
  productColorAvailable: (state) => {
    const opts = state.options;

    return opts.hasVideoPlaybackRequirement
      && opts.hasSecurityPackTypeRequirement
      && opts.hasProductHubHublessRequirement
      && opts.hasCameraCountRequirement
      && securityPackHasIndoor(state);
  },
  productBulbFittingAvailable: ({ options }) => {
    const result = options.hasSecurityPackTypeRequirement
      && options.hasProductHubHublessRequirement
      && options.hasCameraCountRequirement
      && options.hasProductColorRequirement;

    return result;
  },
  securityPackInstallationAvailable: (state) => {
    const opts = state.options;

    const proInstallRequest = opts.hasSecurityPackTypeRequirement
      && opts.hasProductHubHublessRequirement
      && opts.hasCameraCountRequirement
      && opts.hasProductBulbFittingRequirement
      && securityPackHasOutdoor(state);

    return proInstallRequest;
  },
  productSecurityPackType: (state) => {
    const widget = state.widgets['v3-security-pack-type'] || null;
    return widget ? widget.switchKey : 'mixed';
  },
  resetColourSelectionWidget: (state) => {
    const widget = state.widgets['v3-colour-selection'] || null;
    return widget ? widget.resetWidget : false;
  },
  resetCameraCountWidget: (state) => {
    const widget = state.widgets['v3-camera-count'] || null;
    return widget ? widget.resetWidget : false;
  },
  resetInstallationWidget: (state) => {
    const widget = state.widgets['v3-product-installation']
      || state.widgets['v3-security-installation'] || null;
    return widget ? widget.resetWidget : false;
  },
};

export default getters;
