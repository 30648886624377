import Vue from 'vue/dist/vue.esm';

const module = {
  namespaced: true,
  state: {
    isOpen: false,
    includeTrackingScripts: false,
    includeAdobeDtmScript: true,
    debugUIWidgets: false,
    abTests: {},
    reloading: false,
  },
  mutations: {
    enableABTest(state, test) {
      Vue.set(state, 'abTests', {
        ...state.abTests,
        [test]: true,
      });
    },
    disableABTest(state, test) {
      Vue.set(state, 'abTests', {
        ...state.abTests,
        [test]: false,
      });
    },
    change(state, { property, value }) {
      Vue.set(state, property, value);
    },
    init(state, payload) {
      Vue.set(state, 'includeTrackingScripts', payload.includeTrackingScripts);
      Vue.set(state, 'includeAdobeDtmScript', payload.includeAdobeDtmScript);
      Vue.set(state, 'debugUIWidgets', payload.debugUIWidgets);
      Vue.set(state, 'abTests', payload.abTests);
    },
    reload(state) {
      Vue.set(state, 'reloading', true);
    },
  },
  actions: {
    init({ commit }, payload) {
      commit('init', payload);
    },
    reload({ commit }, newState) {
      commit('reload', newState);
      window.location.reload();
    },
    toggleDebugUIWidgets({ commit, state }) {
      const newValue = !state.debugUIWidgets;
      if (newValue) {
        const widgets = $('[data-widget]');
        widgets.css('box-shadow', 'inset 0 0 0 1px red');
        widgets.each((i, e) => {
          console.log(e); // eslint-disable-line no-console
          const elem = ['<small class="debug-ui text-danger">', $(e).data('widget'), '</small>'].join('');
          $(e).prepend(elem);
        });
      } else {
        $('[data-widget]').css('box-shadow', 'none');
        $('.debug-ui').remove();
      }
      commit('change', { property: 'debugUIWidgets', value: newValue });
    },
    toggleAdobeDtmScript({ commit, dispatch, state }) {
      const newValue = !state.includeAdobeDtmScript;
      document.cookie = `disable_adobe_dtm_script=${newValue ? '0' : '1'}`;
      commit('change', { property: 'includeAdobeDtmScript', value: newValue });
      dispatch('reload');
    },
    toggleIncludeTrackingScripts({ commit, dispatch, state }) {
      const newValue = !state.includeTrackingScripts;
      document.cookie = `disable_tracking_scripts=${newValue ? '0' : '1'}`;
      commit('change', { property: 'includeTrackingScripts', value: newValue });
      dispatch('reload');
    },
    toggleABTest({ commit, dispatch, state }, test) {
      const newValue = !state.abTests[test];
      if (newValue) {
        commit('enableABTest', test);
        // eslint-disable-next-line no-console
        console.log(`enable AB test: ${test}`);
        if (window.AB && window.AB[test]) {
          window.AB[test].enable();
        }
      } else {
        commit('disableABTest', test);
        // eslint-disable-next-line no-console
        console.log(`disable AB test: ${test}`);
        if (window.AB && window.AB[test]) {
          window.AB[test].disable();
        }
      }
      dispatch('reload');
    },
    toggle({ commit, state }) {
      const newValue = !state.isOpen;
      commit('change', { property: 'isOpen', value: newValue });
    },
  },
};

export default module;
