import { createNamespacedHelpers } from 'vuex';

const mapProductGetters = createNamespacedHelpers('productBuy').mapGetters;
const mapBasketState = createNamespacedHelpers('basket').mapState;

const component = {
  props: {
    loadingLabel: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    switchToSku: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapProductGetters(['buyButtonAvailable']),
    ...mapBasketState({
      buyButtonDisabled(state) {
        return !this.buyButtonAvailable || state.updating || state.initializing;
      },
    }),
  },
  methods: {
    switchSku() {
      if (this.switchToSku) {
        this.emitAddToBasket();
        return;
      }

      this.$store.dispatch(
        'productBuy/changeSku',
        {
          sku: this.switchToSku,
          callback: this.emitAddToBasket,
        }
      );
    },
    emitAddToBasket() {
      this.$emit('add-to-basket');
    },
  },
};
export default component;
