<template>
  <div id="code-validator">
    <CodeInput
      class="code-validator-container"
      v-bind:field-width="45"
      v-bind:field-height="48"
      require="true"
      v-bind:loading="false"
      v-on:change="onChange"
    />
  </div>
</template>

<script>
import CodeInput from 'vue-verification-code-input';

export default {
  methods: {
    onChange(val) {
      this.$emit('codechange', val);
    },
  },
  components: {
    CodeInput,
  },
};
</script>
