import ZuoraHostedPage from 'components/views/zuora_hosted_page';
import Multipage from 'mixins/multipage';

const component = {
  inject: ['client'],
  mixins: [Multipage],
  methods: {
    onZuoraSuccess(paymentMethodId) {
      return this.client.setDefaultPaymentMethod({ paymentMethodId }, {})
        .then(() => {
          this.currentPage = 'success';
        })
        .catch(() => {
          this.currentPage = 'error';
        });
    },
  },
  components: {
    ZuoraHostedPage,
  },
};

export default component;
