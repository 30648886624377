import Vue from 'vue/dist/vue.esm';
import { baseMutations, baseGetters, makeBaseActions } from '../common_buy_page';

const PROFESSIONAL_INSTALLATION = 'professional';

const mutations = {
  ...baseMutations,
  setShowInstallationPage(state, value) {
    Vue.set(state, 'showInstallationPage', value);
  },
  setIncludeRequirementsInBasket(state, value) {
    Vue.set(state, 'includeRequirementsInBasket', value);
  },
};

const moduleGetters = {
  ...baseGetters,
  pricingPayload(state) {
    const {
      sku,
      quantity,
      showInstallationPage,
      includeRequirementsInBasket,
      homeshieldSku,
    } = state;

    const products = [{ sku, quantity }];

    if (showInstallationPage || includeRequirementsInBasket) {
      products.push({ sku: homeshieldSku, quantity: 1 });
    }

    return { products, sku };
  },
  basketPayload(state) {
    const {
      sku,
      quantity,
      homeshieldSku,
      showInstallationPage,
      includeRequirementsInBasket,
      installationOption,
      professionalInstallationSku,
    } = state;

    const skus = [{ sku, quantity }];

    if (showInstallationPage || includeRequirementsInBasket) {
      skus.push({ sku: homeshieldSku, quantity: 1 });
    }

    if (installationOption === PROFESSIONAL_INSTALLATION) {
      skus.push({ sku: professionalInstallationSku, quantity: 1 });
    }

    const product = { sku: skus };

    if (showInstallationPage) {
      product.showInstallationPage = showInstallationPage;
    }

    const productBuy = { product };

    return { productBuy, quantity: 1 };
  },
  sku(state) {
    return state.sku;
  },
};

const makeActions = ({ client }) => ({
  ...makeBaseActions({ client }),
  init({ commit, dispatch }, {
    sku,
    homeshieldSku,
    showInstallationPage,
    includeRequirementsInBasket,
    installationOption,
    professionalInstallationSku,
  }) {
    commit('setProductSku', { sku });
    commit('setProductSku', { homeshieldSku });
    commit('setShowInstallationPage', showInstallationPage);
    commit('setIncludeRequirementsInBasket', includeRequirementsInBasket);
    if (installationOption) commit('setInstallationOption', installationOption);
    if (professionalInstallationSku) commit('setProfessionalInstallationSku', professionalInstallationSku);

    // if initial value of installationOption is 'professional' then assume pro install widget
    // is in use. this loads the initial installation price and makes subsequent changes to
    // quantity also load new price
    if (installationOption === PROFESSIONAL_INSTALLATION) {
      dispatch('loadProfessionalInstallationPrice');
    }

    return dispatch('loadPricing');
  },
  setProductSku({ commit, dispatch }, sku) {
    commit('setProductSku', { sku });
    window.specEvents.push(`set-product-${sku}`);

    return dispatch('loadPricing', sku);
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      isPriceLoading: false,
      quantity: 1,
      sku: '',
      homeshieldSku: '',
      pricing: {},
      showInstallationPage: false,
      includeRequirementsInBasket: false,
    };
  },
  actions: makeActions(injections),
  getters: moduleGetters,
  mutations,
});

export default makeModule;
