import baseConfig from './shared/quantity_select';

const vuexModule = {
  ...baseConfig,
  getters: {
    productQty({ selected }) {
      if (selected === null) return 0;

      if (selected > 5) return 6;

      return selected;
    },
    motionSensorsQty(state, { productQty }) {
      return productQty;
    },
    contactSensorsQty(state, { productQty }) {
      return productQty;
    },
  },
};

export default vuexModule;
