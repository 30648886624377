<template>
  <span>
    <formatted-money v-bind:value="totalPrice" />
    <span v-if="isAnnual">per year</span>
    <span v-else>per month</span>
  </span>
</template>

<script>
const component = {
  props: {
    priceData: { type: Object, required: true },
  },
  computed: {
    isAnnual() {
      return this.priceData.annual;
    },
    totalPrice() {
      return this.priceData.totalPrice;
    },
  },
};

export default component;
</script>
