const component = {
  props: [],
  data() {
    return {
      selected: 0,
    };
  },
  methods: {
    setSelected(value) {
      this.selected = value;
    },
  },
};

export default component;
