/**
 * A `single-page-pagination` is a component that adds elements to the current page.
 */
const component = {
  props: {
    pageSize: {
      required: true,
      type: Number,
    },
    maxPage: {
      required: false,
      type: Number,
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  computed: {
    hasNextPage() {
      // if maxPage is not set, then assume results are potentially infinite
      return (typeof this.maxPage === 'undefined') || this.page < this.maxPage;
    },
    currentLength() {
      return (this.page + 1) * this.pageSize;
    },
  },
  methods: {
    showMore() {
      this.page = this.page + 1;
    },
  },
};

export default component;
