import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('productBuy');

// Products included section
const component = {
  computed: {
    ...mapState({
      items(state) {
        if (!state.widgets['v3-products-included']) {
          return {};
        }

        return state.widgets['v3-products-included'].items || {};
      },
    }),
  },
};

export default component;
