import Vue from 'vue/dist/vue.esm';

export const UPDATE_BASKET = {
  SUCCESS: 'UPDATE_BASKET_SUCCESS',
  PENDING: 'UPDATE_BASKET_PENDING',
  FAILURE: 'UPDATE_BASKET_FAILURE',
  OPTIMISTIC_UPDATE: 'UPDATE_BASKET_OPTIMISTICALLY',
  RELOAD: 'RELOAD_BASKET',
};

const mutations = {
  [UPDATE_BASKET.SUCCESS](state, { basket }) {
    const { cachedBasket } = state;
    Vue.set(state, 'updating', false);
    Vue.set(state, 'prevBasket', cachedBasket);
    Vue.set(state, 'basket', basket);
    Vue.set(state, 'cachedBasket', undefined);
    window.specEvents.push('basket.updated');
  },
  [UPDATE_BASKET.FAILURE](state, error) {
    Vue.set(state, 'updating', false);
    Vue.set(state, 'error', error);
    if (state.cachedBasket) {
      Vue.set(state, 'basket', state.cachedBasket);
      Vue.set(state, 'cachedBasket', undefined);
    }
  },
  [UPDATE_BASKET.PENDING](state) {
    Vue.set(state, 'updating', true);
  },
  [UPDATE_BASKET.OPTIMISTIC_UPDATE](state, optimisticUpdate) {
    Vue.set(state, 'cachedBasket', state.basket);
    Vue.set(state, 'basket', optimisticUpdate);
  },
  [UPDATE_BASKET.RELOAD](state, newBasketState) {
    Vue.set(state, 'basket', newBasketState);
  },
};

export default mutations;
