import VideoInPhone from 'components/views/video_in_phone';
import Carousel from 'components/views/carousel';
import TrustPilotCards from 'components/views/trust_pilot_cards';
import OverviewFeatureCard from 'components/views/overview_feature_card';

const component = {
  inject: ['jQuery', 'dataLayer'],
  components: {
    'video-in-phone': VideoInPhone,
    'owl-carousel': Carousel,
    'trust-pilot-cards': TrustPilotCards,
    'overview-feature-card': OverviewFeatureCard,
  },
  methods: {
    onFaqLinkClicked({ targetId }) {
      this.jQuery(`#${targetId}`).parent().find('h5.toggled').siblings()
        .collapse('toggle');
      this.jQuery(`#${targetId} div`).collapse('toggle');
    },
  },
  mounted() {
    this.dataLayer.productClickFromList();
  },
};

export default component;
