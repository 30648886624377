import Vue from 'vue/dist/vue.esm';

export const STATUSES = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  PENDING: 'PENDING',
};

export const APPEND_ARTICLES = 'APPEND_ARTICLES';
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';

export const getters = {
  leadingArticle: ({ articles }) => articles[0],
  floatingArticles: ({ articles }) => articles.slice(1, 3),
  wideArticles: ({ articles }) => articles.slice(3, 5),
  normalArticles: ({ articles }) => articles.slice(5),
  hasMoreArticles: ({ pagination }) => !pagination.lastPage,
  isPending: ({ status }) => status === STATUSES.PENDING,
};

export const mutations = {
  [STATUSES.SUCCESS](state) {
    Vue.set(state, 'status', STATUSES.SUCCESS);
  },
  [STATUSES.FAILURE](state) {
    Vue.set(state, 'status', STATUSES.FAILURE);
  },
  [STATUSES.PENDING](state) {
    Vue.set(state, 'status', STATUSES.PENDING);
  },
  [APPEND_ARTICLES](state, articles) {
    state.articles.push(...articles);
  },
  [UPDATE_PAGINATION](state, pagination) {
    Vue.set(state, 'pagination', pagination);
  },
};

const makeActions = ({ client }) => ({
  loadMore({ commit, state, getters }) { // eslint-disable-line no-shadow
    if (getters.isPending) { return false; }

    const { slug, pagination: { page } } = state;

    commit(STATUSES.PENDING);

    return client.getDiscoverHiveArticles({ hubSlug: slug, page: page + 1 })
      .then(({ articles, pagination }) => {
        commit(STATUSES.SUCCESS);
        commit(APPEND_ARTICLES, articles);
        commit(UPDATE_PAGINATION, pagination);
      })
      .catch((error) => {
        commit(STATUSES.FAILURE, error);
      });
  },
});

const makeModule = (injections) => {
  const actions = makeActions(injections);

  return {
    namespaced: true,
    state: {
      slug: '',
      articles: [],
      pagination: {
        page: 1,
        lastPage: true,
      },
      status: '',
    },
    getters,
    mutations,
    actions,
  };
};

export default makeModule;
