import baseConfig from './shared/quantity_select';

const vuexModule = {
  ...baseConfig,
  getters: {
    motionSensorsQty({ selected }) {
      switch (selected) {
        case null: return 0;
        case 1: return 2;
        case 2: return 3;
        case 3: return 4;
        default: return 5;
      }
    },
  },
};

export default vuexModule;
