const component = {
  data() {
    return {
      isDisabled: true,
    };
  },
  methods: {
    onInput({ target }) {
      this.isDisabled = !target.value;
    },
  },
};

export default component;
