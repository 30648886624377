import UAParser from 'ua-parser-js';
import logger from 'utils/logger';
import { Notifier as AirbrakeClient } from '@airbrake/browser';

// Override Airbrake's handling of unhandled Promise
// rejections, so that our logs are not poluted with
// 3rd party script errors that generate this.
AirbrakeClient.prototype.onUnhandledrejection = (e) => {
  logger.debug(`AirbrakeClient.prototype.onUnhandledrejection(${e}) ignored.`);
};

const filterIE = (notice) => {
  const { userAgent } = notice.context;
  const { browser } = UAParser(userAgent);

  if (browser.name === 'IE') {
    logger.error(`Ignoring Internet Explorer JS exception: ${notice}`);
    return null;
  }

  return notice;
};

// This does not seem to work correctly, as exceptions like
// https://herokuapp74415294herokucom.airbrake.io/projects/153481/groups/2559463993382574398?tab=overview are still occuring.
const URL_FILTER_REGEX = /^(\S+-extension|webpack|file):\//;
const EXTERNAL_JS_FILTER_REGEX = /\/\/\S+(adobedtm|trustpilot)/;

const filterExternalScripts = (notice) => {
  const { backtrace } = notice.errors[0] || { backtrace: [] };
  const { file } = backtrace[0] || { file: '' };

  if (URL_FILTER_REGEX.test(file) || EXTERNAL_JS_FILTER_REGEX.test(file)) {
    logger.error(`Ignoring external JS exception: ${notice}`);
    return null;
  }

  return notice;
};

// This is a workaround for an error message that we cannot find the cause of atm
const filterImageErrors = (notice) => {
  const MESSAGES_TO_IGNORE = [
    "null is not an object (evaluating 'imageSrc.src')",
    "Cannot read property 'src' of null",
  ];

  if (notice.errors.find(e => MESSAGES_TO_IGNORE.find(m => e.message.includes(m)))) {
    return null;
  }

  return notice;
};

class ErrorReporter {
  constructor(opts) {
    const { projectId, projectKey } = opts;

    if (projectId && projectKey) {
      this.client = new AirbrakeClient(opts);
      this.client.addFilter(filterIE);
      this.client.addFilter(filterExternalScripts);
      this.client.addFilter(filterImageErrors);
    } else {
      this.client = { notify: () => {} };
    }
  }

  notify({ error, params }) {
    this.client.notify({ error, params });
  }
}

export default ErrorReporter;
