import Vue from 'vue/dist/vue.esm';

const PAYMENT_METHOD_TYPES = ['v12', 'creditCard'];

const mutations = {
  setPaymentMethod(state, value) {
    if (!PAYMENT_METHOD_TYPES.includes(value)) throw new Error(`Invalid payment method: ${value}`);
    Vue.set(state, 'paymentMethod', value);
  },
};

const makeActions = () => ({
  setPaymentMethod({ commit }, value) {
    commit('setPaymentMethod', value);
  },
});

const makeModule = injections => ({
  namespaced: true,
  state() {
    return {
      paymentMethod: null,
    };
  },
  actions: makeActions(injections),
  mutations,
});

export default makeModule;
