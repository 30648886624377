import AmountDiscountBadge from 'mixins/amount_discount_badge';
import TrustPilotPopup from 'components/views/trust_pilot_popup';
import HoneyPlaybackTiers from 'components/views/honey/playback_tiers';
import HoneyVideoPlayback from 'components/views/honey/video_playback';

import { createNamespacedHelpers } from 'vuex';
import AddToBasketButton from 'mixins/add_to_basket_button';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('pdpPlaybackBuyPage');
const actions = ['init', 'setPlayback', 'setPlaybackSku'];

const component = {
  inject: ['config'],
  mixins: [AmountDiscountBadge, AddToBasketButton],
  props: {
    annualPlaybacks: Object,
    monthlyPlaybacks: Object,
    playbackPrices: Object,
  },
  data() {
    return {
      basketInterval: null,
    };
  },
  computed: {
    ...mapState(['sku', 'isPriceLoading', 'playbackTier', 'playbackType']),
    ...mapGetters([
      'hasDiscount',
      'totalPrice',
      'monthlyPrice',
      'annualPrice',
      'recurringAnnualPrice',
      'totalDiscountedPrice',
      'totalDiscount',
      'basketPayload',
    ]),
    isFreeDeliveryVisible() {
      return this.totalDiscountedPrice > this.config.deliveryThreshold;
    },
  },
  created() {
    const { monthlyPlaybacks } = this;
    this.init({ sku: monthlyPlaybacks[1] });
  },
  beforeDestroy() {
    clearInterval(this.basketInterval);
  },
  methods: {
    ...mapActions(actions),
    setPlaybackType(type) {
      this.setPlayback({ type });
      this.setPlaybackSku(this[`${type}Playbacks`][this.playbackTier]);
    },
    setPlaybackTier(tier) {
      this.setPlayback({ tier });
      this.setPlaybackSku(this[`${this.playbackType}Playbacks`][tier]);
    },
  },
  components: {
    TrustPilotPopup,
    HoneyPlaybackTiers,
    HoneyVideoPlayback,
  },
};

export default component;
