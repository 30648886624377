import isEmpty from 'lodash/isEmpty';
import { createNamespacedHelpers } from 'vuex';
import Notifications from 'components/base/notifications';
import Multipage from 'mixins/multipage';
import ScrollToTop from 'mixins/scroll_to_top';
import MyAccountHeader from 'components/base/my_account_header';
import MyAccountAppointmentProduct from 'components/base/my_account_appointment_product';
import AddressBlock from 'components/base/address_block';
import AppointmentSchedulerCalendar from 'components/views/appointment_scheduler_calendar';
import webview from 'utils/webview';
import AppointmentScheduler from 'mixins/appointment_scheduler';

const { mapActions: mapGlobalActions } = createNamespacedHelpers('global');
const {
  mapState: mapCustomerState,
  mapGetters: mapCustomerGetters,
  mapActions: mapCustomerActions,
} = createNamespacedHelpers('customer');

const component = {
  inject: ['client', 'dataLayer'],
  mixins: [Multipage, ScrollToTop, AppointmentScheduler],
  props: {
    initAppointmentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentAppointment: {},
      currentSlots: [],
      loadingSlots: false,
      selectedSlot: {},
      saving: false,
      confirmation: false,
    };
  },
  created() {
    if (this.initAppointment) {
      this.showAppointmentDetails(this.initAppointment, false);
    } else {
      this.showListOrEmpty();
    }
    webview.ready();
  },
  computed: {
    ...mapCustomerState(['appointments']),
    ...mapCustomerGetters(['hasAppointments']),
    initAppointment() {
      if (!this.initAppointmentId) { return null; }

      return this.appointments.find(a => a.jobId === this.initAppointmentId);
    },
    isNoSelectedSlot() {
      return isEmpty(this.selectedSlot);
    },
    appointmentId() {
      return this.currentAppointment.jobId;
    },
  },
  methods: {
    ...mapGlobalActions(['showError']),
    ...mapCustomerActions(['updateAppointment']),
    showListOrEmpty() {
      this.showPage(this.hasAppointments ? 'list' : 'empty');
      this.currentAppointment = {};
      this.currentSlots = [];
      this.selectedSlot = {};
      this.confirmation = false;
    },
    showAppointmentDetails(appointment, triggerClickEvent = true) {
      if (triggerClickEvent) {
        this.dataLayer.manualClick({ moduleId: 'appointments', moduleItemId: appointment.jobId });
      }

      this.currentAppointment = { ...appointment };
      this.showPage('details');
    },
    editAppointment(appointment) {
      this.dataLayer.manualClick({ moduleId: 'appointments', moduleItemId: appointment.jobId });

      this.currentAppointment = { ...appointment };
      this.showPage('edit');
    },
    async getAvailableAppointments() {
      if (!isEmpty(this.currentSlots)) { return this.currentSlots; }

      return this.client.getAvailableAppointments({
        appointmentId: this.appointmentId,
      });
    },
    showCalendar() {
      if (!this.currentAppointment) { return; }

      this.loadingSlots = true;
      this.$modal.show('appointment-scheduler-calendar');

      this.getAvailableAppointments()
        .then((slots) => { this.currentSlots = slots; })
        .finally(() => { this.loadingSlots = false; });
    },
    selectSlot({ date, interval }) {
      const formattedDate = date.toISO8601();
      const currentSlot = this.currentSlots.find(item => item.date === formattedDate);
      const slot = { interval, date };
      if (currentSlot.team === 'Propcert' || currentSlot.team === 'PHjones') {
        // interval: am or pm
        slot.id = currentSlot[`${interval.toLowerCase()}SlotId`];
      }
      this.selectedSlot = slot;
      this.$modal.hide('appointment-scheduler-calendar');
    },
    saveChanges() {
      if (this.isNoSelectedSlot) { return; }

      const { date, interval, id } = this.selectedSlot;

      this.saving = true;
      this.updateAppointment({
        appointmentId: this.appointmentId,
        data: { date: date.toISO8601(), interval, id },
      })
        .then(() => { this.confirmation = true; })
        .catch(() => { this.showError("Couldn't reschedule the appointment."); })
        .finally(() => {
          this.scrollToTop();
          this.saving = false;
        });
    },
  },
  components: {
    MyAccountHeader,
    AddressBlock,
    MyAccountAppointmentProduct,
    Notifications,
    AppointmentSchedulerCalendar,
  },
};

export default component;
