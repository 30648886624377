import AddToBasketButton from 'mixins/add_to_basket_button';

export default {
  mixins: [AddToBasketButton],
  props: {
    ctaUrl: String,
    sku: String,
  },
  data() {
    return {
      totalPrice: 0,
      discountBadge: '',
      hasDiscount: false,
    };
  },
  methods: {
    onButtonClick() {
      window.location.href = this.ctaUrl;
    },
  },
};
