/**
 *
 * Add a css class on element click.
 * Useful for showing the loading icon on clicking
 *
 */
const directive = {
  bind(el, binding) {
    if (!binding.value) { return; }

    el.addEventListener('click', () => {
      el.classList.add(binding.value);
    });
  },
};

export default directive;
