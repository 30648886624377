import { createNamespacedHelpers } from 'vuex';
import get from 'lodash/get';
import Pricing from 'mixins/pricing';

const mapBasketState = createNamespacedHelpers('basket').mapState;
const { mapState } = createNamespacedHelpers('productBuy');

// Product buy page price display component
const component = {
  mixins: [Pricing],
  computed: {
    ...mapState({
      quantity(state) {
        return get(state, 'options.cameraQuantity', 1);
      },
    }),
    ...mapBasketState({
      currency: state => state.basket.currency,
      locale: state => state.basket.locale,
    }),
    nonZeroPrice() {
      return this.product.price > 0.0 || this.product.monthlyPrice > 0.0;
    },
    displayOneOffPrice() {
      return this.displayPrice !== 0 || this.monthlyPrice === 0;
    },
    displaySaving() {
      return this.product.saving;
    },
    displayMonthlySaving() {
      return this.product.monthlySaving;
    },
    displayPriceBeforeSaving() {
      const total = this.product.price * this.quantity;
      return this.priceComponents(total, true);
    },
    displayPrice() {
      const total = this.product.displayPrice * this.quantity;
      return this.priceComponents(total);
    },
    monthlyPrice() {
      return this.product.monthlyPrice;
    },
    displayMonthlyPrice() {
      return this.product.displayMonthlyPrice;
    },
    pricePrefix() {
      return this.product.pricePrefix;
    },
    priceDescription() {
      return this.product.priceDescription;
    },
  },
};

export default component;
