import { NumberFormat } from 'utils/intl';

const component = {
  inject: ['client', 'config'],
  props: {
    apiKey: { type: String, required: true },
  },
  data() {
    return {
      stars: 0,
      total: 0,
    };
  },
  created() {
    this.getBusinessUnitSummary();
  },
  methods: {
    getBusinessUnitSummary() {
      return this.client.trustpilotBusinessUnitSummary({
        apikey: this.apiKey,
      })
        .then((summary) => {
          this.stars = summary.score.stars;
          this.total = new NumberFormat(this.config.locale).format(summary.numberOfReviews.total);
        });
    },
  },
};

export default component;
