import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapState } = createNamespacedHelpers('simpleProductBuyPage');

const component = {
  computed: {
    ...mapState(['sku']),
  },
  methods: {
    ...mapActions([
      'setProductSku',
    ]),
    colourSkuSelected(colourSku) {
      return this.sku === colourSku;
    },
    switchColour(colourSku, slideIndex) {
      this.$emit('change-colour', colourSku);
      this.$emit('change-slide', slideIndex);
    },
  },
};

export default component;
