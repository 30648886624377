<template>
<div>
  <div
    class="VueCarousel-pagination"
    v-bind:class="{ [`VueCarousel-pagination--${carousel.paginationPositionModifierName}`]: carousel.paginationPositionModifierName }"
  >
    <div class="VueCarousel-dot-container" role="tablist" :style="carousel.dotContainerStyle">
      <button
        v-for="(page, index) in numberOfPages"
        :key="`${page}_${index}`"
        class="VueCarousel-dot"
        aria-hidden="false"
        role="tab"
        :title="index"
        :value="index"
        :aria-label="index"
        :aria-selected="isCurrentDot(index) ? 'true' : 'false'"
        v-bind:class="{ 'VueCarousel-dot--active': isCurrentDot(index) }"
      ></button>
    </div>
  </div>
  <div>
    <a href="/my/subscriptions"
      class="btn btn-block btn-blue mt-1">
      Keep my subscription
    </a>

    <button
      class="btn btn-block btn-secondary mt-1"
      :disabled="confirmationDisabled"
      @click="cancel">
      Confirm subscription cancellation
    </button>
  </div>
  <sub v-if="displaySlideWithFiguresMessage">Figures for demonstation only</sub>
  <sub v-if="displaySlideWithIllustrationMessage">Illustation for demonstation only</sub>
</div>
</template>

<script>

const SLIDE_WITH_FIGURES_MESSAGE = 1;
const SLIDE_WITH_ILLUSTRATION_MESSAGE = 2;

const component = {
  inject: ['carousel'],
  data() {
    return {
      confirmationDisabled: true,
      // displayCoolOffError: false,
      subscriptionCancelled: false,
    };
  },
  computed: {
    numberOfPages() {
      return this.carousel.pageCount;
    },
    carouselCurrentPage() {
      return this.carousel.currentPage;
    },
    displaySlideWithFiguresMessage() {
      return this.carouselCurrentPage === SLIDE_WITH_FIGURES_MESSAGE;
    },
    displaySlideWithIllustrationMessage() {
      return this.carouselCurrentPage === SLIDE_WITH_ILLUSTRATION_MESSAGE;
    },
  },
  methods: {
    isCurrentDot(index) {
      return index === this.carouselCurrentPage;
    },
    cancel() {
      this.confirmationDisabled = true;
      this.$emit('cancel');
    },
  },
  watch: {
    carouselCurrentPage(value) {
      if (value === 3) {
        this.confirmationDisabled = false;
      }
    },
  },
};

export default component;

</script>
